var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDatepicker } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../providers/report.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(translate, reportService, excel, router, snackBar) {
        this.translate = translate;
        this.reportService = reportService;
        this.excel = excel;
        this.router = router;
        this.snackBar = snackBar;
        this.startDate = null;
        this.endDate = null;
        this.minDate = new Date(2000, 0, 1);
        this.maxDate = new Date(2023, 0, 1);
        this.reportList = [
            { type: 'lsp', name: 'LSP' },
            { type: 'lspkpi', name: 'LSP KPI' },
            { type: 'perf', name: this.translate.instant('PERFORMANCE') },
            { type: 'oper', name: this.translate.instant('OPERATION') },
            { type: 'bat', name: 'Bateria' },
            { type: 'mdr', name: 'MDRs e DRs' },
            { type: 'stake', name: 'Stakeholders' },
            { type: 'cmdr', name: 'CMDR' },
            { type: 'cov', name: 'COV' },
            { type: 'poddp', name: 'POD DP' },
            { type: 'hub', name: 'Hub' },
            { type: 'dpunion', name: 'DP Union' },
            { type: 'uoc', name: 'UOC' },
        ];
        this.report = 'lsp';
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
    }
    ReportsComponent.prototype.ngOnInit = function () {
        if (this.role !== 1) {
            this.reportList = [{ type: 'stake', name: 'Stakeholders' }];
        }
    };
    ReportsComponent.prototype.download = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.startDate || !this.endDate) {
                    this.snackBar.open('Selecione as datas a consultar', 'Fechar', {
                        duration: 2000,
                    });
                    return [2 /*return*/];
                }
                switch (this.report) {
                    case 'stake':
                        {
                            this.reportService.getStakeholders(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'stake': worksheet }, SheetNames: ['stake'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'stake');
                            });
                        }
                        break;
                    case 'lsp':
                        {
                            this.reportService.getLSP(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'LSP': worksheet }, SheetNames: ['LSP'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'lsp');
                            });
                        }
                        break;
                    case 'lspkpi':
                        {
                            this.reportService.getLSPKPI(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'KPI': worksheet }, SheetNames: ['KPI'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'lspkpi');
                            });
                        }
                        break;
                    case 'perf':
                        {
                            this.reportService.getPerfLSP(this.startDate, this.endDate).subscribe(function (data) {
                                var sheets = {};
                                var names = [];
                                for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
                                    var title = _a[_i];
                                    sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                        skipHeader: true
                                    });
                                    names.push(title);
                                }
                                var workbook = { Sheets: sheets, SheetNames: names };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'perf');
                            });
                        }
                        break;
                    case 'oper':
                        {
                            this.reportService.getOperation(this.startDate, this.endDate).subscribe(function (data) {
                                var sheets = {};
                                var names = [];
                                for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
                                    var title = _a[_i];
                                    sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                        skipHeader: true
                                    });
                                    names.push(title);
                                }
                                var workbook = { Sheets: sheets, SheetNames: names };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'oper');
                            });
                        }
                        break;
                    case 'bat':
                        {
                            this.reportService.getLSP(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'LSP': worksheet }, SheetNames: ['LSP'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'bat');
                            });
                        }
                        break;
                    case 'cmdr':
                        {
                            this.reportService.getCMDR(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'CMDR': worksheet }, SheetNames: ['CMDR'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'cmdr');
                            });
                        }
                        break;
                    case 'cov':
                        {
                            this.reportService.getCOV(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'COV': worksheet }, SheetNames: ['COV'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'cov');
                            });
                        }
                        break;
                    case 'poddp':
                        {
                            this.reportService.getPODDP(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'PODDP': worksheet }, SheetNames: ['PODDP'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'poddp');
                            });
                        }
                        break;
                    case 'hub':
                        {
                            this.reportService.getHub(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'Hub': worksheet }, SheetNames: ['Hub'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'hub');
                            });
                        }
                        break;
                    case 'dpunion':
                        {
                            this.reportService.getDPUnion(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'DPUnion': worksheet }, SheetNames: ['DPUnion'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'dpunion');
                            });
                        }
                        break;
                    case 'uoc':
                        {
                            this.reportService.getUOC(this.startDate, this.endDate).subscribe(function (data) {
                                var worksheet = XLSX.utils.json_to_sheet(data, {
                                    skipHeader: true
                                });
                                var workbook = { Sheets: { 'UOC': worksheet }, SheetNames: ['UOC'] };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'uoc');
                            });
                        }
                        break;
                    case 'mdr':
                        {
                            this.reportService.getCockpit(this.startDate, this.endDate).subscribe(function (data) {
                                var sheets = {};
                                var names = [];
                                for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
                                    var title = _a[_i];
                                    sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                                        skipHeader: true
                                    });
                                    names.push(title);
                                }
                                var workbook = { Sheets: sheets, SheetNames: names };
                                var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                                _this.excel.saveAsExcelFile(excelBuffer, 'mdr-dr');
                            });
                        }
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    return ReportsComponent;
}());
export { ReportsComponent };
