import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';

import { TowerControlComponent } from './tower-control.component';

const routes: Routes = [
  { path: 'control-tower', component: TowerControlComponent, canActivate: [RoleGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TowerControlRoutingModule { }
