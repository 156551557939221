import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
import { parseString } from 'xml2js';
import {NF} from '../../models/nfe';
import {NfeService} from '../../providers/nfe.service';
import {ClientUserService} from '../../providers/client-user.service';

@Component({
  selector: 'app-client-user',
  templateUrl: './client-user.component.html',
  styleUrls: ['./client-user.component.scss']
})

export class ClientUserComponent implements OnInit {
  public searchControl: FormControl;
  displayedColumns3 = ['name', 'clientId', 'remove'];
  dataSourceNF = new MatTableDataSource<any>();
  role: number;
  newNfList: NF[] = [];
  opened: any;
  selectedClient: any;
  selectedTp: any;
  clientList = [];
  tpList = [];

  constructor(public dialog: MatDialog, public clientService: ClientUserService,
              public router: Router, public drService: DrService,
              public snackBar: MatSnackBar) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    this.clientService.getClients().subscribe((data) => {
      this.clientList = data;
      // console.log(data);
    });
    this.clientService.getTpUsers().subscribe( (data) => {
      this.tpList = data;
    });

    this.clientService.getAll().subscribe( (data) => {
      // console.log(data);
      this.dataSourceNF.data = data;
    });
   }

  ngOnInit() {
  }

  addNewRule() {
    // console.log(this.selectedClient);
    // console.log(this.selectedTp);
    this.clientService.create(this.selectedClient, this.selectedTp).subscribe( (data) => {
      this.clientService.getAll().subscribe( (el) => {
        this.dataSourceNF.data = el;
      });
    });
  }

  remove(el: any) {
    this.clientService.remove(el.id).subscribe((data) => {
      // console.log(data);
      this.clientService.getAll().subscribe( (k) => {
        this.dataSourceNF.data = k;
      });
    });
  }
}
