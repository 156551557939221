import { OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
var ImageLightboxComponent = /** @class */ (function () {
    function ImageLightboxComponent(_lightbox) {
        this._lightbox = _lightbox;
        this._albums = [];
        this.imageExtensions = ['png', 'jpeg', 'jpg', 'gif'];
    }
    ImageLightboxComponent.prototype.open = function (index) {
        // override the default config
        this._lightbox.open(this._albums, index);
    };
    ImageLightboxComponent.prototype.open_tab = function (index) {
        window.open(this._albums[index].src, '_blank');
    };
    ImageLightboxComponent.prototype.close = function () {
        // close lightbox programmatically
        this._lightbox.close();
    };
    ImageLightboxComponent.prototype.ngOnInit = function () {
        for (var i = 0; i < this.images.length; i++) {
            var src = this.images[i].url;
            var id = this.images[i].id;
            var thumb = this.images[i].url;
            var album = {
                src: src,
                thumb: thumb
            };
            this._albums.push(album);
        }
    };
    return ImageLightboxComponent;
}());
export { ImageLightboxComponent };
