import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {MDR} from '../models/mdr';
@Injectable()
export class MdrObservable {
    private mdr = new  Subject<MDR>();
    currentMDR = this.mdr.asObservable();

    changeMDR(getMDR: MDR) {
      this.mdr.next(getMDR);
      // console.log(this.mdr);
    }


}
