import {Component, OnInit, ViewChild, Inject, ElementRef, ViewChildren, QueryList, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { interval, forkJoin, of } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material';
import {Router} from '@angular/router';

import {DrService} from '../../providers/dr.service';
import {MdrService} from '../../providers/mdr.service';
import { SocketService } from '../../providers/socket.service';

import { Event } from '../../models/events';

import {DrCardComponent} from './dr-card/dr-card.component';

import { ExcelService } from '../../misc/export-xlsx/export-xlsx';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DR } from '../../models/dr';

import {CookieService} from 'ngx-cookie-service';
import { ConfigurationService } from '../../providers/configuration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit {
  undefinedStatus = 'Outros Status';
  displayList: string[] = [
    'BOOKING',
    'BOOKING_LSP',
    'PACKING_AVAILABLE',
    'WAITING_LSP',
    'RETURNED_BY_LSP',
    'READY_TO_PICKUP',
    'InTransit',
    'Delivered',
    'Waiting ASP',
    'HubReturn',
    'WITHHELD',
    'APPROVED_BY_LSP'
  ];
  statusList: string[] = ['BOOKING',
                          'BOOKINGLSP',
                          'Alistado',
                          'Waiting LSP',
                          'Approved by LSP',
                          'Returned by LSP',
                          'READY_TO_PICKUP',
                          'InTransit',
                          'Delivered',
                          'Replan - Antecipação',
                          'Replan - Postergação',
                          'Replan - Pernoite',
                          'Replan - Retido',
                          'Waiting ASP',
                          'Replan - Sefaz',
                          'HubReturn'
  ]; // todas as strings aqui deve estar presentes em statusNameMap !!
  statusNameMap = {
    'BOOKING' : 'BOOKING',
    'BOOKINGLSP' : 'BOOKING_LSP',
    'Alistado' : 'PACKING_AVAILABLE',
    'Waiting LSP' : 'WAITING_LSP',
    'Approved by LSP' : 'APPROVED_BY_LSP',
    'Returned by LSP': 'RETURNED_BY_LSP',
    'Replan - Antecipação' : 'Retido',
    'Replan - Postergação' : 'Retido',
    'Replan - Retido' : 'Retido',
    'Replan - Pernoite' : 'InTransit',
    'Replan - Sefaz' : 'Retido',
    'Waiting ASP': 'Waiting ASP',
    'READY_TO_PICKUP' : 'READY_TO_PICKUP',
    'HubReturn' : 'HubReturn',
    'InTransit' : 'InTransit',
    'Delivered' : 'Delivered'
  }; // key = status do card ; value = coluna na qual o card será colocado

  drLists: any = {};
  colors: any = {};
  selectedColor: string = null;
  colorList = ['green', 'red', 'yellow', 'undefined'];
  colorStarter = {};
  @ViewChildren(DrCardComponent) drCardList: QueryList<DrCardComponent>;
  drList = [];

  searchDates: any[] = [ null, null ];

  @ViewChild('search')
  public searchElementRef: ElementRef;
  public searchChips: any[] = [];
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  searchTerm = '';
  validSearchFields: string[] = ['dr.seac', 'dr.idMDR', 'pickupID', 'dr.dateScheduling', 'dr.client', 'dr.site', 'dr.prolog', 'dr.deliveryAddress', 'shippingCompany', 'dr.hubName', 'dr.type', 'dr.createdBy', 'dr.unificationId'];
  public ioConnection: any;
  public role: number;
  public chatTypes: number[];
  public search_toggle = false;

  uid = 'cockpit-';

  constructor(
    private drService: DrService,
    private excelService: ExcelService,
    private socketService: SocketService,
    private translateService: TranslateService,
    public configService: ConfigurationService,
    public cookie: CookieService,
    public dialog: MatDialog,
    public pDate: DatePipe,
    public snackBar: MatSnackBar,
  ) {
    // inicializa cores
    this.colorList.forEach((color) => {
      this.colorStarter[color] = 0;
    });

    this.setDisplayList();
  }

  ngOnInit() {
    this.initIoConnection();

    const subscriptions = {
      cockpit: this.drService.getForCockpit().pipe(map((res) => res), catchError(e => of([]))),
    };

    if ( this.role === 1 || this.role === 2 || this.role === 7 ) {
      subscriptions['bookings'] = this.drService.getBookings().pipe(map((res) => res), catchError(e => of([])));
      subscriptions['bookingsLSP'] = this.drService.getBookingsLSP().pipe(map((res) => res), catchError(e => of([])));
    }

    forkJoin(subscriptions).subscribe((response: {cockpit?: any[], bookings?: any[], bookingsLSP?: any[]}) => {
      let data = [];
      if (response['cockpit'] && response['cockpit'].length > 0) {
        data = [].concat(data, response['cockpit']);
      }
      if (response['bookings'] && response['bookings'].length > 0) {
        data = [].concat(data, response['bookings']);
      }
      if (response['bookingsLSP'] && response['bookingsLSP'].length > 0) {
        data = [].concat(data, response['bookingsLSP']);
      }
      this.organizeData(data);
    });

  }

  public setDisplayList() {
    const role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.role = role;

    switch (role) {
      case 1: // ericsson
      case 2: // ericsson
      case 3: // ericsson
        this.chatTypes = [5, 6]; // motorista <-> transportadora ; embarcadora <-> transportadora
        this.initiateStatusList();
        break;

      case 5: // lsp
      case 12: // lsp
        this.displayList = [
          'WAITING_LSP',
          'RETURNED_BY_LSP',
          'READY_TO_PICKUP',
          'InTransit',
          'Delivered',
          'HubReturn',
          'WITHHELD',
          'APPROVED_BY_LSP'
        ];
        this.chatTypes = [6]; // embarcadora <-> transportadora
        this.initiateStatusList();
        break;

      case 7:
        this.displayList = [
          'BOOKING',
          'BOOKING_LSP',
          'PACKING_AVAILABLE'
        ];
        this.initiateStatusList();
        break;

      default:
        this.configService.getConfiguration('hq-booking').subscribe( (resp) => {
          if (resp) {
            this.displayList = [
              'PACKING_AVAILABLE',
              'WAITING_LSP',
              'RETURNED_BY_LSP',
              'READY_TO_PICKUP',
              'InTransit',
              'Delivered',
              'Waiting ASP',
              'HubReturn',
              'WITHHELD',
              'APPROVED_BY_LSP'
            ];
          } else {
            this.displayList = [
              'WAITING_LSP',
              'RETURNED_BY_LSP',
              'READY_TO_PICKUP',
              'InTransit',
              'Delivered',
              'HubReturn',
              'WITHHELD',
              'APPROVED_BY_LSP'
            ];
          }
          this.initiateStatusList();
        });
    }
  }

  public initiateStatusList() {
    const statusList = [].concat(this.displayList, this.undefinedStatus);
    statusList.forEach( (el, i, arr) => {
      // inicializa drLists
      this.drLists[el] = [];

      // incializa colors
      this.colors[el] = Object.assign({}, this.colorStarter);
    });
  }

  public checkDrCards() {
    const sleepAmount = 500; // 0.5s
    if ( this.drList.length > 0
     && this.drList.length === this.drCardList.length ) {
      this.loadSearches();
    } else {
      setTimeout(() => {this.checkDrCards(); }, sleepAmount);
    }
  }

  public getStatusName(status: string) {
    if ( this.statusList.indexOf(status) !== -1 ) {
      return this.statusNameMap[status];
    } else {
      return this.undefinedStatus;
    }
  }

  private initIoConnection(): void {
    this.socketService.initSocket('cockpit');

    this.ioConnection = this.socketService.onEvent(Event.UPDATE_DR)
                            .subscribe((data) => {
                              if (data.what === 'dr created') { // o backend não retorna valores se a DR não puder ser lida pelo usuário logado
                                this.drService.getById(data.status.id).subscribe( (dr) => {
                                  this.drLists[this.getStatusName(dr.status)].push(dr);
                                });
                              } else if (data.what === 'dr updated') { // atualiza somente se o card antigo já estiver sendo mostrado
                                let oldStatus = '';
                                // encontra a coluna do card antigo
                                this.displayList.forEach( (el, i, arr) => {
                                  if (this.drLists[el].map((e) => e.id).indexOf(data.status.id) !== -1) {
                                    oldStatus = el;
                                  }
                                });
                                const position = this.drLists[this.getStatusName(oldStatus)].map((e) => e.id).indexOf(data.status.id);
                                if (oldStatus !== '') {
                                  // remove o card antigo
                                  this.drLists[this.getStatusName(oldStatus)].splice(position, 1);
                                  // adiciona o card novo
                                  this.drLists[this.getStatusName(data.status.status)].unshift(data.status);
                                }
                              } else if ( data.what === 'new occurrence') { // atualiza somente se o card antigo já estiver sendo mostrado
                                let oldStatus = '';
                                // encontra a coluna do card antigo
                                this.displayList.forEach( (el, i, arr) => {
                                  if (this.drLists[el].map((e) => e.id).indexOf(data.status.id) !== -1) {
                                    oldStatus = el;
                                  }
                                });
                                const position = this.drLists[this.getStatusName(data.status.status)].map((e) => e.id).indexOf(data.status.id);
                                if (oldStatus !== '') {
                                  // remove o card antigo
                                  this.drLists[this.getStatusName(oldStatus)].splice(position, 1);
                                  // adiciona o card novo
                                  this.drLists[this.getStatusName(data.status.status)].unshift(data.status);
                                }
                              } else if ( data.what === 'new message') { // não está sendo enviado pelo back
                                if (this.chatTypes.indexOf(Number(data.status.chatType)) !== -1 && data.status.userRole !== this.role) {
                                  // console.log('atualiza mensagens no card',data.status.mdrId);//DEBUG
                                  const updated = this.drCardList.find( (el, i, arr) => {
                                    if ( parseInt(el.dr.idMDR, 10) === parseInt(data.status.mdrId, 10) ) {
                                      return true;
                                    }
                                    return false;
                                  });
                                  updated.novasMensagens[data.status.chatType] = true;
                                }
                              }
                            });

    this.socketService.onEvent(Event.CONNECT)
        .subscribe(() => {
          // console.log('connected');
        });

    this.socketService.onEvent(Event.DISCONNECT)
        .subscribe(() => {
          // console.log('disconnected');
        });
  }

  organizeData(data) {
    // reseta contagem de cores
    this.resetColors();

    // pra cada DR
    data.forEach( (el, i, arr) => {

      // corrige campos que são Date
      ['dateScheduling'].forEach( (key) => {
        el[key] = new Date(el[key]);
      });
      el.hubName = el.hub ? el.hub.name : '';
      el.type = el.mdr ? el.mdr.type : null;
      el.seac = el.salesOrders[0];

      // setta dr.status de acordo com status da MDR associada
      if (el.status === 'READY_TO_PICKUP' && el.idMDR === null) {
        el.status = 'Alistado';
      } else if (el.mdr !== undefined) {
        if (el.mdr.status === 'MDR aguardando LSP') {
          el.status = 'Waiting LSP';
        } else if (el.mdr.status === 'MDR em criação' || el.mdr.status === 'MDR em validação') {
          el.status = 'Waiting LSP';
        } else if (el.mdr.status === 'Entrega de carga certificada pelo LSP') {
          el.status = 'Approved by LSP';
        } else if (el.mdr.status === 'MDR devolvida por LSP') {
          el.status = 'Returned by LSP';
        }
      }
      const status = this.getStatusName(el.status);

      // setta cor
      this.setStatusColor(el);

      // da push na coluna de acordo com mapa de status
      try {
        this.drLists[status].push(el);
      } catch (ex) {
        console.error(ex);
        this.drLists[status] = [el];
      }

      // atualiza cores
      this.colors[status][el.color]++;

    });

    // salva DRs para referencia
    this.drList = data;
    // aguarda DRs carregarem para carregar busca salva
    this.checkDrCards();

  }

  resetColors() {
    // inicializa contadores de cores
    const statusList = [].concat(this.displayList, this.undefinedStatus);
    statusList.forEach( (el, i, arr) => {
      this.colors[el] = Object.assign({}, this.colorStarter);
    });
  }

  setColor(color: string) {
    if (this.selectedColor === color) {
      this.selectedColor = null;
    } else {
      this.selectedColor = color;
    }
    return this.selectedColor;
  }

  getColor() {
    return this.selectedColor;
  }

  dateChanged() {
    this.applyFilter();
  }

  applyFilter() {
    this.saveSearches();

    // valores da busca
    const dates = {
      start: this.searchDates[0],
      end: this.searchDates[1]
    };
    const chips = this.searchChips;
    const chipsSearchFields = this.validSearchFields;

    // reseta cores
    this.resetColors();

    // pra cada <dr-card>
    this.drCardList.forEach( (drcard, i, arr) => {
      const dr = drcard.dr;

      // valida datas
      let checkDates = (searchDates) => true;
      if ( dates.start || dates.end ) {
        checkDates = (searchDates) => {
          const drTime = dr.dateScheduling.getTime();
          if ( searchDates.start && drTime < searchDates.start.getTime() ) {
            return false;
          }
          if ( searchDates.end && searchDates.end.getTime() <= drTime ) {
            return false;
          }
          return true;
        };
      }
      const displayFromDate = checkDates(dates);

      // valida chips
      let checkChip = (chip) => true;
      if ( chipsSearchFields.length ) {
        checkChip = (chip) => {
          return chipsSearchFields.some( (el) => {
            // se for um campo da dr
            if (el.indexOf('dr.') === 0) {
              el = el.split('.')[1];
              if (el in dr && dr[el] !== null) {
                if (typeof dr[el] === 'string' &&
                    dr[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof dr[el] === 'number' &&
                           dr[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            } else { // se for um campo do card
              if (drcard[el] !== null) {
                if (typeof drcard[el] === 'string' &&
                    drcard[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                  return true;
                } else if (typeof drcard[el] === 'number' &&
                           drcard[el] === parseInt(chip, 10)) {
                  return true;
                }
              }
              return false;
            }
          });
        };
      }
      const displayFromChips = this.search_toggle
                             ? chips.map( el => el.name ).some(checkChip)
                             : chips.map( el => el.name ).every(checkChip);

      // oculta/mostra <dr-card>
      drcard.show = displayFromChips && displayFromDate;

      // atualiza cores
      if (drcard.show) {
        this.colors[this.getStatusName(dr.status)][dr.color]++;
      }

    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      for (const text of value.split(',')) {
        this.searchChips.push({'name': text.trim()});
      }
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  trackByFn(index, item) {
    return index;
  }

  exportAsXlsx() {
    const list = [];
    const drs = [];
    this.drCardList.forEach((drCard) => {
      // console.log(drCard);
      if (drCard.show === true  && drCard.dr.status !== 'BOOKING') {
        const card = drCard;
        const dr = {};
        dr['client'] = card.dr.client;
        dr['dateScheduling'] = this.pDate.transform(card.dr.dateScheduling, 'dd/MM/yyyy HH:mm');
        dr['deliveryAddress'] = card.dr.deliveryAddress;
        dr['receiverName'] = card.dr.receiverName ? card.dr.receiverName : '';
        dr['supervisorName'] = card.dr.supervisorName ? card.dr.supervisorName : '';
        dr['pickupID'] = card.dr.mdr ?  card.dr.mdr.pickupID : '';
        dr['pickupAddress'] = card.dr.pickupAddress;
        dr['site'] = card.dr.site;
        dr['status'] = card.dr.status;
        dr['prolog'] = card.dr.prolog;
        dr['hub'] = card.dr.hub ? card.dr.hub.name : '' ;
        dr['SLA'] = card.dr.mdr ? card.dr.mdr.aeroDelivery : '';
        dr['createdBy'] = card.dr.createdBy;
        dr['Tecnologia'] = card.dr.salesOrders.join(', ');
        dr['Projecto'] = card.dr.unificationId;
        dr['model'] = card.dr.model;
        dr['dr type'] = card.dr.type;
        dr['driverPositionCreated'] = card.dr.driverPosition ? card.dr.driverPosition.createdAt : '';
        dr['shippingCompany'] = card.dr.mdr ? card.dr.mdr.shippingCompany : '';
        dr['OccurrenceLastStatus'] = card.dr.occurrences ? (card.dr.occurrences.length > 0 ? card.dr.occurrences[0].status : '') : '';
        dr['mdrStatus'] = card.dr.mdr ? card.dr.mdr.status : '';
        dr['Vehicle'] = card.dr.mdr ? card.dr.mdr.vehicle : '';
        dr['Obs'] = card.dr.obs;
        drs.push(dr);
      }
    });
    this.excelService.exportAsExcelFile(drs, 'relatorios');
  }

  openMap(): void {
    const drList = this.drCardList.filter((e) => {
      return e.show === true && e.dr.status === 'InTransit';
    });
    const dialogRef = this.dialog.open(MapDialogComponent, {
      width: '800px',
      data: drList,
    });
  }

  setStatusColor(dr) {
    const tooltip = [];
    let color = 'green';
    const truckSaw = true;
    const hora = 1000 * 60 * 60;
    const now = Date.now();
    const novasMensagens = {
      5: false,
      6: false
    };

    if (dr === null) {
      return;
    }

    // se existe um caminhao para verificar
    // if ('status' in dr && dr.status === 'InTransit' ) {
    //   if ( !('driverPosition' in dr) || !dr.driverPosition || !dr.driverPosition.createdAt ) {
    //     // se o caminhao ainda nao foi visto
    //     // color = 'red';
    //     truckSaw = false;
    //     tooltip.push('caminhão não encontrado');
    //   } else {
    //     const d = new Date(dr.driverPosition.createdAt);
    //     if ( now - d.getTime() > 1 * hora ) {
    //       // se o caminhao nao é visto a mais de 1h
    //       // color = 'red';
    //       truckSaw = false;
    //       tooltip.push('caminhão não é visto a mais de 1 hora');
    //     } else if ( now - d.getTime() > 0.5 * hora ) {
    //       // se o caminhao nao é visto a mais de 30min
    //       // color = (color === 'red') ? color : 'yellow';
    //       tooltip.push('caminhão não é visto a mais de 30 minutos');
    //     }
    //   }
    // }

    // se está aguardando atualizações da LSP
    if ('status' in dr && dr.status === 'Waiting LSP') {
      const d = new Date(dr.createdAt);

      if (now - d.getTime() < 2 * hora) {
        // se a criação ocorreu a menos de 2h
        color = 'green';
      } else if (now - d.getTime() < 24 * hora) {
        // se a criação ocorreu entre 2 e 24 horas
        color = 'yellow';
        tooltip.push(this.translateService.instant('WAITING-LSP-CREATION-BETWEEN-2-AND-24'));
      } else {
        // se a criação ocorreu a mais de 2h
        color = 'red';
        tooltip.push(this.translateService.instant('WAITING-LSP-CREATION-MORE-THAN-24'));
      }
    }

    // se está pronto para coleta
    if ('status' in dr && dr.status === 'READY_TO_PICKUP' ) {
      if ('mdr.pickupDate' in dr && dr.mdr.pickupDate === null ) {
        color = 'undefined';
        tooltip.push('data de coleta ausente');
      } else {
        const d = new Date(dr['mdr']['pickupDate']);
        if ( d.getTime() - now > 1 * hora ) {
          // mais de 1h até a hora de coleta
          color = 'green';
        } else if ( d.getTime() - now > 0.5 * hora ) {
          // mais de 30min até a hora de coleta
          color = 'yellow';
          tooltip.push('menos de 1h até a hora de coleta');
        } else {
          // menos de 30min até a hora de coleta
          color = 'red';
          tooltip.push('menos de 30 minutos até a hora de coleta');
        }
      }
    }

    if ('status' in dr && dr.status === 'BOOKINGLSP') {
      if ('siteTeam' in dr && dr.siteTeam === '1') {
        color = 'red';
        tooltip.push('removed from Booking');
      }

      if ('siteTeam' in dr && dr.siteTeam === '2') {
        color = 'red';
        tooltip.push('removed from Booking');
      }
    }

    if ('status' in dr && dr.status === 'BOOKING') {
      if ('siteTeam' in dr && dr.siteTeam === '1') {
        color = 'red';
        tooltip.push('removed from Booking');
      }
    }

    // se já foi entregue
    if ('status' in dr && dr.status === 'Delivered' ) {
      if ('updatedAt' in dr && dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 6 * hora ) {
          // se a última atualização ocorreu a menos de 6h
          color = 'green';
        } else if ( now - d.getTime() < 12 * hora ) {
          // se a última atualização ocorreu a menos de 12h
          color = 'yellow';
          tooltip.push('última atualização a mais de 6h');
        } else {
          // se a última atualização ocorreu a mais de 12h
          color = 'red';
          tooltip.push('última atualização a mais de 12h');
        }
      }
    }

    if ('status' in dr &&
          ['HubReturn',
          'Replan - Retido',
          'Replan - Sefaz',
          'Replan - Antecipação',
          'Replan - Postergação',
          'Replan - Pernoite'].indexOf(dr.status) !== -1 ) {
      if ('updated' in dr && dr.updatedAt === null ) {
        // se a DR ainda não foi atualizada
        color = 'undefined';
        tooltip.push('não há atualização');
      } else {
        const d = new Date(dr.updatedAt);
        if ( now - d.getTime() < 5 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 5d
          color = 'green';
        } else if ( now - d.getTime() < 10 * 24 * hora ) {
          // se a última atualização ocorreu a menos de 10d
          color = 'yellow';
          tooltip.push('última atualização a mais de 5 dias');
        } else {
          // se a última atualização ocorreu a mais de 10d
          color = 'red';
          tooltip.push('última atualização a mais de 10 dias');
        }
      }
    }

    // verifica se ha mensagens nao lidas
    // if ( 'idMDR' in dr && dr.idMDR !== null) {
    //   if ( this.role === 5 ) { // lsp
    //     novasMensagens[6] = dr.messages.filter(msg => msg.chatType === 6).some(msg => !msg.seen);
    //   } else if ( this.role === 1 || this.role === 2 || this.role === 3 ) { // ericsson
    //     if ('messages' in dr && dr.messages !== undefined) {
    //       novasMensagens[5] = dr.messages.filter(msg => msg.chatType === 5).some(msg => !msg.seen);
    //       novasMensagens[6] = dr.messages.filter(msg => msg.chatType === 6).some(msg => !msg.seen);
    //     }
    //   }
    // }

    // verifica as ocorrencias da MDR
    if ( 'occurrences' in dr && dr.occurrences.length > 0 ) {
      const occurrencesNotSolved = [];
      for (let i = 0; i < dr.occurrences.length; i++ ) {
        if (this.role === 1 || this.role === 2) {
          if (dr.occurrences[i].status === 'OCCURRENCE_TO_RESOLVE' ) {
            occurrencesNotSolved.push(dr.occurrences[i]);
          }
        } else if (this.role === 5) {
          if (dr.occurrences[i].status === 'SENT_TO_LSP' ) {
            occurrencesNotSolved.push(dr.occurrences[i]);
          }
        }
      }

      if (occurrencesNotSolved.length > 0) {
        const s = ((occurrencesNotSolved.length === 1) ? '' : 's');
        if (occurrencesNotSolved.some((el) => {
          const d = new Date(el.createdAt);
          return (now - d.getTime() > 2 * hora);
        })) {
          // se alguma ocorrencia foi criada mais de 2h atrás
          color = 'red';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        } else {
          // se alguma ocorrencia foi criada, e todas menos de 2h atrás
          color = (color === 'red') ? color : 'yellow';
          tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
        }
      }
    } // fim verificação ocorrencias

    dr['tooltip'] = tooltip;
    dr['color'] = color;
    dr['truckSaw'] = truckSaw;
    dr['novasMensagens'] = novasMensagens;
  }

  /********************
   * auxiliares a salvar buscas
   */
  saveSearches() {
    this.cookie.set(this.uid + 'chips', JSON.stringify(this.searchChips));
    this.cookie.set(this.uid + 'search_toggle', JSON.stringify(this.search_toggle));
  }
  loadSearches() {
    if (this.cookie.check(this.uid + 'chips')) {
      try {
        this.searchChips = JSON.parse(this.cookie.get(this.uid + 'chips'));
      } catch (ex) { }
    }
    if (this.cookie.check(this.uid + 'search_toggle')) {
      try {
        this.search_toggle = JSON.parse(this.cookie.get(this.uid + 'search_toggle'));
      } catch (ex) { }
    }
    this.applyFilter();
  }

}

@Component({
  selector: 'app-cockpit-map-dialog',
  templateUrl: './cockpit-map-dialog.html',
  styleUrls: ['./cockpit.component.scss']
})
export class MapDialogComponent implements OnInit {
  lat = 0;
  lng = 0;
  n = 0;

  list = [];

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    public mdrService: MdrService,
    private router: Router,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    const errors = 0;
    const mdrIdList = data.map(e => e.dr.idMDR);
    mdrService.getLastPositions(mdrIdList).subscribe((result) => {
      for (let i = 0 , len = result.length ; i < len ; i++) {
        const dataI = data.find(e => e.dr.idMDR === result[i].mdrId);
        let marker_url: string;
        if (dataI.color === 'red') {
          marker_url = './assets/images/red_truck.png';
        } else if (dataI.color === 'yellow') {
          marker_url = './assets/images/blue_truck.png';
        } else {
          marker_url = './assets/images/green_truck.png';
        }
        this.list.push({
          lat: result[i].lat,
          lng: result[i].lng,
          icon: {
            url: marker_url,
            scaledSize: {
              width: 40,
              height: 40
            }
          },
          mdrId: result[i].mdrId
        });
      }
      const latSUM = this.list.map(e => e.lat).reduce(function(a, b) { return a + b; }, 0);
      this.lat = latSUM / this.list.length;
      const lngSUM = this.list.map(e => e.lng).reduce(function(a, b) { return a + b; }, 0);
      this.lng = lngSUM / this.list.length;
      if (this.list.length === 0) {
        this.snackBar.open('Nenhuma localização disponível', 'Ok', {duration: 2000});
        this.dialogRef.close();
      }
    }, (error) => {
      this.snackBar.open('Erro ao carregar localizações', 'Ok', {duration: 2000});
      this.dialogRef.close();
    });
  }

  ngOnInit() {
  }

  goToMDR(point) {
    this.router.navigate(['logistic-order/' + point.mdrId]);
    this.dialogRef.close();
  }
}
