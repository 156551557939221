import { OnInit, ElementRef } from '@angular/core';
import { DR } from '../../models/dr';
import { MatSnackBar } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { ERBService } from '../../providers/erb.service';
import { SiteService, ESTRUTURA, ACESSO } from '../../providers/site.service';
import { ClientUserService } from '../../providers/client-user.service';
var DrBookingComponent = /** @class */ (function () {
    function DrBookingComponent(snackBar, mapsAPILoader, clientUserService, drService, erbService, siteService) {
        var _this = this;
        this.snackBar = snackBar;
        this.mapsAPILoader = mapsAPILoader;
        this.clientUserService = clientUserService;
        this.drService = drService;
        this.erbService = erbService;
        this.siteService = siteService;
        this.dr = new DR();
        this.paste_input = '';
        this.showSiteInfo = false;
        this.site = {
            'color': '',
            'tooltips': {
                'grey': 'site não existe',
                'yellow-1': 'site não aprovado',
                'yellow-2': 'site não liberado',
                'yellow-3': 'site não aprovado e não liberado',
                'green': 'site aprovado',
                'undefined': ''
            },
            'icons': {
                'grey': 'fa-question-circle',
                'yellow-1': 'fa-exclamation-triangle',
                'yellow-2': 'fa-exclamation-triangle',
                'yellow-3': 'fa-exclamation-triangle',
                'green': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'grey': 'icon-grey',
                'yellow-1': 'icon-yellow',
                'yellow-2': 'icon-yellow',
                'yellow-3': 'icon-yellow',
                'green': 'icon-green',
                'undefined': ''
            }
        };
        this.clientList = [];
        this.map_show = false;
        this.map_data = null;
        this.red_marker = {
            url: './assets/images/pin_red.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.blue_marker = {
            url: './assets/images/pin.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    DrBookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    DrBookingComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = +lat;
                _this.dr.lng = +lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
            }
        });
    };
    DrBookingComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
            }
        });
    };
    DrBookingComponent.prototype.createDRBooking = function () {
        var _this = this;
        this.dr.deliveryAddress = (document.getElementById('deliveryAddress').value);
        this.dr.salesOrders = this.salesOrders
            .replace(/ /g, ',') // troca espaços por vírgulas
            .split(',') // quebra em array nas vírgulas
            .filter(function (e) { return e.length; }); // remove strings vazias
        this.drService.createBooking(this.dr).subscribe(function (response) {
            _this.snackBar.open('DR Booking criada com sucesso', 'Ok', { duration: 2000 });
            // reseta campos
            _this.dr = new DR();
            _this.salesOrders = '';
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrBookingComponent.prototype.fillFromClipboard = function (e) {
        var _this = this;
        var DMStoDD = function (str) {
            var parts = str.split(/[°'"]/).map(function (part) {
                return +part.replace(',', '.');
            });
            var decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
            return parts[0] + decimal;
        };
        var input = e.clipboardData.getData('text');
        var splitted = input.split('\t');
        this.salesOrders = splitted[1];
        this.dr.deliveryAddress = splitted[6];
        this.dr.lat = +DMStoDD(splitted[25]);
        this.dr.lng = +DMStoDD(splitted[26]);
        this.dr.receiverName = splitted[9];
        this.dr.receiverPhone = parseInt(splitted[10], 10);
        this.dr.dateScheduling = new Date(splitted[3]);
        this.dr.site = splitted[4];
        this.dr.infraType = splitted[11];
        this.dr.unificationId = splitted[0];
        this.dr.obs = splitted[12];
        setTimeout(function () {
            _this.paste_input = '';
        }, 200);
    };
    DrBookingComponent.prototype.toggleMap = function () {
        var _this = this;
        if (this.map_show) {
            this.map_show = false;
        }
        else {
            if (this.dr.lat && this.dr.lng) {
                this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe(function (data) {
                    _this.dr.lat = +_this.dr.lat;
                    _this.dr.lng = +_this.dr.lng;
                    _this.map_data = data;
                    _this.map_show = true;
                });
            }
        }
    };
    DrBookingComponent.prototype.checkSite = function () {
        var _this = this;
        this.showSiteInfo = false;
        if (this.dr.site === '') {
            this.site.color = '';
            return;
        }
        this.siteService.getSiteFromCode(this.dr.site).subscribe(function (data) {
            if (!data) {
                _this.site.color = 'grey';
                return;
            }
            _this.siteSelected = data;
            _this.siteSelected.operation = [];
            if (_this.siteSelected.form_6_1 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Grua');
            }
            if (_this.siteSelected.form_6_2 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Correia');
            }
            if (_this.siteSelected.form_6_3 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Veiculo Esp.');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Manual');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Guindaste');
            }
            if (_this.siteSelected.operation.length > 0) {
                _this.dr.thirdPartInvoice = true;
                // console.log(this.dr.thirdPartInvoice);
            }
            if (!data.aprovado) {
                _this.site.color = 'yellow-1';
            }
            if (data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-2';
            }
            if (!data.aprovado && data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-3';
            }
            if (data.aprovado && data.acesso === ACESSO.LIBERADO) {
                _this.showSiteInfo = true;
                _this.site.color = 'green';
            }
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    return DrBookingComponent;
}());
export { DrBookingComponent };
