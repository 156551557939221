import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
import { parseString } from 'xml2js';
import {NF} from '../../models/nfe';
import {NfeService} from '../../providers/nfe.service';
import {TranslateService} from '@ngx-translate/core';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';

@Component({
  selector: 'app-delivery-request',
  templateUrl: './insert-nfe.component.html',
  styleUrls: ['./insert-nfe.component.scss']
})

export class InsertNfeComponent implements OnInit {
  public searchControl: FormControl;
  public nfe: NF = new NF();
  uploaded = false;
  inputOVs: number;
  opened: any;
  public dateCollect: Date;
  minDate = new Date();
  displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
    'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials', 'prolog'];
  dataSourceNF = new MatTableDataSource<NF>();
  role: number;
  newNfList: NF[] = [];
  clientList: string[] = [];
  update = true;
  attachment: any = null;

  constructor(public dialog: MatDialog, public ovService: OVService,
              public translate: TranslateService,
              public excelService: ExcelService,
              private clientUserService: ClientUserService,
              public router: Router, public drService: DrService, public nfService: NfeService,
              public snackBar: MatSnackBar) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
   }

  ngOnInit() {
  }

  insertNFe() {
    if (!this.uploaded) { // preenchido na mao
      // sobe as NFes
      this.nfService.create(this.nfe).subscribe((data) => {
        if (this.attachment) { // existe anexo
          // sobe o anexo
          this.nfService.attach(data.id, this.attachment).subscribe((response) => {
            this.snackBar.open('NFe cadastradas corretamente', 'Ok', {duration: 4000});
          }, (error) => {
            this.snackBar.open('Erro ao anexar arquivo à NFe', 'Ok', {duration: 4000});
          });
        } else { // sem anexo
          this.snackBar.open('NFe cadastradas corretamente', 'Ok', {duration: 4000});
        }
      }, (error) => {
        this.snackBar.open('Erro ao cadastrar NFe', 'Ok', {duration: 4000});
      });
    } else { // arquivo(s) xml subido(s)
      this.newNfList.forEach( (nf) => {
        this.nfService.getAll(nf.invoiceNumber).subscribe( (ans) => {
          if ( !ans ) {
            this.nfService.create(nf).subscribe( (data) => {
              this.uploaded = false;
              nf.status = true;
              this.snackBar.open('NFe cadastradas corretamente', 'Ok', {duration: 4000});
            });
          } else {
            this.snackBar.open('Algumas NFe já cadastradas no sistema', 'Ok', {duration: 4000});
            nf.status = false;
          }
        });
      });
      this.dataSourceNF = new MatTableDataSource<NF>(this.newNfList);
    }
  }

  attachFile(file) {
    this.attachment = file;
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    const ext = input.files[0].name.split('.').pop();
    if ( ext === 'xlsx' ) {
      this.openXlsx(fileupload);
    } else {
      this.openXml(fileupload);
    }
  }

  openXml(fileupload: any) {
    const input = fileupload.target;
    let data = [];
    for (let index = 0; index < input.files.length; index++) {
      const reader = new FileReader();
      reader.onload = () => {
        parseString(reader.result, (err, result) => {
          const info = result.nfeProc.NFe[0].infNFe[0];
          const nf = new NF();
          nf['totalValue'] = info.total['0'].ICMSTot['0'].vNF['0'];
          nf['emissionDate'] = info.ide['0'].dhEmi['0'];
          nf['client'] = info.dest['0'].xNome['0'];
          nf['series'] = info.ide['0'].serie['0'];
          nf['type'] = info.ide['0'].tpNF['0'];
          nf['weight'] = info.transp['0'].vol['0'].pesoB['0'];
          nf['materials'] = info.det['0'].prod['0'].xProd['0'];
          nf['invoiceNumber'] = info.ide['0'].nNF['0'];
          data.push(nf);
          if (index === input.files.length - 1) {
            const keys = data.length ? Object.keys(data[0]) : [] ;
            data = data.map( (el) => {
              for (let i = 0, l = keys.length; i < l; i++) {
                // remove os valores null
                if ( el[keys[i]] === null ) {
                  el[keys[i]] = '';
                }
                // converte em Date
                if ( keys[i] === 'emissionDate') {
                  el[keys[i]] = new Date(el[keys[i]]);
                }
              }
              return el;
            });
            this.newNfList = data;
            this.dataSourceNF = new MatTableDataSource<NF>(data);
            this.uploaded = true;
          }
        });
      };
      reader.readAsText(input.files[index]);
    }
  }

  openXlsx(fileupload: any) {
    const data = [];
    this.excelService.importFromExcel(fileupload).subscribe( (fileContent) => {
      fileContent.forEach( (el, i, arr) => {
        const nf = new NF();
        nf['totalValue'] = el['value'];
        nf['emissionDate'] = el['emission date'];
        nf['client'] = el['client'];
        nf['series'] = el['series'];
        nf['type'] = el['type'];
        nf['weight'] = el['weight'];
        nf['materials'] = el['materials'];
        nf['invoiceNumber'] = el['invoice number'];
        data.push(nf);
      });
      this.newNfList = data;
      this.dataSourceNF = new MatTableDataSource<NF>(data);
      this.uploaded = true;
    });
  }

  sanitizeInputPrice(field: string, value: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(' ', '');
    input = input.replace('.', '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.nfe[field] = input;
    this.update = true;
  }

}
