import { OnInit } from '@angular/core';
import { Veiculo } from '../logistic-order/transport/transport.component';
import { PriceMakerService } from '../../providers/price-maker.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SiteService } from '../../providers/site.service';
var PriceMakerComponent = /** @class */ (function () {
    function PriceMakerComponent(priceMaker, spinnerService, siteService) {
        var _this = this;
        this.priceMaker = priceMaker;
        this.spinnerService = spinnerService;
        this.siteService = siteService;
        this.price = {};
        this.total = '-1';
        this.deliveryRegion = '';
        this.transportadora = [];
        this.veiculo = [
            { id: '1', name: 'FIORINO' },
            { id: '2', name: 'Sprinter' },
            { id: '3', name: 'VUC' },
            { id: '4', name: 'F608' },
            { id: '5', name: 'TOCO' },
            { id: '6', name: 'TRUCK' },
            { id: '7', name: 'CARRETA' }
        ];
        this.state = [
            { id: 'AC', name: 'AC' },
            { id: 'AL', name: 'AL' },
            { id: 'AP', name: 'AP' },
            { id: 'AM', name: 'AM' },
            { id: 'BA', name: 'BA' },
            { id: 'CE', name: 'CE' },
            { id: 'DF', name: 'DF' },
            { id: 'ES', name: 'ES' },
            { id: 'GO', name: 'GO' },
            { id: 'MA', name: 'MA' },
            { id: 'MT', name: 'MT' },
            { id: 'MS', name: 'MS' },
            { id: 'MG', name: 'MG' },
            { id: 'PA', name: 'PA' },
            { id: 'PB', name: 'PB' },
            { id: 'PR', name: 'PR' },
            { id: 'PE', name: 'PE' },
            { id: 'PI', name: 'PI' },
            { id: 'RJ', name: 'RJ' },
            { id: 'RN', name: 'RN' },
            { id: 'RS', name: 'RS' },
            { id: 'RO', name: 'RO' },
            { id: 'RR', name: 'RR' },
            { id: 'SC', name: 'SC' },
            { id: 'SP', name: 'SP' },
            { id: 'SE', name: 'SE' },
            { id: 'TO', name: 'TO' }
        ];
        this.subRegion = [
            { id: 'Capital', name: 'Capital' },
            { id: 'Interior', name: 'Interior' },
        ];
        this.columns = [
            { value: 'shipC', name: 'LSP' },
            { value: 'price', name: 'Valor do Frete', currency: 'BRL' },
            { value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL' },
            { value: 'adValorem', name: 'adValorem', currency: 'BRL' },
            { value: 'addDelivery', name: 'Adicional de Entrega', currency: 'BRL' },
            { value: 'value', name: 'Valor Total', currency: 'BRL' }
        ];
        this.price['drAmount'] = 0;
        this.siteService.getShippingCompanys().subscribe(function (data) {
            _this.transportadora = data;
        });
    }
    PriceMakerComponent.prototype.ngOnInit = function () {
    };
    PriceMakerComponent.prototype.getPrice = function () {
        var _this = this;
        var vehicle = this.price['vehicle'];
        var distance = this.price['totalDistance'];
        var fromEstate = this.price['deliveryRegion'];
        var toEstate = this.price['pickupRegion'];
        var subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
        var value = this.price['freight'];
        //    const shippingCompany = this.price['shippingCompany'];
        var drAmount = this.price['drAmount'];
        this.spinnerService.show();
        this.priceMaker.getPriceTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount)
            .subscribe(function (result) {
            _this.data = result;
            _this.spinnerService.hide();
            // this.total=result.total;
        });
    };
    PriceMakerComponent.prototype.getTotal = function () {
        // console.log(this.price['deliveryRegion'])
        if (typeof this.price['deliveryRegion'] !== 'undefined') {
            this.deliveryRegion = this.price['deliveryRegion'];
        }
        // console.log( this.deliveryRegion)
        this.total = '-1';
    };
    return PriceMakerComponent;
}());
export { PriceMakerComponent };
