import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {OVService} from '../../providers/ov.service';
import {OV} from '../../models/ov';
import {Router} from '@angular/router';
import {DrService} from '../../providers/dr.service';
import {SelectionModel} from '@angular/cdk/collections';
import {DR} from '../../models/dr';
import { parseString } from 'xml2js';
import {NfeService} from '../../providers/nfe.service';
import {NF} from '../../models/nfe';

@Component({
  selector: 'app-delivery-request',
  templateUrl: './delivery-request.component.html',
  styleUrls: ['./delivery-request.component.scss']
})

export class DeliveryRequestComponent implements OnInit {
  public searchControl: FormControl;
  public searchControl2: FormControl;
  public dr: DR = new DR();
  uploaded = false;
  displayedColumns = ['mdrID', 'route', 'driver', 'status'];
  dataSource = new MatTableDataSource<any>();
  inputOVs: number;
  inputNFs: string;
  ovOrNf = false;
  opened: any;
  checkedColect: any;
  public dateCollect: Date;
  public hour: string;
  minDate = new Date();
  displayedColumns2 = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
  dataSourceOV = new MatTableDataSource<any>();
  displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'pickupAddress', 'site'];
  dataSourceDR = new MatTableDataSource<DR>();
  initialSelection: OV[] = [];
  role: number;
  newId: number;
  selection = new SelectionModel<OV>(true, this.initialSelection);
  newDrList: DR[] = [];
  latlngInsert = false;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('search2')
  public searchElement2Ref: ElementRef;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader, public ovService: OVService,
              public router: Router, public drService: DrService, public nfService: NfeService,
              public snackBar: MatSnackBar) {
    this.dataSource.sort = this.sort;
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    if (this.role === 1) {
      this.minDate = new Date('1970-01-01');
    }
    this.drService.getLastIdInserted().subscribe( (data) => {
      this.newId = data;
    });
   }

  ngOnInit() {

    this.searchControl2 = new FormControl();
    this.searchControl = new FormControl();

    this.drService.getAllMDR().subscribe( (elem) => {
      // console.log(elem);
      this.dataSource = new MatTableDataSource<DR>(elem);
    });

  }

  getRowColor(status) {
    const colors = {
      green: ['Delivered'],
      yellow: ['READY_TO_PICKUP']
    };
    for (const color in colors) {
      if (colors[color].indexOf(status) >= 0) {
        return 'request-status-' + color;
      }
    }
    return 'request-status-undefined';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  backTo() {
    this.uploaded = false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  addNewOV(): void {
    this.selection.selected.forEach(el => {
      if ( this.dataSourceOV.data.indexOf(el) < 0) {
        this.dataSourceOV.data.push(el);
      }
    });

    this.dataSourceOV = new MatTableDataSource<any>(this.dataSourceOV.data);
  }

  openFile(fileupload: any) {
    // console.log(fileupload);
    const input = fileupload.target;
    // console.log(fileupload);
    this.newDrList = [];
    for (let index = 0; index < input.files.length; index++) {
      const reader = new FileReader();
      reader.onload = () => {
        parseString(reader.result, (err, result) => {
          // drAux.deliveryAddress = result.
          let i = 0;
          result.Workbook.Worksheet[0].Table[0].Row.forEach( (el) => {
            if (i !== 0) {
              const drAux = new DR();
              // console.log(el);
              drAux.pickupAddress = el.Cell[0].Data[0]._; // Ver se é nullo
              drAux.deliveryAddress = el.Cell[1].Data[0]._;
              drAux.site = el.Cell[2].Data[0]._;
              drAux.receiverName = el.Cell[3].Data[0]._;
              drAux.receiverPhone = el.Cell[4].Data[0]._;
              drAux.supervisorName = el.Cell[5].Data[0]._;
              drAux.supervisorPhone = el.Cell[6].Data[0]._;
              drAux.infraType = el.Cell[7].Data[0]._;
              drAux.obs = el.Cell[8].Data[0]._;
              drAux.dateScheduling = el.Cell[9].Data[0]._;
              drAux.status = el.Cell[10].Data[0]._;
              drAux.lat = el.Cell[11].Data[0]._;
              drAux.lng = el.Cell[12].Data[0]._;
              // const aux = el.Cell[0].Data[0]._;
              // TODO Insert OVs in ov List and check if only the id works to change
              this.newDrList.push(drAux);
              // drAux.deliveryAddress = el.
            }
            i++;
          });
        });
      };
      reader.readAsText(input.files[index]);
    }
    this.uploaded = true;
    this.dataSourceDR = new MatTableDataSource<DR>(this.newDrList);
    // console.log(this.newDrList);
  }

  // POG
  private validateData(): boolean {
    if (!this.latlngInsert) {
      this.dr.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
      if ( this.dr.deliveryAddress === '') {
        return false;
      }
    }

    this.dr.pickupAddress = ((document.getElementById('pickupAddress') as HTMLInputElement).value);
    // this.dateCollect = ((document.getElementById("deliveryAddress") as HTMLInputElement).value);
    this.hour = ((document.getElementById('hour') as HTMLInputElement).value);

    // console.log(this.hour);
    if ( this.dr.deliveryAddress === '' ) {
      return false;
    }

    if ( this.hour === '') {
      return false;
    }
    return !(this.dr.infraType === '' || this.dr.site === '' || this.dr.obs === '');
  }

  openDialog2(): void {
    const today = new Date();
    let dd = today.getDate() + '';
    let mm = (today.getMonth() + 1) + ''; // January is 0!
    const yyyy = today.getFullYear();

    if (Number(dd) < 10) {
      dd = '0' + dd;
    }

    if ( Number(mm) < 10) {
      mm = '0' + mm;
    }

    const idDate = yyyy + mm + dd + ('00000000' + this.newId).slice(-8);
    // generate a new ID based on last MDR
  }

  isCompleted(correct: number) {
    if (correct < this.newDrList.length) {
      this.snackBar.open('Algumas DRs não puderam ser criadas', 'Ok', {duration: 4000});
    } else {
      this.snackBar.open('DRs Criadas com sucesso', 'Ok', {duration: 4000});
    }
  }

  openDialog(): void {
    // generate a new ID based on last MDR
    const today = new Date();
    let dd = today.getDate() + '';
    let mm = (today.getMonth() + 1) + ''; // January is 0!
    const yyyy = today.getFullYear();

    if (Number(dd) < 10) {
      dd = '0' + dd;
    }

    if ( Number(mm) < 10) {
      mm = '0' + mm;
    }

    const idDate = yyyy + mm + dd + ('00000000' + this.newId).slice(-8);
  }

  searchOV(): void {
    // console.log(this.inputOVs);
    if (this.inputOVs === undefined) {
      this.snackBar.open('Preencha um valor de OV', 'Ok', {duration: 4000});
    } else {
      this.ovService.getAll(this.inputOVs).subscribe(
        (data) => {
          // console.log(data)
          if (data === null) {
            this.snackBar.open('OV já utilizada ou fora do sistema', 'Ok', {duration: 4000});
          } else {
            this.dataSource = new MatTableDataSource<OV>([data]);
          }

        });
    }

  }

  searchNFe(): void {
    if (this.inputNFs === undefined) {
      this.snackBar.open('Preencha um valor de NFe', 'Ok', {duration: 4000});
    } else {
      this.nfService.getAll(this.inputNFs).subscribe(
        (data) => {
          if ( !data || data.drId !== null ) {
            this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', {duration: 4000});
          } else {
            const ovAux = new OV();
            ovAux.salesDocument = '';
            ovAux.referenceNoNf = data[0].invoiceNumber;
            ovAux.customerName = data[0].client;
            ovAux.materialDescription = data[0].materials;
            this.dataSource = new MatTableDataSource<OV>([ovAux]);
          }
        });
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goToMDR(k) {
    this.router.navigate(['logistic-order/' + k.mdr.id]);
  }
}
