import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DR} from '../../../models/dr';
import {MatSnackBar, MatTableDataSource, MatPaginator} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { OV } from '../../../models/ov';
import { OVService } from '../../../providers/ov.service';

import * as FileSaver from 'file-saver';
import { ClientUserService } from '../../../providers/client-user.service';
import { arrayMax } from 'highcharts';

declare var google: any;

@Component({
  selector: 'app-dr-validation',
  templateUrl: './dr-validation.component.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DrValidationComponent implements OnInit {
  id;
  public dr: DR = new DR();
  public listOVs = [];
  salesOrders;
  @ViewChild('addrSearch')
  public searchElementRef: ElementRef;
  dataSource = new MatTableDataSource<any>();
  displayedColumns2 = ['salesOrderQuantity', 'materialCode', 'materialDescription', 'customerName', 'assignmentInformation', 'customerCity', 'customerAddress', 'obs', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  currentUser = null;
  role = null;
  user = null;

  clientList: string[] = [];


  // pro icon de salesOrders
  status = null;
  icons = {
    'status' : '',
    'tooltips': {
      'invalid': 'OVs inválidas',
      'incomplete': 'NFs não existentes',
      'valid': 'DR Booking válido',
      'undefined': ''
    },
    'icons' : {
      'invalid': 'fa-exclamation-circle',
      'incomplete': 'fa-exclamation-triangle',
      'valid': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'invalid' : 'icon-red',
      'incomplete': 'icon-yellow',
      'valid': 'icon-green',
      'undefined': ''
    }
  };

  constructor(
    public router: Router,
    public aRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private clientUserService: ClientUserService,
    private drService: DrService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    public ovService: OVService,
  ) {
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;
  }

  ngOnInit() {
    this.aRoute.params.subscribe( (params) => {
      this.id = params.id;
      this.drService.getById(this.id).subscribe( (dr) => {
        this.dr = dr;
        this.drService.getAllInfo(this.dr.id).subscribe((k) => {
          console.log(k);
          const list = [];
          k.forEach(ov => {
            const el = {};
            el['id'] = ov.id;
            el['material'] = ov.materialCode;
            el['materialDescription'] = ov.materialDescription;
            el['client'] = ov.customerName;
            el['prolog'] = ov.assignmentInformation;
            el['serial'] = ov.customerAddress;
            el['uoc'] = ov.customerCity;
            el['qtd'] = ov.salesOrderQuantity;
            el['obs'] = ov.salesOrganization;
            list.push(el);
            this.listOVs.push(ov);
          });
          // this.listOVs.find;
          this.dataSource = new MatTableDataSource<any>(list);
          setTimeout(() => this.dataSource.paginator = this.paginator);
        });
      });
    });
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  downloadReport2() {
    this.snackBar.open('Relatório gerado com sucesso', 'Ok', {duration: 3000});
  }

  downloadPDF() {
    // const mdrID = 200;
    this.drService.downloadPDF(this.id).subscribe(data => {
      FileSaver.saveAs(data, `Report-Logistic_Order-${this.id}.pdf`);
    });
  }

  uploadSerial(fileupload) {
    this.snackBar.open('Error in upload File', 'Ok', {duration: 3000});
    const input = fileupload.target;
    const error = false;
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(input.files[index]).subscribe( (data) => {
          const stringTo = (new Date()).toString();
          const ovList = [];
          const seed = Math.floor((Math.random() * 1000));
          data.forEach( (el, i, arr) => {
            const ovAux = new OV();
            ovAux.id = el.id;
            ovAux.materialCode = el.client;
            ovAux.materialDescription = el.materialDescription;
            ovAux.assignmentInformation = el.prolog;
            ovAux.customerAddress = el.serial;
            ovAux.customerCity = el.uoc;
            ovAux.salesOrderQuantity = el.qtd;
            ovAux.salesOrganization = el.obs;
            ovList.push(ovAux);
          });
          this.ovService.updateSerial(ovList).subscribe( (response) => {
            this.snackBar.open('Inserted Correcly', 'Ok', {duration: 5000});
          });
        });
      }
    }
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = lat;
        this.dr.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({location: {lat: parseFloat(lat), lng: parseFloat(lng)}}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
      }
    });
  }

  validateDR() {
    this.drService.getValidation(this.id).subscribe((response) => {
      this.dr = response.dr;
      this.salesOrders = this.dr.salesOrders.join(',');
      this.icons.status = response.status;
      this.status = {
        status: response.status,
        error: response.error,
        warning: response.warning
      };
      if ( response.status === 'valid' ) {
        // this.approveDR();
      } else {
        // this.openDialog(null);
      }
    }, (err) => {
      this.snackBar.open('Erro ao buscar DR Booking', 'Ok', {duration: 4000});
      this.router.navigate(['cockpit']);
    });
  }

  remove() {
    this.drService.unmakeDR([this.dr]).subscribe(elem => {
      this.snackBar.open('Removed', 'Ok', {duration: 4000});
      this.router.navigate(['cockpit']);
    });
  }

  reproveBooking() {
    if (this.dr.obs === '' || this.dr.obs === null) {
      this.snackBar.open('Please fill observation field', 'Ok', {duration: 4000});
    } else {
      this.dr.status = 'BOOKING';
      this.dr.siteTeam = '1';
      this.drService.update(this.dr).subscribe((response) => {
        this.snackBar.open('Saved', 'Ok', {duration: 4000});
        this.router.navigate(['delivery-optimizer']);
      });
    }
  }

  save() {
    this.drService.update(this.dr).subscribe((response) => {
      this.snackBar.open('Saved', 'Ok', {duration: 4000});
    });
  }

  goToMDRAvailable() {
    this.dr.thirdPartInvoice = true;
    this.drService.update(this.dr).subscribe(k => {
    });
    this.snackBar.open('Correctly sent to MDR Available', 'Ok', {
      duration: 4000,
    });
    this.router.navigate(['delivery-optimizer']);
  }

  goToMDRUnavailable() {
      this.dr.thirdPartInvoice = false;
      this.drService.update(this.dr).subscribe(k => {
      });
      this.snackBar.open('Correctly sent to MDR Unavailable', 'Ok', {
        duration: 4000,
      });
      this.router.navigate(['delivery-optimizer']);
  }


  saveAndValidate() { // TODO unificar
    // this.dr.salesOrders = this.salesOrders
    //                           .replace(/ /g,',')   // troca espaços por vírgulas
    //                           .split(',')          // quebra em array nas vírgulas
    //                           .filter(e=>e.length) // remove strings vazias
    const newDr = this.dr;
    newDr.status = 'BOOKINGLSP';
    newDr.siteTeam = null;
    this.drService.update(newDr).subscribe((response) => {
      this.router.navigate(['cockpit']);
      this.snackBar.open('Validated', 'Ok', {duration: 4000});
    },
    (err) => {
      this.snackBar.open('Erro updating DR Booking', 'Ok', {duration: 2000});
    });
  }

  downloadReport() {
    const drAux = this.dr;
    for ( const [key, value] of Object.entries(drAux)) {
      if (key === 'dateScheduling' || key === 'createdAt' || key === 'updatedAt') {
        drAux[key] = new Date(value);
      }
    }
    this.dataSource.data.forEach(el => {
      el = Object.assign(el, drAux);
    });
    this.excelService.exportAsExcelFile(this.dataSource.data, 'BRBs');
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(DialogDrValidationComponent, {
      data: {
        row: row,
        listOv: this.listOVs
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'approve' ) {
        this.drService.getAllInfo(this.dr.id).subscribe((k) => {
          console.log(k);
          const list = [];
          k.forEach(ov => {
            const el = {};
            el['id'] = ov.id;
            el['material'] = ov.materialCode;
            el['materialDescription'] = ov.materialDescription;
            el['client'] = ov.customerName;
            el['prolog'] = ov.assignmentInformation;
            el['serial'] = ov.customerAddress;
            el['uoc'] = ov.customerCity;
            el['qtd'] = ov.salesOrderQuantity;
            el['obs'] = ov.salesOrganization;
            list.push(el);
            this.listOVs.push(ov);
          });
          this.dataSource = new MatTableDataSource<any>(list);
          setTimeout(() => this.dataSource.paginator = this.paginator);
        });
        this.snackBar.open('Updated Correctly', 'Ok', {duration: 4000});
      }
    });
  }

  addNewRow() {
    const row = {material: null, materialDescription: null, client: null, prolog: null, serial: null, uoc: null, qtd: null, obs: null};
    this.openDialog(row);
  }

  approveDR() {
    this.drService.updateBookingToDR(this.id).subscribe((response) => {
      this.router.navigate(['delivery-optimizer']);
    }, (err) => {
      this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', {duration: 2000});
    });
  }

}

@Component({
  selector: 'app-dialog-dr-validation',
  templateUrl: 'dialog-dr-validation.html',
  styleUrls: ['./dr-validation.component.scss']
})
export class DialogDrValidationComponent {
  el = {};
  constructor(
    public ovService: OVService,
    public dialogRef: MatDialogRef<DialogDrValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.el = {};
      this.el['id'] = data.row.id;
      this.el['material'] = data.row.material;
      this.el['materialDescription'] = data.row.materialDescription;
      this.el['client'] = data.row.client;
      this.el['prolog'] = data.row.prolog;
      this.el['serial'] = data.row.serial;
      this.el['uoc'] = data.row.uoc;
      this.el['qtd'] = data.row.qtd;
      this.el['drId'] = data.row.drId;
      this.el['obs'] = data.row.obs;
    }

  onClickOk(): void {
    let ovAux = this.data.listOv.find(e => e.id === this.el['id']);
    if (ovAux === undefined) {
      ovAux = new OV();
      ovAux.drId = this.data.listOv[0].drId;
      ovAux.salesDocument = this.data.listOv[0].salesDocument;
      ovAux.salesSequence = (Math.random() * 1000) % 100;
      ovAux.referenceNoNf = this.data.listOv[0].referenceNoNf;
    }

    ovAux.id = this.el['id'];
    ovAux.materialCode = this.el['material'];
    ovAux.customerName = this.el['client'];
    ovAux.materialDescription = this.el['materialDescription'];
    ovAux.assignmentInformation = this.el['prolog'];
    ovAux.customerAddress = this.el['serial'];
    ovAux.customerCity = this.el['uoc'];
    ovAux.salesOrderQuantity = this.el['qtd'];
    ovAux.salesOrganization = this.el['obs'];
    this.ovService.saveMultipleOV([ovAux]).subscribe(resp => {
      this.dialogRef.close({ what: 'approve' , ov: this.el});
    });
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }

}
