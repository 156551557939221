import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../providers/auth.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class CheckTokenGuard implements CanActivate {

  constructor(
    private router: Router,
    private service: AuthService,
    private snack: MatSnackBar
  ) { }

  /**********
   * espera-se que a url siga o seguinte formato:
   *
   *  /view/TOKEN/<resto da url>
   *
   */
  private extractTokenFromURL(url: string): string {
    const token = url.split('/')[2];
    return token ? token : null;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.extractTokenFromURL(state.url);
    if (token === null) {
      this.snack.open('Token ausente', 'Ok', {duration: 4000});
      this.router.navigate(['login']);
      return false;
    }

    return this.service.checkTokenWithoutLogin(token).map((response) => {
      if ( response === true ) {
        return true;
      } else {
        this.snack.open('Token inválido', 'Ok', {duration: 4000});
        this.router.navigate(['login']);
        return false;
      }
    });
  }
}
