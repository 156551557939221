var UOCMDR = /** @class */ (function () {
    function UOCMDR() {
        this.id = null;
        this.uoc = '';
        this.idMDR = null;
        this.value = null;
        this.observation = '';
        this.isCopq = null;
        this.status = null;
        this.approvedAt = null;
        this.approvedBy = null;
        this.createdAt = null;
        this.createdBy = null;
        this.drList = [];
    }
    return UOCMDR;
}());
export { UOCMDR };
