<div>
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'COCKPIT/title' | translate }}</p>
    <p class="mx-4">{{ 'COCKPIT/sub-title' | translate }}</p>
  </div>
  <div class="colored-background d-flex flex-column h-100">
    <div class="d-flex minHeight justify-content-between mx-5">
      <mat-form-field class="mt-3 pt-1 pl-3 rounded-corners col-md-6" floatLabel="never">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let chip of searchChips"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chip)">
            {{chip.name}}
            <i matChipRemove class="fa fa-times ml-1" *ngIf="removable"></i>
          </mat-chip>
          <input placeholder=" {{ 'COCKPIT/type' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <div class="searchToggle">
          Todos<mat-slide-toggle [(ngModel)]="search_toggle" (change)="applyFilter()">Alguns</mat-slide-toggle>
        </div>
      </mat-form-field>

      <mat-form-field class="mt-3 pt-1 pl-3 rounded-corners col-md-2">
        <input matInput
          (dateTimeChange)="dateChanged()"
          [(ngModel)]="searchDates.start"
          placeholder="{{ 'COCKPIT/date_init' | translate }}"
          [owlDateTimeTrigger]="pickerStart"
          [owlDateTime]="pickerStart">
        <owl-date-time #pickerStart pickerType="calendar"></owl-date-time>
      </mat-form-field>

      <mat-form-field class="mt-3 pt-1 pl-3 mr-2 rounded-corners col-md-2">
        <input matInput
          (dateTimeChange)="dateChanged()"
          [(ngModel)]="searchDates.end"
          placeholder="{{ 'COCKPIT/date_end' | translate }}"
          [owlDateTimeTrigger]="pickerEnd"
          [owlDateTime]="pickerEnd">
        <owl-date-time #pickerEnd pickerType="calendar"></owl-date-time>
      </mat-form-field>
    </div>
    <div class="columns flex-fill">
      <div class="pt-3 pb-3 mt-1 mx-5 d-flex justify-content-between h-100">
        <agm-map id="map" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
          <ng-container *ngFor="let pos of list">
            <agm-marker *ngIf="pos.show" [iconUrl]="pos.icon" [latitude]="pos.lat" [longitude]="pos.lng" (markerClick)="showMDR(pos)"></agm-marker>
          </ng-container>
        </agm-map>
      </div>
    </div>
  </div>
</div>
