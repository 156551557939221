import { Router } from '@angular/router';
import { AuthService } from './auth.service';
var RoleGuardService = /** @class */ (function () {
    function RoleGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    /*****
     * chama a função .canDeactivate() do componente usando o canDeactivate guard
     */
    RoleGuardService.prototype.canDeactivate = function (component) {
        return component.canDeactivate ? component.canDeactivate() : true;
    };
    RoleGuardService.prototype.canActivate = function (aRoute) {
        // se ja esta autenticado
        if (this.auth.isAuthenticated()) {
            return true;
        }
        // const url = this.router.url;
        var urlParts = aRoute.url;
        var url = '';
        urlParts.forEach(function (el) {
            if (url.length) {
                url += '/';
            }
            url += el.path;
        });
        console.log(url);
        this.router.navigate(['/login'], { queryParams: { url: url } });
        return false;
    };
    return RoleGuardService;
}());
export { RoleGuardService };
