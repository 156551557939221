var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MdrService } from '../../../../providers/mdr.service';
import { Legs, Pin } from '../../../../models/legs';
import { MatTableDataSource } from '@angular/material';
var GeolocationComponent = /** @class */ (function () {
    function GeolocationComponent(route, router, mdrService) {
        this.route = route;
        this.router = router;
        this.mdrService = mdrService;
        // mdr.status para os quais considera-se que mdr foi finalizada
        this.finishedDriveStatus = ['Carga entregue com sucesso', 'Carga retornada total', 'Entrega de carga certificada pelo LSP', 'MDR paga / concluída'];
        // campos preenchidos pela getLegs
        this.legsNewLegs = [];
        this.markers = [];
        this.dir = false;
        this.dataSource = new MatTableDataSource();
        this.showMap = true;
        this.lat = 19.694777;
        this.lng = -99.211361;
        this.icon = {
            url: './assets/images/red_truck.png',
            scaledSize: {
                height: 40,
                width: 40
            },
        };
        this.iconPoint = {
            url: './assets/images/red_point.png',
            scaledSize: {
                height: 5,
                width: 5
            },
        };
        this.renderOptions = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#AAA', zIndex: 1 },
        };
        this.renderOptionsDriver = {
            suppressMarkers: true,
            draggable: false,
            polylineOptions: { strokeColor: '#00f', zIndex: 2 },
        };
        this.haveDriver = false;
        this.markersTruck = [];
        this.markersDriver = [];
        this.colorPrefix = 'COLOR/';
        this.coloredPoint = [
            { scaledSize: { height: 5, width: 5 }, color: 'red', url: './assets/images/red_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'blue', url: './assets/images/blue_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'green', url: './assets/images/green_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'pink', url: './assets/images/pink_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'purple', url: './assets/images/purple_point.png' },
            { scaledSize: { height: 5, width: 5 }, color: 'orange', url: './assets/images/orange_point.png' }
        ];
        this.driverPositionTableColumns = [
            { value: 'createdAt', name: 'GEOLOCATION/table/time' },
            { value: 'lat', name: 'GEOLOCATION/table/lat' },
            { value: 'lng', name: 'GEOLOCATION/table/lng' },
            { value: 'speed', name: 'GEOLOCATION/table/speed' },
            { value: 'phoneNumber', name: 'GEOLOCATION/table/phone' },
            { value: 'color', name: 'GEOLOCATION/table/color' }
        ];
    }
    GeolocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.token = _this.route.snapshot.paramMap.get('token');
            _this.mdr = data.mdr;
            _this.getLegs();
            _this.getDriverLastPosition();
            _this.getDriverPositions();
        });
    };
    GeolocationComponent.prototype.getLegs = function () {
        var _this = this;
        this.mdrService.getLegsWithToken(this.mdr.id, this.token).subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.legsNewLegs = [];
                        this.markers = [];
                        data.forEach(function (leg, i) {
                            var legNew = new Legs();
                            legNew.id = leg.id;
                            var letterInittoInit = _this.legsNewLegs.filter(function (legs) {
                                return (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng);
                            }).map(function (legs) { return legs.pointInit.label; });
                            var letterInittoEnd = _this.legsNewLegs.filter(function (legs) {
                                return (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng);
                            }).map(function (legs) { return legs.pointEnd.label; });
                            var letterEndtoEnd = _this.legsNewLegs.filter(function (legs) {
                                return (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng);
                            }).map(function (legs) { return legs.pointEnd.label; });
                            var letterEndtoInit = _this.legsNewLegs.filter(function (legs) {
                                return (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng);
                            }).map(function (legs) { return legs.pointInit.label; });
                            if (i === 0) {
                                _this.markers.push({
                                    lat: parseFloat(leg.pointInit.lat),
                                    lng: parseFloat(leg.pointInit.lng),
                                    label: 'A',
                                    title: '',
                                });
                            }
                            if (letterInittoInit.length > 0) {
                                legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                                legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                                legNew.pointInit.label = letterInittoInit[0];
                            }
                            else if (letterInittoEnd.length === 0 && i !== 0) {
                                legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                                legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                                legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
                                _this.markers.push({
                                    lat: parseFloat(leg.pointInit.lat),
                                    lng: parseFloat(leg.pointInit.lng),
                                    label: String.fromCharCode(i + 1 + 65),
                                    title: '',
                                });
                            }
                            else {
                                legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
                                legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
                                legNew.pointInit.label = String.fromCharCode(i + 65);
                            }
                            if (letterEndtoEnd.length > 0) {
                                legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                                legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                                legNew.pointEnd.label = letterEndtoEnd[0];
                            }
                            else if (letterEndtoInit.length > 0) {
                                legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                                legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                                legNew.pointEnd.label = letterEndtoInit[0];
                            }
                            else {
                                legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
                                legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
                                legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
                                _this.markers.push({
                                    lat: parseFloat(leg.pointEnd.lat),
                                    lng: parseFloat(leg.pointEnd.lng),
                                    label: String.fromCharCode(i + 1 + 65),
                                    title: '',
                                });
                            }
                            legNew.type = leg.type;
                            legNew.order = leg.order;
                            _this.legsNewLegs.push(legNew);
                        });
                        this.dir = true;
                        this.updateETA();
                        this.dataSource = new MatTableDataSource(this.legsNewLegs);
                        this.showMap = false;
                        return [4 /*yield*/, setTimeout(function () { _this.showMap = true; }, 1000)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    GeolocationComponent.prototype.updateETA = function () {
        var dist = 0;
        var ETA = 0;
        this.legsNewLegs.forEach(function (leg) {
            if (leg.type === 'Rodoviário') {
                dist += leg.distance;
                ETA += leg.ETA;
            }
        });
        this.distanceRemaining = Math.floor(dist / 1000) + ' km';
        this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor((ETA % 3600) / 60) + ' min';
    };
    GeolocationComponent.prototype.onResponse = function (event) {
        this.legsNewLegs.forEach(function (leg) {
            if (Math.abs(leg.pointInit.lat - event.routes[0].legs[0].start_location.lat()) < 0.01 &&
                Math.abs(leg.pointInit.lng - event.routes[0].legs[0].start_location.lng()) < 0.01 &&
                Math.abs(leg.pointEnd.lat - event.routes[0].legs[0].end_location.lat()) < 0.01 &&
                Math.abs(leg.pointEnd.lng - event.routes[0].legs[0].end_location.lng()) < 0.01) {
                leg.distance = event.routes[0].legs[0].distance.value;
                leg.ETA = event.routes[0].legs[0].duration.value;
            }
        });
        this.updateETA();
    };
    // pega última posição da MDR
    GeolocationComponent.prototype.getDriverLastPosition = function () {
        var _this = this;
        this.mdrService.getDriverLastPositionWithToken(this.mdr.id, this.token).subscribe(function (elem) {
            if (elem) {
                _this.driverPosition = new Pin();
                _this.driverPosition.lat = parseFloat(elem.lat);
                _this.driverPosition.lng = parseFloat(elem.lng);
                _this.destination = new Pin();
                _this.haveDriver = true;
            }
        });
    };
    // pega posições do tracking
    GeolocationComponent.prototype.getDriverPositions = function () {
        var _this = this;
        this.markersTruck = [];
        this.mdrService.getDriverPositionWithToken(this.mdr.id, this.token).subscribe(function (driverList) {
            // monta lista de posições pra tabela e última posição do motorista
            _this.driverPositionList = [];
            driverList.forEach(function (driver, i) {
                // pra cada posição do motorista
                driver['positions'].forEach(function (position) {
                    // adiciona o telefone do motorista às posições
                    position['phoneNumber'] = driver['phoneNumber'];
                    // converte velocidade em km/h
                    position['speed'] = position['speed'] * 3.6;
                    // converte createdAt em Date
                    position['createdAt'] = new Date(position['createdAt']);
                    // escolhe cor para as posições
                    var colorI = i % _this.coloredPoint.length;
                    position['color'] = _this.colorPrefix + _this.coloredPoint[colorI].color;
                    position['icon'] = _this.coloredPoint[colorI];
                });
                // adiciona posições à lista de latlngs
                _this.driverPositionList = [].concat(_this.driverPositionList, driver['positions']);
            });
            // monta lista de markers pro mapa
            _this.driverPositionList.forEach(function (position) {
                _this.markersDriver.push({
                    lat: parseFloat(position['lat']),
                    lng: parseFloat(position['lng']),
                    label: '',
                    title: position['phoneNumber'],
                    icon: position['icon']
                });
            });
        });
    };
    GeolocationComponent.prototype.onResponseDriver = function (event) {
        var dist = event.routes[0].legs[0].distance.value;
        var ETA = event.routes[0].legs[0].duration.value;
        this.distanceRemaining = Math.floor(dist / 1000) + ' km';
        this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor((ETA % 3600) / 60) + ' min';
    };
    return GeolocationComponent;
}());
export { GeolocationComponent };
