import { Input, Component, OnInit } from '@angular/core';
import { OVService } from '../../../providers/ov.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { DR } from '../../../models/dr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-termresult',
  templateUrl: './termresults.component.html',
  styleUrls: ['./termresults.component.scss']
})
export class TermResultComponent implements OnInit {
  @Input() search_term: string;
  @Input() data: any;

  OVDataSource = new MatTableDataSource<DR>([]);
  NFDataSource = new MatTableDataSource<DR>([]);
  MDRDataSource = new MatTableDataSource<DR>([]);
  SiteDataSource = new MatTableDataSource<DR>([]);

  displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'action'];

  constructor(public aRoute: ActivatedRoute,
              public ovService: OVService,
              public router: Router) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.data) {
      this.search_term = this.data.term;
      this.OVDataSource.data = this.data.ovs;
      this.NFDataSource.data = this.data.nfs;
      this.MDRDataSource.data = this.data.mdrs;
      this.SiteDataSource.data = this.data.sites;
    }
  }

  gotoMDR(el) {
    this.router.navigate(['logistic-order/' + el.idMDR]);
  }
}
