import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-view-mdr',
  templateUrl: './view-mdr.component.html',
  styleUrls: ['./view-mdr.component.scss']
})
export class ViewMdrComponent implements OnInit {
  mdr;
  token;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { mdr: any }) => {
      this.token = this.route.snapshot.paramMap.get('token');
      this.mdr = data.mdr;
    });
  }

}
