<h1 mat-dialog-title>Alterar Status da DR</h1>
<div mat-dialog-content>
  <form #form="ngForm" class="d-flex flex-wrap">
    <mat-form-field class="col-md-6">
      <input matInput placeholder="Endereço" name="text" [(ngModel)]="current.deliveryAddress" required>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <mat-select [(ngModel)]="current.status" placeholder="Selecione o Status" name="status" required>
        <mat-option *ngFor="let status of statusList" [value]="status">
          {{ status | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="current.status === 'HubReturn'" class="col-md-6">
      <mat-select  placeholder="HUB" name="HubId" [(ngModel)]="hubId" required>
        <mat-option *ngFor="let elem of hubList" [value]="elem.id"  >
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="current.status === 'HubReturn'"  class="col-md-6">
      <input matInput placeholder="Observação" name="obs" [(ngModel)]="hubObs" required>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput required
        placeholder="Data de Coleta"
        name="datecollect"
        [(ngModel)]="current.datePickupScheduling"
        [owlDateTimeTrigger]="date"
        [owlDateTime]="date">
      <owl-date-time #date pickerType="both"></owl-date-time>
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput required
        placeholder="Data de Entrega"
        name="datepickup"
        [(ngModel)]="current.dateScheduling"
        [owlDateTimeTrigger]="date2"
        [owlDateTime]="date2">
      <owl-date-time #date2 pickerType="both"></owl-date-time>
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <input matInput placeholder="{{ 'DR_BOOKING/responsable-cpm' | translate }}" [(ngModel)]="current.responsableCPM" name="current.responsableCPM">
    </mat-form-field>
    <mat-form-field class="col-md-6">
      <input matInput placeholder="{{ 'DR_BOOKING/responsable-asp' | translate }}" [(ngModel)]="current.responsableASP" name="current.responsableASP">
    </mat-form-field>

    <mat-form-field class="col-md-6">
      <input matInput
        [disabled]="data.role !== 1"
        [owlDateTimeTrigger]="dt"
        [owlDateTime]="dt"
        name="userArrivalTime"
        placeholder="Horário de chegada"
        [(ngModel)]="current.userArrivalTime">
      <owl-date-time #dt></owl-date-time>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="Cidade" [(ngModel)]="current.city" name="city" required>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="Weight" [(ngModel)]="current.weight" name="weight" required>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="UF" [(ngModel)]="current.uf" name="uf" required>
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <input matInput placeholder="Infra" [(ngModel)]="current.infraType" name="infra">
    </mat-form-field>
    <mat-form-field class='col-md-6'>
      <mat-label>{{'NEW-DELIVERY-REQUEST/text/client' | translate}}</mat-label>
      <mat-select [(ngModel)]="current.client" name="client" required>
        <mat-option *ngFor="let client of clientList" [value]="client">
          {{client}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class='col-md-12'>
      <textarea placeholder="Prolog" matInput [(ngModel)]="current.prolog" name="prolog" required></textarea>
    </mat-form-field>
  </form>
</div>
<br>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
  <button mat-button (click)="onYesClick()" [disabled]="!form.valid">Sim</button>
  <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
