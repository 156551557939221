import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public router: Router,
    public snackBar: MatSnackBar
  ) {
    this.auth.logout().subscribe(result => {
      localStorage.clear();
      this.router.navigate(['login']);
    }, error => {
      this.snackBar.open('Erro ao realizar logout', 'Ok', {duration: 2000});
      window.history.back();
    });
  }

  ngOnInit() {
  }

}
