<h1 mat-dialog-title >{{data.text}}</h1>
<mat-form-field style="width: 100%;">
  <mat-chip-list #chipList aria-label="OV list">
    <mat-chip [ngClass]="{ 'red': !elem.found }" *ngFor="let elem of data.ovs" [selectable]="false"
      [removable]="true" (removed)="remove(elem)">
      {{elem.value}}
      <i matChipRemove font class="fa fa-times-circle"></i>
    </mat-chip>
    <input placeholder="Email"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
</mat-form-field>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="[true,data.ovs]" cdkFocusInitial >{{ 'Confirm' | translate }}</button>
  <button mat-button [mat-dialog-close]="[false]" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
</div>
