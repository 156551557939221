<div>

  <div>
    <div *ngIf="currentFileUpload.length > 0" class="progress" style="width: 40%; float: left; margin: 12px 0px;">
      <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar" attr.aria-valuenow="{{progress.percentage}}"
           aria-valuemin="0" aria-valuemax="100" [ngStyle]="{width:progress.percentage+'%'}">
        {{progress.percentage}}%</div>
    </div>
    <div style="width: 100%; float: right;padding: 0px 20px;">
      <label class="btn btn-default">
        <input type="file" #fileInput [(ngModel)]="fileInputValue" (change)="selectFile($event)" style="display:none" multiple>
        <span *ngIf="currentFileUpload.length > 0"  (click)="fileInput.click()"> {{ currentFileUpload.length }} arquivos selecionados</span>
      </label>
      <!--<button *ngIf="currentFileUpload.length > 0" class="btn btn-success" style="float: right;" [disabled]="!selectedFiles" (click)="upload()">Upload</button>-->
      <span *ngIf="currentFileUpload.length === 0" (click)="fileInput.click()"><i class="fas fa-upload"></i></span>
      <!-- <button class="button green-button text-white" *ngIf="currentFileUpload.length === 0" (click)="fileInput.click()">Selecionar Arquivos</button> -->
    </div>


  </div>

</div>
