import {Component, OnInit, ViewChild, Inject, ElementRef, Input, SimpleChange, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource, MatSort, MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import {Occurrence} from '../../../models/occurrence';
import {environment} from '../../../../environments/environment';
import {MDR} from '../../../models/mdr';
import {UploadFileService} from '../../../providers/upload.service';
import {NfeService} from '../../../providers/nfe.service';
import {MdrService} from '../../../providers/mdr.service';
import {DomSanitizer} from '@angular/platform-browser';
import {OVService} from '../../../providers/ov.service';
import { saveAs, encodeBase64 } from 'file-saver/FileSaver';
import {MdrObservable} from '../../../services/mdr.service';
import * as JSZip from 'jszip';

const base_url: string = environment.apiUrl; // + 'file/aqui';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})

export class AttachmentsComponent implements OnInit, OnChanges {
  @Input() mdr: MDR;

  @Output() updateValue = new EventEmitter<string>();

  _mdr: MDR;
  nfList: any[];
  count = 0;
  nfFiles = [];
  rgAux = '';
  nameAux = '';
  dateAux = '';
  uploadMassive: {entity: string, id: number}[] = [];
  hourAux = '';
  podFiles = 0;
  uocList = [];
  disablePODEditing = false;
  uocFiles = [];
  sendStatus = false;
  statusContainer = false;
  totalNfCost: number;

  updateLock = false;

  // public token;
  // OVS_DATA_UOC: File[] = [{fileName: 'Arquivo.xml'}];
  // OVS_DATA_XML: File[] = [{fileName: 'Arquivo.xml'}];
  // OVS_DATA_POD: File[] = [{fileName: 'Arquivo.xml'}];
  //
  // xmlColumns = ['fileName', 'action'];
  // dataFilesXML = new MatTableDataSource<File>(this.OVS_DATA_XML);
  // uocColumns = ['fileName', 'action'];
  // dataFilesUOC = new MatTableDataSource<File>(this.OVS_DATA_UOC);
  // podColumns = ['fileName', 'action'];
  // dataFilesPOD = new MatTableDataSource<File>(this.OVS_DATA_POD);
  //

  constructor(public ovService: OVService, public uploadService: UploadFileService,
              private sanitizer: DomSanitizer, public mdrService: MdrService, public snackBar: MatSnackBar,
              public nfeservice: NfeService, public mdrObservable: MdrObservable) {}
  ngOnInit() {
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      const PODS_BLOCKED_STATUS = [
        'Entrega de carga certificada pelo LSP',
        'Entrega certificada-LSP',
        'MDR paga / concluída',
        'MDR paid / completed'
      ];
      this.disablePODEditing = PODS_BLOCKED_STATUS.includes(this.mdr['status']);
      this.updateNF();
      this.getUOC();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
      // this.updateNF();
  }
  receiveFile($event) {
    // console.log($event);
    if (this.count === this.uploadMassive.length) {
      this.updateNF();
      this.count = 0;
    } else {
      this.count++;
    }

  }

  getUOC() {
    this.mdrService.getUOC(this.mdr).subscribe( (data) => {
      this.uocList = data;
      this.uocList.forEach( (el, i, arr) => {
        this.mdrService.getUOCWithInfo(el.id).subscribe( (response) => {
          el['files'] = response;
        });
      });
    });
  }

  updateInfo() {
    this.nfList.forEach( (nf) => {
      if (typeof nf['file'] !== 'undefined' && nf['protected'] === false) {
        nf['file'].forEach( (file) => {
          this.uploadService.updateFileInfo(file).subscribe((data) => {
            // console.log(data);
          });
        }, this);
      }
    }, this);
  }

  removeNF(nf: any) {
    this.uploadService.deleteByEntity(nf['id'], 'mdr').subscribe(response => {
      // console.log(response);
      this.updateNF();
    });
  }
  removePOD(nf: any) {
    this.uploadService.deleteByEntity(nf['id'], 'nf').subscribe(response => {
      this.updateNF();
    });
  }

  hasEmptyPOD() {
    const emptyUocs = this.uocList.some(uoc => uoc.status && uoc.files.length === 0);
    const emptyNfs = this.nfList.some(nf => nf.file === undefined);

    return emptyUocs || emptyNfs;
  }

  savePOD(nf: any) {
    const info = { 'info_id' : nf.id };
    ['info_deliveredAt', 'info_name', 'info_rg', 'info_timeDeliveredAt'].forEach( (el, i, arr) => {
      if ( el in nf ) {
        info[el] = nf[el];
      }
    });
    this.uploadService.updateFileInfo( info ).subscribe( (response) => {
      this.snackBar.open('Dados do Recebedor atualizados', 'Fechar', {
        duration: 4000,
      });
    });
  }

  // public createPayment() {
  //   this.nfList.forEach((k) => {
  //     //console.log(k);
  //   });
  // }
  public updateNF() {
    if (!this.updateLock) {
      this.updateLock = true;

      this.podFiles = 0;
      this.totalNfCost = 0;
      this.nfFiles = [];
      if (this.mdr.id !== null) {
        this.ovService.getNfByMdr(this.mdr.id).subscribe((data) => {
          this.nfList = data;
          this.nfList.forEach(elem => {
            this.uploadMassive.push({entity: 'nf', id: elem['nf']});
          });
         // console.log(this.nfList);
          setTimeout(() => {
            this.updateLock = false;
          }, this.nfList.length * 200);

          this.uploadService.findByEntity(this.mdr.id, 'mdr').subscribe((files) => {
            this.nfFiles = [].concat(this.nfFiles, files);
            // this.nfFiles.push(...files);
          });

          this.nfList.forEach((k) => {
            const id = +k.nf.split('-', 1)[0];

            // anexos das NFes
            if ('attachment' in k) {
              k.attachment['protected'] = true;
              this.nfFiles.push({
                url: k.attachment.path,
                fileName: k.nf + ' - ' + k.attachment.fileOriginalName,
                protected: true
              });
            }

            this.nfeservice.getAll_xml(id).subscribe((arr) => {
              if (arr.length > 0) {
                this.uploadService.findByEntity(id, 'nfe_xml').subscribe((files) => {
                  files[0]['protected'] = true;
                  this.nfFiles.push(files[0]);
                });
                this.totalNfCost += +arr[0].totalValue;
                this.updateValue.next('');
              }
            });
            this.uploadService.findByEntityWithInfo(k.nf, 'nf').subscribe((ans) => {
              if (ans.length === 0) {
                k.file = undefined;
              } else {
                k.file = [];
                this.podFiles++;
                for (const i of Object.keys(ans)) {
                  k.file.push(ans[i]);
                }
              }
            });

          });
          this.getUOC();
        });

      } else {
        this.updateLock = false;
      }
    }
  }

  copyData(file) {
    this.rgAux = file.info_rg;
    this.nameAux = file.info_name;
    this.dateAux = file.info_deliveredAt;
    this.hourAux = file.info_timeDeliveredAt;
  }

  pasteData(file) {
    file.info_rg = this.rgAux;
    file.info_name = this.nameAux;
    file.info_deliveredAt = this.dateAux;
    file.info_timeDeliveredAt = this.hourAux;
  }

  getDataUrl = function (img) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL();
  };

  async dowloadAll() {
    // console.log(this.nfFiles);
    const zip = new JSZip();
    const img = zip.folder('images');
    const nffiles = this.nfFiles;
    const uploadService = this.uploadService;
    for (const i of nffiles) {
      const image = i;
      const url = i.url;
      await uploadService.getImage(url).subscribe((result) => {
        const reader = new FileReader();
        // console.log(result);
        reader.readAsText(result);
        reader.onloadend = function () {
          const imgData = reader.result;
          img.file(image['id'] + image['fileName'], result, {base64: true});
          // console.log(i);
          if (i === nffiles[nffiles.length - 1]) {
            zip.generateAsync({type: 'blob'})
               .then(function (content) {
                 // see FileSaver.js
                 saveAs(content, 'notas_fiscais.zip');
               });
          }
        };
      });
    }

  }

}
