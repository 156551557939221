import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatSnackBar, MatDatepicker } from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../providers/report.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
    @ViewChild(MatDatepicker) start: MatDatepicker<Date>;
    @ViewChild(MatDatepicker) end: MatDatepicker<Date>;

    startDate: Date = null;
    endDate: Date = null;
    role: number;

    minDate = new Date(2000, 0, 1);
    maxDate = new Date(2023, 0, 1);

    reportList = [
        {type: 'lsp', name: 'LSP'},
        {type: 'lspkpi', name: 'LSP KPI'},
        {type: 'perf', name: this.translate.instant('PERFORMANCE')},
        {type: 'oper', name: this.translate.instant('OPERATION')},
        {type: 'bat', name: 'Bateria'},
        {type: 'mdr', name: 'MDRs e DRs'},
        {type: 'stake', name: 'Stakeholders'},
        {type: 'cmdr', name: 'CMDR'},
        {type: 'cov', name: 'COV'},
        {type: 'poddp', name: 'POD DP'},
        {type: 'hub', name: 'Hub'},
        {type: 'dpunion', name: 'DP Union'},
        {type: 'uoc', name: 'UOC'},
    ];

    report = 'lsp';

    constructor(public translate: TranslateService,
                public reportService: ReportService,
                public excel: ExcelService,
                public router: Router,
                public snackBar: MatSnackBar) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = currentUser.user.role;
    }

    ngOnInit() {
        if (this.role !== 1) {
            this.reportList = [{type: 'stake', name: 'Stakeholders'}];
        }
    }

    async download() {
        if (!this.startDate || !this.endDate) {
            this.snackBar.open('Selecione as datas a consultar', 'Fechar', {
                duration: 2000,
            });
            return;
        }
        switch (this.report) {
            case 'stake': {
                this.reportService.getStakeholders(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'stake': worksheet }, SheetNames: ['stake'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'stake');
                });
            }
            break;
            case 'lsp': {
                this.reportService.getLSP(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'LSP': worksheet }, SheetNames: ['LSP'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'lsp');
                });
            }
            break;
            case 'lspkpi': {
                this.reportService.getLSPKPI(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'KPI': worksheet }, SheetNames: ['KPI'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'lspkpi');
                });
            }
            break;
            case 'perf': {
                this.reportService.getPerfLSP(this.startDate, this.endDate).subscribe((data) => {
                    const sheets = {};
                    const names = [];
                    for (const title of Object.keys(data)) {
                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                            skipHeader: true
                        });
                        names.push(title);
                    }
                    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: names };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'perf');
                });
            }
            break;
            case 'oper': {
                this.reportService.getOperation(this.startDate, this.endDate).subscribe((data) => {
                    const sheets = {};
                    const names = [];
                    for (const title of Object.keys(data)) {
                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                            skipHeader: true
                        });
                        names.push(title);
                    }
                    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: names };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'oper');
                });
            }
            break;
            case 'bat': {
                this.reportService.getLSP(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'LSP': worksheet }, SheetNames: ['LSP'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'bat');
                });
            }
            break;
            case 'cmdr': {
                this.reportService.getCMDR(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'CMDR': worksheet }, SheetNames: ['CMDR'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'cmdr');
                });
            }
            break;
            case 'cov': {
                this.reportService.getCOV(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'COV': worksheet }, SheetNames: ['COV'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'cov');
                });
            }
            break;
            case 'poddp': {
                this.reportService.getPODDP(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'PODDP': worksheet }, SheetNames: ['PODDP'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'poddp');
                });
            }
            break;
            case 'hub': {
                this.reportService.getHub(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'Hub': worksheet }, SheetNames: ['Hub'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'hub');
                });
            }
            break;
            case 'dpunion': {
                this.reportService.getDPUnion(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'DPUnion': worksheet }, SheetNames: ['DPUnion'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'dpunion');
                });
            }
            break;
            case 'uoc': {
                this.reportService.getUOC(this.startDate, this.endDate).subscribe((data) => {
                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
                        skipHeader: true
                    });
                    const workbook: XLSX.WorkBook = { Sheets: { 'UOC': worksheet }, SheetNames: ['UOC'] };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'uoc');
                });
            }
            break;
            case 'mdr': {
                this.reportService.getCockpit(this.startDate, this.endDate).subscribe((data) => {
                    const sheets = {};
                    const names = [];
                    for (const title of Object.keys(data)) {
                        sheets[title] = XLSX.utils.json_to_sheet(data[title], {
                            skipHeader: true
                        });
                        names.push(title);
                    }
                    const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: names };
                    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.excel.saveAsExcelFile(excelBuffer, 'mdr-dr');
                });
            }
            break;
        }
    }
}
