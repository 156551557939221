import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';
import {MDR} from '../models/mdr';

const base_url: string = environment.apiUrl;

@Injectable()
export class NotificationService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAll(id: number): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    // let params = new HttpParams().set("token",token);
      return this.http.get(base_url + 'Notification/' + id, {headers: header})
          .map((response) => {
            // console.log(response);
            return response;
          });
    }
  createMessage(idMDR: number, idUser: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    // let params = new HttpParams().set("token",token);
    return this.http.post(base_url + 'Notification/create', {
      idMDR: idMDR,
      idUser: idUser,
    }, { headers: header}).map((response) => {
     // console.log(response);
      return response;
    });
  }


}
