<h1 mat-dialog-title >{{ 'LSPEMAIL/title' | translate }}</h1>
<div mat-dialog-actions>
  <mat-form-field class="dropdown">
    <mat-select placeholder="Usuarios" [formControl]="selected" multiple>
      <mat-option *ngFor="let u of data" [value]="u">{{u.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="[false]" >{{ 'Cancel' | translate }}</button>
    <button mat-button class="primary-button" [mat-dialog-close]="[true, selected]" cdkFocusInitial>{{ 'Confirm' | translate }}</button>
  </div>
</div>
