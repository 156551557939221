import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CheckTokenGuard } from './check-token.guard';
import { ResolverService } from './resolver.service';

import { ViewMdrComponent } from './view-mdr/view-mdr.component';

const routes: Routes = [
  {
    path: 'view/:token',
    canActivate: [CheckTokenGuard],
    resolve: {
      mdr: ResolverService
    },
    children: [
      { path: '', redirectTo: 'mdr', pathMatch: 'full' },
      { path: 'mdr', component: ViewMdrComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WithoutLoginRoutingModule { }
