import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { OVService } from '../../providers/ov.service';

@Component({
  selector: 'app-vehicle-calculator',
  templateUrl: './vehicle-calculator.component.html',
  styleUrls: ['./vehicle-calculator.component.scss']
})
export class VehicleCalculatorComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  columns = [
    {value: 'ourOrderNo', name: 'VEHICLE_CALCULATOR/table/ov'},
    {value: 'boxNo', name: 'VEHICLE_CALCULATOR/table/box'},
    {value: 'grossWeight', name: 'VEHICLE_CALCULATOR/table/weight'},
    {value: 'height', name: 'VEHICLE_CALCULATOR/table/height'},
    {value: 'length', name: 'VEHICLE_CALCULATOR/table/lenght'},
    {value: 'width', name: 'VEHICLE_CALCULATOR/table/widht'},
    {value: 'volume', name: 'VEHICLE_CALCULATOR/table/volume'},
    {value: 'site', name: 'VEHICLE_CALCULATOR/table/site'},
    {value: 'delivery', name: 'VEHICLE_CALCULATOR/table/delivery'}
  ];
  ovs = [];
  vehicle: any = null;

  constructor(private ovservice: OVService) { }

  ngOnInit() {
  }

  getVehicle() {
    this.ovservice.getVehicle(this.ovs.map((e) => e.value)).subscribe((data) => {
      // console.log(data);
      for (const ov of this.ovs) {
        if (data.validOVs.indexOf(ov.value.toString()) !== -1) {
          ov.found = true;
        } else {
          ov.found = false;
        }
      }
      if (data.validOVs.length > 0) {
        data.searched.height = data.searched.height / 10;
        data.searched.width = data.searched.width / 10;
        data.searched.length = data.searched.length / 10;
        data.searched.volume = data.searched.volume / 1000;
        this.vehicle = data;
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = +event.value.trim();

    if (value) {
      let duplicate = false;
      for (const ov of this.ovs) {
        if (ov.value === value) {
          duplicate = true;
          break;
        }
      }

      if (!duplicate) {
        this.ovs.push({ value: value, found: true });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(ov): void {
    const index = this.ovs.indexOf(ov);

    if (index >= 0) {
      this.ovs.splice(index, 1);
    }
  }
}
