import { OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DrService } from '../../providers/dr.service';
import { MDR } from '../../models/mdr';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
var DeliveryOptimizerComponent = /** @class */ (function () {
    function DeliveryOptimizerComponent(dialog, mapsAPILoader, drService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.selection = new SelectionModel(true, []);
        this.showLightBox = false;
        this.filter = '';
        this.currentUser = null;
        this.role = null;
        this.user = null;
        this.isBooking = false;
        this.dataSource = null;
        this.displayedColumns = ['select', 'idDRidentifier', 'deliveryAddress', 'dateScheduling', 'site', 'createdBy', 'client', 'thirdPartInvoice', 'goTo'];
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.sort = this.sort;
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
    }
    DeliveryOptimizerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.sort = this.sort;
        console.log(this.isBooking);
        if (this.role === 7 || this.isBooking) {
            this.displayedColumns = ['select', 'idDRidentifier', 'site', 'prolog', 'deliveryAddress', 'dateScheduling', 'goTo'];
            this.drService.getBookingsLSP().subscribe(function (elem) {
                console.log(elem);
                elem.forEach(function (row) {
                    if (row.ovs) {
                        row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                    }
                    else {
                        row.ovList = '';
                    }
                });
                var listDRs = [];
                elem.forEach(function (k) {
                    if (k.status === 'BOOKINGLSP') {
                        listDRs.push(k);
                    }
                });
                _this.data = listDRs;
                // console.log(this.data);
                _this.dataSource = new MatTableDataSource(_this.data);
                setTimeout(function () { _this.dataSource.sort = _this.sort; }, 10);
            });
        }
        else {
            var listDR_1 = [];
            this.drService.getAllOpen().subscribe(function (data2) {
                data2.forEach(function (row) {
                    if (row.ovs) {
                        row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                    }
                    else {
                        row.ovList = '';
                    }
                    if (row.status === 'READY_TO_PICKUP') {
                        listDR_1.push(row);
                    }
                });
                _this.data = listDR_1;
                // console.log(this.data);
                _this.dataSource = new MatTableDataSource(_this.data);
                setTimeout(function () { _this.dataSource.sort = _this.sort; }, 10);
            });
        }
    };
    DeliveryOptimizerComponent.prototype.changeBooking = function () {
        this.isBooking = !this.isBooking;
        this.ngOnInit();
    };
    DeliveryOptimizerComponent.prototype.goToBooking = function (elem) {
        this.router.navigate(['dr-validation/' + elem.id]);
    };
    DeliveryOptimizerComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    };
    /** Whether the number of selected elements matches the total number of rows. */
    DeliveryOptimizerComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    DeliveryOptimizerComponent.prototype.masterToggle = function () {
        var _this = this;
        // console.log(this.dataSource.filteredData);
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) {
                if (_this.dataSource.filteredData.indexOf(row) > -1) {
                    _this.selection.select(row);
                }
            });
    };
    DeliveryOptimizerComponent.prototype.unmakeDR = function () {
        var _this = this;
        this.drService.unmakeDR(this.selection.selected).subscribe(function (data) {
            var listDR = [];
            _this.drService.getAllOpen().subscribe(function (data2) {
                data2.forEach(function (row) {
                    if (row.ovs) {
                        row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                    }
                    else {
                        row.ovList = '';
                    }
                    if (row.status === 'READY_TO_PICKUP') {
                        listDR.push(row);
                    }
                });
                _this.data = listDR;
                // console.log(this.data);
                _this.dataSource = new MatTableDataSource(_this.data);
                _this.dataSource.sort = _this.sort;
                _this.snackBar.open('Undo Correctly', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['delivery-optimizer']);
            });
        });
    };
    DeliveryOptimizerComponent.prototype.returnDR = function () {
        var _this = this;
        this.selection.selected.forEach(function (dr) {
            dr.status = 'BOOKINGLSP';
            dr.siteTeam = '2';
            dr.obs = 'WARNING: REMOVED FROM ALISTADO!   ' + dr.obs;
            _this.drService.update(dr).subscribe(function (k) {
                var listDR = [];
                _this.drService.getAllOpen().subscribe(function (data2) {
                    data2.forEach(function (row) {
                        if (row.ovs) {
                            row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                        }
                        else {
                            row.ovList = '';
                        }
                        if (row.status === 'BOOKINGLSP') {
                            listDR.push(row);
                        }
                    });
                    _this.data = listDR;
                    // console.log(this.data);
                    _this.dataSource = new MatTableDataSource(_this.data);
                    _this.dataSource.sort = _this.sort;
                    _this.snackBar.open('Removed correctly. Please reload the page.', 'Ok', {
                        duration: 4000,
                    });
                    _this.router.navigate(['delivery-optimizer']);
                });
            });
        });
    };
    DeliveryOptimizerComponent.prototype.goToMDRAvailable = function () {
        var _this = this;
        this.selection.selected.forEach(function (dr) {
            dr.thirdPartInvoice = true;
            _this.drService.update(dr).subscribe(function (k) {
            });
            _this.snackBar.open('Correctly sent to MDR Available', 'Ok', {
                duration: 4000,
            });
            _this.router.navigate(['delivery-optimizer']);
        });
    };
    DeliveryOptimizerComponent.prototype.goToMDRUnavailable = function () {
        var _this = this;
        this.selection.selected.forEach(function (dr) {
            dr.thirdPartInvoice = false;
            _this.drService.update(dr).subscribe(function (k) {
            });
            _this.snackBar.open('Correctly sent to MDR Unavailable', 'Ok', {
                duration: 4000,
            });
            _this.router.navigate(['delivery-optimizer']);
        });
    };
    DeliveryOptimizerComponent.prototype.validateDR = function () {
        var _this = this;
        this.selection.selected.forEach(function (dr) {
            dr.status = 'READY_TO_PICKUP';
            _this.drService.update(dr).subscribe(function (k) {
                // const listDR = [];
                // this.drService.getAllOpen().subscribe( (data2) => {
                //   data2.forEach( (row) => {
                //     if (row.ovs) {
                //       row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
                //     } else {
                //       row.ovList = '';
                //     }
                //     if (row.status === 'BOOKINGLSP' ) {
                //       listDR.push(row);
                //     }
                //   });
                //   this.data = listDR;
                // // console.log(this.data);
                //   this.dataSource = new MatTableDataSource<DR>(this.data);
                //   this.snackBar.open('Validated Correctly', 'Ok', {
                //     duration: 4000,
                //   });
                //   this.router.navigate(['delivery-optimizer']);
                // });
            });
            _this.snackBar.open('Validated Correctly', 'Ok', {
                duration: 4000,
            });
            _this.ngOnInit();
        });
    };
    DeliveryOptimizerComponent.prototype.createConsolidationMDR = function () {
        var _this = this;
        var mdr = new MDR();
        mdr.status = 'MDR em validação';
        this.selection.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        this.mdrService.insertConsolidation(mdr).subscribe(function (data) {
            // console.log('MDR new Service');
            // console.log(data);
            _this.mdrService.createLegs(data.id, false).subscribe(function (legs) {
                // console.log(legs);
            });
            var listDR = [];
            _this.drService.getAllOpen().subscribe(function (data2) {
                data2.forEach(function (row) {
                    if (row.ovs) {
                        row.ovList = row.ovs.map(function (e) { return e.salesDocument; }).join(', ');
                    }
                    else {
                        row.ovList = '';
                    }
                    if (row.status === 'READY_TO_PICKUP') {
                        listDR.push(row);
                    }
                });
                _this.data = listDR;
                // console.log(this.data);
                _this.dataSource = new MatTableDataSource(_this.data);
                _this.dataSource.sort = _this.sort;
                _this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['delivery-optimizer']);
            });
        });
    };
    DeliveryOptimizerComponent.prototype.openDialog = function () {
        var _this = this;
        var flag = 0;
        var isOwner = 0;
        this.selection.selected.forEach(function (el) {
            if (el.thirdPartInvoice !== true) {
                _this.snackBar.open('DR selected not available to shipment', 'Ok', {
                    duration: 4000,
                });
                flag = 1;
            }
            if (el.createdBy !== _this.currentUser.user.name) {
                isOwner = 1;
            }
        });
        if (flag === 0) {
            var dialogRef = this.dialog.open(TypeMdrDialogComponent, {
                width: '600px',
                height: '500px',
                data: {
                    id: 1,
                    isOwner: isOwner,
                }
            });
            dialogRef.afterClosed().subscribe(function (data) {
                if (data !== undefined) {
                    _this.createNewMDR(data);
                }
            });
        }
    };
    DeliveryOptimizerComponent.prototype.createNewMDR = function (typeMDR) {
        var _this = this;
        // console.log(typeMDR);
        // cria MDR
        var mdr = new MDR();
        mdr.type = typeMDR.type;
        mdr.status = 'MDR em validação';
        // adiciona DRs selecionadas
        this.selection.selected.forEach(function (el) {
            mdr.drList.push(el);
        });
        var flag = false;
        if (mdr.type === 'Cross Doc') {
            this.createConsolidationMDR();
            return;
        }
        else if (mdr.type === 'CMS' && typeMDR.data) {
            mdr.SAD = typeMDR.data;
            if (typeMDR.data === true) {
                flag = true;
            }
        }
        else if (mdr.type === 'DTR' && typeMDR.data) {
            mdr['dtr'] = typeMDR.data;
        }
        // }else if(mdr.type === 'DTR' && typeMDR.data){
        //   const drList = [];
        //   for(let i = typeMDR.data.length-1; i>=0; i--){
        //     const dr = new DR();
        //     dr.deliveryAddress = typeMDR.data[i].address;
        //     dr.lat = typeMDR.data[i].lat;
        //     dr.lng = typeMDR.data[i].lng;
        //     dr.uf = typeMDR.data[i].uf;
        //     earlydate.setDate(earlydate.getDate() - i - 1);
        //     dr.dateScheduling = earlydate;
        //     drList.push(dr);
        //   }
        //   //console.log(drList);
        // }
        this.mdrService.insert(mdr, flag).subscribe(function (data) {
            var listDR = [];
            _this.selection = new SelectionModel(true, []);
            _this.ngOnInit();
        });
    };
    return DeliveryOptimizerComponent;
}());
export { DeliveryOptimizerComponent };
var TypeMdrDialogComponent = /** @class */ (function () {
    function TypeMdrDialogComponent(dialogRef, mapsAPILoader, data) {
        this.dialogRef = dialogRef;
        this.mapsAPILoader = mapsAPILoader;
        this.data = data;
        this.types = [{ id: 'MF', name: 'MF' }, { id: 'SWAP', name: 'SWAP' }, { id: 'CMS', name: 'CMS' }, { id: 'DTR', name: 'INV' }, { id: 'Cross Doc', name: 'HUB' }];
        this.returnLeg = false;
        this.listAddresses = [];
        this.addressReturn = [];
        this.address = null;
        this.isFromSameUser = false;
    }
    TypeMdrDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.typeMDR = null;
        console.log(this.data.isOwner);
        if (this.data.isOwner === 1) {
            this.isFromSameUser = true;
        }
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    TypeMdrDialogComponent.prototype.addAddress = function () {
        var _this = this;
        var address = (document.getElementById('deliveryAddress').value);
        this.listAddresses.push({ address: address });
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
            // console.log(results);
            var index = _this.listAddresses.length - 1;
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                var uf = results[0].address_components[2].short_name;
                _this.addressReturn.push({ address: address, lat: lat, lng: lng, uf: uf });
                // console.log(this.addressReturn);
            }
            else {
                _this.listAddresses.splice(index, 1);
                // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
                // console.log(status);
            }
        });
    };
    TypeMdrDialogComponent.prototype.removeAddress = function (i) {
        this.listAddresses.splice(i, 1);
    };
    TypeMdrDialogComponent.prototype.onYesClick = function () {
        if (this.typeMDR === 'CMS') {
            this.dialogRef.close({ type: this.typeMDR, data: this.returnLeg });
        }
        else if (this.typeMDR === 'DTR') {
            this.dialogRef.close({ type: this.typeMDR, data: this.addressReturn });
        }
        else {
            this.dialogRef.close({ type: this.typeMDR, data: false });
        }
    };
    TypeMdrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return TypeMdrDialogComponent;
}());
export { TypeMdrDialogComponent };
