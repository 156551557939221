import { OnInit } from '@angular/core';
import { MatSnackBar, MatPaginator } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { OVService } from '../../providers/ov.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { parseString } from 'xml2js';
import { NF } from '../../models/nfe';
import { NfeService } from '../../providers/nfe.service';
import { TranslateService } from '@ngx-translate/core';
var BulkNfeComponent = /** @class */ (function () {
    function BulkNfeComponent(dialog, ovService, translate, router, drService, nfService, snackBar) {
        this.dialog = dialog;
        this.ovService = ovService;
        this.translate = translate;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.snackBar = snackBar;
        this.uploaded = false;
        this.displayedColumns3 = ['invoiceNumber', 'series', 'client', 'type',
            'weight', 'totalValue', 'emissionDate', 'width', 'height', 'depth', 'materials'];
        this.dataSourceNF = new MatTableDataSource();
        this.newNfList = [];
        this.fileList = [];
    }
    BulkNfeComponent.prototype.ngOnInit = function () {
    };
    BulkNfeComponent.prototype.insertNFe = function () {
        var _this = this;
        this.nfService.create_xml(this.newNfList, this.fileList).subscribe(function (data) {
            _this.uploaded = false;
        });
    };
    BulkNfeComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        this.newNfList = [];
        this.fileList = [];
        var _loop_1 = function (index) {
            var reader = new FileReader();
            reader.readAsText(input.files[index]);
            reader.onload = function () {
                parseString(reader.result, function (err, result) {
                    var info = result.nfeProc.NFe[0].infNFe[0];
                    var nf = new NF();
                    nf.totalValue = info.total['0'].ICMSTot['0'].vNF['0'];
                    nf.emissionDate = info.ide['0'].dhEmi['0'];
                    nf.client = info.dest['0'].xNome['0'];
                    nf.series = info.ide['0'].serie['0'];
                    nf.type = info.ide['0'].tpNF['0'];
                    // console.log(info.transp['0'].vol['0']);
                    if (info.transp['0'].vol['0'].pesoB !== undefined) {
                        nf.weight = info.transp['0'].vol['0'].pesoB['0'];
                    }
                    else {
                        nf.weight = '0';
                    }
                    nf.materials = info.det['0'].prod['0'].xProd['0'];
                    nf.invoiceNumber = info.ide['0'].nNF['0'];
                    _this.newNfList.push(nf);
                    _this.fileList.push(input.files[index]);
                    if (index === input.files.length - 1) {
                        _this.dataSourceNF = new MatTableDataSource(_this.newNfList);
                        _this.dataSourceNF.paginator = _this.paginator;
                        _this.uploaded = true;
                    }
                });
            };
        };
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
    };
    return BulkNfeComponent;
}());
export { BulkNfeComponent };
