<h1 mat-dialog-title>Edição da Ocorrência</h1>
<div mat-dialog-content>
   <form #form="ngForm" class="row">
      <div class="col-md-12"><p>Ocorrência inicial: {{_occ.uoc}}</p></div>
      <mat-form-field class="col-lg-12 col-md-12">
         <mat-select placeholder="{{ 'OCURRENCE/type' | translate }}" [(ngModel)]="occ.uoc" name="uoc" required>
           <mat-option *ngFor="let elem of uoc" [value]="elem.name">
             {{ elem.name }}
           </mat-option>
         </mat-select>
       </mat-form-field>
       <div class="col-md-12"><p>Descricíon: {{_occ.text}}</p></div>
       <mat-form-field class="col-lg-12 col-md-12">
         <input matInput style="width: 100%" placeholder="Descricíon" name="text" [(ngModel)]="occ.text" required>
        </mat-form-field>
   <!-- Checkbox is UOC -->
   </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button [disabled]="!form.valid" (click)="onYesClick()" cdkFocusInitial>Sim</button>
   <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
