<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'PRICE/title' | translate }}</p>
</div>

<div class="blue-background back-container">
</div>

<div class="row">
  <div class="white-background container-row center-container container-border col-md-10">
    <div class="row" >
      <mat-form-field class="col-md-3">
        <mat-select  placeholder="{{ 'PRICE/state_init' | translate }}"  (selectionChange)="getTotal()" [(ngModel)]="price['deliveryRegion']"  >
          <mat-option *ngFor="let elem of state" [value]="elem.id"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-3" *ngIf="deliveryRegion==='SP'" >
        <mat-select  placeholder="{{ 'PRICE/sub_state_init' | translate }}"  [(ngModel)]="price.subRegion"  >
          <mat-option *ngFor="let elem of subRegion" [value]="elem.id"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-select  placeholder="{{ 'PRICE/state_end' | translate }}"  [(ngModel)]="price['pickupRegion']"  >
          <mat-option *ngFor="let elem of state" [value]="elem.id"  >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row" >
      <mat-form-field class="col-md-3">
        <mat-select  placeholder="{{ 'PRICE/drive' | translate }}" [(ngModel)]="price['vehicle']">
          <mat-option *ngFor="let elem of veiculo" [value]="elem.name" >
            {{ elem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input  matInput type="text" placeholder="{{ 'PRICE/all_km' | translate }}" [(ngModel)]="price['totalDistance']">
      </mat-form-field>
    </div>
    <div class="row" >
      <mat-form-field class="col-md-3">
        <input  matInput type="number" placeholder="{{ 'PRICE/add' | translate }}" [(ngModel)]="price['drAmount']">
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input  matInput type="number" placeholder="{{ 'PRICE/total_value' | translate }}" [(ngModel)]="price['freight']">
        <!-- <span matPrefix>R$</span> -->
      </mat-form-field>
    </div>
    <div class="row" >
      <div class="col-md-3" *ngIf='total !== "-1"'>
        {{ 'Price/total' | translate }} {{total}}
      </div>
    </div>
    <div class="cd-md-1">
      <button  mat-raised-button class=' button green-button text-white' (click)="getPrice()">{{ 'PRICE/calculate' | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="data" class="colored-background table-background px-5 py-2">
  <app-table
    [data]="data"
    [columns]="columns"
  ></app-table>
</div>
