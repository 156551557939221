export class HUBDR {

  public id: number;
  public hubID: number;
  public drID: number;
  public observation: string;
  public when: Date;

  constructor() {
    this.id = null;
    this.observation = null;
    this.drID = null;
    this.hubID = null;
    this.when = null;
  }

}
