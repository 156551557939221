import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { ClientUserService } from '../../providers/client-user.service';
var ClientUserComponent = /** @class */ (function () {
    function ClientUserComponent(dialog, clientService, router, drService, snackBar) {
        var _this = this;
        this.dialog = dialog;
        this.clientService = clientService;
        this.router = router;
        this.drService = drService;
        this.snackBar = snackBar;
        this.displayedColumns3 = ['name', 'clientId', 'remove'];
        this.dataSourceNF = new MatTableDataSource();
        this.newNfList = [];
        this.clientList = [];
        this.tpList = [];
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        this.clientService.getClients().subscribe(function (data) {
            _this.clientList = data;
            // console.log(data);
        });
        this.clientService.getTpUsers().subscribe(function (data) {
            _this.tpList = data;
        });
        this.clientService.getAll().subscribe(function (data) {
            // console.log(data);
            _this.dataSourceNF.data = data;
        });
    }
    ClientUserComponent.prototype.ngOnInit = function () {
    };
    ClientUserComponent.prototype.addNewRule = function () {
        var _this = this;
        // console.log(this.selectedClient);
        // console.log(this.selectedTp);
        this.clientService.create(this.selectedClient, this.selectedTp).subscribe(function (data) {
            _this.clientService.getAll().subscribe(function (el) {
                _this.dataSourceNF.data = el;
            });
        });
    };
    ClientUserComponent.prototype.remove = function (el) {
        var _this = this;
        this.clientService.remove(el.id).subscribe(function (data) {
            // console.log(data);
            _this.clientService.getAll().subscribe(function (k) {
                _this.dataSourceNF.data = k;
            });
        });
    };
    return ClientUserComponent;
}());
export { ClientUserComponent };
