import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { groupBy } from 'rxjs/operators';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class DashboardService {
  private token: string;
  private header;
  private url: string;
  public dateRange = {
    startDate: new Date,
    endDate: new Date
  };

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    this.url = base_url + 'statistics/';
    this.token = JSON.parse(localStorage.getItem('currentUser')).token;
    this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);

  }
  getMdrByShippingCompany(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/shippingCompany', { headers: this.header,
      params: {
        groupBy: 'month',
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected} });
  }

  getDeliveryPrecision(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/status/precision',
    { headers: this.header ,
      params: {
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected
      }
      });
  }

  getDrByClient(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/client', { headers: this.header, params: {
      startDate: start,
      endDate: end,
      type: typeSelected,
      lsp: lspSelected,
      uf: ufSelected,
      client: clientSelected,
    } });
  }

  getMdrByCreated(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/createdAt/shippingCompany',
    { headers: this.header ,
      params: {
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected
      }
      });
  }

  getMdrByDelivered(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/status/shippingCompany', {
      headers: this.header,
      params: {
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected,
        status: ['MDR paga / concluída', 'Entrega de carga certificada pelo LSP', 'Carga entregue com sucesso'],
      }
   });
  }

  getDrByStatus(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/status', { headers: this.header , params: {
      startDate: start,
      endDate: end,
      type: typeSelected,
      lsp: lspSelected,
      uf: ufSelected,
      client: clientSelected,
      groupBy: 'month'
    } });
  }

  getC02ByCreatedAt(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);


    return this.http.get(this.url + 'mdr/createdAt/c02', { headers: this.header , params: {
      startDate: start,
      endDate: end,
      type: typeSelected,
      lsp: lspSelected,
      uf: ufSelected,
      client: clientSelected,
      groupBy: 'month'
    } });
  }


  getDrByDriverPosition(dates?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/driverPosition', { headers: this.header });
  }

  getOccurrence() {
    return this.http.get(this.url + 'occurrence', { headers: this.header,
    params: {
      startDate: this.dateRange.startDate.toISOString(),
      endDate: this.dateRange.endDate.toISOString(),
    }});
  }

  getOccurrenceWords(dates?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'occurrence/text', { headers: this.header,  params: {
        startDate: start,
        endDate: end
    }});
  }

  getPods() {
    return this.http.get(this.url + 'pod', { headers: this.header,
      params: {
        startDate: this.dateRange.startDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString() }});
  }

  getCostKg(group: string, dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'mdr/costPerKilo', {
      headers: this.header,
      params: {
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected,
        groupBy: group
      }
    });
  }

  getOVsDelivered(dates?, ufSelected?, typeSelected?, lspSelected?, clientSelected?) {
    const {start, end} = this.parseDates(dates);

    return this.http.get(this.url + 'dr/status/delivered', {
      headers: this.header,
      params: {
        startDate: start,
        endDate: end,
        type: typeSelected,
        lsp: lspSelected,
        uf: ufSelected,
        client: clientSelected,
      }
    });
  }

  parseDates(dates?) {
    let stDate, fnDate;
    const now = new Date();
    if (dates === undefined) {
      // no Dates received:
      // get prior month as start
      // and now as end
      stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      stDate.setMonth(stDate.getMonth() - 1);
      fnDate = now;
    } else {
      // start date received
      if (dates[0] !== null) {
        // convert the value to Date
        stDate = new Date(dates[0]);
      } else {
        // no start date received
        // get prior month as start
        stDate = new Date(now.getFullYear(), now.getMonth(),  now.getDate());
        stDate.setMonth(stDate.getMonth() - 1);
      }

      // end date received
      if (dates[1] !== null) {
        // convert the value to Date
        fnDate = new Date(dates[1]);
      } else {
        // use today
        fnDate = now;
      }
    }

    return { start: stDate.toISOString(), end: fnDate.toISOString() };
  }


}
