var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { MDR } from '../../../models/mdr';
import { UploadFileService } from '../../../providers/upload.service';
import { NfeService } from '../../../providers/nfe.service';
import { MdrService } from '../../../providers/mdr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OVService } from '../../../providers/ov.service';
import { saveAs } from 'file-saver/FileSaver';
import { MdrObservable } from '../../../services/mdr.service';
import * as JSZip from 'jszip';
var base_url = environment.apiUrl; // + 'file/aqui';
var AttachmentsComponent = /** @class */ (function () {
    // public token;
    // OVS_DATA_UOC: File[] = [{fileName: 'Arquivo.xml'}];
    // OVS_DATA_XML: File[] = [{fileName: 'Arquivo.xml'}];
    // OVS_DATA_POD: File[] = [{fileName: 'Arquivo.xml'}];
    //
    // xmlColumns = ['fileName', 'action'];
    // dataFilesXML = new MatTableDataSource<File>(this.OVS_DATA_XML);
    // uocColumns = ['fileName', 'action'];
    // dataFilesUOC = new MatTableDataSource<File>(this.OVS_DATA_UOC);
    // podColumns = ['fileName', 'action'];
    // dataFilesPOD = new MatTableDataSource<File>(this.OVS_DATA_POD);
    //
    function AttachmentsComponent(ovService, uploadService, sanitizer, mdrService, snackBar, nfeservice, mdrObservable) {
        this.ovService = ovService;
        this.uploadService = uploadService;
        this.sanitizer = sanitizer;
        this.mdrService = mdrService;
        this.snackBar = snackBar;
        this.nfeservice = nfeservice;
        this.mdrObservable = mdrObservable;
        this.updateValue = new EventEmitter();
        this.count = 0;
        this.nfFiles = [];
        this.rgAux = '';
        this.nameAux = '';
        this.dateAux = '';
        this.uploadMassive = [];
        this.hourAux = '';
        this.podFiles = 0;
        this.uocList = [];
        this.disablePODEditing = false;
        this.uocFiles = [];
        this.sendStatus = false;
        this.statusContainer = false;
        this.updateLock = false;
        this.getDataUrl = function (img) {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // If the image is not png, the format
            // must be specified here
            return canvas.toDataURL();
        };
    }
    AttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            var PODS_BLOCKED_STATUS = [
                'Entrega de carga certificada pelo LSP',
                'Entrega certificada-LSP',
                'MDR paga / concluída',
                'MDR paid / completed'
            ];
            _this.disablePODEditing = PODS_BLOCKED_STATUS.includes(_this.mdr['status']);
            _this.updateNF();
            _this.getUOC();
        });
    };
    AttachmentsComponent.prototype.ngOnChanges = function (changes) {
        // console.log(changes);
        // this.updateNF();
    };
    AttachmentsComponent.prototype.receiveFile = function ($event) {
        // console.log($event);
        if (this.count === this.uploadMassive.length) {
            this.updateNF();
            this.count = 0;
        }
        else {
            this.count++;
        }
    };
    AttachmentsComponent.prototype.getUOC = function () {
        var _this = this;
        this.mdrService.getUOC(this.mdr).subscribe(function (data) {
            _this.uocList = data;
            _this.uocList.forEach(function (el, i, arr) {
                _this.mdrService.getUOCWithInfo(el.id).subscribe(function (response) {
                    el['files'] = response;
                });
            });
        });
    };
    AttachmentsComponent.prototype.updateInfo = function () {
        var _this = this;
        this.nfList.forEach(function (nf) {
            if (typeof nf['file'] !== 'undefined' && nf['protected'] === false) {
                nf['file'].forEach(function (file) {
                    _this.uploadService.updateFileInfo(file).subscribe(function (data) {
                        // console.log(data);
                    });
                }, _this);
            }
        }, this);
    };
    AttachmentsComponent.prototype.removeNF = function (nf) {
        var _this = this;
        this.uploadService.deleteByEntity(nf['id'], 'mdr').subscribe(function (response) {
            // console.log(response);
            _this.updateNF();
        });
    };
    AttachmentsComponent.prototype.removePOD = function (nf) {
        var _this = this;
        this.uploadService.deleteByEntity(nf['id'], 'nf').subscribe(function (response) {
            _this.updateNF();
        });
    };
    AttachmentsComponent.prototype.hasEmptyPOD = function () {
        var emptyUocs = this.uocList.some(function (uoc) { return uoc.status && uoc.files.length === 0; });
        var emptyNfs = this.nfList.some(function (nf) { return nf.file === undefined; });
        return emptyUocs || emptyNfs;
    };
    AttachmentsComponent.prototype.savePOD = function (nf) {
        var _this = this;
        var info = { 'info_id': nf.id };
        ['info_deliveredAt', 'info_name', 'info_rg', 'info_timeDeliveredAt'].forEach(function (el, i, arr) {
            if (el in nf) {
                info[el] = nf[el];
            }
        });
        this.uploadService.updateFileInfo(info).subscribe(function (response) {
            _this.snackBar.open('Dados do Recebedor atualizados', 'Fechar', {
                duration: 4000,
            });
        });
    };
    // public createPayment() {
    //   this.nfList.forEach((k) => {
    //     //console.log(k);
    //   });
    // }
    AttachmentsComponent.prototype.updateNF = function () {
        var _this = this;
        if (!this.updateLock) {
            this.updateLock = true;
            this.podFiles = 0;
            this.totalNfCost = 0;
            this.nfFiles = [];
            if (this.mdr.id !== null) {
                this.ovService.getNfByMdr(this.mdr.id).subscribe(function (data) {
                    _this.nfList = data;
                    _this.nfList.forEach(function (elem) {
                        _this.uploadMassive.push({ entity: 'nf', id: elem['nf'] });
                    });
                    // console.log(this.nfList);
                    setTimeout(function () {
                        _this.updateLock = false;
                    }, _this.nfList.length * 200);
                    _this.uploadService.findByEntity(_this.mdr.id, 'mdr').subscribe(function (files) {
                        _this.nfFiles = [].concat(_this.nfFiles, files);
                        // this.nfFiles.push(...files);
                    });
                    _this.nfList.forEach(function (k) {
                        var id = +k.nf.split('-', 1)[0];
                        // anexos das NFes
                        if ('attachment' in k) {
                            k.attachment['protected'] = true;
                            _this.nfFiles.push({
                                url: k.attachment.path,
                                fileName: k.nf + ' - ' + k.attachment.fileOriginalName,
                                protected: true
                            });
                        }
                        _this.nfeservice.getAll_xml(id).subscribe(function (arr) {
                            if (arr.length > 0) {
                                _this.uploadService.findByEntity(id, 'nfe_xml').subscribe(function (files) {
                                    files[0]['protected'] = true;
                                    _this.nfFiles.push(files[0]);
                                });
                                _this.totalNfCost += +arr[0].totalValue;
                                _this.updateValue.next('');
                            }
                        });
                        _this.uploadService.findByEntityWithInfo(k.nf, 'nf').subscribe(function (ans) {
                            if (ans.length === 0) {
                                k.file = undefined;
                            }
                            else {
                                k.file = [];
                                _this.podFiles++;
                                for (var _i = 0, _a = Object.keys(ans); _i < _a.length; _i++) {
                                    var i = _a[_i];
                                    k.file.push(ans[i]);
                                }
                            }
                        });
                    });
                    _this.getUOC();
                });
            }
            else {
                this.updateLock = false;
            }
        }
    };
    AttachmentsComponent.prototype.copyData = function (file) {
        this.rgAux = file.info_rg;
        this.nameAux = file.info_name;
        this.dateAux = file.info_deliveredAt;
        this.hourAux = file.info_timeDeliveredAt;
    };
    AttachmentsComponent.prototype.pasteData = function (file) {
        file.info_rg = this.rgAux;
        file.info_name = this.nameAux;
        file.info_deliveredAt = this.dateAux;
        file.info_timeDeliveredAt = this.hourAux;
    };
    AttachmentsComponent.prototype.dowloadAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var zip, img, nffiles, uploadService, _loop_1, _i, nffiles_1, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        zip = new JSZip();
                        img = zip.folder('images');
                        nffiles = this.nfFiles;
                        uploadService = this.uploadService;
                        _loop_1 = function (i) {
                            var image, url;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        image = i;
                                        url = i.url;
                                        return [4 /*yield*/, uploadService.getImage(url).subscribe(function (result) {
                                                var reader = new FileReader();
                                                // console.log(result);
                                                reader.readAsText(result);
                                                reader.onloadend = function () {
                                                    var imgData = reader.result;
                                                    img.file(image['id'] + image['fileName'], result, { base64: true });
                                                    // console.log(i);
                                                    if (i === nffiles[nffiles.length - 1]) {
                                                        zip.generateAsync({ type: 'blob' })
                                                            .then(function (content) {
                                                            // see FileSaver.js
                                                            saveAs(content, 'notas_fiscais.zip');
                                                        });
                                                    }
                                                };
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, nffiles_1 = nffiles;
                        _a.label = 1;
                    case 1:
                        if (!(_i < nffiles_1.length)) return [3 /*break*/, 4];
                        i = nffiles_1[_i];
                        return [5 /*yield**/, _loop_1(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AttachmentsComponent;
}());
export { AttachmentsComponent };
