import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { MatSnackBar } from '@angular/material';
var ForgotComponent = /** @class */ (function () {
    function ForgotComponent(translate, auth, router, activatedRoute, snackBar) {
        this.translate = translate;
        this.auth = auth;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.snackBar = snackBar;
        this.password = '';
        this.loading = false;
        this.captcha = false;
        this.error = '';
    }
    ForgotComponent.prototype.resolved = function (captchaResponse) {
        // this.auth.verifyCaptchaToken(captchaResponse).subscribe(result => {
        //   this.captcha =result;
        //   //console.log( this.captcha);
        // });
        this.captcha = true;
    };
    ForgotComponent.prototype.ngOnInit = function () {
        var _this = this;
        var token = this.activatedRoute['currentUrlTree'].queryParams.token;
        this.auth.validateForgotPasswordToken(token)
            .subscribe(function (result) {
            if (result === false) {
                // login failed
                _this.error = 'Username or password is incorrect';
                _this.loading = false;
                return false;
            }
            else {
                var data1 = Date.parse(result['user'].createdAt);
                var data2 = Date.parse(result['user'].dateNow);
                if (data2 - data1 >= 8640000) {
                    _this.snackBar.open('Token Expirado', 'Fechar', {
                        duration: 2000,
                    });
                    _this.router.navigate(['login']);
                }
                else {
                    _this.email = result['user'].email;
                }
            }
        });
    };
    ForgotComponent.prototype.sendPassword = function () {
        var _this = this;
        if (this.captcha === false) {
            // login failed
            this.snackBar.open('Captcha não verificado', 'Fechar', {
                duration: 2000,
            });
            this.error = 'Username or password is incorrect';
            this.loading = false;
            return;
        }
        else if (this.password.length >= 8) {
            if (this.password === this.confimPassword) {
                this.loading = true;
                // console.log(this.password)
                this.auth.changeForgotPassword(this.email, this.password)
                    .subscribe(function (result) {
                    if (result) {
                        // login failed
                        _this.snackBar.open('Senha alterada', 'Fechar', {
                            duration: 2000,
                        });
                        _this.router.navigate(['login']);
                    }
                    else {
                        _this.snackBar.open('Erro na alteração de senha', 'Fechar', {
                            duration: 2000,
                        });
                    }
                });
            }
            else {
                this.snackBar.open('Senhas diferentes', 'Fechar', {
                    duration: 2000,
                });
            }
        }
        else {
            this.snackBar.open('Senha deve ter pelo menos 8 caracteres', 'Fechar', {
                duration: 2000,
            });
        }
    };
    return ForgotComponent;
}());
export { ForgotComponent };
