import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {UOC} from '../models/uoc';
@Injectable()
export class UOCObservable {
    private uoc = new  Subject<UOC[]>();
    currentUOC = this.uoc.asObservable();

    changeUOC(getUOC: UOC[]) {
      this.uoc.next(getUOC);
      // console.log(this.uoc);

    }


}
