import { ElementRef, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DrService } from '../../providers/dr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ClientUserService } from '../../providers/client-user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HubService } from '../../providers/hub.service';
var HubComponent = /** @class */ (function () {
    function HubComponent(dialog, mapsAPILoader, clientService, drService, hubService, router, snackBar, dpipe) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.clientService = clientService;
        this.drService = drService;
        this.hubService = hubService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.selection = new SelectionModel(true, []);
        this.marker = false;
        this.displayedColumns = ['address', 'name', 'shippingCompany', 'latitude', 'longitude'];
        this.hubColumns = ['dr', 'value', 'since'];
        this.dataSource = new MatTableDataSource(this.data);
        this.hubSource = new MatTableDataSource();
        this.totalHub = 0;
        this.searchChips = [];
        this.removable = true;
        this.selectable = true;
        this.selectedHub = undefined;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        this.searchTerm = '';
        this.tokenSeparator = '@@@@@@@@@';
        this.dataSource.sort = this.sort;
        this.hubService.getAll().subscribe(function (response) {
            _this.data = response;
            _this.dataSource = new MatTableDataSource(_this.data);
            _this.dataSource.filterPredicate = function (data, mergedFilter) {
                var filter = _this.splitSearchTerms(mergedFilter);
                var display = false;
                _this.displayedColumns.forEach(function (el, i, arr) {
                    // atualmente é feita a comparação com OR lógico
                    // para mudar para AND lógico: trocar .some() por .every()
                    if (filter.length === 0) {
                        display = true;
                    }
                    else if (filter.some(function (chip) {
                        if (el in data &&
                            data[el] !== null) {
                            if (typeof data[el] === 'string' &&
                                data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                                return true;
                            }
                            else if (typeof data[el] === 'number' &&
                                data[el] === parseInt(chip, 10)) {
                                return true;
                            }
                        }
                        return false;
                    })) {
                        display = true;
                    }
                });
                return display;
            };
        });
    }
    HubComponent.prototype.ngAfterViewInit = function () {
    };
    HubComponent.prototype.getTowerControl = function () {
        this.drService.getTower().subscribe(function (elem) {
            // console.log(elem);
        });
    };
    HubComponent.prototype.goToHub = function (row) {
        var _this = this;
        this.hubService.getFromHub(row.id).subscribe(function (data) {
            _this.selectedHub = row;
            // console.log(data);
            _this.hubSource = new MatTableDataSource(data);
            _this.totalHub = 0;
            data.forEach(function (el) {
                _this.totalHub += parseInt(el.dr.value, 10);
            });
        });
    };
    HubComponent.prototype.addHub = function () {
        this.router.navigate(['add-hub/']);
    };
    HubComponent.prototype.editHub = function () {
        // console.log(this.selectedHub);
        this.router.navigate(['add-hub/' + this.selectedHub.id]);
    };
    HubComponent.prototype.removeHub = function () {
        var _this = this;
        // console.log(this.selectedHub);
        this.hubService.removeHub(this.selectedHub.id).subscribe(function (data) {
            _this.data = _this.data.filter(function (e) { return (e.id !== _this.selectedHub.id); });
            _this.dataSource = new MatTableDataSource(_this.data);
            _this.selectedHub = undefined;
        }, function (error) {
            // console.log(error);
        });
    };
    HubComponent.prototype.getMapClass = function () {
        return this.marker ? 'map-show' : 'map-hidden';
    };
    HubComponent.prototype.viewMap = function () {
        // console.log(this.marker);
        if (this.marker === true) {
            this.marker = false;
        }
        else {
            // console.log(this.lat);
            if (this.lat === undefined || this.lng === undefined) {
                this.convertAddressToLatLng();
                this.marker = true;
            }
            else {
                this.marker = true;
            }
        }
    };
    HubComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        // const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': this.selectedHub.address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.lat = lat;
                _this.lng = lng;
                _this.marker = true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    HubComponent.prototype.goToMDR = function (k) {
        this.router.navigate(['logistic-order/' + k.dr.idMDR]);
    };
    HubComponent.prototype.applyFilter = function () {
        this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
    };
    HubComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.searchChips.push({ 'name': value.trim() });
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    HubComponent.prototype.remove = function (chip) {
        var index = this.searchChips.indexOf(chip);
        if (index >= 0) {
            this.searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    HubComponent.prototype.mergeSearchTerms = function (terms) {
        var _this = this;
        var merged = '';
        terms.forEach(function (el, i, arr) {
            if (typeof el.name === 'string') {
                merged = merged + _this.tokenSeparator + '"' + el.name + '"';
            }
            else if (typeof el.name === 'number') {
                merged = merged + _this.tokenSeparator + String(el.name);
            }
        });
        merged = merged.substring(this.tokenSeparator.length, merged.length);
        return merged;
    };
    HubComponent.prototype.splitSearchTerms = function (terms) {
        var split = [];
        terms.split(this.tokenSeparator).forEach(function (el, i, arr) {
            if (el[0] === '"' && el[el.length - 1] === '"') {
                split.push(el.substring(1, el.length - 1));
            }
            else {
                split.push(parseInt(el, 10));
            }
        });
        return split;
    };
    HubComponent.prototype.return = function () {
        this.selectedHub = undefined;
    };
    return HubComponent;
}());
export { HubComponent };
