import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MdrService } from '../../../../providers/mdr.service';
import { Legs, Pin } from '../../../../models/legs';

import { MatTableDataSource } from '@angular/material';

import { } from 'googlemaps';

@Component({
  selector: 'app-geolocation-without-login',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnInit {
  token;
  mdr;
  // mdr.status para os quais considera-se que mdr foi finalizada
  finishedDriveStatus = ['Carga entregue com sucesso', 'Carga retornada total', 'Entrega de carga certificada pelo LSP', 'MDR paga / concluída'];

  // campos preenchidos pela getLegs
  legsNewLegs: Legs[] = [];
  markers: Pin[] = [];
  dir = false;
  dataSource = new MatTableDataSource<Legs>();
  showMap = true;

  // campos preenchidos pela updateETA
  distanceRemaining: string;
  timeRemaining: string;

  // configurações do mapa
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  lat = 19.694777;
  lng = -99.211361;
  icon = {
    url: './assets/images/red_truck.png',
    scaledSize: {
      height: 40,
      width: 40
    },
  };
  iconPoint = {
    url: './assets/images/red_point.png',
    scaledSize: {
      height: 5,
      width: 5
    },
  };
  renderOptions = {
    suppressMarkers: true,
    draggable: false,
    polylineOptions: {strokeColor: '#AAA', zIndex: 1},
  };
  renderOptionsDriver = {
    suppressMarkers: true,
    draggable: false,
    polylineOptions: {strokeColor: '#00f', zIndex: 2},
  };

  // relativos aos pontos do tracking
  driverPosition: Pin;
  destination: Pin;
  haveDriver = false;
  markersTruck = [];
  driverPositionList: any[];
  markersDriver: any[] = [];
  colorPrefix = 'COLOR/';
  coloredPoint = [
    { scaledSize: { height: 5, width: 5 }, color: 'red', url: './assets/images/red_point.png' },
    { scaledSize: { height: 5, width: 5 }, color: 'blue', url: './assets/images/blue_point.png' },
    { scaledSize: { height: 5, width: 5 }, color: 'green', url: './assets/images/green_point.png' },
    { scaledSize: { height: 5, width: 5 }, color: 'pink', url: './assets/images/pink_point.png' },
    { scaledSize: { height: 5, width: 5 }, color: 'purple', url: './assets/images/purple_point.png' },
    { scaledSize: { height: 5, width: 5 }, color: 'orange', url: './assets/images/orange_point.png' }
  ];
  driverPositionTableColumns = [
    { value: 'createdAt', name: 'GEOLOCATION/table/time' },
    { value: 'lat', name: 'GEOLOCATION/table/lat' },
    { value: 'lng', name: 'GEOLOCATION/table/lng' },
    { value: 'speed', name: 'GEOLOCATION/table/speed' },
    { value: 'phoneNumber', name: 'GEOLOCATION/table/phone' },
    { value: 'color', name: 'GEOLOCATION/table/color' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mdrService: MdrService
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: { mdr: any }) => {
      this.token = this.route.snapshot.paramMap.get('token');
      this.mdr = data.mdr;
      this.getLegs();
      this.getDriverLastPosition();
      this.getDriverPositions();
    });
  }

  getLegs() {
    this.mdrService.getLegsWithToken(this.mdr.id, this.token).subscribe(async (data) => {
      this.legsNewLegs = [];
      this.markers = [];
      data.forEach((leg, i) => {

        const legNew = new Legs();
        legNew.id = leg.id;
        const letterInittoInit = this.legsNewLegs.filter(legs => {
          return (parseFloat(leg.pointInit.lat) === legs.pointInit.lat && parseFloat(leg.pointInit.lng) === legs.pointInit.lng);
        }).map(legs => legs.pointInit.label);
        const letterInittoEnd = this.legsNewLegs.filter(legs => {
          return (parseFloat(leg.pointInit.lat) === legs.pointEnd.lat && parseFloat(leg.pointInit.lng) === legs.pointEnd.lng);
        }).map(legs => legs.pointEnd.label);
        const letterEndtoEnd = this.legsNewLegs.filter(legs => {
          return (parseFloat(leg.pointEnd.lat) === legs.pointEnd.lat && parseFloat(leg.pointEnd.lng) === legs.pointEnd.lng);
        }).map(legs => legs.pointEnd.label);
        const letterEndtoInit = this.legsNewLegs.filter(legs => {
          return (parseFloat(leg.pointEnd.lat) === legs.pointInit.lat && parseFloat(leg.pointEnd.lng) === legs.pointInit.lng);
        }).map(legs => legs.pointInit.label);

        if ( i === 0 ) {
          this.markers.push({
            lat: parseFloat(leg.pointInit.lat),
            lng: parseFloat(leg.pointInit.lng),
            label: 'A',
            title: '',
          });
        }

        if (letterInittoInit.length > 0) {
          legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
          legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
          legNew.pointInit.label = letterInittoInit[0];
        } else if (letterInittoEnd.length === 0 && i !== 0) {
          legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
          legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
          legNew.pointInit.label = String.fromCharCode(i + 1 + 65);
          this.markers.push({
            lat: parseFloat(leg.pointInit.lat),
            lng: parseFloat(leg.pointInit.lng),
            label: String.fromCharCode(i + 1 + 65),
            title: '',
          });
        } else {
          legNew.pointInit.lat = parseFloat(leg.pointInit.lat);
          legNew.pointInit.lng = parseFloat(leg.pointInit.lng);
          legNew.pointInit.label = String.fromCharCode(i + 65);
        }
        if (letterEndtoEnd.length > 0) {
          legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
          legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
          legNew.pointEnd.label = letterEndtoEnd[0];
        } else if (letterEndtoInit.length > 0) {
          legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
          legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
          legNew.pointEnd.label = letterEndtoInit[0];
        } else {
          legNew.pointEnd.lat = parseFloat(leg.pointEnd.lat);
          legNew.pointEnd.lng = parseFloat(leg.pointEnd.lng);
          legNew.pointEnd.label = String.fromCharCode(i + 1 + 65);
          this.markers.push({
            lat: parseFloat(leg.pointEnd.lat),
            lng: parseFloat(leg.pointEnd.lng),
            label: String.fromCharCode(i + 1 + 65),
            title: '',
          });
        }
        legNew.type = leg.type;
        legNew.order = leg.order;
        this.legsNewLegs.push(legNew);

      });

      this.dir = true;
      this.updateETA();
      this.dataSource = new MatTableDataSource<Legs>(this.legsNewLegs);
      this.showMap = false;
      await setTimeout(() => { this.showMap = true; }, 1000);

    });
  }

  updateETA() {
    let dist = 0;
    let ETA = 0;
    this.legsNewLegs.forEach( (leg) => {
      if (leg.type === 'Rodoviário') {
        dist += leg.distance;
        ETA += leg.ETA;
      }
    });

    this.distanceRemaining = Math.floor(dist / 1000) + ' km';
    this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor( (ETA % 3600) / 60) + ' min';
  }

  onResponse(event: any) {
    this.legsNewLegs.forEach( (leg) => {
      if (
        Math.abs(leg.pointInit.lat - event.routes[0].legs[0].start_location.lat()) < 0.01 &&
        Math.abs(leg.pointInit.lng - event.routes[0].legs[0].start_location.lng()) < 0.01 &&
        Math.abs( leg.pointEnd.lat - event.routes[0].legs[0].end_location.lat())   < 0.01 &&
        Math.abs( leg.pointEnd.lng - event.routes[0].legs[0].end_location.lng())   < 0.01
      ) {
        leg.distance = event.routes[0].legs[0].distance.value;
        leg.ETA = event.routes[0].legs[0].duration.value;
      }
    });
    this.updateETA();
  }

  // pega última posição da MDR
  getDriverLastPosition() {
    this.mdrService.getDriverLastPositionWithToken(this.mdr.id, this.token).subscribe( (elem) => {
      if (elem) {
        this.driverPosition = new Pin();
        this.driverPosition.lat = parseFloat(elem.lat);
        this.driverPosition.lng = parseFloat(elem.lng);
        this.destination = new Pin();
        this.haveDriver = true;
      }
    });
  }

  // pega posições do tracking
  getDriverPositions() {
    this.markersTruck = [];

    this.mdrService.getDriverPositionWithToken(this.mdr.id, this.token).subscribe( (driverList) => {
      // monta lista de posições pra tabela e última posição do motorista
      this.driverPositionList = [];
      driverList.forEach((driver, i) => {

        // pra cada posição do motorista
        driver['positions'].forEach((position) => {
          // adiciona o telefone do motorista às posições
          position['phoneNumber'] = driver['phoneNumber'];
          // converte velocidade em km/h
          position['speed'] = position['speed'] * 3.6;
          // converte createdAt em Date
          position['createdAt'] = new Date(position['createdAt']);
          // escolhe cor para as posições
          const colorI = i % this.coloredPoint.length;
          position['color'] = this.colorPrefix + this.coloredPoint[colorI].color;
          position['icon'] = this.coloredPoint[colorI];
        });

        // adiciona posições à lista de latlngs
        this.driverPositionList = [].concat(this.driverPositionList, driver['positions']);

      });

      // monta lista de markers pro mapa
      this.driverPositionList.forEach((position) => {
        this.markersDriver.push({
          lat: parseFloat(position['lat']),
          lng: parseFloat(position['lng']),
          label: '',
          title: position['phoneNumber'],
          icon: position['icon']
        });
      });
    });
  }

  onResponseDriver(event: any) {
    const dist = event.routes[0].legs[0].distance.value;
    const ETA = event.routes[0].legs[0].duration.value;
    this.distanceRemaining = Math.floor(dist / 1000) + ' km';
    this.timeRemaining = Math.floor(ETA / 3600) + ' h e ' + Math.floor( (ETA % 3600) / 60) + ' min';
  }


}
