import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ClientUserService } from '../../providers/client-user.service';
var DocsCntrlComponent = /** @class */ (function () {
    function DocsCntrlComponent(clientService, mdrService, router, snackBar, dpipe) {
        var _this = this;
        this.clientService = clientService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.clientList = [];
        this.columns = [
            { value: 'client', name: 'SHEETS/table/client' },
            { value: 'pickupID', name: 'SHEETS/table/pickupId' },
            { value: 'status', name: 'SHEETS/table/status' },
            { value: 'site', name: 'SHEETS/table/site' },
            { value: 'ovs', name: 'SHEETS/table/salesDocument' },
            { value: 'uf', name: 'SHEETS/table/uf' },
            { value: 'lsp', name: 'SHEETS/table/lsp' },
            // {value:'pickupDate',name:'Data da Coleta'},
            { value: 'createdat', name: 'SHEETS/table/deliveryDate' },
            { value: 'occTipo', name: 'Tipo de Occ.' },
            { value: 'occObs', name: 'Obs Occ.' },
            { value: 'occStatus', name: 'Status Occ.' },
            { value: 'occResolution', name: 'Resposta Occ.' },
            { value: 'currency', name: 'CoPQ', currency: 'BRL' }
        ];
        this.clientService.getClients().subscribe(function (data) {
            _this.clientList = data;
        });
    }
    DocsCntrlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getDocsInfo().subscribe(function (elem) {
            var dataAux = [];
            var _loop_1 = function (k) {
                var j = {};
                elem[k].uf = elem[k].uf ? elem[k].uf : '';
                elem[k].client = '';
                if (elem[k].mdr) {
                    if (elem[k].mdr.pickupDate) {
                        elem[k].pickupDate = _this.dpipe.transform(elem[k].mdr.pickupDate, 'dd/MM/yyyy hh:mm');
                    }
                    else {
                        elem[k].pickupDate = '';
                    }
                    if (elem[k].mdr.pickupID) {
                        elem[k].pickupID = elem[k].mdr.pickupID;
                    }
                    else {
                        elem[k].pickupID = '';
                    }
                    if (elem[k].mdr.shippingCompany) {
                        elem[k].lsp = elem[k].mdr.shippingCompany;
                    }
                    else {
                        elem[k].lsp = '';
                    }
                    if (elem[k].mdr.client) {
                        elem[k].client = elem[k].mdr.client.clientGroup;
                    }
                    else {
                    }
                }
                else {
                    elem[k].pickupDate = '';
                    elem[k].pickupID = '';
                    elem[k].lsp = '';
                    elem[k].client = '';
                }
                if (elem[k].salesOrders) {
                    elem[k].ovs = elem[k].salesOrders.join('.');
                }
                else {
                    elem[k].ovs = '';
                }
                elem[k].currency = 0;
                if (elem[k].copq !== undefined) {
                    for (var i = 0; i < elem[k].copq.length; i++) {
                        elem[k].currency += elem[k].copq[i].value;
                    }
                }
                elem[k].createdat = elem[k].dateScheduling ? new Date(elem[k].dateScheduling) : null;
                elem[k].currency = elem[k].currency / 100;
                if (elem[k].occ.length > 0) {
                    elem[k].occTipo = elem[k].occ[elem[k].occ.length - 1].uoc;
                    elem[k].occObs = '';
                    for (var i = 0; i < elem[k].occ.length; i++) {
                        elem[k].occObs += '[' + _this.dpipe.transform(elem[k].occ[i].createdAt, 'dd/MM/yyyy hh:mm') + '] ' + elem[k].occ[i].text + '\n';
                    }
                    elem[k].occStatus = elem[k].occ[elem[k].occ.length - 1].status;
                    elem[k].occResolution = elem[k].occ[elem[k].occ.length - 1].resolution;
                }
                ['uf', 'client', 'pickupID', 'status', 'site', 'ovs', 'uf', 'lsp', 'createdat', 'pickupDate', 'occTipo', 'occObs', 'occStatus', 'occResolution', 'currency', 'mdr'].forEach(function (key) {
                    j[key] = elem[k][key];
                });
                dataAux.push(j);
            };
            for (var k = 0; k < elem.length; k++) {
                _loop_1(k);
            }
        });
    };
    DocsCntrlComponent.prototype.goToMDR = function (row, that) {
        that.router.navigate(['logistic-order/' + row.mdr.id]);
    };
    return DocsCntrlComponent;
}());
export { DocsCntrlComponent };
