import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.dateRange = {
            startDate: new Date,
            endDate: new Date
        };
        // set token if saved in local storage
        this.url = base_url + 'statistics/';
        this.token = JSON.parse(localStorage.getItem('currentUser')).token;
        this.header = new HttpHeaders().set('Authorization', 'Bearer ' + this.token).set('x-api-key', api_key);
    }
    DashboardService.prototype.getMdrByShippingCompany = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/shippingCompany', { headers: this.header,
            params: {
                groupBy: 'month',
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected
            } });
    };
    DashboardService.prototype.getDeliveryPrecision = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/status/precision', { headers: this.header,
            params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected
            }
        });
    };
    DashboardService.prototype.getDrByClient = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/client', { headers: this.header, params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
            } });
    };
    DashboardService.prototype.getMdrByCreated = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/createdAt/shippingCompany', { headers: this.header,
            params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected
            }
        });
    };
    DashboardService.prototype.getMdrByDelivered = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/status/shippingCompany', {
            headers: this.header,
            params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
                status: ['MDR paga / concluída', 'Entrega de carga certificada pelo LSP', 'Carga entregue com sucesso'],
            }
        });
    };
    DashboardService.prototype.getDrByStatus = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/status', { headers: this.header, params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
                groupBy: 'month'
            } });
    };
    DashboardService.prototype.getC02ByCreatedAt = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/createdAt/c02', { headers: this.header, params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
                groupBy: 'month'
            } });
    };
    DashboardService.prototype.getDrByDriverPosition = function (dates) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/driverPosition', { headers: this.header });
    };
    DashboardService.prototype.getOccurrence = function () {
        return this.http.get(this.url + 'occurrence', { headers: this.header,
            params: {
                startDate: this.dateRange.startDate.toISOString(),
                endDate: this.dateRange.endDate.toISOString(),
            } });
    };
    DashboardService.prototype.getOccurrenceWords = function (dates) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'occurrence/text', { headers: this.header, params: {
                startDate: start,
                endDate: end
            } });
    };
    DashboardService.prototype.getPods = function () {
        return this.http.get(this.url + 'pod', { headers: this.header,
            params: {
                startDate: this.dateRange.startDate.toISOString(),
                endDate: this.dateRange.endDate.toISOString()
            } });
    };
    DashboardService.prototype.getCostKg = function (group, dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'mdr/costPerKilo', {
            headers: this.header,
            params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
                groupBy: group
            }
        });
    };
    DashboardService.prototype.getOVsDelivered = function (dates, ufSelected, typeSelected, lspSelected, clientSelected) {
        var _a = this.parseDates(dates), start = _a.start, end = _a.end;
        return this.http.get(this.url + 'dr/status/delivered', {
            headers: this.header,
            params: {
                startDate: start,
                endDate: end,
                type: typeSelected,
                lsp: lspSelected,
                uf: ufSelected,
                client: clientSelected,
            }
        });
    };
    DashboardService.prototype.parseDates = function (dates) {
        var stDate, fnDate;
        var now = new Date();
        if (dates === undefined) {
            // no Dates received:
            // get prior month as start
            // and now as end
            stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            stDate.setMonth(stDate.getMonth() - 1);
            fnDate = now;
        }
        else {
            // start date received
            if (dates[0] !== null) {
                // convert the value to Date
                stDate = new Date(dates[0]);
            }
            else {
                // no start date received
                // get prior month as start
                stDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                stDate.setMonth(stDate.getMonth() - 1);
            }
            // end date received
            if (dates[1] !== null) {
                // convert the value to Date
                fnDate = new Date(dates[1]);
            }
            else {
                // use today
                fnDate = now;
            }
        }
        return { start: stDate.toISOString(), end: fnDate.toISOString() };
    };
    return DashboardService;
}());
export { DashboardService };
