export var MDR_STATUS;
(function (MDR_STATUS) {
    MDR_STATUS["MDR_EM_CRIACAO"] = "MDR em cria\u00E7\u00E3o";
    MDR_STATUS["MDR_DEVOLVIDA_POR_LSP"] = "MDR devolvida por LSP";
    MDR_STATUS["CARGA_RETORNADA_TOTAL"] = "Carga retornada total";
    MDR_STATUS["MDR_EM_VALIDACAO"] = "MDR em valida\u00E7\u00E3o";
    MDR_STATUS["MDR_AGUARDANDO_LSP"] = "MDR aguardando LSP";
    MDR_STATUS["MDR_APROVADA_LSP"] = "MDR aprovada LSP";
    MDR_STATUS["MOTORISTA_E_VEICULOS_DEFINIDOS"] = "Motorista e veiculo definidos";
    MDR_STATUS["VEICULO_CARREGADO"] = "Ve\u00EDculo carregado";
    MDR_STATUS["CARGA_ENTREGUE_COM_SUCESSO"] = "Carga entregue com sucesso";
    MDR_STATUS["ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP"] = "Entrega de carga certificada pelo LSP";
    MDR_STATUS["MDR_PAGA_CONCLUIDA"] = "MDR paga / conclu\u00EDda";
    MDR_STATUS["MDR_APROVADA_COM_RESSALVAS_LSP"] = "MDR aprovada com ressalvas LSP";
    MDR_STATUS["CARGA_RETORNADA_PARA_REENTREGA"] = "Carga retornada para reentrega";
    MDR_STATUS["MDR_REPROVADO"] = "MDR Reprovado";
})(MDR_STATUS || (MDR_STATUS = {}));
var MDR = /** @class */ (function () {
    function MDR() {
        this.id = null;
        this.drList = new Array();
        this.status = '';
        this.shippingCompany = '';
        this.vehicle = '';
        this.aeroDelivery = null;
        this.firstHubLeg = null;
        this.totalKM = null;
        this.totalNF = null;
        this.pickupDate = null;
        this.pickupID = null;
        this.deliveryOutSOP = null;
        this.observation = '';
        this.SAD = '';
        this.sapCode = '';
        this.createdAt = null;
        this.createBy = '';
        this.frete = 0;
        this.valorem = 0;
        this.additional = 0;
        this.lock = true;
        this.type = null;
    }
    return MDR;
}());
export { MDR };
