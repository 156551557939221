import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import wordCloud from 'highcharts/modules/wordcloud';
import HC_solidgauge from 'highcharts/modules/solid-gauge';
import { TranslateService } from '@ngx-translate/core';
HC_solidgauge(Highcharts);

// Now init modules:
// HighchartsSolidGauge(Highcharts);
wordCloud(Highcharts);
more(Highcharts);
@Component({
  selector: 'app-wordcloud-chart',
  templateUrl: './wordcloud-chart.component.html',
  styleUrls: ['./wordcloud-chart.component.scss', '../graph-card.component.scss']
})
export class WordcloudChartComponent implements OnInit {
  chart: Chart;
  @Input() container: string;
  colors = ['#B6BCE9', '#AFDEEE', '#ECE196'];
  data = [];
  emptyData = true;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {}

  getStatusColor(i) {
    return this.colors[i];
  }

  createChart(data: any[], id: string, translate: TranslateService) {
    const heatMapWords = [];
    Object.keys(data).forEach((key) => {
      if ( key.length >= 3) {
        heatMapWords.push({name: key, weight: data[key]});
      }
    });
    heatMapWords.sort((a, b) => b.weight - a.weight).splice(20);
    if (heatMapWords.length === 0) {
      this.emptyData = true;
    } else {
      this.emptyData = false;
    }
    Highcharts.chart(id, {
      tooltip: {
        enabled: true,
      },
      chart: {
        type: 'wordcloud',
        renderTo: id,
      },
      title: {
        text: ''
      },
      series: [{
        type: 'wordcloud',
        data: heatMapWords,
        name: 'id'
      }],
    });
  }
}
