export class COPQMDR {
  id: number;
  copq: string;
  idMDR: number;
  value: number;
  observation: string;

  constructor() {
    this.id = null;
    this.copq = '';
    this.idMDR = null;
    this.value = null;
    this.observation = '';
  }
}
