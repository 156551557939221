export class HUB {

  public id: number;
  public name: string;
  public shippingCompany: string;
  public address: string;
  public lat: number;
  public lng: number;

  constructor() {
    this.id = null;
    this.name = null;
    this.shippingCompany = null;
    this.address = null;
    this.lat = null;
    this.lng = null;
  }

}
