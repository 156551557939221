<h1 mat-dialog-title>{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/restore/title' | translate }} ({{ data.when }})</h1>
<div mat-dialog-content>
  <p>{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/restore/1' | translate }}</p>
  <p>{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/restore/2' | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickNo()" cdkFocusInitial>{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/restore/btn/no' | translate }}</button>
  <span></span>
  <button mat-button class="primary-button" (click)="onClickOk()" cdkFocusInitial>{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/restore/btn/ok' | translate }}</button>
</div>
