import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var OVService = /** @class */ (function () {
    function OVService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    OVService.prototype.getAll = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/' + id, { headers: header });
    };
    OVService.prototype.updateSerial = function (ovList) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/serial', {
            ovList: ovList
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.saveMultipleOV = function (listOV) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV', {
            listOV: listOV
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.saveMultipleZH = function (listZH) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/zh', {
            listZH: listZH
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.saveMultipleZRS = function (listZRS) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/zrs', {
            listZRS: listZRS
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.getNfByMdr = function (id) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/nf/' + id, { headers: header })
            .map(function (response) {
            var OVdata = response;
            return OVdata;
        });
    };
    OVService.prototype.validateOVs = function (idList) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/validate', {
            idList: idList
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.validateHub = function (hubName) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'OV/validateHUB', {
            hub: hubName
        }, { headers: header })
            .map(function (response) {
            return response;
        });
    };
    OVService.prototype.getVehicle = function (ovs) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var params = new HttpParams().set('ovs', '[' + ovs.toString() + ']');
        return this.http.get(base_url + 'OV/vehicle', { headers: header, params: params });
    };
    OVService.prototype.searchByOV = function (ov) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'OV/search/' + ov, { headers: header });
    };
    return OVService;
}());
export { OVService };
