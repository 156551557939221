<h1 mat-dialog-title>Deletar usuário</h1>
<div mat-dialog-content>
   <p>Tem certeza que deseja deletar o usuário <b>{{data.u_name}}</b> da <b>{{data.company}}</b>?</p>
   <p>Esta ação é irreversivel</p>
   <!-- <form class="d-flex flex-wrap">
        <mat-form-field class="col-md-6">
        <input matInput placeholder="Nome do usuário" name="name" [(ngModel)]="user.name">
        </mat-form-field>
        <mat-form-field class="col-md-6">
        <input matInput placeholder="email" name="user" [(ngModel)]="user.email">
        </mat-form-field>
        <mat-form-field class="col-md-6">
        <mat-select  placeholder="Transportadora" name="lsp" [(ngModel)]="lsp"  >
        <mat-option *ngFor="let elem of lspList" [value]="elem.id"  >
        {{ elem.name }}
        </mat-option>
        </mat-select>
        </mat-form-field>
        </form> -->
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button (click)="onYesClick()" cdkFocusInitial>Sim</button>
   <button mat-button cdkFocusInitial (click)="onNoClick()">Não</button>
</div>
