
<i class="fa fa-file-pdf click-button" matTooltip="Download em excel" (click)="download()"></i>
<div *ngIf="chart !== 'empty'" fxLayout="column" fxLayoutAlign="space-around center">
  <div *ngIf="emptyData" class="subtitle" fxLayout="row" fxLayoutAlign="space-around center">
    {{ 'MISSING_DATA' | translate }}
  </div>
  <div class="number"> {{ chartData.number }} </div>
  <div class="subtitle"> {{ chartData.subtitle }} </div>
  <div class="subtitle {{ chartData.percentage > 0 ? 'green' : 'red' }}">
    <div *ngIf="chartData.percentage < 0">
      <i class="fa fa-angle-down"></i>
      {{ - chartData.percentage }}%
    </div>
    <div *ngIf="chartData.percentage > 0">
      <i class="fa fa-angle-up"></i>
      {{ chartData.percentage }}%
    </div>
  </div>
</div>
<div id="{{container}}" class="chart"></div>
