import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MdrService } from '../../../providers/mdr.service';
var SlaComponent = /** @class */ (function () {
    function SlaComponent(route, router, mdrService) {
        this.route = route;
        this.router = router;
        this.mdrService = mdrService;
        this.isSLA = false;
    }
    SlaComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id$ = this.route.paramMap.pipe(switchMap(function (params) {
            return _this.mdrService.isSLA(parseInt(params.get('id'), 10));
        }));
        id$.subscribe(function (response) {
            _this.isSLA = response;
        });
    };
    SlaComponent.prototype.toggleSLA = function () {
        if (this.isSLA) {
            this.unsetSLA();
        }
        else {
            this.setSLA();
        }
    };
    SlaComponent.prototype.setSLA = function () {
        var _this = this;
        this.mdrService.setSLA(this.id).subscribe(function (response) {
            _this.isSLA = true;
        });
    };
    SlaComponent.prototype.unsetSLA = function () {
        var _this = this;
        this.mdrService.unsetSLA(this.id).subscribe(function (response) {
            _this.isSLA = false;
        });
    };
    return SlaComponent;
}());
export { SlaComponent };
