import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import {DrService} from '../../providers/dr.service';
import { ConfigurationService } from '../../providers/configuration.service';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  currentUser: any;
  role: number;
  lastUpdate: Date;
  show = false;
  openOccurrence = '0';

  menuItems = [
    { name: 'MENU/REQUISITION',        icon: 'fa-clipboard',           link: '/new-delivery-request'   },
    { name: 'MENU/OPTIMIZER',          icon: 'fa-cogs',                link: '/delivery-optimizer'     },
    // { name: 'MENU/CONSOLIDE',          icon: 'fa-boxes',               link: '/mdr-optimizer'          },
    { name: 'MENU/NEW_MDR',            icon: 'fa-shield-alt',          link: '/mdr-management'         },
    { name: 'MENU/FOLLOW_MDR',         icon: 'fa-truck',               link: '/list-mdrs'              },
    { name: 'MENU/MAP_MDR',            icon: 'fa-map',                 link: '/mdr-map'                },
    { name: 'MENU/TOWER_CONTROL',      icon: 'fa-broadcast-tower',     link: '/control-tower'          },
    { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'                },
    { name: 'MENU/OCCURRENCE',         icon: 'fa-envelope-open-text',  link: '/occurrence-list',       type: 'badge'  },
    // Gerenciamento Operacional
    // { name: 'MENU/WAREHOUSE',          icon: 'fa-warehouse',           link: '/hub'                    },
    // { name: 'MENU/PRICE_MAKER_TABLE',  icon: 'fa-tags',                link: '/price-maker/table'      },
    // { name: 'MENU/ADD_LSP_USER',       icon: 'fa-users-cog',           link: '/lsp-user-admin'         },
    // Gerenciamento de Arquivos
    { name: 'MENU/UPLOAD',             icon: 'fa-cloud-upload-alt',    link: '/insert-new-data'        },
    { name: 'MENU/DASHBOARD',          icon: 'fa-chart-line',          link: '/dashboard'             },
    // { name: 'MENU/BULK_NFE_UP',        icon: 'fa-file-upload',         link: '/bulk-nfe-upload'        },
    // { name: 'MENU/INSERT_NFE',         icon: 'fa-edit',                link: '/insert-nfe'             },
    { name: 'MENU/REPORTS',            icon: 'fa-clipboard-list',      link: '/reports'                },

    { name: 'MENU/FINANCE',            icon: 'fa-file-invoice-dollar', link: '/finance-list'           },

    // SI (...)

    // { name: 'MENU/CALCULATOR',         icon: 'fa-calculator',          link: '/price-maker'            },
    // { name: 'MENU/ADD_HUB',            icon: 'fa-plus',                link: '/add-hub'                },
    // { name: 'MENU/CLIENT_TP',          icon: 'fa-link',                link: '/client-tp'              },
    // { name: 'MENU/DELIVERY_ORDER',     icon: 'fa-stream',              link: '/delivery-order'         },
    // { name: 'MENU/DELIVERY_REQUESTS',  icon: 'fa-paper-plane',         link: '/delivery-requests'      },
    // { name: 'MENU/MDR_LSP',            icon: 'fa-location-arrow',      link: '/mdr-lsp'                },
    // { name: 'MENU/NEW_DELIVERY_ORDER', icon: 'fa-search-location',     link: '/new-delivery-order'     },
    // { name: 'MENU/NEW_MDR_REQUEST',    icon: 'fa-align-justify',       link: '/new-mdr-request'        },
    // { name: 'MENU/SHIPPING_C_ADMIN',   icon: 'fa-user-minus',          link: '/shipping-company-admin' },
    // { name: 'MENU/SITE_INVESTIGATION', icon: 'fa-table',               link: '/site-investigation'     },
    // { name: 'MENU/HOME',               icon: 'fa-chart-pie',           link: '/home'                   },
    // { name: 'MENU/DR_BOOKING',         icon: 'fa-map-pin',             link: '/dr-booking'             },

  ];

  constructor(public auth: AuthService, public router: Router, public drService: DrService, public occService: ConfigurationService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    // console.log(this.role);
    if (this.role === 5) {
      this.menuItems = [
        { name: 'MENU/FOLLOW_MDR',         icon: 'fa-truck',               link: '/list-mdrs'              },
        { name: 'MENU/TOWER_CONTROL',      icon: 'fa-broadcast-tower',     link: '/control-tower'          },
        { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'                },
        { name: 'MENU/OCCURRENCE',         icon: 'fa-envelope-open-text',  link: '/occurrence-list',       type: 'badge'  },
        { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports'  },
        { name: 'MENU/LOGOUT',             icon: 'fa-sign-out-alt',        link: '/logout'             }
      ];
    } else if (this.role === 2) {
      this.menuItems = [
        { name: 'MENU/OPTIMIZER',          icon: 'fa-cogs',                link: '/delivery-optimizer'     },
        // { name: 'MENU/CONSOLIDE',          icon: 'fa-boxes',               link: '/mdr-optimizer'          },
        { name: 'MENU/NEW_MDR',            icon: 'fa-shield-alt',          link: '/mdr-management'         },
        { name: 'MENU/FOLLOW_MDR',         icon: 'fa-truck',               link: '/list-mdrs'              },
        { name: 'MENU/MAP_MDR',            icon: 'fa-map',                 link: '/mdr-map'                },
        { name: 'MENU/TOWER_CONTROL',      icon: 'fa-broadcast-tower',     link: '/control-tower'          },
        { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'                },
        { name: 'MENU/OCCURRENCE',         icon: 'fa-envelope-open-text',  link: '/occurrence-list',       type: 'badge'  },
        // Gerenciamento Operacional
        // { name: 'MENU/WAREHOUSE',          icon: 'fa-warehouse',           link: '/hub'                    },
        // { name: 'MENU/PRICE_MAKER_TABLE',  icon: 'fa-tags',                link: '/price-maker/table'      },
        // { name: 'MENU/ADD_LSP_USER',       icon: 'fa-users-cog',           link: '/lsp-user-admin'         },
        // Gerenciamento de Arquivos
        { name: 'MENU/UPLOAD',             icon: 'fa-cloud-upload-alt',    link: '/insert-new-data'        },
      ];
    } else if (this.role === 7) {
      this.menuItems = [
        { name: 'MENU/OPTIMIZER',          icon: 'fa-cogs',                link: '/delivery-optimizer'     },
        { name: 'MENU/COCKPIT',            icon: 'fa-building',            link: '/cockpit'                },
        {name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout'}
      ];
    } else if (this.role === 8) {
      this.menuItems = [
        { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
        { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
      ];
    } else if (this.role === 6) {
      this.menuItems = [];
    } else if (this.role === 9) {
      this.menuItems = [
        { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports'  },
        { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
      ];
    }
    this.occService.allCount().subscribe((response) => {
      if (response > 100) {
        this.openOccurrence = '99+';
      } else {
        this.openOccurrence = response + '';
      }
    });
    // this.drService.getLastInserted().subscribe((response) => {
    //   this.lastUpdate = response;
    // });
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }
}
