import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule, MarkerManager } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';

import { WithoutLoginRoutingModule } from './without-login-routing.module';
import { CheckTokenGuard } from './check-token.guard';
import { ResolverService } from './resolver.service';
import { PipesModule } from '../../misc/pipes/pipes.module';

import { ViewMdrComponent } from './view-mdr/view-mdr.component';
import { GeolocationComponent } from './view-mdr/geolocation/geolocation.component';
import { DrCardComponent } from './view-mdr/dr-card/dr-card.component';
import { HeaderComponent } from './view-mdr/header/header.component';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    WithoutLoginRoutingModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbtxRrJstl_v2wIpqaJK_FRcTN-UsI5ns',
      libraries: ['places'],
      language: 'pt'
    }),
    AgmDirectionModule,
    MatListModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,

  ],
  declarations: [ViewMdrComponent, GeolocationComponent, DrCardComponent, HeaderComponent],
  providers: [
    CheckTokenGuard,
    ResolverService
  ]
})
export class WithoutLoginModule { }
