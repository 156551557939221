import {DR} from './dr';

export class UOCMDR {
  id: number;
  uoc: string;
  idMDR: number;
  value: number;
  observation: string;
  isCopq: string;
  approvedBy: string;
  createdAt: Date;
  approvedAt: Date;
  status: boolean;
  createdBy: string;
  drList: DR[];

  constructor() {
    this.id = null;
    this.uoc = '';
    this.idMDR = null;
    this.value = null;
    this.observation = '';
    this.isCopq = null;
    this.status = null;
    this.approvedAt = null;
    this.approvedBy = null;
    this.createdAt = null;
    this.createdBy = null;
    this.drList = [];
  }
}
