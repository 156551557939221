<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'SITE_INVESTIGATION_WITH_TOKEN' | translate }}</p>
  <p class="mx-4">{{ 'SITE_INVESTIGATION_WITH_TOKEN/txt' | translate }}</p>
  <i class="fa fa-file-pdf" matTooltip="Download em pdf" (click)="onClickPrint()"></i>
</div>

<div *ngIf="!site" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'SITE_INVESTIGATION_WITH_TOKEN/empty' | translate }}</p>
</div>

<div *ngIf="site">
  <div class="colored-background pt-5">
    <mat-card id="printable" class="mx-5 py-3">
      <mat-card-content>
        <div class="flex-wrap d-flex">
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/code' | translate }}</p>
              <p class="text">{{ site.code }} </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/operadora' | translate }}</p>
              <p class="text">{{site.operadora}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/visitadatahora/data' | translate }}</p>
              <p class="text"> {{ site.visitadatahora | date: 'dd/MM/yyyy hh:mm:ss'}} </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/vistoriadatahora/data' | translate }}</p>
              <p class="text"> {{ site.vistoriadatahora | date: 'dd/MM/yyyy hh:mm:ss'}} </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/acesso' | translate }}</p>
              <p class="text">{{site.acesso  }}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/obs' | translate }}</p>
              <p class="text">{{site.obs}}</p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/address' | translate }}</p>
              <p class="text">{{site.address}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/lat' | translate }}</p>
              <p class="text">{{site.lat | number: '1.6-6'}}"</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/lng' | translate }}</p>
              <p class="text">{{site.lng | number: '1.6-6'}}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/municipio' | translate }}</p>
              <p class="text">{{site.municipio}}</p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/rsu' | translate }}</p>
              <p class="text">{{site.rsu}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/site' | translate }}</p>
              <p class="text">{{site.site}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/regional' | translate }}</p>
              <p class="text">{{site.regional}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/visitanumero' | translate }}</p>
              <p class="text">{{site.visitanumero}}</p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/tipo' | translate }}</p>
              <p class="text">
                <span *ngFor="let item of enums.tipo"><span *ngIf="site.tipo[item.value]">{{item.show | translate}}, </span></span>
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="box-border">
              <p class="title">{{ 'SITE_INVESTIGATION/table/infra' | translate }}</p>
              <p class="text">{{site.infra}}</p>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_1' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_1_1','form_1_2','form_1_3','form_1_4','form_1_5','form_1_6','form_1_7','form_1_8','form_1_9']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_2' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_2_1','form_2_2','form_2_3','form_2_4','form_2_5','form_2_6','form_2_7','form_2_8','form_2_9']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_3' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_3_1','form_3_2','form_3_3']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_4' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_4_1','form_4_2','form_4_3']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_5' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_5_1','form_5_2','form_5_3']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_6' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_6_1','form_6_2','form_6_3','form_6_4','form_6_5']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_7' | translate }}</h5>
          <div class="col-md-12" *ngFor="let form of ['form_7_1','form_7_2','form_7_3']">
            <div *ngIf="site[form] === 'existente'" class="box-border">
              <div>
                <p class="title">{{ 'SITE_INVESTIGATION/table/'+form | translate }}: {{site[form]}}</p>
                <p class="text obs">{{site[form+'_obs']}}</p>
              </div>
              <div class="img">
                <img  *ngIf="site[form+'_anexo']" crossOrigin="*" [src]="site[form+'_anexo'].path">
                <p class='text' *ngIf="!site[form+'_anexo']">Sem imagem disponível</p>
              </div>
            </div>
          </div>

        </div>

      </mat-card-content>

    </mat-card>
  </div>
</div>
