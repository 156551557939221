import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DR } from '../../../models/dr';
import { MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../../providers/dr.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { OV } from '../../../models/ov';
import { OVService } from '../../../providers/ov.service';
import * as FileSaver from 'file-saver';
import { ClientUserService } from '../../../providers/client-user.service';
var DrValidationComponent = /** @class */ (function () {
    function DrValidationComponent(router, aRoute, snackBar, mapsAPILoader, clientUserService, drService, dialog, excelService, ovService) {
        var _this = this;
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mapsAPILoader = mapsAPILoader;
        this.clientUserService = clientUserService;
        this.drService = drService;
        this.dialog = dialog;
        this.excelService = excelService;
        this.ovService = ovService;
        this.dr = new DR();
        this.listOVs = [];
        this.dataSource = new MatTableDataSource();
        this.displayedColumns2 = ['salesOrderQuantity', 'materialCode', 'materialDescription', 'customerName', 'assignmentInformation', 'customerCity', 'customerAddress', 'obs', 'action'];
        this.currentUser = null;
        this.role = null;
        this.user = null;
        this.clientList = [];
        // pro icon de salesOrders
        this.status = null;
        this.icons = {
            'status': '',
            'tooltips': {
                'invalid': 'OVs inválidas',
                'incomplete': 'NFs não existentes',
                'valid': 'DR Booking válido',
                'undefined': ''
            },
            'icons': {
                'invalid': 'fa-exclamation-circle',
                'incomplete': 'fa-exclamation-triangle',
                'valid': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'invalid': 'icon-red',
                'incomplete': 'icon-yellow',
                'valid': 'icon-green',
                'undefined': ''
            }
        };
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
    }
    DrValidationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.id = params.id;
            _this.drService.getById(_this.id).subscribe(function (dr) {
                _this.dr = dr;
                _this.drService.getAllInfo(_this.dr.id).subscribe(function (k) {
                    console.log(k);
                    var list = [];
                    k.forEach(function (ov) {
                        var el = {};
                        el['id'] = ov.id;
                        el['material'] = ov.materialCode;
                        el['materialDescription'] = ov.materialDescription;
                        el['client'] = ov.customerName;
                        el['prolog'] = ov.assignmentInformation;
                        el['serial'] = ov.customerAddress;
                        el['uoc'] = ov.customerCity;
                        el['qtd'] = ov.salesOrderQuantity;
                        el['obs'] = ov.salesOrganization;
                        list.push(el);
                        _this.listOVs.push(ov);
                    });
                    // this.listOVs.find;
                    _this.dataSource = new MatTableDataSource(list);
                    setTimeout(function () { return _this.dataSource.paginator = _this.paginator; });
                });
            });
        });
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    DrValidationComponent.prototype.downloadReport2 = function () {
        this.snackBar.open('Relatório gerado com sucesso', 'Ok', { duration: 3000 });
    };
    DrValidationComponent.prototype.downloadPDF = function () {
        var _this = this;
        // const mdrID = 200;
        this.drService.downloadPDF(this.id).subscribe(function (data) {
            FileSaver.saveAs(data, "Report-Logistic_Order-" + _this.id + ".pdf");
        });
    };
    DrValidationComponent.prototype.uploadSerial = function (fileupload) {
        var _this = this;
        this.snackBar.open('Error in upload File', 'Ok', { duration: 3000 });
        var input = fileupload.target;
        var error = false;
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(input.files[index]).subscribe(function (data) {
                    var stringTo = (new Date()).toString();
                    var ovList = [];
                    var seed = Math.floor((Math.random() * 1000));
                    data.forEach(function (el, i, arr) {
                        var ovAux = new OV();
                        ovAux.id = el.id;
                        ovAux.materialCode = el.client;
                        ovAux.materialDescription = el.materialDescription;
                        ovAux.assignmentInformation = el.prolog;
                        ovAux.customerAddress = el.serial;
                        ovAux.customerCity = el.uoc;
                        ovAux.salesOrderQuantity = el.qtd;
                        ovAux.salesOrganization = el.obs;
                        ovList.push(ovAux);
                    });
                    _this.ovService.updateSerial(ovList).subscribe(function (response) {
                        _this.snackBar.open('Inserted Correcly', 'Ok', { duration: 5000 });
                    });
                });
            }
        }
    };
    DrValidationComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
            }
        });
    };
    DrValidationComponent.prototype.validateDR = function () {
        var _this = this;
        this.drService.getValidation(this.id).subscribe(function (response) {
            _this.dr = response.dr;
            _this.salesOrders = _this.dr.salesOrders.join(',');
            _this.icons.status = response.status;
            _this.status = {
                status: response.status,
                error: response.error,
                warning: response.warning
            };
            if (response.status === 'valid') {
                // this.approveDR();
            }
            else {
                // this.openDialog(null);
            }
        }, function (err) {
            _this.snackBar.open('Erro ao buscar DR Booking', 'Ok', { duration: 4000 });
            _this.router.navigate(['cockpit']);
        });
    };
    DrValidationComponent.prototype.remove = function () {
        var _this = this;
        this.drService.unmakeDR([this.dr]).subscribe(function (elem) {
            _this.snackBar.open('Removed', 'Ok', { duration: 4000 });
            _this.router.navigate(['cockpit']);
        });
    };
    DrValidationComponent.prototype.reproveBooking = function () {
        var _this = this;
        if (this.dr.obs === '' || this.dr.obs === null) {
            this.snackBar.open('Please fill observation field', 'Ok', { duration: 4000 });
        }
        else {
            this.dr.status = 'BOOKING';
            this.dr.siteTeam = '1';
            this.drService.update(this.dr).subscribe(function (response) {
                _this.snackBar.open('Saved', 'Ok', { duration: 4000 });
                _this.router.navigate(['delivery-optimizer']);
            });
        }
    };
    DrValidationComponent.prototype.save = function () {
        var _this = this;
        this.drService.update(this.dr).subscribe(function (response) {
            _this.snackBar.open('Saved', 'Ok', { duration: 4000 });
        });
    };
    DrValidationComponent.prototype.goToMDRAvailable = function () {
        this.dr.thirdPartInvoice = true;
        this.drService.update(this.dr).subscribe(function (k) {
        });
        this.snackBar.open('Correctly sent to MDR Available', 'Ok', {
            duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
    };
    DrValidationComponent.prototype.goToMDRUnavailable = function () {
        this.dr.thirdPartInvoice = false;
        this.drService.update(this.dr).subscribe(function (k) {
        });
        this.snackBar.open('Correctly sent to MDR Unavailable', 'Ok', {
            duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
    };
    DrValidationComponent.prototype.saveAndValidate = function () {
        var _this = this;
        // this.dr.salesOrders = this.salesOrders
        //                           .replace(/ /g,',')   // troca espaços por vírgulas
        //                           .split(',')          // quebra em array nas vírgulas
        //                           .filter(e=>e.length) // remove strings vazias
        var newDr = this.dr;
        newDr.status = 'BOOKINGLSP';
        newDr.siteTeam = null;
        this.drService.update(newDr).subscribe(function (response) {
            _this.router.navigate(['cockpit']);
            _this.snackBar.open('Validated', 'Ok', { duration: 4000 });
        }, function (err) {
            _this.snackBar.open('Erro updating DR Booking', 'Ok', { duration: 2000 });
        });
    };
    DrValidationComponent.prototype.downloadReport = function () {
        var drAux = this.dr;
        for (var _i = 0, _a = Object.entries(drAux); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key === 'dateScheduling' || key === 'createdAt' || key === 'updatedAt') {
                drAux[key] = new Date(value);
            }
        }
        this.dataSource.data.forEach(function (el) {
            el = Object.assign(el, drAux);
        });
        this.excelService.exportAsExcelFile(this.dataSource.data, 'BRBs');
    };
    DrValidationComponent.prototype.openDialog = function (row) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDrValidationComponent, {
            data: {
                row: row,
                listOv: this.listOVs
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'approve') {
                _this.drService.getAllInfo(_this.dr.id).subscribe(function (k) {
                    console.log(k);
                    var list = [];
                    k.forEach(function (ov) {
                        var el = {};
                        el['id'] = ov.id;
                        el['material'] = ov.materialCode;
                        el['materialDescription'] = ov.materialDescription;
                        el['client'] = ov.customerName;
                        el['prolog'] = ov.assignmentInformation;
                        el['serial'] = ov.customerAddress;
                        el['uoc'] = ov.customerCity;
                        el['qtd'] = ov.salesOrderQuantity;
                        el['obs'] = ov.salesOrganization;
                        list.push(el);
                        _this.listOVs.push(ov);
                    });
                    _this.dataSource = new MatTableDataSource(list);
                    setTimeout(function () { return _this.dataSource.paginator = _this.paginator; });
                });
                _this.snackBar.open('Updated Correctly', 'Ok', { duration: 4000 });
            }
        });
    };
    DrValidationComponent.prototype.addNewRow = function () {
        var row = { material: null, materialDescription: null, client: null, prolog: null, serial: null, uoc: null, qtd: null, obs: null };
        this.openDialog(row);
    };
    DrValidationComponent.prototype.approveDR = function () {
        var _this = this;
        this.drService.updateBookingToDR(this.id).subscribe(function (response) {
            _this.router.navigate(['delivery-optimizer']);
        }, function (err) {
            _this.snackBar.open('Erro ao criar DR a partir de DR Booking', 'Ok', { duration: 2000 });
        });
    };
    return DrValidationComponent;
}());
export { DrValidationComponent };
var DialogDrValidationComponent = /** @class */ (function () {
    function DialogDrValidationComponent(ovService, dialogRef, data) {
        this.ovService = ovService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.el = {};
        this.el = {};
        this.el['id'] = data.row.id;
        this.el['material'] = data.row.material;
        this.el['materialDescription'] = data.row.materialDescription;
        this.el['client'] = data.row.client;
        this.el['prolog'] = data.row.prolog;
        this.el['serial'] = data.row.serial;
        this.el['uoc'] = data.row.uoc;
        this.el['qtd'] = data.row.qtd;
        this.el['drId'] = data.row.drId;
        this.el['obs'] = data.row.obs;
    }
    DialogDrValidationComponent.prototype.onClickOk = function () {
        var _this = this;
        var ovAux = this.data.listOv.find(function (e) { return e.id === _this.el['id']; });
        if (ovAux === undefined) {
            ovAux = new OV();
            ovAux.drId = this.data.listOv[0].drId;
            ovAux.salesDocument = this.data.listOv[0].salesDocument;
            ovAux.salesSequence = (Math.random() * 1000) % 100;
            ovAux.referenceNoNf = this.data.listOv[0].referenceNoNf;
        }
        ovAux.id = this.el['id'];
        ovAux.materialCode = this.el['material'];
        ovAux.customerName = this.el['client'];
        ovAux.materialDescription = this.el['materialDescription'];
        ovAux.assignmentInformation = this.el['prolog'];
        ovAux.customerAddress = this.el['serial'];
        ovAux.customerCity = this.el['uoc'];
        ovAux.salesOrderQuantity = this.el['qtd'];
        ovAux.salesOrganization = this.el['obs'];
        this.ovService.saveMultipleOV([ovAux]).subscribe(function (resp) {
            _this.dialogRef.close({ what: 'approve', ov: _this.el });
        });
    };
    DialogDrValidationComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    return DialogDrValidationComponent;
}());
export { DialogDrValidationComponent };
