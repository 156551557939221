import { AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import { DashboardService } from '../../providers/dashboard.service';
import { GraphCardComponent } from '../../components/graph-card/graph-card.component';
import { BarObject } from '../../models/bar-object';
import { TranslateService } from '@ngx-translate/core';
import { ClientUserService } from '../../providers/client-user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements AfterViewInit {
  costWeightData: any;
  dateReference = [];
  selectedDate = '';
  clientList = [];
  lspList = [];
  lspSelected = [];
  typeSelected = [];
  clientSelected = [];
  ufSelected = [];
  ufList = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  typeList = ['EDB', 'ED', 'CMS', 'DTR', 'Cross Doc', 'Cross Docking', 'Cross Docking'];
  colors = ['#8ed4c6', '#b4bae8', '#ebe095', '#9bdeb2', '#addded', '#eedab8'];
  @ViewChild('pieComponentChart') pieComponentChart: GraphCardComponent;
  @ViewChild('mdrByLspChart') mdrByLspChart: GraphCardComponent;
  @ViewChild('dpPerc') dpPerc: GraphCardComponent;
  @ViewChild('drsStatusChart') drsStatusChart: GraphCardComponent;
  @ViewChild('compositeBarsChart') mdrsChart: GraphCardComponent;
  @ViewChild('deliveredOvsChart') deliveredOvsChart: GraphCardComponent;
  @ViewChild('costPerKiloChart') costPerKiloChart: GraphCardComponent;
  @ViewChild('firstTimeRightChart') firstTimeRightChart: GraphCardComponent;
  @ViewChild('drDelivery') drDelivery: GraphCardComponent;
  @ViewChild('drPrecision') drPrecision: GraphCardComponent;
  @ViewChild('wordcloudChart') wordcloudChart: GraphCardComponent;
  @ViewChild('c02Chart') c02Chart: GraphCardComponent;
  @ViewChild('searchType') searchType;
  // relativos a busca de data
  @Input() dateSearch: string[] = [];
  dates: any[] = [ null, null ];
  constructor(private dashboardService: DashboardService, private translateService: TranslateService, public clientService: ClientUserService) {
    this.clientService.getClients().subscribe((data) => {
      this.clientList = data;
      console.log(this.clientList);
    });
    this.clientService.getLspList().subscribe( (lsps) => {
      this.lspList = lsps;
      console.log(this.lspList);
    });
  }

  ngAfterViewInit(): void {
    this.loadCharts();
  }

  applyFilter() {
    this.loadCharts();
  }

  loadCharts() {
    this.createMdrChart();
    this.createOvsChart();
    this.createDrsByClient();
    this.createCostKgChart();
    this.createDrByStatusChart();
    this.createEmptyBarChartData();
    this.createDeliveryPrecision();
    this.createMdrByShippingChart();
  }

  setDRData(data) {
    return data['aggregate'].map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countRightPathDRs + day.countWrongPathDRs
      };
    });
  }

  setDrDeliveryPrecisionData(data) {
    return data['aggregate'].map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countWrongPathDRs
      };
    });
  }

  createMdrByLspChart(data: any) {
    const chartData = [{name: 'Outside Delivery Precision', y: 0}, {name: 'Inside Delivery Precision', y: 0}];

    data['aggregate'].forEach(el => {
      chartData[0].y += el['countRightPathDRs'];
      chartData[1].y += el['countWrongPathDRs'];
    });

    this.dpPerc.createChart(chartData, 'dp-perc', data['separate']);
  }

  createDrDeliveryChart(data: any) {
    const ovData = this.setDRData(data);

    if (ovData.length !== 0) {
      const chartData = {
        number: ovData.map(e => e.total).reduce((a, b) => a + b, 0),
        percentage: this.setDRsPercentage(ovData),
        categories: ovData.map(arr => arr.day),
        series: [{
          name: this.translateService.instant('DASHBOARD/drDelivered'),
          type: undefined,
          data: ovData.map((arr) => (arr.total))
        }],
        subtitle: this.translateService.instant('DASHBOARD/drDelivered/Subtitle'),
      };
      this.drDelivery.createChart(chartData, 'dr-delivered', data['separate']);
    } else {
      const emptyData = this.createEmptyBarChartData();
      this.drDelivery.createChart(emptyData, 'dr-delivered', data['separate']);
    }
  }

  createDrPrecisionChart(data: any) {
    // first time right
    const firstTimeData = this.setDrDeliveryPrecisionData(data);
    // console.log(firstTimeData);

    if (firstTimeData.length !== 0) {
      const chartData = {
        number: firstTimeData.map(e => e.total).reduce((a, b) => a + b, 0),
        percentage: this.setDRsPercentage(firstTimeData),
        categories: firstTimeData.map(arr => arr.day),
        series: [{
          name: this.translateService.instant('DASHBOARD/deliveryPrecision'),
          type: undefined,
          data: firstTimeData.map((arr) => (arr.total))
        }],
        subtitle: this.translateService.instant('DASHBOARD/deliveryPrecision/Subtitle'),
      };
      this.drPrecision.createChart(chartData, 'delivery-precision', data['separate']);
    } else {
      const emptyData = this.createEmptyBarChartData();
      this.drPrecision.createChart(emptyData, 'delivery-precision', data['separate']);
    }
  }

  createDeliveryPrecision() {
    this.dashboardService.getDeliveryPrecision(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      console.log('Delivery Precision');
      console.log(data);
      this.createMdrByLspChart(data);
      this.createDrDeliveryChart(data);
      this.createDrPrecisionChart(data);
    });
  }

  sortByDate(data) {
    return data.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt <= b.createdAt) {
        return -1;
      }
    });
  }

  setCostPerKiloData(data) {
    return data['aggregate'].map(day => {
      const currentDay = new Date(day.startDate);
      return { 'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(), 'cost': day.cost, 'weight': day.weight };
    });
  }

  sortDate(data) {
    return data.sort((b, a) => {
      if (a.year > b.year) {
        return 1;
      } else if (a.year < b.year) {
        return -1;
      } else {
        if (a.month > b.month) {
          return 1;
        } else if (a.month < b.month) {
          return -1;
        } else {
          if (a.day > b.day) {
            return 1;
          } else if (a.day < b.day) {
            return -1;
          }
          return 0;
        }
      }
    });
  }

  joinDates(dates: any, numberDays: number) {
    const data = [];
    this.sortDate(dates);
    for (let i = 1; (numberDays > 0) && (i < dates.length); i++) {
      if (dates[i - 1].date === dates[i].date) {
        dates[i - 1].countCreated += dates[i].countCreated;
        dates[i - 1].countFinished += dates[i].countFinished;
        data.push(dates[i - 1]);
        i++;
      } else {
        data.push(dates[i - 1]);
      }
      numberDays--;
    }
    return data;
  }

  formate(data: any) {
    let sum: number;
    let finished;
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < data[i].length; j++) {
        data[i][j].startDate = new Date(data[i][j].startDate);
        data[i][j] = {
          'date': data[i][j].startDate.getDate() + '/' + (data[i][j].startDate.getMonth() + 1) + '/' + data[i][j].startDate.getFullYear(),
          'day': data[i][j].startDate.getDate(),
          'month': data[i][j].startDate.getMonth() + 1,
          'year': data[i][j].startDate.getFullYear(),
          'count': Object.values(data[i][j].shippingCompanies)
        };
        sum = 0;
        data[i][j].count.forEach(elem => sum += elem);
        if (i === 0) {
          data[i][j].countCreated = sum;
          data[i][j].countFinished = 0;
        } else {
          data[i][j].countCreated = 0;
          data[i][j].countFinished = sum;
        }
      }
    }

      finished = this.joinDates(data[0].concat(data[1]), 30);

    data = {
      dates: [],
      valuesCreated: [],
      valuesFinished: [],
      sumCreated: 0,
      sumFinished: 0
    };

    for (let i = 0; i < finished.length; i++) {
      data.dates.push(finished[i].date);
      data.valuesCreated.push(finished[i].countCreated);
      data.valuesFinished.push(finished[i].countFinished);
      data.sumCreated += finished[i].countCreated;
      data.sumFinished += finished[i].countFinished;
    }
    data.dates.reverse();
    data.valuesCreated.reverse();
    data.valuesFinished.reverse();
    return data;
  }

  setOVData(data) {
    return data.map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countRightPathDRs + day.countWrongPathDRs
      };
    });
  }

  setFirstTimeRightData(data) {
    return data.map(day => {
      const currentDay = new Date(day.startDate);
      return {
        'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
        'total': day.countRightPathDRs
      };
    });
  }

  setCostPerKiloPercentage(data) {
    let percentage = 0;
    let numberBefore = data.map((arr) => (arr.cost / arr.weight));
    if (numberBefore.length >= 2) {
      numberBefore = numberBefore[numberBefore.length - 2];
      let number = data.map((arr) => (arr.cost / arr.weight));
      number = number[number.length - 1];
      percentage = (number - numberBefore) / numberBefore * 100;
      percentage = Math.round(percentage * 100) / 100;
    }
    return percentage;
  }

  setDRsPercentage(data) {
    let percentage = 0;
    let numberBefore = data.map((arr) => (arr.total));
    if (numberBefore.length >= 2) {
      numberBefore = numberBefore[numberBefore.length - 2];
      let number = data.map((arr) => (arr.total));
      number = number[number.length - 1];
      percentage = (number - numberBefore) / numberBefore * 100;
      percentage = Math.round(percentage * 100) / 100;
    }
    return percentage;
  }

  createEmptyBarChartData() {
    const chartData: BarObject = {
      number: 0,
      percentage: 0,
      categories: [],
      series: [],
      subtitle: '',
    };
    return chartData;
  }

  createMdrChart() {
    this.dashboardService.getMdrByCreated(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      this.dashboardService.getMdrByDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data2: any) => {
          const finished = [];
          finished.push(data['aggregate']);
          finished.push(data2['aggregate']);
          // console.log(finished);
          // console.log({"final":this.formate(finished)} );
          const finalData = this.formate(finished);
          // console.log(finalData);
          finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
          finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
          finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
          finalData['finishedTooltip'] = 'Delivered';

          this.mdrsChart.createChart(finalData, 'composite-bars', data['separate']);
      });
    });
  }

  createMdrByShippingChart() {
    this.dashboardService.getMdrByShippingCompany(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
     // get names of lsps
      let lsps = this.lspList.map(lsp => lsp.name);
      if (this.lspSelected.length > 0) {
        lsps = this.lspSelected;
      }

      // create record to merge count by lsp, backend separate data by day, month or year
      const mergedLspCount: Record<string, number> = {};

      // merge and add total
      this.lspList.map(lsp => mergedLspCount[lsp.name] = 0);
      data['aggregate'].reduce((acc, val) => {
        acc[val.shippingCompany] += val.count;
        return acc;
      }, mergedLspCount as Record<string, number>);
      const chartData = [];
      // separate only selected lsps for plotting
      lsps.forEach(shippingCompany => {
        chartData.push({ name: shippingCompany, y: mergedLspCount[shippingCompany] });
      });

      if (chartData) {
        this.mdrByLspChart.createChart(chartData, 'mdr-by-lsp', data['separate']);
      } else {
        const emptyData = this.createEmptyBarChartData();
        this.mdrByLspChart.createChart(emptyData, 'mdr-by-lsp', data['separate']);
      }
    });
  }

  createDrsByClient() {
    this.dashboardService.getDrByClient(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      data['aggregate'] = data['aggregate'].sort((a, b) => a.count > b.count ? -1 : a.count < b.count ? 1 : 0).slice(0, 6);
      const chartData = [];
      data['aggregate'].forEach(client => {
        if (client.client === null) {
          client.client = 'CLIENTE';
        }
        chartData.push({ name: client.client, y: client.count });
      });
      this.pieComponentChart.createChart(chartData, 'dr-by-client', data['separate']);
    });
  }

  createOvsChart() {
    // related to first time right and delivered ovs charts
    this.dashboardService.getOVsDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data) => {
      // delivered ovs
      const ovData = this.setOVData(data['aggregate']);
      const firstTimeData = this.setFirstTimeRightData(data['aggregate']);
      const percentage = [];
      for (let i = 0; i < ovData.length; i++) {
        percentage.push({'day': ovData[i].day, 'total': firstTimeData[i].total * 100 / ovData[i].total});
      }
      if (ovData.length !== 0) {
        const chartData = {
          number: ovData[ovData.length - 1].total,
          percentage: Math.round(firstTimeData[firstTimeData.length - 1 ].total * 100 / ovData[ovData.length - 1].total),
          categories: ovData.map(arr => arr.day),
          series: [{
            name: this.translateService.instant('TOTAL_DRS'),
            type: undefined,
            visible: false,
            data: ovData.map((arr) => (arr.total))
          }, {
            name: this.translateService.instant('FIRST_TIME_RIGHT'),
            type: undefined,
            visible: false,
            data: firstTimeData.map((arr) => (arr.total))
          }, {
            name: 'FTR Percentage',
            type: undefined,
            data: percentage.map((arr) => (arr.total))
          }],
          subtitle: this.translateService.instant('TOTAL_DRS'),
        };
        this.deliveredOvsChart.createChart(chartData, 'delivered-ovs', data['separate']);
      } else {
        const emptyData = this.createEmptyBarChartData();
        this.deliveredOvsChart.createChart(emptyData, 'delivered-ovs', data['separate']);
      }
      // first time right
      // if (firstTimeData.length !== 0) {
      //   const chartData = {
      //     number: firstTimeData[firstTimeData.length - 1].total,
      //     percentage: this.setDRsPercentage(firstTimeData),
      //     categories: firstTimeData.map(arr => arr.day),
      //     series: [{
      //       name: this.translateService.instant('FIRST_TIME_RIGHT'),
      //       type: undefined,
      //       data: firstTimeData.map((arr) => (arr.total))
      //     },
      //   ],
      //     subtitle: this.translateService.instant('FIRST_TIME_RIGHT'),
      //   };
      //   this.firstTimeRightChart.createChart(chartData, 'first-time-right', data['separate']);
      // } else {
      //   const emptyData = this.createEmptyBarChartData();
      //   this.firstTimeRightChart.createChart(emptyData, 'first-time-right', data['separate']);
      // }
    });
  }

  createDrByStatusChart() {
    this.dashboardService.getC02ByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data: any) => {
      // console.log('co2', data);
      const resultObj = {};
      resultObj['dates'] = [];
      resultObj['valuesCreated'] = [];
      resultObj['valuesFinished'] = [];
      resultObj['sumCreated'] = 0;
      resultObj['sumFinished'] = 0;
      for (const [key, value] of Object.entries(data['resultPerVehicle'])) {
        resultObj['dates'].push(key);
        resultObj['valuesCreated'].push(value['kgco2'] / 1000);
        resultObj['valuesFinished'].push(value['kg'] / 1000);
        resultObj['sumCreated'] += Math.floor(value['kgco2'] / 1000);
        resultObj['sumFinished'] += Math.floor(value['kg'] / 1000);
        resultObj['createdTitle'] = 'CO2/created/title';
        resultObj['finishedTitle'] = 'CO2/finished/title';
        resultObj['createdTooltip'] = 'CO2/created/tooltip';
        resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
        resultObj['eco'] = 'eco';
      }
    });
  }
  createCostKgChart() {

    // related to cost per kilo chart
    this.dashboardService.getCostKg('day', this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe((data) => {
      const dataDay = this.setCostPerKiloData(data);
      if (dataDay.length !== 0) {
        const dataNumber = dataDay.map((arr) => (arr.cost / arr.weight));
        const chartData: BarObject = {
          number: `$ ${Math.round(dataNumber[dataNumber.length - 1] * 100) / 100}`,
          percentage: this.setCostPerKiloPercentage(dataDay),
          categories: dataDay.map((arr) => arr.day),
          series: [{
            name: this.translateService.instant('COST_KG'),
            type: undefined,
            data: dataDay.map((costPerKilo) => (costPerKilo.cost / costPerKilo.weight))
          }, {
            name: this.translateService.instant('COST'),
            visible: false,
            type: 'line',
            data: dataDay.map((cost) => cost.cost)
          }, {
            name: this.translateService.instant('WEIGHT'),
            visible: false,
            type: 'line',
            data: dataDay.map((weight) => weight.weight)
          }],
          subtitle: this.translateService.instant('COST_PER')
        };
        this.costPerKiloChart.createChart(chartData, 'cost-per-kilo', data['separate']);
      } else {
        const emptyData = this.createEmptyBarChartData();
        this.costPerKiloChart.createChart(emptyData, 'cost-per-kilo', data['separate']);
      }
    });

    // Occurrence Word Map
    this.dashboardService.getOccurrenceWords(this.dates).subscribe( (elem ) => {
      this.wordcloudChart.createChart(elem, 'wordcloud');
      // console.log({word:elem});
    });
  }
}
