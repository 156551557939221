import { Component, OnInit, ViewChild, Input, Inject, ElementRef, OnDestroy } from '@angular/core';
import {MatGridList, TooltipPosition} from '@angular/material';
import {MatTableDataSource, MatSort, MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import {AgmCoreModule, LatLng, MapsAPILoader} from '@agm/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {MdrService} from '../../providers/mdr.service';
import {DR} from '../../models/dr';
import {UOC} from '../../models/uoc';
import {MDR, MDR_STATUS} from '../../models/mdr';
import {Occurrence} from '../../models/occurrence';
import {MdrObservable} from '../../services/mdr.service';
import {PriceObservable} from '../../services/price.service';
import {DrObservable} from '../../services/dr.service';
import {UOCObservable} from '../../services/uoc.service';
import {User} from '../../models/user';

import {DrService} from '../../providers/dr.service';

declare var google: any;
import { } from 'googlemaps';

import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import {OverviewExampleDialogComponent} from '../new-delivery-request/new-delivery-request.component';
import {OV} from '../../models/ov';
import {UOCMDR} from '../../models/uoc__mdr';
import {COPQMDR} from '../../models/copq__mdr';
import {CostAvoidanceMDR} from '../../models/costAvoidance__mdr';
import {CostAvoidance} from '../../models/costAvoidance';
import {Copq} from '../../models/copq';
import {Driver} from '../../models/driver';
import {Observable} from 'rxjs/Observable';
import {IntervalObservable} from 'rxjs/observable/IntervalObservable';
import {TimerObservable} from 'rxjs/observable/TimerObservable';
import 'rxjs/add/operator/takeWhile';
import {TranslateService} from '@ngx-translate/core';
import {TransportComponent} from './transport/transport.component';
import {TaxComponent} from './tax/tax.component';
import {Message} from '../../models/message';
import {SocketService} from '../../providers/socket.service';
import {ChatService} from '../../providers/chat.service';
import {Event} from '../../models/events';
import {AttachmentsComponent} from './attachments/attachments.component';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-logistic-order',
  templateUrl: './logistic-order.component.html',
  styleUrls: ['./logistic-order.component.scss']
})


export class LogisticOrderComponent implements OnInit, OnDestroy {
  public chatType = 5; // transportadora <-> motorista

  @ViewChild(TransportComponent) transport;
  @ViewChild(TaxComponent) tax;
  @ViewChild(AttachmentsComponent) attachments;

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'printable', // the id of html/table element
    options: {
      pagespilt: true,
      html2canvas: {
        useCORS: true,
      },
      pagebreak: {
        mode: 'avoid-all',
      }
    }
  };

  public searchControl: FormControl;
  private alive: boolean; // used to unsubscribe from the IntervalObservable
  driver: Driver;
  drivers: Driver[] = [];
  opened: any;
  addNewOV: any;
  print = false;
  mdr: MDR = new MDR();
  editable = true;
  driverEditable = false;
  currentUser: any;
  role = 0;
  editMDRStatus = false;
  selectedVehicle: any;
  selectedShippingCompany: any;
  private idsocket: string;
  private display: boolean; // whether to display info in the component
  // use *ngIf="display" in your html to take
  // advantage of this
  reservation: any = '';
  reprove: any = '';
  shipmentReturned: any = '';
  positionOptions: TooltipPosition = 'after';
  tooltip = 'Esta MDR esta sendo editada por outra pessoa';

  price: Price =  {price: 0, total: 0, adValorem: 0, addDelivery: 0, taxedPrice: 0, sc: '', vehi: '' };
  @ViewChild(MatSort) sort: MatSort;


  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild(TransportComponent)
  transportInfo: TransportComponent;


  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  hiddenMap = true;

  public channel: number;
  public ioConnection: any;
  private user: string;
  private lock = false;
  public valueNfStatus = false;
  mdrStatus = [
    { value: 'MDR em validação', name: 'LOGISTIC-ORDER/mdr-status/validation' },
    { value: 'MDR em criação', name: 'LOGISTIC-ORDER/mdr-status/creation' },
    { value: 'MDR devolvida por LSP', name:  'LOGISTIC-ORDER/mdr-status/returned-lsp' },
    { value: 'MDR aguardando LSP', name: 'LOGISTIC-ORDER/mdr-status/waiting-lsp' },
    { value: 'MDR aprovada LSP', name: 'LOGISTIC-ORDER/mdr-status/approved-lsp' },
    { value: 'Motorista e veiculo definidos', name: 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined'},
    { value: 'Veículo carregado', name: 'LOGISTIC-ORDER/mdr-status/vehicle-loaded' },
    { value: 'Carga entregue com sucesso', name: 'LOGISTIC-ORDER/mdr-status/cargo-delivered' },
    { value: 'Carga retornada total', name: 'LOGISTIC-ORDER/mdr-status/cargo-returned' },
    { value: 'Entrega de carga certificada pelo LSP', name: 'LOGISTIC-ORDER/mdr-status/certified-delivery'},
    { value: 'MDR paga / concluída', name: 'LOGISTIC-ORDER/mdr-status/mdr-paid'},
  ];

  mdrStatusTranslated = {
    'MDR em validação': 'LOGISTIC-ORDER/mdr-status/validation',
    'MDR em criação': 'LOGISTIC-ORDER/mdr-status/creation',
    'MDR devolvida por LSP':  'LOGISTIC-ORDER/mdr-status/returned-lsp',
    'MDR aguardando LSP': 'LOGISTIC-ORDER/mdr-status/waiting-lsp',
    'MDR aprovada LSP': 'LOGISTIC-ORDER/mdr-status/approved-lsp',
    'Motorista e veiculo definidos': 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined',
    'Veículo carregado': 'LOGISTIC-ORDER/mdr-status/vehicle-loaded',
    'Carga entregue com sucesso': 'LOGISTIC-ORDER/mdr-status/cargo-delivered' ,
    'Carga retornada total': 'LOGISTIC-ORDER/mdr-status/cargo-returned' ,
    'Entrega de carga certificada pelo LSP': 'LOGISTIC-ORDER/mdr-status/certified-delivery',
    'MDR paga / concluída': 'LOGISTIC-ORDER/mdr-status/mdr-paid',
  };

  MDR_STATUS = MDR_STATUS;

  constructor(public dialog: MatDialog, private titleService: Title, private translate: TranslateService,
              public mdrService: MdrService, public router: Router, public aRoute: ActivatedRoute,
              public snackBar: MatSnackBar, public mdrObservable: MdrObservable,
              public priceObservable: PriceObservable, public drObservable: DrObservable,
              public drService: DrService , public uocObservable: UOCObservable,
              private socketService: SocketService, public exportAsService: ExportAsService,
              public chat: ChatService) {


    this.driver = new Driver();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.display = false;
    this.alive = true;
  }

  ngOnDestroy() {
    this.titleService.setTitle('Bluebee');
  }

  startup() {
    this.aRoute.params.subscribe( (params) => {
      this.channel = params.id;
    });

    TimerObservable.create(0, 5 * 60 * 10000)
                   .takeWhile(() => this.alive)
                   .subscribe(() => {
                   });
    this.aRoute.params.subscribe( params => {
      this.mdrService.getMDR(params.id).subscribe((data) => {
        this.mdrService.getNf(params.id).subscribe((nfs) => {
          const totalValue = nfs.filter(nf => parseFloat(nf.totalValue) >= 500000);
          if (totalValue.length > 0) {
            this.valueNfStatus = true;
          }
        });
        this.mdr = data;
        if (this.mdr.pickupID !== null) {
          this.titleService.setTitle('BB: ' + this.mdr.pickupID);
        }
        if (this.mdr.status === 'MDR aprovada com ressalvas LSP') {
          this.mdrService.getMDRReservation(params.id).subscribe((elem) => {
            // console.log(elem);
            this.reservation = elem;
          });
        }
        if (this.mdr.status === 'MDR em criação') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            this.reprove = elem;
          });
        }
        if (this.mdr.status === 'MDR devolvida por LSP') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            this.reprove = elem;
          });
        }
        if (this.mdr.status === 'Carga retornada total') {
          this.mdrService.getMDRReprove(params.id).subscribe((elem) => {
            // console.log(elem);
            this.shipmentReturned = elem;
          });
        }
        this.aRoute.params.subscribe( route => {
          this.drService.getByMdr(route.id).subscribe((newDr) => {
            this.drObservable.changeDR(newDr);
          });
        });
        this.mdrService.getListUOC().subscribe((uoc) => {
          this.uocObservable.changeUOC(uoc);
        });
        this.mdrObservable.changeMDR(this.mdr);
        this.initIoConnection(this.channel);
        if (this.mdr.status === 'MDR em validação') {
          this.mdrService.createLegs(this.mdr.id, false).subscribe((legs) => {
            // console.log(legs);
          });
        } else if (this.mdr.status === 'MDR em criação') {
          this.priceObservable.changePrice(this.price);
          this.mdrService.getAllPriceR(this.mdr).subscribe((elem) => {
            console.log(elem);
            this.price = elem;
            // this.mdr.totalNF = '0';
            // this.mdr.sapCode = '0';
            this.mdr.SAD = elem.sapCd;
            this.mdr.totalKM = elem.km;
            this.mdr.vehicle = elem.vehi;
            this.priceObservable.changePrice(elem);
            this.mdrObservable.changeMDR(this.mdr);
          });
        } else {
          this.mdrService.getAlreadyExistentPrice(this.mdr).subscribe((elem) => {
            this.price = elem;
            this.price.addDelivery = elem.addEntrega;
            this.price.adValorem = elem.adValorem;
            this.price.price = elem.frete;
            this.price.total = elem.total;
            this.price.taxedPrice = elem.freteTax;
            this.priceObservable.changePrice(elem);
          });
        }
      }, (error) => {
        this.snackBar.open('Erro ao carregar MDR.', 'Ok', {
          duration: 4000,
        });
        this.router.navigate(['/']);
      });
    });
  }

  ngOnInit() {
    this.startup();
  }

  updateNFCost() {
    this.tax.nfePrice = this.attachments.totalNfCost;
  }

  ngAfterViewInit() {
  }

  mapButtonClick() {
    this.hiddenMap = !this.hiddenMap;
  }

  showMap() {
    switch (this.mdr.status) {
      case 'MDR em validação':
      case 'MDR em criação':
      case 'MDR devolvida por LSP':
      case 'MDR aguardando LSP':
      case 'MDR aprovada LSP':
      case 'Motorista e veiculo definidos':
      case 'Veículo carregado':
        return true;
      default:
        return false;
    }
  }

  onClickPrint() {
    this.print = true;
    this.exportAsService.save(this.exportAsConfig, this.mdr.pickupID);
    this.print = false;
  }

  editStatus() {
    this.editMDRStatus = !this.editMDRStatus;
  }

  saveStatus() {
    this.editMDRStatus = !this.editMDRStatus;
    // console.log(this.currentUser.user);
    this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe( (data) => {
      this.snackBar.open('Status atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
        duration: 4000,
      });
    });
  }

  createDrivers() {
    this.transport.drivers.forEach( (el, i, arr) => {
      this.createDriver(el);
    });
  }

  createDriver(driver: Driver) {
    driver.mdrId = this.mdr.id;
    this.mdrService.createDriver(driver).subscribe( (response) => {
      // console.log(response);
    });
  }
  private initIoConnection(id: number): void {
    this.user = this.currentUser.user.id;
    this.socketService.initSocketMDR(id, this.user);

    // this.ioConnection = this.socketService.onComeMDRLists()
    //   .subscribe((message) => {
    //    //console.log(message);
    //     this.idsocket= message['user'][this.user];
    //     if(message['socket'][0]!==this.idsocket){
    //       this.lock =false;
    //     }else{
    //       this.lock =true;
    //     }
    //     this.aRoute.params.subscribe( params => {
    //       this.mdrService.getMDR(params.id).subscribe((data) => {
    //         this.mdr = data;
    //         this.mdr.lock = this.lock;
    //         this.mdrObservable.changeMDR(this.mdr);
    //       })
    //     });
    //
    //   });

    this.socketService.onEvent(Event.CONNECT)
        .subscribe(() => {
          // console.log('connected');
        });

    this.socketService.onEvent(Event.NEW_MESSAGE)
        .subscribe((message) => {
          // console.log(message);
          // this.messages.push(message);
        });

    this.socketService.onEvent(Event.DISCONNECT)
        .subscribe(() => {
          // console.log('disconnected');
        });

  }



  cancelMDR(): void {
    this.mdr.status = 'MDR aguardando LSP';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);

    });
  }
  deleteMDR(): void {
    this.mdr.status = 'MDR Reprovado';
    this.mdrService.update(this.mdr, this.user).subscribe( async (response) => {
      await this.mdrService.liberateDR(this.mdr).subscribe((data) => {
        this.snackBar.open(this.mdr.status, 'Ok', {
          duration: 4000,
        });
      });
      this.router.navigate(['delivery-order']);

    });
  }

  createToken(): void {
    this.mdrService.createToken(this.mdr.id).subscribe( (response) => {
      const link = 'view/' + response.token + '/mdr';
      const dialogRef = this.dialog.open(CreateTokenDialogComponent, {
        data: {
          link: link
        }
      });
    });
  }

  reproveMDR(): void {
    const dialogRef = this.dialog.open(AcceptMdrReproveDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdr.shippingCompany = '';
        this.mdrService.updateReprove(this.mdr, response[1]).subscribe( (data) => {
          this.snackBar.open(this.mdr.status, 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['delivery-order']);
        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }

  openHistory() {
    this.mdrService.getHistory(this.mdr.id).subscribe((history) => {
      const hist = history.sort( (a, b) =>  a.quando - b.quando);
       const dialogRef = this.dialog.open(MdrHistoryDialogComponent, {
         width: '80vw',
         maxHeight: '80vh',
         data: {history: hist}
       });
    });
  }


  giveBackMDR(): void {
    this.mdr.status = 'MDR devolvida por LSP';
    this.mdr.shippingCompany = '';
    this.mdrService.update(this.mdr, this.user).subscribe( (response) => {
      this.mdrService.giveBack(this.mdr).subscribe( (data) => {
      });
    });
  }

  cancelTake(): void {
    this.mdr.status = 'MDR em criação';
    // this.mdr.shippingCompany = '';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
    });
  }

  allShipmentReturned(): void {
    const dialogRef = this.dialog.open(AcceptMdrReturnDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdr.status = 'Carga retornada total';
        this.notification(response);
        this.mdrService.updateReturn(this.mdr, response[1]).subscribe( (data) => {

        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }

  updateMDR(): void {
    if (this.mdr.status === 'MDR em criação') {
      this.tax.savePrice();
    }
    this.transport.saveDriver();
    this.transport.saveConveyor();
    this.tax.saveConveyor();
    this.tax.saveUOC();
    this.tax.saveCOPQ();
    this.tax.saveCA();
    this.attachments.updateInfo();
  }

  approveWithConditionsMDR(): void {
    const dialogRef = this.dialog.open(AcceptMdrReservationDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdr.status = 'MDR aprovada com ressalvas LSP';
        this.notification(response);
        this.mdrService.updateRessalva(this.mdr, response[1]).subscribe( (data) => {

        });
      } else {
        this.router.navigate(['delivery-order']);


      }
    });
  }

  returnTodeliveryAgain(): void {
    this.mdr.status = 'Carga retornada para re-entrega';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);

    });
  }

  sendLSP(): void {
    // verifica se as informações de transportadora estão preenchidas
  // console.log();
    if ( ['shippingCompany', 'vehicle', 'pickupDate', 'pickupID'].some( e => {
      if (this.transportInfo.mdr[e] === null ||
         this.transportInfo.mdr[e] === '' ) {
        return true;
      }
      return false;
    })) {
      this.snackBar.open(this.translate.instant('ERROR/MISSING_INFO_SEND_LSP'), 'Ok');
      return;
    }

    // verifica se a data de coleta é válida
    // const pickupDate = new Date(this.transportInfo.mdr.pickupDate);
    // this.drService.getByMdr(this.transportInfo.mdr.id).subscribe((drList) => {
    //   if( drList.some( e => {
    //     let dateScheduling = new Date(e.dateScheduling);
    //     if(pickupDate.getTime() > dateScheduling.getTime()) {
    //       // se a data de coleta for maior que a data da entrega
    //       return true;
    //     }
    //     return false;
    //   })){
    //     this.snackBar.open("Data de coleta deve ser menor que a data de entrega",'Ok', { duration: 4000 });
    //   } else {
    // data é válida, prossegue


    this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe ( (users) => {
      if (users !== null) {
        if (users.length > 0) {
          const dialogRef = this.dialog.open(LSPEmailDialogComponent, {
            data: users,
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result[0]) {
              const selectedUsers: User[] = result[1].value;
              if (selectedUsers !== null) {
                this.mdrService.sendLSPEmail(this.mdr, selectedUsers).subscribe();
              }

              this.tax.savePrice();
              if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
                const uoc = new UOCMDR();
                uoc.idMDR = this.mdr.id;
                uoc.value = this.tax.getTotal() * 0.5 * 100;
                uoc.uoc = 'Retorno Ericsson';
                this.mdrService.createUOC(uoc).subscribe((resp) => {
                  // console.log(resp);
                });
              }
              this.mdr.status = 'MDR aguardando LSP';
              this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
                // this.router.navigate(['delivery-optimizer']);
              });
            }
          });
        } else {
          if (this.mdr.type === 'CMS' && this.mdr.SAD === 'true') {
            const uoc = new UOCMDR();
            uoc.idMDR = this.mdr.id;
            uoc.value = this.tax.getTotal() * 0.5 * 100;
            uoc.uoc = 'Retorno Ericsson';
            // console.log(uoc);
            this.mdrService.createUOC(uoc).subscribe((resp) => {
              // console.log(resp);
            });
          }
          this.tax.savePrice();
          this.mdr.status = 'MDR aguardando LSP';
          this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
            this.router.navigate(['delivery-optimizer']);
          });
        }
      }
    });

    // this.mdrService.savePrice(this.price, this.mdr).subscribe((el) => {
    // console.log('chegou aqui!');
    // });

  }

  saveAddressInMapp(): void {
    this.mdr.status = 'MDR em criação';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.startup();
    });
  }

  reproveRessalva(): void {
    this.mdr.status = 'MDR em criação';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });
  }
  aproveRessalva(): void {
    this.mdr.status = 'MDR aprovada LSP';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });
  }

  acceptMDR(): void {
    this.mdr.status = 'MDR aprovada LSP';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });
  }

  sendWithoutDriver(): void {
    this.mdr.status = 'Motorista e veiculo definidos';
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      // this.notification(data);
    });
  }

  defineDriver(): void {
    if (this.transport.drivers.length === 0) {
      this.snackBar.open('Favor definir um motorista', 'Ok', {duration: 4000});
      return;
    }

    this.mdr.status = 'Motorista e veiculo definidos';
    this.createDrivers();
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      // this.notification(data);
    });
  }

  vehicleLoaded(): void {
    this.mdr.status = 'Veículo carregado';
    // //console.log(this.mdr);
    this.drService.getByMdr(this.mdr.id).subscribe( (drList) => {
      for (let i = 0; i < drList.length; i++) {
        if (drList[i].status === 'READY_TO_PICKUP') {
          drList[i].status = 'InTransit';
          this.drService.update(drList[i]).subscribe(k => {
            // console.log("UPDATED");
          });
        }
      }
    });
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });
  }

  delivered(): void {
    this.mdr.status = 'Carga entregue com sucesso';
    this.drService.getByMdr(this.mdr.id).subscribe( (drList) => {
      for (let i = 0; i < drList.length; i++) {
        if (drList[i].status === 'READY_TO_PICKUP' || drList[i].status === 'InTransit') {
          drList[i].status = 'Delivered';
          this.drService.update(drList[i]).subscribe(k => {
            // console.log("UPDATED");
          });
        }
      }
    });
    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });

    this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
      this.notification(data);
    });
  }

  deliveredCertified(): void {
    if (!this.attachments.hasEmptyPOD()) {
      const dialogRef = this.dialog.open(DeliveredCertifiedDialogComponent, {
        width: '600px',
        data: {
          text: '',
        }
      });
      dialogRef.afterClosed().subscribe( (response) => {
        if (response[0] === true) {
          this.mdr.status = 'Entrega de carga certificada pelo LSP';
          this.mdrService.update(this.mdr, this.user).subscribe((data) => {
            this.router.navigate(['delivery-order']);
          });
        }
      });
    } else {
      this.snackBar.open('Faltam inserir PODs ou Foto de UOC', 'Ok', {
        duration: 4000,
      });
    }
  }

  pay(): void {
    this.mdr.status = 'MDR paga / concluída';
    this.drService.getByMdr(this.mdr.id).subscribe( (drList) => {
      let k = true;
      drList.forEach((dr) => {
        if (!dr.prolog) {
          k = false;
        }
      });
      // if (k) {
        this.mdrService.update(this.mdr, this.user).subscribe( (data) => {
          this.notification(data);
        });
      // } else {
      //   this.snackBar.open('DRs sem prolog. Impossível realizar', 'Ok', {
      //     duration: 4000,
      //   });
      // }
    });
  }
  goToChat(chatType) {
    this.router.navigate(['chat/' + this.mdr.id + '/' + chatType]);
  }
  notification(data) {
    this.socketService.initSocketNotification(Number(this.user));
    const message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
    this.socketService.sendNotification(message);
    // console.log(data);
    data.forEach(value => {
      const notification = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), this.chatType);
      this.socketService.sendNotification(notification);
    });
    this.snackBar.open(this.mdr.status, 'Ok', {
      duration: 4000,
    });
    this.router.navigate(['delivery-optimizer']);

  }

}

export interface Transportadora {
  id: number;
  name: string;
}

export interface Veiculo {
  id: number;
  name: string;
}

export interface Price {
  adValorem: number;
  addDelivery: number;
  price: number;
  taxedPrice: number;
  total: number;
  sc: string;
  vehi: string;
}
@Component({
  selector: 'app-accept-mdr-reservation-dialog',
  templateUrl: 'accept-mdr-reservation-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReservationDialogComponent {
  public valueRe;

  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReservationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}
@Component({
  selector: 'app-accept-mdr-return-dialog',
  templateUrl: 'accept-mdr-return-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReturnDialogComponent {
  public valueRe;

  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReturnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}


@Component({
  selector: 'app-accept-mdr-reprove-dialog',
  templateUrl: 'accept-mdr-reprove-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class AcceptMdrReproveDialogComponent {
  public valueRe;

  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-delivered-certified-dialog',
  templateUrl: 'delivered-certified-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class DeliveredCertifiedDialogComponent {
  public valueRe;
  constructor(
    public dialogRef: MatDialogRef<AcceptMdrReproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-lsp-email-dialog',
  templateUrl: 'lsp-email-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class LSPEmailDialogComponent {
  selected = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<LSPEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-create-token-dialog',
  templateUrl: 'create-token-dialog.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class CreateTokenDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onClickOk(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-mdr-history',
  templateUrl: 'mdr-history.html',
  styleUrls: ['./logistic-order.component.scss']
})
export class MdrHistoryDialogComponent implements OnInit {
  history = [];
  columns = [
    {value: 'status', name: 'LOGISTIC-ORDER/history/status/'},
    {value: 'quem', name: 'LOGISTIC-ORDER/history/by/'},
    {value: 'quando', name: 'LOGISTIC-ORDER/history/on/'},
    {value: 'pickupDate', name: 'LOGISTIC-ORDER/history/date/'}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.history = data.history;
    // console.log(data.history);
  }
  ngOnInit() {

  }
}
