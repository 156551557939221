/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wordcloud-chart.component.scss.shim.ngstyle";
import * as i1 from "../graph-card.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./wordcloud-chart.component";
var styles_WordcloudChartComponent = [i0.styles, i1.styles];
var RenderType_WordcloudChartComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_WordcloudChartComponent, data: {} });
export { RenderType_WordcloudChartComponent as RenderType_WordcloudChartComponent };
function View_WordcloudChartComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "subtitle"], ["fxLayout", "row"], ["fxLayoutAlign", "space-around center"]], null, null, null, null, null)), i2.ɵdid(1, 671744, null, 0, i3.DefaultLayoutDirective, [i2.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i2.ɵdid(2, 671744, null, 0, i3.DefaultLayoutAlignDirective, [i2.ElementRef, i4.StyleUtils, [2, i3.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i2.ɵted(3, null, [" ", "\n"])), i2.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i2.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-around center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i2.ɵunv(_v, 3, 0, i2.ɵnov(_v, 4).transform("MISSING_DATA")); _ck(_v, 3, 0, currVal_2); }); }
export function View_WordcloudChartComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_WordcloudChartComponent_1)), i2.ɵdid(1, 16384, null, 0, i6.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyData; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i2.ɵinlineInterpolate(1, "", _co.container, ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_WordcloudChartComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-wordcloud-chart", [], null, null, null, View_WordcloudChartComponent_0, RenderType_WordcloudChartComponent)), i2.ɵdid(1, 114688, null, 0, i7.WordcloudChartComponent, [i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WordcloudChartComponentNgFactory = i2.ɵccf("app-wordcloud-chart", i7.WordcloudChartComponent, View_WordcloudChartComponent_Host_0, { container: "container" }, {}, []);
export { WordcloudChartComponentNgFactory as WordcloudChartComponentNgFactory };
