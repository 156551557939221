export class NF {

  public id: number;
  public invoiceNumber: string;
  public series: string;
  public client: string;
  public type: string;
  public weight: string;
  public materials: string;
  public totalValue: number;
  public emissionDate: Date;
  public width: string;
  public height: string;
  public prolog: string;
  public depth: string;
  public status: boolean;
  public drId: number;

  constructor() {
    this.id = null;
    this.invoiceNumber = null;
    this.series = null;
    this.client = null;
    this.type = null;
    this.weight = null;
    this.materials = null;
    this.totalValue = null;
    this.emissionDate = null;
    this.width = null;
    this.height = null;
    this.depth = null;
    this.drId = null;
  }

}
