<mat-card *ngIf="show" class="p-0 py-1" [ngClass]="getStatusClass()">
  <mat-card-title class="m-0 pl-2 d-flex justify-content-between align-items-center">
    <a class='title'  [matTooltip]="pickupID" [href]="setRedirectUrl()">{{ dr.mdr ? (pickupID ? pickupID : (dr.idMDR | mdrId)) : "COCKPIT/no-mdr" | translate }}</a>
    <div class="m-0 p-0 align-items-center">
      <i text class="fa fa-comments"
              matTooltip="há mensagens não lidas entre transportadora e embarcadora"
              matTooltipPosition="left"
              *ngIf="novasMensagens[6]">
      </i>
      <i text class="fa fa-comments"
              matTooltip="há mensagens não lidas entre transportadora e motorista"
              matTooltipPosition="left"
              *ngIf="novasMensagens[5]">
      </i>
      <i text class="fa fa-bookmark"
              matTooltip="Available to create MDR"
              matTooltipPosition="left"
              *ngIf="dr.thirdPartInvoice && dr.status === 'Alistado'">
      </i>
      <i text class="fa fa-street-view"
              matTooltip="Motorista no Site"
              matTooltipPosition="left"
              *ngIf="dr.arrivalTime && dr.status === 'InTransit'">
      </i>
      <i text class="fa fa-wifi"
              matTooltip="Caminhão visto na última hora"
              matTooltipPosition="left"
              *ngIf="!truckSaw && dr.status === 'InTransit'">
      </i>
      <i text class="fa"
              [matTooltip]="tooltip.join(', ')"
              matTooltipPosition="left"
              [ngClass]="icons[color]"
              *ngIf="icons[color]">
      </i>
    </div>
  </mat-card-title>
  <mat-card-content class="px-2 py-1">
    <div class="d-flex justify-content-between smaller-text">
      <div class="d-flex justify-content-center align-items-center">
        <div>{{dr.datePickupScheduling | date: 'dd/MM/yyyy - HH:mm' }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div>{{dr.dateScheduling | date: 'dd/MM/yyyy - HH:mm' }}</div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center smaller-text">
      <div>{</div>
    </div> -->
    <div class="d-flex justify-content-center smaller-text">
      <div>{{ dr.mdr ? dr.mdr.shippingCompany : 'No LSP' }}</div>
    </div>
    <div class="d-flex justify-content-between smaller-text">
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.mdr ? (dr.mdr.type? dr.mdr.type: ('COCKPIT/dr-card/without-type' | translate)) : ('COCKPIT/dr-card/without-type' | translate) }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.model ? dr.model : '-' }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.prolog ? dr.prolog : 'Sem Prolog' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between smaller-text">
      <div class="d-flex justify-content-center align-items-center">
        <div><p>{{ dr.unificationId ? dr.unificationId : '-' }}</p></div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div><p>{{ dr.salesOrders ? dr.salesOrders : '-' }}</p></div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div><p>{{ dr.createdBy ? dr.createdBy : '-' }}</p></div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-center align-items-center">
        <div>{{ dr.client ? dr.client : 'sem cliente' }}</div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class='site' [matTooltip]="dr.site">{{ dr.site ? dr.site : 'sem site' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{ dr.pickupAddress ? dr.hub.name : 'Ericsson' }}</div>
      </div>
      <i text class="fa fa-arrow-right"></i>
      <div class="p-0 col-md-5 d-flex justify-content-center align-items-center">
        <div>{{( dr.city ? dr.city : (dr.deliveryAddress ? dr.deliveryAddress : 'Ericsson') )}}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
