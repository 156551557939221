export class Zhstatus {
  public id: number;
  public shipment: string;
  public delivery: string;
  public salesDoc: string;
  public storBin: string;
  public sorg: string;
  public handlingUnit: string;
  public shipPoint: string;
  public totalWght: string;
  public totalVol: string;
  public packMatls: string;
  public status: string;
  public shty: string;
  public trspPlanPt: string;
  public servcAgent: string;
  public spPi: string;
  public planned: string;
  public loadStart: string;
  public loadEnd: string;
  public completion: string;
  public shmtStart: string;
  public shmtEnd: string;
  public currShipSt: string;
  public gm: string;
  public ops: string;
  public ps: string;
  public podStatus: string;
  public poReference: string;
  public siteId: string;
  public completionIndicator: string;
  public integrated: boolean;
  public insertedBy: number;
  public updatedBy: number;
  public insert: Date;
  public update: Date;
  public active: boolean;

  constructor() {
    this.id = null;
    this.shipment = null;
    this.delivery = null;
    this.salesDoc = null;
    this.storBin = null;
    this.sorg = null;
    this.handlingUnit = null;
    this.shipPoint = null;
    this.totalWght = null;
    this.totalVol = null;
    this.packMatls = null;
    this.status = null;
    this.shty = null;
    this.trspPlanPt = null;
    this.servcAgent = null;
    this.spPi = null;
    this.planned = null;
    this.loadStart = null;
    this.loadEnd = null;
    this.completion = null;
    this.shmtStart = null;
    this.shmtEnd = null;
    this.currShipSt = null;
    this.gm = null;
    this.ops = null;
    this.ps = null;
    this.podStatus = null;
    this.poReference = null;
    this.siteId = null;
    this.completionIndicator = null;
    this.integrated = null;
    this.insertedBy = null;
    this.updatedBy = null;
    this.insert = null;
    this.update = null;
    this.active = null;
  }
}
