import {Component, OnInit, ViewChild, Inject, ElementRef, Input, Output, EventEmitter
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {environment} from '../../../environments/environment';
import {UploadFileService} from '../../providers/upload.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import { saveAs } from 'file-saver/FileSaver';


@Component({
  selector: 'app-upload-files',
  templateUrl: 'upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent  implements OnInit {
  @Input() entity: string;
  @Input() id: number;
  @Input() uploadMassive: {entity: string, id: number}[] = null;
  @Output() messageEvent = new EventEmitter<string>();

  selectedFiles: FileList;
  currentFileUpload: File[] = [];
  progress: { percentage: number } = {percentage: 0};
  fileInputValue = '';
  sendStatus = false;

  constructor(private uploadService: UploadFileService) {}

  ngOnInit() {}
  selectFile(event) {
    this.selectedFiles = event.target.files;
    for (let k = 0; k < this.selectedFiles.length; k++) {
      this.currentFileUpload.push(this.selectedFiles.item(k));
    }
    if (this.currentFileUpload.length > 1) {
      this.uploadMulti();
    } else {
      this.upload();
    }
  }

  upload() {
    if (this.uploadMassive !== null) {
      // console.log(this.uploadMassive);
      this.uploadMassive.forEach( elem => {
        // console.log(elem);
        this.sendStatus = false;
        this.progress.percentage = 0;
          this.uploadService.pushFileToStorage(this.currentFileUpload, elem.id + '', elem.entity).subscribe(event => {
            if (HttpEventType.UploadProgress === event.type ) {
              this.progress.percentage = Math.round(100 * event.loaded / event.total);
            } else   {
              // console.log('File is completely uploaded!');
                // this.currentFileUpload = [];
                this.fileInputValue = '';
                this.messageEvent.emit('ok');
                this.sendStatus = true;
            }
          });
      });
      this.selectedFiles = undefined;
    } else {
      this.sendStatus = false;
      this.progress.percentage = 0;
        this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(event => {
          if (HttpEventType.UploadProgress === event.type ) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
          } else   {
            // console.log('File is completely uploaded!');
              this.currentFileUpload = [];
              this.fileInputValue = '';
              this.messageEvent.emit('ok');
              this.sendStatus = true;
          }
        });
      this.selectedFiles = undefined;
    }
  }

  uploadMulti() {
    this.sendStatus = false;
    this.progress.percentage = 0;
    this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(event => {
      if (HttpEventType.UploadProgress === event.type ) {
        this.progress.percentage = Math.round(100 * event.loaded / event.total);
      } else if ( this.progress.percentage === 100 && this.sendStatus === false)  {
        // console.log('File is completely uploaded!');
        this.currentFileUpload = [];
        this.fileInputValue = '';
        this.messageEvent.emit('ok');
        this.sendStatus = true;
      }
    });
    this.selectedFiles = undefined;
  }
}
