<h1 mat-dialog-title>{{ 'SITE_INVESTIGATION/dialog/token' | translate }}</h1>
<div mat-dialog-content>

  <form #form="ngForm">
    <mat-form-field class="col-md-10">
      <mat-label>{{'SITE_INVESTIGATION/dialog/site'|translate}}</mat-label>
      <mat-select required [(ngModel)]="data.site" name="site">
        <mat-option *ngFor="let s of sites;" [value]="s.id">{{s.name}} [id={{s.id}}]</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-10">
      <mat-label>{{'SITE_INVESTIGATION/dialog/shippingcompany'|translate}}</mat-label>
      <mat-select required [(ngModel)]="data.shippingcompany" name="shippingcompany">
        <mat-option *ngFor="let sc of shippingcompanys;" [value]="sc.id">{{sc.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-10">
      <input matInput name="visitadatahora" [owlDateTimeTrigger]="dt" name="visitaTime" [owlDateTime]="dt" [placeholder]="'SITE_INVESTIGATION/table/visitadatahora' | translate" [(ngModel)]="data.visitadatahora" required>
      <owl-date-time #dt></owl-date-time>
    </mat-form-field>
    <div class="col-md-10">
      <mat-label>{{'SITE_INVESTIGATION/dialog/attachment'|translate}}</mat-label>
      <app-form-upload name="anexo" (setFile)="data.anexo = $event"></app-form-upload>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickBack()" cdkFocusInitial>{{ 'SITE_INVESTIGATION/dialog/btn/back' | translate }}</button>
  <span></span>
  <button mat-raised-button [disabled]="!form.valid" class="primary-button" (click)="onClickToken()">{{ 'SITE_INVESTIGATION/dialog/btn/token' | translate }}</button>
</div>
