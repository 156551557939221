<mat-card>
  <mat-card-title>Associação Cliente - TP</mat-card-title>
  <mat-card-content>
    <form>
      <mat-form-field>
        <mat-select placeholder="Escolher cliente" [(ngModel)]="selectedClient" name="client">
          <mat-option *ngFor="let client of clientList" [value]="client.clientGroup">
            {{client.clientGroup}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Escolher Usuário" [(ngModel)]="selectedTp" name="user">
          <mat-option *ngFor="let user of tpList" [value]="user.id">
            {{user.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button style="float: right; width: 10%;" class="login" (click)="addNewRule()">Adicionar</button>
    </form>

    <table mat-table #table [dataSource]="dataSourceNF" matSort>

      <ng-container matColumnDef="clientId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente <th>
          <td mat-cell *matCellDef="let element"> {{element.clientId }} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuário <th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <th>
          <td mat-cell *matCellDef="let element"> <i font class="fa fa-trash" style="font-size: 14px;" (click)="remove(element)" aria-hidden="true"></i> </td>
      </ng-container>


      <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns3;" ></tr>
    </table>
  </mat-card-content>
</mat-card>
