<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'DELIVERY_ROUTE/title' | translate }} : {{ mdr.pickupID ? mdr.pickupID : (mdr.id | mdrId)}}
        </div>
      </div>
      <hr>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef class="top"> {{ 'DELIVERY_ROUTE/'+column | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="column === 'deliveryAddress' || column === 'site' || column === 'client'"> {{ element[column] }} </div>
            <div *ngIf="['dateScheduling','arrivalTime','datePickupScheduling'].indexOf(column) !== -1"> {{ element[column] | date:'d/M/yy, h:mm a'}} </div>
            <div *ngIf="column === 'actualDelivery'"> {{ element.deliveryDateShadow | date: "dd/MM/yy, h:mm a" }} </div>
            <div *ngIf="column === 'status'"> {{ element.status | translate }} </div>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="container-fluid"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expanded-columns column">

                <!-- content -->
                <div class="row justify-content-around">
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/dr' | translate }}: </span> {{ element.idDRidentifier }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}: </span> {{ element.receiverName }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/receiver_phone' | translate }}: </span> {{ element.receiverPhone }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}: </span> {{ element.supervisorName }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/supervisor_phone' | translate }}: </span> {{ element.supervisorPhone }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/infra' | translate }}: </span> {{ element.site_form6.join(', ') }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/ov' | translate }}: </span> {{ element.salesOrders }} </div>
                </div>
                <div class="row justify-content-around">
                  <div class="col"> <span class="bold"> Obs: </span> {{ element.obs }} </div>
                </div>

                <!-- buttons -->
                <div class="row justify-content-end buttons-row">
                  <button *ngIf='role === 5 && element.userArrivalTime === null' (click)="setUserArrivalTime(element)" class="col-6 blue">Chegou</button>
                  <button *ngIf='role !== 5' (click)="openEditDialog(element)" class="col-6 blue"> {{ 'DELIVERY_ROUTE/edit' | translate }} </button>
                  <button (click)="openViewDialog(element)" class="col blue"> {{ 'DELIVERY_ROUTE/view' | translate }} </button>
                  <button (click)="openHistoryDialog(element)" class="col blue"> {{ 'DELIVERY_ROUTE/history' | translate }} </button>
                </div>

              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row"
          [class.expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>
</div>
