import { OnInit } from '@angular/core';
import { MatTableDataSource, MatSort, MatSnackBar, MatDialogRef, MatDialog, MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { HubService } from '../../../providers/hub.service';
import { HUBDR } from '../../../models/hub__dr';
import { OVService } from '../../../providers/ov.service';
import { MDR } from '../../../models/mdr';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { ESTRUTURA } from '../../../providers/site.service';
import { ClientUserService } from '../../../providers/client-user.service';
import * as FileSaver from 'file-saver';
import { DialogDrValidationComponent } from '../../dr-booking/dr-validation/dr-validation.component';
var DeliveryRouteComponent = /** @class */ (function () {
    function DeliveryRouteComponent(aRoute, drService, hubService, clientService, snackBar, drObservable, dialog, ovService) {
        var _this = this;
        this.aRoute = aRoute;
        this.drService = drService;
        this.hubService = hubService;
        this.clientService = clientService;
        this.snackBar = snackBar;
        this.drObservable = drObservable;
        this.dialog = dialog;
        this.ovService = ovService;
        this.data = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.columnsToDisplay = ['deliveryAddress', 'site', 'status', 'datePickupScheduling', 'dateScheduling', 'client', 'arrivalTime', 'actualDelivery'];
        this.siteNomes = {
            'form_6_1': 'Grua',
            'form_6_2': 'Correia',
            'form_6_3': 'Veículos Esp.',
            'form_6_4': 'Operação Manual',
            'form_6_5': 'Guindates'
        };
        this.clientList = [];
        this.hubList = [];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.clientService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
        this.hubService.getAll().subscribe(function (hubs) {
            _this.hubList = hubs;
        });
    }
    DeliveryRouteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drObservable.currentDR.subscribe(function (dr) {
            _this.data = dr;
            // console.log(this.data);
            for (var i = 0; i < _this.data.length; i++) { // TODO optimize this n3 disgusting function
                var ovAux = [];
                var str = '';
                if (_this.data[i].ovList !== undefined) {
                    for (var j = 0; j < _this.data[i].ovList.length; j++) {
                        var flag = 0;
                        for (var k = 0; k < ovAux.length; k++) {
                            if (ovAux[k].salesDocument === _this.data[i].ovList[j].salesDocument) {
                                flag = 1;
                                break;
                            }
                        }
                        if (flag === 0) {
                            ovAux.push(_this.data[i].ovList[j]);
                            if (j !== 0) {
                                str += ', ';
                            }
                            str += _this.data[i].ovList[j].salesDocument;
                        }
                    }
                    _this.data[i].ovList = ovAux;
                    _this.data[i].obs = str;
                    console.log(_this.data[i]['deliveryDateShadow']);
                    // this.data[i]['deliveryDateShadow'] = if( new Date(this.data[i]['deliveryDateShadow']);
                }
                // detalhes do site.form_6_?
                _this.data[i]['site_form6'] = [];
                if (_this.data[i]['site_form']) {
                    var keys = Object.keys(_this.data[i]['site_form']).filter(function (e) { return e.indexOf('form_6_') === 0; }).filter(function (e) { return e.indexOf('_obs') === -1; });
                    for (var j = 0, len = keys.length; j < len; j++) {
                        // console.log(this.data[i]['site_form'][keys[j]]);
                        if (_this.data[i]['site_form'][keys[j]] === ESTRUTURA.EX) {
                            _this.data[i]['site_form6'].push(_this.siteNomes[keys[j]]);
                        }
                    }
                }
            }
            _this.dataSource = new MatTableDataSource(_this.data);
        });
    };
    DeliveryRouteComponent.prototype.setUserArrivalTime = function (dr) {
        var _this = this;
        dr.userArrivalTime = new Date();
        this.drService.update(dr).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
            }
            else {
                dr.userArrivalTime = null;
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    DeliveryRouteComponent.prototype.openEditDialog = function (dr) {
        var dialogRef = this.dialog.open(EditDrDialogComponent, {
            data: {
                role: this.role,
                dr: dr,
                clientList: this.clientList,
                hubList: this.hubList
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
        });
    };
    DeliveryRouteComponent.prototype.openViewDialog = function (dr) {
        var dialogRef = this.dialog.open(ViewInfoComponent, {
            width: '800px',
            data: {
                dr: dr,
                role: this.role
            },
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
        });
    };
    DeliveryRouteComponent.prototype.openHistoryDialog = function (dr) {
        var _this = this;
        this.drService.getHistory(dr.id).subscribe(function (history) {
            // console.log(history);
            var hist = history.sort(function (a, b) { return a.quando - b.quando; });
            var dialogRef = _this.dialog.open(HistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: hist }
            });
        });
    };
    return DeliveryRouteComponent;
}());
export { DeliveryRouteComponent };
var EditDrDialogComponent = /** @class */ (function () {
    function EditDrDialogComponent(dialogRef, hubService, data, translate, drService, snackBar) {
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.clientList = [];
        this.statusList = ['InTransit',
            'READY_TO_PICKUP',
            'Replan - Retido',
            'Replan - Sefaz',
            'Replan - Pernoite',
            'Replan - Antecipação',
            'Replan - Postergação',
            'Waiting ASP',
            'HubReturn',
            'Canceled',
            'Delivered'];
        this.hubList = data.hubList;
        this.clientList = data.clientList;
    }
    EditDrDialogComponent.prototype.ngOnInit = function () {
        // faz uma copia da DR para edição
        this.current = Object.assign({}, this.data.dr);
    };
    EditDrDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // compara DR copiada para edição com DR anterior
        var checkChanges = function (key) {
            return (_this.current[key] !== _this.data.dr[key]);
        };
        // se nao houve alguma modificação
        var keys = Object.keys(this.current);
        if (!keys.some(checkChanges)) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        if (this.current.status === 'HubReturn') {
            var newHUBDR = new HUBDR();
            newHUBDR.hubID = this.hubId;
            newHUBDR.drID = this.data.dr.id;
            newHUBDR.when = new Date();
            newHUBDR.observation = this.hubObs;
            this.hubService.createHubDr(newHUBDR).subscribe(function (elem) {
                // console.log(elem);
            });
        }
        this.drService.update(this.current).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
                // copia valores pra DR anterior
                keys.forEach(function (key) {
                    _this.data.dr[key] = _this.current[key];
                });
                _this.dialogRef.close();
            }
            else {
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    EditDrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditDrDialogComponent;
}());
export { EditDrDialogComponent };
var ViewInfoComponent = /** @class */ (function () {
    function ViewInfoComponent(dialogRef, hubService, excelService, dialog, data, translate, drService, snackBar) {
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.excelService = excelService;
        this.dialog = dialog;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.listOVs = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns2 = ['salesOrderQuantity', 'materialCode', 'materialDescription', 'customerName', 'assignmentInformation', 'customerCity', 'customerAddress', 'salesOrganization', 'action'];
        this.HUs = [];
        // console.log("Received Data");
        // console.log(data);
        this.role = data.role;
    }
    ViewInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        // console.log(this.data.id);
        this.drService.getAllInfo(this.data.dr.id).subscribe(function (k) {
            var list = [];
            k.forEach(function (ov) {
                var el = {};
                el['id'] = ov.id;
                el['material'] = ov.materialCode;
                el['materialDescription'] = ov.materialDescription;
                el['client'] = ov.customerName;
                el['prolog'] = ov.assignmentInformation;
                el['serial'] = ov.customerAddress;
                el['uoc'] = ov.customerCity;
                el['qtd'] = ov.salesOrderQuantity;
                el['obs'] = ov.salesOrganization;
                el['drId'] = ov.drId;
                list.push(el);
                _this.listOVs.push(ov);
            });
            // this.listOVs.find;
            _this.dataSource = new MatTableDataSource(list);
            setTimeout(function () { return _this.dataSource.paginator = _this.paginator; });
        });
    };
    ViewInfoComponent.prototype.addNewRow = function () {
        var row = { material: null, materialDescription: null, client: null, prolog: null, serial: null, uoc: null, qtd: null, salesOrganization: null };
        this.openDialog(row);
    };
    ViewInfoComponent.prototype.openDialog = function (row) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogDrValidationComponent, {
            data: {
                row: row,
                listOv: this.listOVs
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            } // unfocus clicando fora do modal
            if (result.what === 'approve') {
                // this.drService.getAllInfo(this.dr.id).subscribe((k) => {
                //   console.log(k);
                //   const list = [];
                //   k.forEach(ov => {
                //     const el = {};
                //     el['id'] = ov.id;
                //     el['material'] = ov.materialCode;
                //     el['materialDescription'] = ov.materialDescription;
                //     el['client'] = ov.customerName;
                //     el['prolog'] = ov.assignmentInformation;
                //     el['serial'] = ov.customerAddress;
                //     el['uoc'] = ov.customerCity;
                //     el['qtd'] = ov.salesOrderQuantity;
                //     list.push(el);
                //     this.listOVs.push(ov);
                //   });
                //   this.dataSource = new MatTableDataSource<any>(list);
                //   setTimeout(() => this.dataSource.paginator = this.paginator);
                // });
                // this.snackBar.open('Updated Correctly', 'Ok', {duration: 4000});
                _this.ngOnInit();
            }
        });
    };
    ViewInfoComponent.prototype.downloadPDF = function () {
        var _this = this;
        this.drService.downloadPDF(this.data.dr.id).subscribe(function (data) {
            FileSaver.saveAs(data, "Report-Logistic_Order-" + _this.data.dr.id + ".pdf");
        });
    };
    ViewInfoComponent.prototype.downloadReport = function () {
        var _this = this;
        // const arr = [];
        // this.dataSource.data.forEach( (k) => {
        //   const elem = {};
        //   elem['materialCode'] = k.materialCode;
        //   elem['materialDescription'] = k.materialDescription;
        //   elem['salesOrderQuantity'] = k.salesOrderQuantity;
        //   elem['']
        // });
        // exporta
        this.drService.downloadPDFData(this.data.dr.id).subscribe(function (data) {
            // console.log(data);
            _this.excelService.exportAsExcelFile(data, 'CFID-' + _this.data.dr.site + '');
        });
    };
    ViewInfoComponent.prototype.onYesClick = function () {
        var _this = this;
        if (this.previous.select === this.select
            && this.previous.dateCollect === this.dateCollect
            && this.previous.hour === this.hour
            && this.previous.deliveryAddress === this.data.dr.deliveryAddress) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        if (this.select === 'HubReturn') {
            var newHUBDR = new HUBDR();
            newHUBDR.hubID = this.hubId;
            newHUBDR.drID = this.data.dr.id;
            newHUBDR.when = new Date();
            newHUBDR.observation = this.hubObs;
            this.hubService.createHubDr(newHUBDR).subscribe(function (elem) {
                // console.log(elem);
            });
        }
        // if (this.previous === 'HubReturn') {
        //
        // }
        this.data.dr.status = this.select;
        if (this.hour !== undefined && this.dateCollect !== undefined) {
            // console.log('cheguei!');
            var time = this.hour.split(':');
            this.data.dr.dateScheduling = new Date(this.dateCollect.setHours(parseInt(time[0], 0), parseInt(time[1], 0)));
        }
        // console.log(this.data);
        this.drService.update(this.data.dr).subscribe(function (el) {
            if (el === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
                _this.dialogRef.close();
            }
            else {
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    ViewInfoComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return ViewInfoComponent;
}());
export { ViewInfoComponent };
var HistoryDialogComponent = /** @class */ (function () {
    function HistoryDialogComponent(data) {
        this.data = data;
        this.history = [];
        this.columns = [
            { value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address' },
            { value: 'status', name: 'DELIVERY_ROUTE/status' },
            { value: 'quem', name: 'DELIVERY_ROUTE/by' },
            { value: 'quando', name: 'DELIVERY_ROUTE/on' },
            { value: 'dateScheduling', name: 'DELIVERY_ROUTE/date' }
        ];
        this.history = data.history;
    }
    HistoryDialogComponent.prototype.ngOnInit = function () {
    };
    return HistoryDialogComponent;
}());
export { HistoryDialogComponent };
