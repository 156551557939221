<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'DELIVERY_ORDER/title' | translate }}</p>
</div>
<div class="colored-background table-background px-5 py-2">
  <app-table *ngIf="mdr.length > 0"
    [columns]="columns"
    [data]="mdr"
    [this]="this"
    [clickCallback]="goTo"
  ></app-table>
</div>

<div *ngIf="mdr.length === 0" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'DELIVERY_ORDER/empty' | translate }}</p>
</div>
