import { ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
var OccurrenceListComponent = /** @class */ (function () {
    function OccurrenceListComponent(dialog, drService, mdrService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.drService = drService;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.displayedColumns = [
            { value: 'idMDR', name: 'ID MDR' },
            { value: 'uoc', name: 'Tipo de ocurrencias' },
            { value: 'mdrCode', name: 'MDR' },
            { value: 'text', name: 'Obs' },
            { value: 'client', name: 'OCURRENCE/client' },
            { value: 'createdBy', name: 'OCURRENCE/createdby' },
            { value: 'shipping', name: 'LSP' },
            { value: 'dateScheduling', name: 'Criado Em' },
        ];
        this.selected = [];
        // displayedColumns = ['idMDR', 'uoc', 'text', 'dr.client', 'mdr.shippingCompany', 'createdAt'];
        this.dataSource = new MatTableDataSource(this.data);
    }
    OccurrenceListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getOccurencesList().subscribe(function (data2) {
            console.log(data2);
            data2.forEach(function (el) {
                el['client'] = el['dr'] ? el['dr'].client : 'Sem Cliente';
                el['shipping'] = el['mdr'] ? el['mdr'].shippingCompany : 'Sem LSP';
                el['mdrCode'] = el['mdr'] ? el['mdr'].pickupID : 'Sem Nome';
                el['createdBy'] = el['mdr'] ? el['mdr'].createBy : 'Sin Nombre';
                el['dateScheduling'] = new Date(el['createdAt']);
            });
            _this.data = data2;
        });
    };
    OccurrenceListComponent.prototype.navigateToMDR = function (row, that) {
        // console.log(row);
        // console.log(that);
        window.open(window.location.origin + '/logistic-order/' + row.idMDR);
        // that.router.navigate(['/' + row.idMDR]);
    };
    // unmakeDR() {
    //   this.selection.selected.forEach((el) => {
    //     this.drService.unmakeDR(el).subscribe(
    //       (data) => {
    //         //console.log('Removed');
    //       });
    //   });
    //
    //   this.drService.getAllOpen().subscribe(
    //     (data2) => {
    //       this.data = data2;
    //       this.dataSource = new MatTableDataSource<DR>(this.data);
    //       this.snackBar.open('Ordens de entrega desfeitas com sucesso', 'Ok', {
    //         duration: 4000,
    //       });
    //     }
    //   );
    // }
    // createNewMDR() {
    //   const mdr = new MDR();
    //   mdr.status = 'MDR em criação';
    //   this.selection.selected.forEach((el: DR) => {
    //     mdr.drList.push(el);
    //   });
    //   this.mdrService.insert(mdr).subscribe( (data) => {
    //     this.drService.getAllOpen().subscribe(
    //       (data2) => {
    //         this.data = data2;
    //         this.dataSource = new MatTableDataSource<DR>(this.data);
    //         this.snackBar.open('Ordens de entrega liberada com sucesso', 'Ok', {
    //           duration: 4000,
    //         });
    //       }
    //     );
    //   });
    // }
    OccurrenceListComponent.prototype.applyFilter = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    return OccurrenceListComponent;
}());
export { OccurrenceListComponent };
