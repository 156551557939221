import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageLightboxComponent } from './image-lightbox.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ImageLightboxComponent,
  ],
  exports: [
    ImageLightboxComponent,
  ]
})
export class ImageLightboxModule { }
