<h1 mat-dialog-title>{{ 'DR_BOOKING/dialog/status' | translate }}</h1>
  <form #form="ngForm" class="d-flex flex-wrap">
      <mat-form-field class="col-md-6">
        <input matInput required  type="text" placeholder="Material" [(ngModel)]="el['material']" name="material">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required type="text" placeholder="Material Description" [(ngModel)]="el['materialDescription']" name="materialDescription">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required  type="text" placeholder="Client" [(ngModel)]="el['client']" name="client">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput type="text" placeholder="Prolog" [(ngModel)]="el['prolog']" name="prolog">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput type="text" placeholder="Serial" [(ngModel)]="el['serial']" name="serial">
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput type="text"  placeholder="UOC" [(ngModel)]="el['uoc']" name="uoc">
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <input matInput type="number" required  placeholder="Quantity" [(ngModel)]="el['qtd']" name="qtd">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput type="textarea"  placeholder="Obs" [(ngModel)]="el['obs']" name="obs">
      </mat-form-field>
      <!-- fim preenchimento campos -->

  </form>
<div mat-dialog-actions>
  <button mat-button (click)="onClickBack()">Cancel</button>
  <span></span>
  <button mat-button class="primary-button" (click)="onClickOk()" cdkFocusInitial>Save</button>
</div>
