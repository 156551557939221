import { Component, OnInit } from '@angular/core';
import {Transportadora, Veiculo} from '../logistic-order/transport/transport.component';
import {AuthService} from '../../providers/auth.service';
import {PriceMakerService} from '../../providers/price-maker.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import { SiteService } from '../../providers/site.service';

@Component({
  selector: 'app-price-maker',
  templateUrl: './price-maker.component.html',
  styleUrls: ['./price-maker.component.scss']
})
export class PriceMakerComponent implements OnInit {
  public price = {};
  public total = '-1';
  public deliveryRegion = '';
  transportadora: any[] = [];
  veiculo: Veiculo[] = [
    {id: '1', name: 'FIORINO' },
    {id: '2', name: 'Sprinter'},
    {id: '3', name: 'VUC'},
    {id: '4', name: 'F608'},
    {id: '5', name: 'TOCO'},
    {id: '6', name: 'TRUCK'},
    {id: '7', name: 'CARRETA'}];
  state = [
    {id: 'AC', name: 'AC'},
    {id: 'AL', name: 'AL'},
    {id: 'AP', name: 'AP'},
    {id: 'AM', name: 'AM'},
    {id: 'BA', name: 'BA'},
    {id: 'CE', name: 'CE'},
    {id: 'DF', name: 'DF'},
    {id: 'ES', name: 'ES'},
    {id: 'GO', name: 'GO'},
    {id: 'MA', name: 'MA'},
    {id: 'MT', name: 'MT'},
    {id: 'MS', name: 'MS'},
    {id: 'MG', name: 'MG'},
    {id: 'PA', name: 'PA'},
    {id: 'PB', name: 'PB'},
    {id: 'PR', name: 'PR'},
    {id: 'PE', name: 'PE'},
    {id: 'PI', name: 'PI'},
    {id: 'RJ', name: 'RJ'},
    {id: 'RN', name: 'RN'},
    {id: 'RS', name: 'RS'},
    {id: 'RO', name: 'RO'},
    {id: 'RR', name: 'RR'},
    {id: 'SC', name: 'SC'},
    {id: 'SP', name: 'SP'},
    {id: 'SE', name: 'SE'},
    {id: 'TO', name: 'TO'}];
  subRegion = [
    {id: 'Capital', name: 'Capital' },
    {id: 'Interior', name: 'Interior'},
  ];

  columns = [
    {value: 'shipC', name: 'LSP'},
    {value: 'price', name: 'Valor do Frete', currency: 'BRL'},
    {value: 'taxedPrice', name: 'Frete com taxas', currency: 'BRL'},
    {value: 'adValorem', name: 'adValorem', currency: 'BRL'},
    {value: 'addDelivery', name: 'Adicional de Entrega', currency: 'BRL'},
    {value: 'value', name: 'Valor Total', currency: 'BRL'}
  ];
  data: any[];

  constructor(public priceMaker: PriceMakerService, public spinnerService: Ng4LoadingSpinnerService, public siteService: SiteService) {
    this.price['drAmount'] = 0;
    this.siteService.getShippingCompanys().subscribe((data) => {
      this.transportadora = data;
    });
  }

  ngOnInit() {
  }
  getPrice() {
    const vehicle = this.price['vehicle'];
    const distance = this.price['totalDistance'];
    const fromEstate = this.price['deliveryRegion'];
    const toEstate = this.price['pickupRegion'];
    const subRegion = this.price['subRegion'] ? this.price['subRegion'] : null;
    const value = this.price['freight'];
//    const shippingCompany = this.price['shippingCompany'];
    const drAmount = this.price['drAmount'];
    this.spinnerService.show();
    this.priceMaker.getPriceTable(vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount)
        .subscribe(result => {
          this.data = result;
          this.spinnerService.hide();
          // this.total=result.total;
        });
  }
  getTotal() {
    // console.log(this.price['deliveryRegion'])
    if (typeof this.price['deliveryRegion'] !== 'undefined') {
      this.deliveryRegion = this.price['deliveryRegion'];
    }
    // console.log( this.deliveryRegion)

    this.total = '-1';
  }

}
