import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {environment} from '../../environments/environment';

const base_url: string = environment.apiUrl;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  endpoints = [ 'MDR/', 'client/', 'occurrence/', 'driver/', 'OV/', 'chat/'];

  constructor(public spinnerService: Ng4LoadingSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    if ( this.endpoints.every( (el, i, arr) => {
      return url.indexOf(base_url + el) === -1;
      })
    ) {
      // não faz nada
      // console.log(url,' passou reto');
      return next.handle(request);
    }

    this.spinnerService.show();
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // this.spinnerService.hide();
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log('erro na request');
            if (err.status === 401) {
              // redirect to the login route
              // or show a modal
            }
          }
        }
      )
    );
  }
}
