import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs/Observable';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ERBService {
  public token: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  public getNearERB(lat: number, lng: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get(base_url + 'erb/getNearERB/' + lat + '/' + lng, {headers: header});
  }
}
