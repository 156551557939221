<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <!-- this creates a google map on the page with the given lat/lng from -->
      <!-- the component as the initial center of the map: -->
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'OCURRENCE/title' | translate }}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <form #form="ngForm" *ngIf="mdr.status !== 'MDR em validação'">
            <mat-form-field class="col-lg-4 col-md-6">
              <mat-select placeholder="{{ 'OCURRENCE/type' | translate }}" [(ngModel)]="occurrence.uoc"
                (selectionChange)="getUOCValue()" name="uoc" required>
                <mat-option *ngFor="let elem of uoc" [value]="elem.name">
                  {{ elem.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-lg-4 col-md-6">
              <input matInput placeholder="{{ 'OCCURRENCE-LIST/table/description' | translate }}" [(ngModel)]="occurrence.text" name="text" required>
            </mat-form-field>
            <button mat-raised-button
              class="login"
              [disabled]="!form.valid"
              class="green-button text-white col-lg-4 col-md-12"
              (click)="createOccurrence()">
              {{ 'OCURRENCE/create' | translate }}
            </button>

            <!-- tabela de occurrences-->

            <table mat-table [dataSource]="dataOccurrence" multiTemplateDataRows class="mat-elevation-z8">
              <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                <th mat-header-cell *matHeaderCellDef class="top"> {{ 'OCURRENCE/'+column | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="column !== 'status'" class="top-content">
                    {{ element[column] }}
                  </div>
                  <div *ngIf="column === 'status'" class="top-content">
                    <div *ngIf="element.status === 'SENT_TO_LSP' && role === 5" style="color: red"> {{ 'SENT_TO_LSP' | translate }} </div>
                    <div *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role === 5" style="color: green"> {{ 'OCCURRENCE_TO_RESOLVE' | translate }} </div>
                    <div *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE' && role !== 5" style="color: red"> {{ 'OCCURRENCE_TO_RESOLVE' | translate }} </div>
                    <div *ngIf="element.status === 'OCCURRENCE_RESOLVED'" style="color: green"> {{ 'OCCURRENCE_RESOLVED' | translate }} </div>
                    <div *ngIf="element.status === 'OCCURRENCE_DENIED' && role === 5" style="color: green"> {{ 'OCCURRENCE_DENIED' | translate }} </div>
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                  <div class="container-fluid"
                    [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <div class="expanded-columns column">
                      <div class="row justify-content-around">
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/createdby' | translate }}: </span> {{element.createdBy}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/date' | translate }}: </span> {{element.createdAt | date:'d/M/yy, h:mm a'}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/updatedby' | translate }}: </span> {{element.updatedBy}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/update' | translate }}: </span> {{element.updatedAt | date:'d/M/yy, h:mm a'}} </div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/obs' | translate }}: </span> {{element.resolution}}</div>
                        <div class="col"> <span class="bold"> {{ 'OCURRENCE/image' | translate }}: </span></div>
                      </div>
                      <div class="row justify-content-around" >
                        <div class="col" *ngIf="element.file !== undefined">
                          <app-image-lightbox class="col" [images]="element.file"></app-image-lightbox>
                        </div>
                      </div>
                      <div class="row justify-content-end buttons-row">
                        <button class="blue col-8">
                          <app-upload-files [id]="element.id" [entity]="'occurrence'"
                            (messageEvent)="receiveFile($event)">
                          </app-upload-files>
                        </button>
                        <button *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE'" class="blue col"
                          (click)="openEditOccurece(element)"> Edit
                        </button>
                        <button *ngIf="role === 5 && element.status === 'SENT_TO_LSP'" class="blue col"
                          (click)="openEditOccurece(element)"> Edit
                        </button>
                        <button *ngIf="element.status === 'OCCURRENCE_TO_RESOLVE'" class="green col"
                          (click)="openDialog(element)"> Resolver
                        </button>
                        <button *ngIf="role === 1" class="red col" (click)="removeOccurrence(element)"
                          [ngClass]="{'fas-grey':mdr.lock === false}"> Remover
                        </button>
                        <button *ngIf="element.status === 'SENT_TO_LSP' && role === 5" class="green col"
                          (click)="approveOccurrence(element)">
                          {{ 'OCURRENCE/aprove' | translate }}
                        </button>
                        <button *ngIf="element.status === 'SENT_TO_LSP' && role === 5" class="red col"
                          (click)="rejectOccurrence(element)">
                          {{ 'OCURRENCE/reprove' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
