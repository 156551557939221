import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';
import {HUB} from '../models/hub';
import {NF} from '../models/nfe';
import {HUBDR} from '../models/hub__dr';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class HubService {
  public token: string;

  constructor(private http: HttpClient) {
      // set token if saved in local storage
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  getAll(): Observable<HUB[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // let params = new HttpParams().set("token",token);
      return this.http.get(base_url + 'hub/all', {headers: header})
          .map((response: HUB[]) => {
            return response;
          });
    }

  getFromHub(id: number): Observable<any[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // let params = new HttpParams().set("token",token);
    return this.http.get(base_url + 'hub/' + id, {headers: header})
      .map((response: any[]) => {
        return response;
      });
  }

   getHub(id: number): Observable<HUB> {
      const token = JSON.parse(localStorage.getItem('currentUser')).token;
      const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
      // let params = new HttpParams().set("token",token);
      return this.http.get(base_url + 'hub/one/' + id, {headers: header})
        .map((response: HUB) => {
          return response;
        });
    }

  create(hub: HUB): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'hub/new', {hub: hub}, {headers: header})
      .map((response) => {
        return response;
      });
  }

  createHubDr(hubdr: HUBDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'hub/' + hubdr.hubID + '/' + hubdr.drID, {hub: hubdr}, {headers: header})
      .map((response) => {
       // console.log(response);
        return response;
      });
  }

  removeHubDr(hubdr: HUBDR): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'hub/' + hubdr.hubID + '/' + hubdr.drID, {headers: header})
      .map((response) => {
       // console.log(response);
        return response;
      });
  }

  removeHub(hubid: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.delete(base_url + 'hub/' + hubid, {headers: header})
               .map((response) => {
                 return response;
               });
  }

}
