/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insert-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/common";
import * as i11 from "../../misc/table/table.component.ngfactory";
import * as i12 from "../../misc/table/table.component";
import * as i13 from "../../services/xlsx.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@angular/router";
import * as i16 from "ngx-cookie-service";
import * as i17 from "./insert-data.component";
import * as i18 from "ngx-papaparse";
import * as i19 from "@angular/material/snack-bar";
import * as i20 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i21 from "../../misc/export-xlsx/export-xlsx";
import * as i22 from "../../providers/ov.service";
import * as i23 from "../../providers/mdr.service";
import * as i24 from "../../providers/configuration.service";
var styles_InsertDataComponent = [i0.styles];
var RenderType_InsertDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InsertDataComponent, data: {} });
export { RenderType_InsertDataComponent as RenderType_InsertDataComponent };
function View_InsertDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mx-5 py-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "mat-card", [["class", "rounded-corners mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-content", [["class", "d-flex flex-wrap mat-card-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [["fileInput", 1]], null, 0, "input", [["class", "hidden"], ["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.openFile(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "w-100 green-button"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 8).disabled || null); var currVal_1 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(("INSERT-DATA/not-uploaded/button/" + _v.context.$implicit))); _ck(_v, 9, 0, currVal_2); }); }
function View_InsertDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "colored-background table-background"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsertDataComponent_2)), i1.ɵdid(7, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.validUploads; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("INSERT/data")); _ck(_v, 3, 0, currVal_0); }); }
function View_InsertDataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, i11.View_TableComponent_0, i11.RenderType_TableComponent)), i1.ɵdid(1, 638976, null, 0, i12.TableComponent, [i13.XlsxService, i14.MatDialog, i15.Router, i16.CookieService], { columns: [0, "columns"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns[_co.fileUploaded]; var currVal_1 = _co.dataUploaded; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InsertDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mx-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "mat-h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsertDataComponent_4)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "ml-auto col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "green-button"], ["mat-raised-button", ""], ["style", "position: relative; margin-bottom: 100px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.insert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.dataUploaded.length > 0); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("INSERT-DATA/uploaded/title")); _ck(_v, 3, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("INSERT-DATA/uploaded/button")); _ck(_v, 10, 0, currVal_4); }); }
export function View_InsertDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsertDataComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InsertDataComponent_3)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.fileUploaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.fileUploaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_InsertDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delivery-request", [], null, null, null, View_InsertDataComponent_0, RenderType_InsertDataComponent)), i1.ɵdid(1, 114688, null, 0, i17.InsertDataComponent, [i18.Papa, i19.MatSnackBar, i20.MapsAPILoader, i21.ExcelService, i22.OVService, i23.MdrService, i24.ConfigurationService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsertDataComponentNgFactory = i1.ɵccf("app-delivery-request", i17.InsertDataComponent, View_InsertDataComponent_Host_0, {}, {}, []);
export { InsertDataComponentNgFactory as InsertDataComponentNgFactory };
