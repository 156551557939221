import { OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { OVService } from '../../providers/ov.service';
import { ERBService } from '../../providers/erb.service';
import { OV } from '../../models/ov';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DR } from '../../models/dr';
import { parseString } from 'xml2js';
import { NfeService } from '../../providers/nfe.service';
import { HubService } from '../../providers/hub.service';
import { SiteService, ESTRUTURA, ACESSO } from '../../providers/site.service';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { ClientUserService } from '../../providers/client-user.service';
var NewDeliveryRequestComponent = /** @class */ (function () {
    function NewDeliveryRequestComponent(dialog, mapsAPILoader, ovService, router, drService, nfService, excelService, siteService, clientUserService, snackBar, hubService, erbService) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.ovService = ovService;
        this.router = router;
        this.drService = drService;
        this.nfService = nfService;
        this.excelService = excelService;
        this.siteService = siteService;
        this.clientUserService = clientUserService;
        this.snackBar = snackBar;
        this.hubService = hubService;
        this.erbService = erbService;
        this.dr = new DR();
        this.uploaded = false;
        this.displayedColumns = ['select', 'salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
        this.dataSource = new MatTableDataSource();
        this.ovOrNf = false;
        this.allValid = false;
        this.minDate = new Date();
        this.displayedColumns2 = ['salesDocument', 'referenceNoNf', 'customerName', 'materialDescription'];
        this.dataSourceOV = new MatTableDataSource();
        this.displayedColumns3 = ['dateScheduling', 'deliveryAddress', 'infraType', 'obs', 'status', 'pickupAddress', 'site'];
        this.dataSourceDR = new MatTableDataSource();
        this.initialSelection = [];
        this.selection = new SelectionModel(true, this.initialSelection);
        this.newDrList = [];
        this.showSiteInfo = false;
        this.site = {
            'color': '',
            'tooltips': {
                'grey': 'site não existe',
                'yellow-1': 'site não aprovado',
                'yellow-2': 'site não liberado',
                'yellow-3': 'site não aprovado e não liberado',
                'green': 'site aprovado',
                'undefined': ''
            },
            'icons': {
                'grey': 'fa-question-circle',
                'yellow-1': 'fa-exclamation-triangle',
                'yellow-2': 'fa-exclamation-triangle',
                'yellow-3': 'fa-exclamation-triangle',
                'green': 'fa-check',
                'undefined': ''
            },
            'classes': {
                'grey': 'icon-grey',
                'yellow-1': 'icon-yellow',
                'yellow-2': 'icon-yellow',
                'yellow-3': 'icon-yellow',
                'green': 'icon-green',
                'undefined': ''
            }
        };
        this.clientList = [];
        this.paste_input = '';
        this.map_show = false;
        this.map_data = null;
        this.red_marker = {
            url: './assets/images/pin_red.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.blue_marker = {
            url: './assets/images/pin.png',
            scaledSize: {
                width: 35,
                height: 40
            }
        };
        this.dataSource.sort = this.sort;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = currentUser.user.role;
        if (this.role === 1) {
            this.minDate = new Date('1970-01-01');
        }
        this.hubService.getAll().subscribe(function (data) {
            _this.hubList = data;
        });
        this.clientUserService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
    }
    NewDeliveryRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElement2Ref.nativeElement, {
                types: ['address']
            });
        });
    };
    NewDeliveryRequestComponent.prototype.fillFromClipboard = function (e) {
        var _this = this;
        var DMStoDD = function (str) {
            var parts = str.split(/[°'"]/).map(function (part) {
                return +part.replace(',', '.');
            });
            var decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
            return parts[0] + decimal;
        };
        var input = e.clipboardData.getData('text');
        var splitted = input.split('\t');
        // this.salesOrders = splitted[1];
        this.dr.deliveryAddress = splitted[6];
        this.dr.lat = +DMStoDD(splitted[25]);
        this.dr.lng = +DMStoDD(splitted[26]);
        this.dr.receiverName = splitted[9];
        this.dr.receiverPhone = parseInt(splitted[10], 10);
        this.dr.dateScheduling = new Date(splitted[3]);
        this.dr.site = splitted[4];
        this.dr.infraType = splitted[11];
        this.dr.unificationId = splitted[0];
        this.dr.obs = splitted[12];
        setTimeout(function () {
            _this.paste_input = '';
        }, 200);
    };
    NewDeliveryRequestComponent.prototype.toggleMap = function () {
        var _this = this;
        if (this.map_show) {
            this.map_show = false;
        }
        else {
            if (this.dr.lat && this.dr.lng) {
                this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe(function (data) {
                    _this.dr.lat = +_this.dr.lat;
                    _this.dr.lng = +_this.dr.lng;
                    _this.map_data = data;
                    _this.map_show = true;
                });
            }
        }
    };
    NewDeliveryRequestComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('deliveryAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.dr.lat = lat;
                _this.dr.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertDRAddressToLatLng = function (dr) {
        var _this = this;
        var error = false;
        var geocoder = new google.maps.Geocoder();
        var addr = dr.deliveryAddress;
        // console.log(addr);
        return geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                dr.deliveryAddress = results[0].formatted_address;
                dr.lat = results[0].geometry.location.lat();
                dr.lng = results[0].geometry.location.lng();
                return true;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                return false;
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertAddressToLatLngXml = function (addr) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                // console.log('true');
                return true;
            }
            else {
                // console.log('false');
                return false;
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertLatLngToAddressFromUpload = function (latInp, lngInp) {
        var _this = this;
        if (latInp === null || lngInp === null) {
            return { resp: false, data: null };
        }
        var lat = latInp;
        var lng = lngInp;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                return { resp: true, data: results[0].formatted_address };
            }
            else {
                _this.snackBar.open('Não foi possível converter uma ou mais coordenadas em um endereço', 'Ok', { duration: 2000 });
                return { resp: false, data: null };
            }
        });
    };
    NewDeliveryRequestComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.dr.lat === null || this.dr.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.dr.deliveryAddress = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    /** Whether the number of selected elements matches the total number of rows. */
    NewDeliveryRequestComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    NewDeliveryRequestComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    NewDeliveryRequestComponent.prototype.addNewOV = function () {
        var _this = this;
        this.selection.selected.forEach(function (el) {
            if (_this.dataSourceOV.data.indexOf(el) < 0) {
                _this.dataSourceOV.data.push(el);
            }
        });
        this.dataSourceOV = new MatTableDataSource(this.dataSourceOV.data);
    };
    NewDeliveryRequestComponent.prototype.openFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        console.log(fileupload);
        this.newDrList = [];
        var ovList = [];
        var re = /(?:\.([^.]+))?$/;
        var _loop_1 = function (index) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this_1.excelService.importFromExcel(input.files[index]).subscribe(function (data) {
                    data.forEach(function (el, i, arr) {
                        var drAux = new DR();
                        try {
                            drAux.pickupAddress = el.pickup_address;
                            drAux.deliveryAddress = el.delivery_address;
                            drAux.site = el.site;
                            drAux.receiverName = el.receiver_name;
                            drAux.receiverPhone = el.receiver_phone;
                            drAux.supervisorName = el.supervisor_name;
                            drAux.supervisorPhone = el.supervisor_phone;
                            drAux.infraType = el.infra;
                            drAux.obs = el.obs;
                            drAux.dateScheduling = el.dateScheduling;
                            drAux.status = el.ov;
                            // console.log(el.ov);
                            // console.log(drAux.status);
                            if (_this.convertDRAddressToLatLng(drAux) === false) {
                                _this.snackBar.open('Endereço inválido', 'Ok', { duration: 2000 });
                                error = true;
                            }
                            // console.log(drAux.pickupAddress);
                            if (drAux.pickupAddress.toLowerCase() !== 'ericsson') {
                                drAux.pickupDifAddress = true;
                            }
                            else {
                                drAux.pickupAddress = null;
                            }
                            // console.log(drAux.pickupAddress);
                            _this.newDrList.push(drAux);
                            String(drAux.status).split(',').forEach(function (part) {
                                ovList.push(+part.trim());
                            });
                        }
                        catch (ex) {
                            // console.log('erro ao ler arquivo xlsx ',ex);
                            _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                        }
                        _this.uploaded = true;
                        _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                        // this.ovService.validateOVs(ovList).subscribe( (isValid) => {
                        // console.log(isValid);
                        // if( isValid === false || error === true ){
                        //   this.uploaded = true;
                        //   //console.log(isValid);
                        //   //console.log(error);
                        //   this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', {duration: 4000});
                        // } else {
                    });
                });
            }
            else { // arquivo xml
                var reader_1 = new FileReader();
                reader_1.onload = function () {
                    parseString(reader_1.result, function (err, result) {
                        // drAux.deliveryAddress = result.
                        result.Workbook.Worksheet[0].Table[0].Row.forEach(function (el, i, arr) {
                            if (i === 0) { // pula a primeira linha
                                return;
                            }
                            var drAux = new DR();
                            // console.log(el);
                            drAux.pickupAddress = el.Cell[0].Data[0]._; // Ver se é nullo
                            if (_this.convertAddressToLatLngXml(drAux.pickupAddress) === false) {
                                _this.snackBar.open('Endereço invalido', 'Ok', { duration: 2000 });
                                // console.log(drAux.pickupAddress);
                                error = true;
                            }
                            drAux.deliveryAddress = el.Cell[1].Data[0]._;
                            if (_this.convertAddressToLatLngXml(drAux.deliveryAddress) === false) {
                                _this.snackBar.open('Endereço invalido', 'Ok', { duration: 2000 });
                                // console.log(drAux.pickupAddress);
                                error = true;
                            }
                            drAux.site = el.Cell[2].Data[0]._;
                            drAux.receiverName = el.Cell[3].Data[0]._;
                            drAux.receiverPhone = el.Cell[4].Data[0]._;
                            drAux.supervisorName = el.Cell[5].Data[0]._;
                            drAux.supervisorPhone = el.Cell[6].Data[0]._;
                            drAux.infraType = el.Cell[7].Data[0]._;
                            drAux.obs = el.Cell[8].Data[0]._;
                            drAux.dateScheduling = el.Cell[9].Data[0]._;
                            drAux.status = el.Cell[10].Data[0]._;
                            // const aux = el.Cell[0].Data[0]._;
                            // TODO Insert OVs in ov List and check if only the id works to change
                            _this.newDrList.push(drAux);
                            // drAux.deliveryAddress = el.
                            drAux.status.split(',').forEach(function (part) {
                                ovList.push(part.trim());
                            });
                        });
                        _this.ovService.validateOVs(ovList).subscribe(function (isValid) {
                            if (isValid === false || error === false) {
                                _this.uploaded = false;
                                _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                            }
                            else {
                                _this.uploaded = true;
                                _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                            }
                        });
                    });
                };
                reader_1.readAsText(input.files[index]);
            }
        };
        var this_1 = this;
        for (var index = 0; index < input.files.length; index++) {
            _loop_1(index);
        }
        // console.log(this.newDrList);
        // console.log(this.dataSourceDR);
    };
    NewDeliveryRequestComponent.prototype.validateData = function () {
        this.dr.deliveryAddress = (document.getElementById('deliveryAddress').value);
        if (this.dr.deliveryAddress === '' && (this.dr.lat === null || this.dr.lng === null)) {
            return false;
        }
        return true;
    };
    NewDeliveryRequestComponent.prototype.validate = function () {
        var _this = this;
        this.newDrList.forEach(function (elem) {
            var ovs = String(elem.status).split(',');
            for (var i = 0; i < ovs.length; i++) {
                if (ovs[i] !== undefined) {
                    ovs[i] = ovs[i].trim();
                }
            }
            ovs.forEach(function (k) {
                var newOv = new OV();
                newOv.salesDocument = k;
                elem.ovList.push(newOv);
            });
            // valida OVs recebidas do arquivo
            _this.ovService.validateOVs(ovs).subscribe(function (isValid) {
                _this.allValid = isValid;
                if (isValid === false) {
                    // OVs invalidas segundo backend
                    elem.idDRidentifier = 'red';
                    _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                    _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                }
                else if (elem.pickupDifAddress === true) {
                    // valida HUB recebido do arquivo
                    _this.ovService.validateHub(elem.pickupAddress).subscribe(function (resp) {
                        if (resp !== undefined && resp.text !== 0) {
                            // HUB é válido
                            elem.pickupAddress = resp.text;
                        }
                        else {
                            // HUB é inválido
                            _this.allValid = false;
                            elem.idDRidentifier = 'red';
                            _this.snackBar.open('Erro ao criar requisições a partir de arquivo', 'Ok', { duration: 4000 });
                            _this.dataSourceDR = new MatTableDataSource(_this.newDrList);
                        }
                    });
                }
            });
        });
    };
    NewDeliveryRequestComponent.prototype.create = function () {
        var _this = this;
        if (this.validateData()) {
            this.dr.ovList = new Array();
            this.dr.receiverPhone = this.dr.receiverPhone ? this.dr.receiverPhone.valueOf() : null;
            this.dr.supervisorPhone = this.dr.supervisorPhone ? this.dr.supervisorPhone.valueOf() : null;
            this.dataSourceOV.data.forEach(function (el) {
                // console.log(el);
                _this.dr.ovList.push(el);
            });
            this.drService.insert(this.dr).subscribe(function (response) {
                var dialogRef = _this.dialog.open(OverviewExampleDialogComponent, {
                    data: {
                        id: response.idDRidentifier
                    }
                });
                dialogRef.afterClosed().subscribe(function (data) {
                    _this.router.navigate(['delivery-optimizer']);
                });
            }, function (error) {
                _this.snackBar.open('Erro ao criar DR: uma ou mais OVs já estão em uso', 'Ok', { duration: 4000 });
                _this.dataSource.data = [];
                _this.dataSourceOV.data = [];
            });
        }
        else {
            this.snackBar.open('Alguns campos obrigatórios estão faltando', 'Ok', { duration: 4000 });
        }
    };
    NewDeliveryRequestComponent.prototype.createRequisition = function () {
        var _this = this;
        this.newDrList.forEach(function (elem) {
            _this.drService.insert(elem).subscribe(function (response) {
                _this.snackBar.open("DR created: [" + response.idDRidentifier + "]", 'Ok', { duration: 4000 });
                _this.uploaded = false;
            });
        });
    };
    NewDeliveryRequestComponent.prototype.checkSite = function () {
        var _this = this;
        this.showSiteInfo = false;
        if (this.dr.site === '') {
            this.site.color = '';
            return;
        }
        this.siteService.getSiteFromCode(this.dr.site).subscribe(function (data) {
            if (!data) {
                _this.site.color = 'grey';
                return;
            }
            _this.siteSelected = data;
            _this.siteSelected.operation = [];
            if (_this.siteSelected.form_6_1 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Grua');
            }
            if (_this.siteSelected.form_6_2 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Correia');
            }
            if (_this.siteSelected.form_6_3 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Veiculo Esp.');
            }
            if (_this.siteSelected.form_6_4 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Manual');
            }
            if (_this.siteSelected.form_6_5 === ESTRUTURA.EX) {
                _this.siteSelected.operation.push('Guindaste');
            }
            if (_this.siteSelected.operation.length > 0) {
                _this.dr.thirdPartInvoice = true;
                // console.log(this.dr.thirdPartInvoice);
            }
            if (!data.aprovado) {
                _this.site.color = 'yellow-1';
            }
            if (data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-2';
            }
            if (!data.aprovado && data.acesso !== ACESSO.LIBERADO) {
                _this.site.color = 'yellow-3';
            }
            if (data.aprovado && data.acesso === ACESSO.LIBERADO) {
                _this.showSiteInfo = true;
                _this.site.color = 'green';
            }
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    NewDeliveryRequestComponent.prototype.setSite = function (idZVSD1) {
        var _this = this;
        // nao modifica o site se já houver um valor
        if (this.dr.site !== '') {
            return;
        }
        this.siteService.getSiteID(idZVSD1).subscribe(function (data) {
            _this.dr.site = data.code.slice(-4); // ultimos 4 caracteres
            _this.checkSite();
        }, function (error) {
            _this.site.color = 'grey';
        });
    };
    NewDeliveryRequestComponent.prototype.searchOV = function () {
        var _this = this;
        // console.log('searchOV',this.inputOVs);
        if (this.inputOVs === undefined) {
            this.snackBar.open('Preencha um valor de OV', 'Ok', { duration: 4000 });
        }
        else {
            this.ovService.getAll(this.inputOVs).subscribe(function (data) {
                // console.log(data);
                if (data === null) {
                    _this.snackBar.open('Shipment not in the system', '', { duration: 4000 });
                }
                else if (data.drId) {
                    _this.snackBar.open('Shipment not available', '', { duration: 4000 });
                }
                else if (data.mdr) {
                    if (data.mdr === 'NOT_YET_ASSOCIATED') {
                        _this.snackBar.open('Shipment assigned to Delivery Request', 'Go', { duration: 10000 })
                            .onAction().subscribe(function () {
                            _this.router.navigate(['delivery-optimizer']);
                        });
                    }
                    else {
                        _this.snackBar.open('Shipment used on', data.mdr.pickupID, { duration: 10000 })
                            .onAction().subscribe(function () {
                            _this.router.navigate(['logistic-order', data.mdr.id]);
                        });
                    }
                }
                else if (data.drId) {
                    _this.snackBar.open('Shipment not available', '', { duration: 4000 });
                }
                else {
                    _this.dataSource = new MatTableDataSource([data]);
                    _this.setSite(data.id);
                    _this.dr.client = data.client;
                    _this.dr.deliveryAddress = data.customerAddress;
                    _this.dr.obs = data.customerAddress;
                    _this.dr.prolog = data.assignmentInformation;
                    _this.convertDRAddressToLatLng(_this.dr);
                }
            }, function (error) {
                _this.snackBar.open('Shipment not in the system', '', { duration: 4000 });
            });
        }
    };
    NewDeliveryRequestComponent.prototype.searchNFe = function () {
        var _this = this;
        // console.log('searchNFe',this.inputNFs);
        if (this.inputNFs === undefined) {
            this.snackBar.open('Preencha um valor de NFe', 'Ok', { duration: 4000 });
        }
        else {
            this.nfService.getAll(this.inputNFs).subscribe(function (data) {
                if (!data || data.drId !== null) {
                    _this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
                }
                else {
                    var ovAux = new OV();
                    ovAux.salesDocument = '';
                    ovAux.referenceNoNf = data['invoiceNumber'];
                    ovAux.customerName = data['client'];
                    ovAux.materialDescription = data['materials'];
                    // console.log(data);
                    // console.log(ovAux);
                    _this.dataSource = new MatTableDataSource([ovAux]);
                }
            }, function (error) {
                // console.log(error);
                _this.snackBar.open('NF já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
            });
        }
    };
    return NewDeliveryRequestComponent;
}());
export { NewDeliveryRequestComponent };
var OverviewExampleDialogComponent = /** @class */ (function () {
    function OverviewExampleDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    OverviewExampleDialogComponent.prototype.onYesClick = function () {
        this.data.value = 1;
        this.dialogRef.close();
    };
    OverviewExampleDialogComponent.prototype.onNoClick = function () {
        this.data.value = 0;
        this.dialogRef.close();
    };
    return OverviewExampleDialogComponent;
}());
export { OverviewExampleDialogComponent };
