import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var LoadingInterceptor = /** @class */ (function () {
    function LoadingInterceptor(spinnerService) {
        this.spinnerService = spinnerService;
        this.endpoints = ['MDR/', 'client/', 'occurrence/', 'driver/', 'OV/', 'chat/'];
    }
    LoadingInterceptor.prototype.intercept = function (request, next) {
        var url = request.url;
        if (this.endpoints.every(function (el, i, arr) {
            return url.indexOf(base_url + el) === -1;
        })) {
            // não faz nada
            // console.log(url,' passou reto');
            return next.handle(request);
        }
        this.spinnerService.show();
        return next.handle(request).pipe(tap(function (event) {
            // this.spinnerService.hide();
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                // console.log('erro na request');
                if (err.status === 401) {
                    // redirect to the login route
                    // or show a modal
                }
            }
        }));
    };
    return LoadingInterceptor;
}());
export { LoadingInterceptor };
