import { ElementRef, OnInit } from '@angular/core';
import { MatChipInputEvent, MatDialog, MatDialogRef, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ClientUserService } from '../../providers/client-user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DrService } from '../../providers/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../models/user';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
var LspUserAdminComponent = /** @class */ (function () {
    function LspUserAdminComponent(userService, dialog, clientUserService, spinnerService, drService, router, snackBar, dpipe) {
        this.userService = userService;
        this.dialog = dialog;
        this.clientUserService = clientUserService;
        this.spinnerService = spinnerService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.selection = new SelectionModel(true, []);
        // displayedColumns = ['status', 'client', 'mdrShippingCompany', 'mdrID', 'site', 'uf', 'city', 'dateScheduling'];
        this.displayedColumns = ['name', 'email', 'company', 'edit'];
        this.dataSource = new MatTableDataSource(this.data);
        this.searchChips = [];
        this.removable = true;
        this.selectable = true;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        this.searchTerm = '';
        this.tokenSeparator = '@@@@@@@@@';
        this.dataSource.sort = this.sort;
    }
    LspUserAdminComponent.prototype.ngOnInit = function () {
        this.reloadData();
    };
    LspUserAdminComponent.prototype.reloadData = function () {
        var _this = this;
        this.spinnerService.show();
        this.userService.getUserFromLSP().subscribe(function (elem) {
            elem.forEach(function (k) {
                k.company = k.name;
                k.name = k.u_name;
                k.email = k.u_email;
            });
            _this.data = elem;
            _this.dataSource = new MatTableDataSource(_this.data);
            _this.dataSource.filterPredicate = function (data, mergedFilter) {
                var filter = _this.splitSearchTerms(mergedFilter);
                var display = false;
                _this.displayedColumns.forEach(function (el, i, arr) {
                    // atualmente é feita a comparação com OR lógico
                    // para mudar para AND lógico: trocar .some() por .every()
                    if (filter.length === 0) {
                        display = true;
                    }
                    else if (filter.some(function (chip) {
                        if (el in data &&
                            data[el] !== null) {
                            if (typeof data[el] === 'string' &&
                                data[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                                return true;
                            }
                            else if (typeof data[el] === 'number' &&
                                data[el] === parseInt(chip, 10)) {
                                return true;
                            }
                        }
                        return false;
                    })) {
                        display = true;
                    }
                });
                return display;
            };
            _this.spinnerService.hide();
        });
    };
    LspUserAdminComponent.prototype.applyFilter = function () {
        this.dataSource.filter = this.mergeSearchTerms(this.searchChips);
    };
    LspUserAdminComponent.prototype.deleteUser = function (user) {
        var _this = this;
        this.clientUserService.deleteUser(user.ush_id).subscribe(function (data) {
            _this.reloadData();
        });
    };
    LspUserAdminComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.searchChips.push({ 'name': value.trim() });
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    LspUserAdminComponent.prototype.remove = function (chip) {
        var index = this.searchChips.indexOf(chip);
        if (index >= 0) {
            this.searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    LspUserAdminComponent.prototype.mergeSearchTerms = function (terms) {
        var _this = this;
        var merged = '';
        terms.forEach(function (el, i, arr) {
            if (typeof el.name === 'string') {
                merged = merged + _this.tokenSeparator + '"' + el.name + '"';
            }
            else if (typeof el.name === 'number') {
                merged = merged + _this.tokenSeparator + String(el.name);
            }
        });
        merged = merged.substring(this.tokenSeparator.length, merged.length);
        return merged;
    };
    LspUserAdminComponent.prototype.splitSearchTerms = function (terms) {
        var split = [];
        terms.split(this.tokenSeparator).forEach(function (el, i, arr) {
            if (el[0] === '"' && el[el.length - 1] === '"') {
                split.push(el.substring(1, el.length - 1));
            }
            else {
                split.push(parseInt(el, 10));
            }
        });
        return split;
    };
    LspUserAdminComponent.prototype.openDialog = function (user) {
        var _this = this;
        if (user === undefined) {
            user = 'Ola';
        }
        var dialogRef = this.dialog.open(EditUserDialogComponent, {
            width: '600px',
            data: user,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response && response.text.indexOf('User saved correctly') !== -1) {
                _this.reloadData();
            }
        });
    };
    LspUserAdminComponent.prototype.openDeleteDialog = function (user) {
        var _this = this;
        if (user === undefined) {
            user = 'Ola';
        }
        var dialogRef = this.dialog.open(DeleteUserDialogComponent, {
            width: '600px',
            data: user,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.deleteUser(response);
                _this.reloadData();
            }
        });
    };
    return LspUserAdminComponent;
}());
export { LspUserAdminComponent };
var EditUserDialogComponent = /** @class */ (function () {
    function EditUserDialogComponent(dialogRef, clientUserService, data, translate, drService, snackBar) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.clientUserService = clientUserService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.user = new User();
        this.lspList = [];
        // console.log("Received Data");
        // console.log(data);
        this.clientUserService.getLspList().subscribe(function (lsps) {
            _this.lspList = lsps;
        });
        // console.log(data);
    }
    EditUserDialogComponent.prototype.ngOnInit = function () {
    };
    EditUserDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        this.clientUserService.createUser(this.user.name, this.user.email, this.lsp).subscribe(function (data) {
            _this.dialogRef.close(data);
        });
    };
    EditUserDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditUserDialogComponent;
}());
export { EditUserDialogComponent };
var DeleteUserDialogComponent = /** @class */ (function () {
    function DeleteUserDialogComponent(dialogRef, clientUserService, data, translate, drService, snackBar) {
        this.dialogRef = dialogRef;
        this.clientUserService = clientUserService;
        this.data = data;
        this.translate = translate;
        this.drService = drService;
        this.snackBar = snackBar;
        this.user = new User();
        this.lspList = [];
        // console.log("Received Data");
        // console.log(data);
        //    this.clientUserService.getLspList().subscribe( (lsps) => {
        //      this.lspList = lsps;
        //    });
        // console.log(data);
        this.data = data;
    }
    DeleteUserDialogComponent.prototype.ngOnInit = function () {
    };
    DeleteUserDialogComponent.prototype.onYesClick = function () {
        this.dialogRef.close(this.data);
    };
    DeleteUserDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return DeleteUserDialogComponent;
}());
export { DeleteUserDialogComponent };
