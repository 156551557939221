import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CockpitRoutingModule } from './cockpit-routing.module';

import {TranslateModule} from '@ngx-translate/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FlexLayoutModule } from '@angular/flex-layout';

import {PipesModule} from '../../misc/pipes/pipes.module';
import {AgmCoreModule, MarkerManager} from '@agm/core';

import { CockpitComponent } from './cockpit.component';
import { MapDialogComponent } from './cockpit.component';
import { DrCardComponent } from './dr-card/dr-card.component';



@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatCardModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    PipesModule,
    AgmCoreModule,
    CockpitRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FlexLayoutModule
  ],
  declarations: [
    CockpitComponent,
    DrCardComponent,
    MapDialogComponent,
  ],
  entryComponents: [
    MapDialogComponent,
  ],
  exports: []
})
export class CockpitModule { }
