import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {DR} from '../models/dr';
@Injectable()
export class DrObservable {
    private dr = new  Subject<DR[]>();
    currentDR = this.dr.asObservable();

    changeDR(getDR: DR[]) {
      this.dr.next(getDR);
      // console.log(this.dr);
    }


}
