/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sla.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./sla.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../providers/mdr.service";
var styles_SlaComponent = [i0.styles];
var RenderType_SlaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlaComponent, data: {} });
export { RenderType_SlaComponent as RenderType_SlaComponent };
export function View_SlaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSLA() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isSLA ? "is-sla" : "is-not-sla"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("SLA")); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform((_co.isSLA ? "YES" : "NO"))); _ck(_v, 5, 0, currVal_2); }); }
export function View_SlaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sla", [], null, null, null, View_SlaComponent_0, RenderType_SlaComponent)), i1.ɵdid(1, 114688, null, 0, i4.SlaComponent, [i5.ActivatedRoute, i5.Router, i6.MdrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SlaComponentNgFactory = i1.ɵccf("app-sla", i4.SlaComponent, View_SlaComponent_Host_0, { id: "id", type: "type" }, {}, []);
export { SlaComponentNgFactory as SlaComponentNgFactory };
