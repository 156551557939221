import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ReportService {
    public token: string;

    constructor(private http: HttpClient) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    getOperation(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/operation/' + start + '/' + end, {
            headers: header,
        });
    }

    getLSP(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/LSP/' + start + '/' + end, {
            headers: header,
        });
    }

    getStakeholders(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders/' + start + '/' + end, {
            headers: header,
        });
    }

    getStakeholdersByDR(drs: any[]): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders_dr/[' + drs + ']', {
            headers: header,
        });
    }

    getPerfLSP(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/perfLSP/' + start + '/' + end, {
            headers: header,
        });
    }

    getLSPKPI(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/LSPKPI/' + start + '/' + end, {
            headers: header,
        });
    }

    getBattery(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/battery/' + start + '/' + end, {
            headers: header,
        });
    }

    getCMDR(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cmdr/' + start + '/' + end, {
            headers: header,
        });
    }

    getCOV(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cov/' + start + '/' + end, {
            headers: header,
        });
    }

    getPODDP(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/poddp/' + start + '/' + end, {
            headers: header,
        });
    }

    getHub(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/hub/' + start + '/' + end, {
            headers: header,
        });
    }

    getDPUnion(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/dpunion/' + start + '/' + end, {
            headers: header,
        });
    }

    getCockpit(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cockpit/' + start + '/' + end, {
            headers: header,
        });
    }

    getUOC(start: Date, end: Date): Observable<any> {
        const token = JSON.parse(localStorage.getItem('currentUser')).token;
        const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/uoc/' + start + '/' + end, {
            headers: header,
        });
    }
}
