var Message = /** @class */ (function () {
    function Message(user, content, channel, createdAt, chatType) {
        this.user = user;
        this.content = content;
        this.channel = channel;
        this.createdAt = createdAt;
        this.chatType = chatType;
    }
    Message.prototype.getUser = function () {
        return this.user;
    };
    Message.prototype.getContent = function () {
        return this.content;
    };
    return Message;
}());
export { Message };
