import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router, Resolve, RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';

import { MdrService } from '../../providers/mdr.service';

@Injectable()
export class ResolverService implements Resolve<any> {
  constructor(private service: MdrService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const token = route.paramMap.get('token');

    return this.service.getMdrWithToken(token).take(1).map(response => {
      if (response) {
        return response;
      } else { // mdr not found
        this.router.navigate(['/login']);
        return null;
      }
    });
  }
}
