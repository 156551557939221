<h1 mat-dialog-title>{{'OCCURRENCE/title' | translate }}</h1>
<div mat-dialog-content>
   <form #form="ngForm" class="row">
      <mat-form-field class="col-md-10" *ngIf="role === 1 || role === 2" >
         <input matInput style="width: 100%" placeholder="Comentário" name="text" [(ngModel)]="data.text" required>
      </mat-form-field>
   <!-- Checkbox is UOC -->
   <div class="row">
      <mat-checkbox class="col-md-6" name="checkboxUOC" style="display: block;" [(ngModel)]="isUOC">{{'OCCURRENCE/isUOC' | translate }}</mat-checkbox>
   </div>
   <div class="row" *ngIf="isUOC">
      <mat-form-field class="col-md-6">
         <mat-select placeholder="Tipo de UOC" [(ngModel)]="uocSelected" name="uocType" [required]="isUOC">
            <mat-option (click)="getUOCValue(elem)" *ngFor="let elem of uoc" [value]="elem.name">
               {{ elem.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput placeholder="Valor Base" [(ngModel)]="valueUOC" name="baseValue" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput type="number" [step]="1" placeholder="Multiplicador" [(ngModel)]="uocMult" name="multiplier" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput placeholder="Valor Final" [(ngModel)]="totalValue" [value]="( valueUOC * uocMult )" disabled="true" name="finalValue" [required]="isUOC">
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <mat-select placeholder="DRs" [formControl]="myControl" multiple name="DRs" [required]="isUOC">
            <mat-option *ngFor="let dr of drList" [value]="dr.id">{{dr.idDRidentifier}} - {{dr.prolog}} - {{dr.site}}</mat-option>
         </mat-select>
      </mat-form-field>
   </div>
   <!-- Checkbox is CoPQ -->
   <div class="row" *ngIf="isUOC">
      <mat-checkbox class="example-margin col-md-6 " style="display: block;" name="checkboxCOPQ" [(ngModel)]="isCOPQ">{{'OCCURRENCE/isCOPQ' | translate }}</mat-checkbox>
   </div>
   <div class="row" *ngIf="isCOPQ">
      <mat-form-field class="col-md-6">
         <mat-select placeholder="Tipo de CoPQ" [(ngModel)]="copqSelected" name="copqType" [required]="isCOPQ">
            <mat-option *ngFor="let elem of copq" [value]="elem.name">
               {{ elem.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput placeholder="Valor Base" [(ngModel)]="valueCOPQ" name="baseValueCOPQ" [required]="isCOPQ">
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput placeholder="Multiplicador" [step]="1" [(ngModel)]="copqMult" name="multiplierCOPQ" [required]="isCOPQ">
      </mat-form-field>
      <mat-form-field class="col-md-6">
         <input matInput placeholder="Valor Final" [(ngModel)]="copqFinalValue" [value]="( valueCOPQ * copqMult )" disabled="true" name="finalValueCOPQ" [required]="isCOPQ">
      </mat-form-field>
   </div>
</form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between w-100">
   <button mat-button [disabled]="!form.valid" (click)="onYesClick()" cdkFocusInitial>{{"YES" | translate}}</button>
   <button mat-button cdkFocusInitial (click)="onNoClick()">{{"NO" | translate}}</button>
</div>
