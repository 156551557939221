<mat-card>
   <mat-card-title>Ordem de Transporte</mat-card-title>
   <mat-card-content>
      <div class="mat-elevation-z8" style="margin-bottom: 30px;">
         <table mat-table #table [dataSource]="dataSource" matSort>

            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ordem de Transporte </th>
              <td mat-cell *matCellDef="let element"><a (click)="goTo(element)"> {{ element.id }} </a></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element"><a (click)="goTo(element)"> {{element.status}} </a></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         </table>
      </div>
      <!-- <div>
           <button class="login" mat-raised-button style="float: right;" (click)="addNewOV()">Adicionar Nova Ordem de Transporte</button>
           </div> -->
   </mat-card-content>
</mat-card>
