import { Observable } from 'rxjs/Observable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var ExcelService = /** @class */ (function () {
    function ExcelService() {
    }
    ExcelService.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    ExcelService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    ExcelService.prototype.importFromExcel = function (ev) {
        var workbook;
        var excelInJSON;
        var fileReader = new FileReader();
        console.log(ev);
        // init read
        fileReader.readAsArrayBuffer(ev);
        return new Observable(function (observer) {
            // if success
            fileReader.onload = function (file) {
                var binary = '';
                var bytes = new Uint8Array(file.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                // Converts the excel data in to json
                workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
                // only first sheet
                excelInJSON = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                observer.next(excelInJSON);
            };
            // if failed
            fileReader.onerror = function (error) { return observer.error(error); };
        });
    };
    return ExcelService;
}());
export { ExcelService };
