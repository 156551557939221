import { Component, OnInit, ViewChild,
         Inject, ElementRef, Input } from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-dr-card',
  templateUrl: './dr-card.component.html',
  styleUrls: ['./dr-card.component.scss']
})

export class DrCardComponent implements OnInit {
  @Input() dr: any;
  @Input() show: boolean;
  color: string;
  tooltip: string[] = [];
  pickupID: any = null;
  shippingCompany = '';
  truckSaw = false;
  novasMensagens = {
    5: false,
    6: false
  };

  icons = {
    'red': 'fa-exclamation-circle',
    'yellow': 'fa-exclamation-triangle',
    'green': '',
    'undefined': 'fa-hourglass-half'
  };

  constructor(public snackBar: MatSnackBar) {
    this.show = true;
  }

  getStatusClass() {
    return 'dr-status-' + this.color;
  }

  ngOnInit() {
    this.color = this.dr['color'];
    this.tooltip = this.dr['tooltip'];
    this.truckSaw = this.dr['truckSaw'];
    this.novasMensagens = this.dr['novasMensagens'];

    if (this.dr.mdr !== undefined) {
      this.shippingCompany = this.dr.mdr.shippingCompany;
      this.pickupID = this.dr.mdr.pickupID;
      if (this.dr.mdr.type === 'DTR') {
        this.dr.mdr.type = 'INV';
      }
    }
  }

  setRedirectUrl(): string {
    if (this.dr.status === 'BOOKING') {
      return(window.location.origin + '/dr-validation/' + this.dr.id );
    }
    if ( this.dr.idMDR !== null && this.dr.idMDR !== 0 ) {
      return(window.location.origin + '/logistic-order/' + this.dr.idMDR );
    }
    return(window.location.origin + '/dr-validation/' + this.dr.id );
  }

}
