<span *ngIf="finishedDriveStatus.indexOf(mdr.status) === -1">
  <p>{{ 'GEOLOCATION/distanceRemaining' | translate }} {{ distanceRemaining }}</p>
  <p>{{ 'GEOLOCATION/timeRemaining' | translate }} {{ timeRemaining }}</p>
</span>


<div *ngIf="showMap">
  <agm-map #gmap [latitude]="lat" [longitude]="lng" [mapTypeControl]="true"  [usePanning]="true">
    <span *ngIf="dir">
      <agm-marker *ngFor="let mark of markers"
        [markerDraggable]="false"
        [label]="{color: '#FFFFFF', fontSize: '16px', text: mark.label}"
        [latitude]="mark.lat"
        [longitude]="mark.lng"
      ></agm-marker>

      <!-- pontos do tracking -->
      <agm-marker *ngFor="let mark of markersDriver"
        [markerDraggable]="false"
        [iconUrl]="mark.icon"
        [latitude]="mark.lat"
        [longitude]="mark.lng"
      ></agm-marker>

      <!-- linha cinza -->
      <span *ngFor="let leg of legsNewLegs">
        <agm-direction *ngIf="leg.type === 'Rodoviário'"
          (onResponse)="onResponse($event)"
          (onChange)="onResponse($event)"
          [visible] = "show"
          [renderOptions]="renderOptions"
          [origin]="leg.pointInit"
          [destination]="leg.pointEnd"
        ></agm-direction>

        <agm-polyline *ngIf="leg.type !== 'Rodoviário'"
          [geodesic]="true"
          [visible] = "show">
          <agm-polyline-point
            [latitude]="leg.pointInit.lat"
            [longitude]="leg.pointInit.lng"
          ></agm-polyline-point>
          <agm-polyline-point
            [latitude]="leg.pointEnd.lat"
            [longitude]="leg.pointEnd.lng"
          ></agm-polyline-point>
        </agm-polyline>
      </span>

      <span *ngIf="finishedDriveStatus.indexOf(mdr.status) === -1">
        <!-- linha azul -->
        <agm-direction (onResponse)="onResponseDriver($event)" (onChange)="onResponseDriver($event)" *ngIf="haveDriver && legsNewLegs.length > 0" [visible] = "show" [renderOptions]="renderOptionsDriver" [origin]="driverPosition" [destination]="legsNewLegs[0].pointEnd" ></agm-direction>

        <!-- marker do caminhão -->
        <agm-marker *ngIf="haveDriver" [markerDraggable]="false" [latitude]="driverPosition.lat" [iconUrl]="icon" [longitude]="driverPosition.lng"></agm-marker>
      </span>

    </span>
  </agm-map>
</div>
