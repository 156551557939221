<div class="mx-5 my-4">
  <span class="mat-h1">{{ 'DR_OPTIMIZER/title' | translate }}</span>
</div>
<div class="searchToggle mx-5 my-4" *ngIf="role !== 7">
  Normal<mat-slide-toggle [(ngModel)]="search_toggle" (change)="changeBooking()">Booking LSP</mat-slide-toggle>
</div>
<div *ngIf="dataSource" class="colored-background table-background">
  <div class="mx-5" *ngIf="dataSource.data.length > 0">
    <mat-form-field  class="my-3 pt-1 pl-4 rounded-corners col-md-8" floatLabel="never">
      <input matInput  [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{ 'DR_OPTIMIZER/filter' | translate }}">
    </mat-form-field>
    <table mat-table #table  [dataSource]="dataSource" matSort>
      <p>{{ 'DR_OPTIMIZER/list' | translate }}o</p>

      <ng-container matColumnDef="select">
        <th mat-header-cell  [ngClass]="'customWidthClass'" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell [ngClass]="'customWidthClass'" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="idDRidentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/id' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.idDRidentifier}} </td>
      </ng-container>

      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/address' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/site' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.site}} </td>
      </ng-container>

      <ng-container matColumnDef="dateScheduling">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DR_OPTIMIZER/date' | translate }}  </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateScheduling | date: "dd/MM/yy, h:mm a"}} </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/created' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.createdBy}}</td>
      </ng-container>

      <ng-container matColumnDef="prolog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prolog</th>
        <td mat-cell *matCellDef="let element">{{element.prolog}}</td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/table/client' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.client}}</td>
      </ng-container>

      <ng-container matColumnDef="ovList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DR_OPTIMIZER/table/ovList' | translate }}</th>
        <td mat-cell *matCellDef="let element" matTooltipShowDelay="0" matTooltipPosition="above" [matTooltip]="element.ovList">{{element.ovList}}</td>
      </ng-container>

      <ng-container matColumnDef="goTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Link</th>
        <td mat-cell *matCellDef="let element"><span (click)="goToBooking(element)"><i text class='fas fa-eye'></i></span></td>
      </ng-container>

      <ng-container matColumnDef="thirdPartInvoice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Alistado</th>
        <td mat-cell *matCellDef="let element"><i text class='fa fa-bookmark' *ngIf='element.thirdPartInvoice'></i></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="role !== 7 && !isBooking" class="d-flex justify-content-between m-3 pb-3">
    <button *ngIf="role === 1 || role === 2" class="primary-button" mat-raised-button (click)="openDialog()">{{ 'DR_OPTIMIZER/create' | translate }}</button>
    <button *ngIf="role === 1 || role === 2" class="primary-button" mat-raised-button (click)="goToMDRAvailable()">{{ 'DR_OPTIMIZER/available' | translate }}</button>
    <button *ngIf="role === 1 || role === 2" class="primary-button" mat-raised-button (click)="goToMDRUnavailable()">{{ 'DR_OPTIMIZER/unavailable' | translate }}</button>
    <!-- <button class="primary-button" mat-raised-button (click)="createConsolidationMDR()">Criar consolidação</button> -->
    <button class="warn-button" mat-raised-button (click)="returnDR()">{{ 'DR_OPTIMIZER/undo' | translate }}</button>
  </div>
  <div *ngIf="role === 7 || isBooking" class="d-flex justify-content-between m-3 pb-3">
    <button class="primary-button" mat-raised-button (click)="validateDR()">ValidateDR</button>
  </div>
</div>

<div *ngIf="!dataSource" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'DR_OPTIMIZER/empty' | translate }}</p>
</div>
