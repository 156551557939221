<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'DR_BOOKING/title' | translate }}</p>
  <p class="mx-4">{{ 'DR_BOOKING/sub-title' | translate }}</p>
  <i text class='fa fa-bookmark' *ngIf='dr.thirdPartInvoice'></i>
</div>

<div class="colored-background pt-5">
  <mat-card class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <form #form="ngForm" class="d-flex flex-wrap">
        <span (click)="downloadReport()"><i class="fa fa-download"></i></span>
        <span (click)="downloadPDF()" style="position: absolute; right: 10px;"><i class="fa fa-file-pdf"></i></span>
        <div class="col-md-12"><button *ngIf="role !== 7" mat-raised-button class="w-100 primary-button col-md-2  offset-md-8" style="float: right;" (click)="addNewRow()">Add</button></div>
        <div *ngIf="dataSource.data.length > 0" class="col-md-12">
          <mat-table #table2 [dataSource]="dataSource" matSort>

            <!-- <ng-container matColumnDef="salesDocument">
              <mat-header-cell *matHeaderCellDef mat-sort-header> OV </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.salesDocument}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="assignmentInformation">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Prolog </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.prolog}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="customerCity">
              <mat-header-cell *matHeaderCellDef mat-sort-header> UOM </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.uoc}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="customerAddress">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.serial}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="salesOrderQuantity">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.qtd}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="materialCode">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Material </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.material}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="materialDescription">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Material Description</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.materialDescription}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="customerName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.client}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="obs">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Obs </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.obs}} </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="receiverPhone">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/phone' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.receiverPhone}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="receiverName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.receiverName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateScheduling">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/date' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/status' | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.status | translate}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nameOfTheEndUserForFTrade">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Customer </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.nameOfTheEndUserForFTrade }} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
              <mat-cell *matCellDef="let element"> <span (click)="openDialog(element)">{{ 'DELIVERY_ROUTE/edit' | translate }}</span> </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>

          </mat-table>
          <mat-paginator #paginator
            [pageSize]="5"
            [pageSizeOptions]="[5, 10]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      <!-- <mat-form-field class="col-md-12">
        <i matSuffix
          class="fa"
          [ngClass]="icons.status ? [ icons.icons[icons.status] , icons.classes[icons.status] ] : ''"
          [matTooltip]="icons.tooltips[icons.status]"
          (click)="openDialog()"
        ></i>
        <input matInput required
          placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}"
          [(ngModel)]="salesOrders"
          name="dr.salesOrders">
      </mat-form-field> -->
      <mat-form-field class="col-md-6">
        <input matInput required #addrSearch
          type="text"
          autocapitalize="off"
          spellcheck="off"
          placeholder="{{ 'DR_BOOKING/text/delivery-address' | translate }}"
          name="deliveryAddress"
          id="deliveryAddress"
          [(ngModel)]="dr.deliveryAddress"
          (change)="convertAddressToLatLng()">
      </mat-form-field>
      <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lat' | translate }}"
            id="latitudeAddress"
            name="latitudeAddress"
            [(ngModel)]="dr.lat"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lng' | translate }}"
            id="longitudeAddress"
            name="longitudeAddress"
            [(ngModel)]="dr.lng"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
      </div>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/name-receptor' | translate }}" [(ngModel)]="dr.receiverName" name="receiverName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput mask="(00) 00009-0000" type="text" placeholder="{{ 'DR_BOOKING/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone" name="receiverPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/name-supervisor' | translate }}" [(ngModel)]="dr.supervisorName" name="dr.supervisorName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput mask="(00) 00009-0000"  placeholder="{{ 'DR_BOOKING/text/cellphone-higher' | translate }}" [(ngModel)]="dr.supervisorPhone" name="dr.supervisorPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/responsable-cpm' | translate }}" [(ngModel)]="dr.responsableCPM" name="dr.responsableCPM">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/responsable-asp' | translate }}" [(ngModel)]="dr.responsableASP" name="dr.responsableASP">
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input matInput required name="dateCollect" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" [placeholder]="'DR_BOOKING/text/date' | translate" [(ngModel)]="dr.dateScheduling">
        <owl-date-time #dt></owl-date-time>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/site' | translate }}" [(ngModel)]="dr.site" name="dr.site">
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput placeholder="{{ 'DR_BOOKING/text/infra' | translate }}" [(ngModel)]="dr.infraType" name="dr.infraType">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <textarea matInput placeholder="{{ 'DR_BOOKING/text/obs' | translate }}" [(ngModel)]="dr.obs" name="dr.obs"></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <textarea matInput placeholder="{{ 'DR_BOOKING/text/weight' | translate }}" [(ngModel)]="dr.weight" name="dr.weight"></textarea>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>{{'DR_BOOKING/text/client' | translate}}</mat-label>
        <mat-select required [(ngModel)]="dr.client" name="client">
          <mat-option *ngFor="let client of clientList" [value]="client">
            {{client}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}" [(ngModel)]="dr.salesOrders" name="salesOrders">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/unification-id' | translate }}" [(ngModel)]="dr.unificationId" name="unificationId">
      </mat-form-field>
      <!-- <mat-form-field class="col-md-6">
        <mat-label>{{'DR_BOOKING/text/client' | translate}}</mat-label>
        <mat-select required [(ngModel)]="dr.client" name="client">
          <mat-option *ngFor="let client of clientList" [value]="client">
            {{client}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/site-team' | translate }}" [(ngModel)]="dr.siteTeam" name="siteTeam">
      </mat-form-field> -->


      <!-- fim preenchimento campos -->

    </form>

    <!-- <button  mat-raised-button class="w-100 warn-button col-md-2" style="margin-right: 10px" [disabled]="!form.valid" (click)="uploadSerial()">Serial Upload</button> -->
    <div style="width: 100%;" class='button-role'>
      <input #fileInput multiple
              class="hidden"
              style="width: 0px"
              type='file'
              (change)='uploadSerial($event)'
            >
            <button mat-raised-button
              class="w-100 col-md-2 info-button"
              (click)="fileInput.click()"
            >Serial Upload</button>
      <button *ngIf="role !== 7 && dr.status !== 'READY_TO_PICKUP'" mat-raised-button class="w-100 primary-button col-md-2" style="margin-right: 10px" [disabled]="!form.valid" (click)="saveAndValidate()">{{'DR_BOOKING/btn/validate-dr' | translate}}</button>
      <button *ngIf="(role === 1 || role === 2) && dr.status !== 'BOOKING'" class="primary-button" mat-raised-button class="w-100 primary-button col-md-2"style="margin-right: 10px" [disabled]="!form.valid" (click)="goToMDRAvailable()">{{ 'DR_OPTIMIZER/available' | translate }}</button>
      <button *ngIf="(role === 1 || role === 2) && dr.status !== 'BOOKING'" class="primary-button" mat-raised-button class="w-100 warn-button col-md-2" style="margin-right: 10px" (click)="goToMDRUnavailable()">{{ 'DR_OPTIMIZER/unavailable' | translate }}</button>
      <button *ngIf="role === 1" mat-raised-button class="w-100 warn-button col-md-2" style="margin-right: 10px" [disabled]="!form.valid" (click)="remove()">Remove DR</button>
      <button mat-raised-button class="w-100 primary-button col-md-3" style="margin-right: 10px" (click)="save()">Save DR Booking</button>
      <button *ngIf="role === 7" mat-raised-button class="w-100 warn-button col-md-2" style="margin-right: 10px" (click)="reproveBooking()">Reprove DR Booking</button>
    </div>
  </mat-card>
</div>
