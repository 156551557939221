import { OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { ConfigurationService } from '../../providers/configuration.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(auth, router, drService, occService) {
        var _this = this;
        this.auth = auth;
        this.router = router;
        this.drService = drService;
        this.occService = occService;
        this.show = false;
        this.openOccurrence = '0';
        this.menuItems = [
            { name: 'MENU/REQUISITION', icon: 'fa-clipboard', link: '/new-delivery-request' },
            { name: 'MENU/OPTIMIZER', icon: 'fa-cogs', link: '/delivery-optimizer' },
            // { name: 'MENU/CONSOLIDE',          icon: 'fa-boxes',               link: '/mdr-optimizer'          },
            { name: 'MENU/NEW_MDR', icon: 'fa-shield-alt', link: '/mdr-management' },
            { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
            { name: 'MENU/MAP_MDR', icon: 'fa-map', link: '/mdr-map' },
            { name: 'MENU/TOWER_CONTROL', icon: 'fa-broadcast-tower', link: '/control-tower' },
            { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
            { name: 'MENU/OCCURRENCE', icon: 'fa-envelope-open-text', link: '/occurrence-list', type: 'badge' },
            // Gerenciamento Operacional
            // { name: 'MENU/WAREHOUSE',          icon: 'fa-warehouse',           link: '/hub'                    },
            // { name: 'MENU/PRICE_MAKER_TABLE',  icon: 'fa-tags',                link: '/price-maker/table'      },
            // { name: 'MENU/ADD_LSP_USER',       icon: 'fa-users-cog',           link: '/lsp-user-admin'         },
            // Gerenciamento de Arquivos
            { name: 'MENU/UPLOAD', icon: 'fa-cloud-upload-alt', link: '/insert-new-data' },
            { name: 'MENU/DASHBOARD', icon: 'fa-chart-line', link: '/dashboard' },
            // { name: 'MENU/BULK_NFE_UP',        icon: 'fa-file-upload',         link: '/bulk-nfe-upload'        },
            // { name: 'MENU/INSERT_NFE',         icon: 'fa-edit',                link: '/insert-nfe'             },
            { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
            { name: 'MENU/FINANCE', icon: 'fa-file-invoice-dollar', link: '/finance-list' },
        ];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        // console.log(this.role);
        if (this.role === 5) {
            this.menuItems = [
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-broadcast-tower', link: '/control-tower' },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-envelope-open-text', link: '/occurrence-list', type: 'badge' },
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 2) {
            this.menuItems = [
                { name: 'MENU/OPTIMIZER', icon: 'fa-cogs', link: '/delivery-optimizer' },
                // { name: 'MENU/CONSOLIDE',          icon: 'fa-boxes',               link: '/mdr-optimizer'          },
                { name: 'MENU/NEW_MDR', icon: 'fa-shield-alt', link: '/mdr-management' },
                { name: 'MENU/FOLLOW_MDR', icon: 'fa-truck', link: '/list-mdrs' },
                { name: 'MENU/MAP_MDR', icon: 'fa-map', link: '/mdr-map' },
                { name: 'MENU/TOWER_CONTROL', icon: 'fa-broadcast-tower', link: '/control-tower' },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/OCCURRENCE', icon: 'fa-envelope-open-text', link: '/occurrence-list', type: 'badge' },
                // Gerenciamento Operacional
                // { name: 'MENU/WAREHOUSE',          icon: 'fa-warehouse',           link: '/hub'                    },
                // { name: 'MENU/PRICE_MAKER_TABLE',  icon: 'fa-tags',                link: '/price-maker/table'      },
                // { name: 'MENU/ADD_LSP_USER',       icon: 'fa-users-cog',           link: '/lsp-user-admin'         },
                // Gerenciamento de Arquivos
                { name: 'MENU/UPLOAD', icon: 'fa-cloud-upload-alt', link: '/insert-new-data' },
            ];
        }
        else if (this.role === 7) {
            this.menuItems = [
                { name: 'MENU/OPTIMIZER', icon: 'fa-cogs', link: '/delivery-optimizer' },
                { name: 'MENU/COCKPIT', icon: 'fa-building', link: '/cockpit' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 8) {
            this.menuItems = [
                { name: 'MENU/CALCULATOR', icon: 'fa-calculator', link: '/price-maker' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        else if (this.role === 6) {
            this.menuItems = [];
        }
        else if (this.role === 9) {
            this.menuItems = [
                { name: 'MENU/REPORTS', icon: 'fa-clipboard-list', link: '/reports' },
                { name: 'MENU/LOGOUT', icon: 'fa-sign-out-alt', link: '/logout' }
            ];
        }
        this.occService.allCount().subscribe(function (response) {
            if (response > 100) {
                _this.openOccurrence = '99+';
            }
            else {
                _this.openOccurrence = response + '';
            }
        });
        // this.drService.getLastInserted().subscribe((response) => {
        //   this.lastUpdate = response;
        // });
    }
    MenuComponent.prototype.ngOnInit = function () {
    };
    MenuComponent.prototype.logout = function () {
        this.auth.logout();
        this.router.navigate(['login']);
    };
    return MenuComponent;
}());
export { MenuComponent };
