<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'ATTACHMENTS/title' | translate }}
        </div>
      </div>
      <hr>

      <div class="row border-button">
        <div class="col-md-9">
          <span class="title">{{ 'ATTACHMENTS/nf' |translate }}</span>
          <button *ngIf="nfFiles.length" class="button green-button text-white" style="float: right; width: 150px; height: 38px;" (click)="dowloadAll()"> {{ 'ATTACHMENTS/download' | translate }} </button>
        </div>
        <div class="col-md-3">
          <app-upload-files [entity]="'mdr'" [id]="mdr.id" (messageEvent)="receiveFile($event)" > </app-upload-files>
        </div>
      </div>
      <div *ngIf="nfList !== undefined">
        <div *ngFor="let nf of nfFiles" class="row">
          <div class="column col-md-10" >
            <a class="e2e-trusted-url" [href]="nf.url" target="_blank"> {{ nf.fileName }} </a>
          </div>
          <div class="column col-md-1" *ngIf="!nf.protected">
            <button class="button red-button text-white"  mat-raised-button   (click)="removeNF(nf)"> {{ 'ATTACHMENTS/remove' | translate }} </button>
          </div>
        </div>
      </div>

      <!-- fim NFs -->

      <div class="row border-button">
        <div class="col-md-9">
          <span class="title">{{ 'ATTACHMENTS/pod' | translate }}</span>
        </div>
        <div class="col-md-3 center-text">
          <app-upload-files [id]="'1'" [entity]="'1'" [uploadMassive]="uploadMassive" (messageEvent)="receiveFile($event)"  ></app-upload-files>
        </div>
      </div>
      <div *ngFor="let nf of nfList" class="border-button">
        <div  class="row title-anex">
          <div class="col-md-9 center-text">
            {{nf.nf}}
          </div>
          <div class="col-md-3 center-text">
            <app-upload-files [id]="nf.nf" [entity]="'nf'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 my-3" *ngFor="let file of nf.file"><!-- para cada POD ja subido -->
            <div class="d-flex">
              <app-image-lightbox class="col-md-3"  [images]="[file]"></app-image-lightbox>
              <form class="col-md-8">
                <i class="fa fa-copy" style="margin: 0px 15px;" matTooltip="{{ 'ATTACHMENTS/copy' | translate }}" (click)="copyData(file)"></i>
                <i class="fa fa-paste" matTooltip="{{ 'ATTACHMENTS/paste' | translate }}" (click)="pasteData(file)"></i>
                <mat-form-field class="col-md-12"><input matInput  [disabled]="disablePODEditing" placeholder="{{ 'ATTACHMENTS/name' | translate }}" name="info_name" [(ngModel)]="file.info_name"></mat-form-field>
                <mat-form-field class="col-md-4"><input matInput  [disabled]="disablePODEditing" placeholder="{{ 'ATTACHMENTS/rg' | translate }}" name="info_rg" [(ngModel)]="file.info_rg"></mat-form-field>
                <mat-form-field class="col-md-4">
                  <input matInput
                    name="deliveredat"
                    [(ngModel)]="file.info_deliveredAt"
                    placeholder="Data da entrega"
                    [disabled]="disablePODEditing"
                    [owlDateTimeTrigger]="myDatepickerCal"
                    [owlDateTime]="myDatepickerCal">
                  <owl-date-time #myDatepickerCal 
                  [disabled]="disablePODEditing" pickerType="calendar"></owl-date-time>
                </mat-form-field>
                <mat-form-field class="col-md-3">
                  <input matInput
                    [owlDateTimeTrigger]="myTimepicker"
                    [owlDateTime]="myTimepicker"
                    [disabled]="disablePODEditing"
                    placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
                    [(ngModel)]="file.info_timeDeliveredAt"
                    name="timedeliveredat">
                  <owl-date-time #myTimepicker 
                  [disabled]="disablePODEditing" pickerType="timer"></owl-date-time>
                </mat-form-field>
                <div class="col-md-12">{{ 'ATTACHMENTS/date' | translate }} {{ file.createdAt | date :'dd/MM/yyyy hh:mm:ss'}}</div>
                <div class="d-flex justify-content-between">
                  <button class="button red-button text-white col-md-3"  [disabled]="disablePODEditing" mat-raised-button   (click)="removePOD(file)"> Remover </button>
                  <button class="button green-button text-white col-md-3"  [disabled]="disablePODEditing"   mat-raised-button   (click)="savePOD(file)"> Salvar </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- fim POD -->

      <div class="row border-button">
        <div class="col-md-9">
          <span class="title">{{ 'ATTACHMENTS/uoc' | translate }}</span>
        </div>
        <div *ngIf="uocList !== undefined" class="col-md-12">
          <div *ngFor="let uoc of uocList" class="row">
            <div *ngIf="uoc.status" class="col-md-12">

              <div class="column col-md-9" >
                {{ uoc.uoc }}
              </div>
              <div class="col-md-3 center-text"  >
                <app-upload-files [id]="uoc.id" [entity]="'uoc'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
              </div>
              <div class="row">
                <div class="col-md-10 my-3" *ngFor="let file of uoc.files">
                  <app-image-lightbox class="col-md-3" [images]="[file]"></app-image-lightbox>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div><!-- fim UOC -->
    </div>
  </div>
</div>
