// Actions you can take on the App
export enum Action {
  JOINED,
  LEFT,
  RENAME
}

// Socket.io events
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  NEW_MESSAGE = 'refresh messages',
  NEW_NOTIFICATION = 'refresh notification',
  NEW_MDRList = 'refresh mdr',
  UPDATE_DR = 'refresh DRs',
  NEW_OCCURRENCE = 'new occurrence'
}
