import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface Price {
  adValorem: number;
  addDelivery: number;
  price: number;
  taxedPrice: number;
  total: number;
  sc: string;
  vehi: string;
}

@Injectable()
export class PriceObservable {
  private price = new  Subject<Price>();
  currentPrice = this.price.asObservable();

  changePrice(getPrice: Price) {
    this.price.next(getPrice);
  }

  public getPrice() {
    return this.price;
  }
}
