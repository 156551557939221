import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import {MatSnackBar} from '@angular/material';
import { Router } from '@angular/router';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { SiteService,
         ESTRUTURA,
         ACESSO } from '../../providers/site.service';
import { LSPEmailDialogComponent } from '../logistic-order/logistic-order.component';
import { AddressPipe } from '../../misc/pipes/parse-address/address.pipe';

import * as https from 'https';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';

declare var google: any;

function convertLatLngToAddress(obj: any) {
  if ( isNaN(obj.lat) || isNaN(obj.lng) ) {
    return;
  }
  const lat = obj.lat;
  const lng = obj.lng;

  const options = {
    hostname: 'nominatim.openstreetmap.org',
    path: '/reverse?format=jsonv2&lat=' + lat + '&lon=' + lng,
    headers: { 'User-Agent': 'AwareLog/1.0' }
  };
  https.get(options, (resp) => {
    let data = '';
    resp.on('data', (chunk) => {
      data += chunk;
    });
    resp.on('end', () => {
      const location = JSON.parse(data);
      const address = location.display_name;
      obj.address = address;
    });
  }).on('error', (err) => {
   // console.log("Error: " + err.message);
  });

}

@Component({
  selector: 'app-site-investigation',
  templateUrl: './site-investigation.component.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class SiteInvestigationComponent implements OnInit {
  url = ''; // url do link para geração do token, preenchido no constructor
  shippingcompanys: any[] = [];
  shippingcompanyusers: any[] = [];
  tipos: any = {};
  data: any[] = [];
  siteList: any[] = [];
  columns = [
    {name: 'SITE_INVESTIGATION/table/id', value: 'id'},
    {name: 'SITE_INVESTIGATION/table/code', value: 'code'},
    {name: 'SITE_INVESTIGATION/table/address', value: 'address'},
    {name: 'SITE_INVESTIGATION/table/shippingcompany', value: 'shippingcompany'},
    {name: 'SITE_INVESTIGATION/table/operadora', value: 'operadora'},
    {name: 'SITE_INVESTIGATION/table/regional', value: 'regional'},
    {name: 'SITE_INVESTIGATION/table/visitadatahora', value: 'visitadatahora'},
    {name: 'SITE_INVESTIGATION/table/vistoriadatahora', value: 'vistoriadatahora'},
    {name: 'SITE_INVESTIGATION/table/createdat', value: 'createdAt'},
    {name: 'SITE_INVESTIGATION/table/token', value: 'accesstokenshow'},
    {name: 'SITE_INVESTIGATION/table/aprovado', value: 'aprovado'},

  ];
  role = -1;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public router: Router,
              public addressPipe: AddressPipe,
              public siteService: SiteService, public excelService: ExcelService) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.url = location.href.replace(this.router.url, '');
  }

  ngOnInit() {
    this.siteService.read().subscribe((data) => {
      // pega as keys dos objetos retornados
      const keys = data.length ? Object.keys(data[0]) : [] ;
      this.data = data.map( (el) => {
        for (let i = 0, l = keys.length; i < l; i++) {
          // remove os valores null
          if ( el[keys[i]] === null ) {
            el[keys[i]] = '';
          }
          // converte em Date
          if ( keys[i] === 'visitadatahora'
            || keys[i] === 'createdAt'
            || keys[i] === 'vistoriadatahora') {
            el[keys[i]] = new Date(el[keys[i]]);
          }
        }
        // gera o link de acesso ao token
        if ( 'accesstoken' in el && el.accesstoken ) {
          el.accesstokenshow = true;
          el.accesstokenlink = this.url + '/site-investigation/' + el.id + '/' + el.accesstoken;
        } else {
          el.accesstokenshow = false;
          el.accesstokenlink = false;
        }
        // extrai UF do endereço
        el.uf = this.addressPipe.transform(el.address, '%E');
        // extrai LSP
        if ( 'shippingcompany' in el ) {
          el.shippingcompany = el.shippingcompany.name;
        } else {
          el.shippingcompany = 'sem LSP';
        }
        // gera o link pro laudo
        el.reportlink = this.url + '/site-report/' + el.id;

        return el;
      });
    });
    this.siteService.getShippingCompanys().subscribe((data) => {
      this.shippingcompanys = data;
      this.siteService.getShippingCompanyUsers().subscribe( (users) => {
        // inclui nome da LSP no nome do usuario
        users.map((u) => {
          const sc = this.shippingcompanys.find(el => {
            return el.id === u.usc.idShippingCompany;
          });
          if (sc) { u.name = u.name + ' [' + sc.name + ']'; }
        });
        this.shippingcompanyusers = users;
      });
    });
    this.tipos = this.siteService.getEnums();
  }

  approveSiteInvestigation(site: any) {
    const dialogRef = this.dialog.open(ApproveDialogComponent, {
      data: {
        site: site
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'approve' ) {
        this.siteService.approve(site.id).subscribe((data) => {
          site.aprovado = true;
          this.snackBar.open('Laudo aprovado com sucesso', 'Ok', {duration: 4000});
        });
      }
    });
  }

  convertAddressToLatLng(obj: any) {
    const addr = obj.address;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        obj.lat = lat;
        obj.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  openFile(fileupload: any) {
    const input = fileupload.target;
    const error = false;
    // para dar match com os varios tipos que sao criados quando eh feito o download
    const regex = new RegExp('tipo\.*\.tipo');

    // console.log(fileupload);
    this.siteList = [];
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(fileupload).subscribe( (data) => {
          // itera nos sites
          data.forEach( (el, i, arr) => {
            try {
              const arrayTipos = [];
              const arrayKeys = Object.keys(el);
              // itera nas keys
              arrayKeys.forEach( (e) => {
                // coloca em arrayTipos os tipos que batem
                if ( regex.test(e) ) {
                  arrayTipos.push(e);
              }
              });
              el.tipo = [];
              // atualiza os tipos no site
              arrayTipos.forEach( (tipos) => {
                el.tipo.push({tipo: el[tipos]});
                delete el[tipos];
              });
              el.acesso = el.acesso.toLowerCase();
              this.siteList.push(el);
            } catch (ex) {
              // console.log('erro ao ler arquivo xlsx ',ex);
              this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
            }
          });
          // console.log(this.siteList);
          this.siteList.forEach( (el) => {
            this.siteService.create(el).subscribe(() => {
              // console.log("Criado com sucesso");
              this.snackBar.open('Criado com sucesso, atualize a página', 'Ok', {duration: 4000});
              // console.log(data);
            });
          });
        });
      } else {
        this.snackBar.open('Arquivo precisa estar no formato xlsx', 'Ok', {duration: 4000});
      }
    }
  }

  createToken(siteID?: number) {
    const dialogRef = this.dialog.open(NewTokenDialogComponent, {
      data: {
        anexo: null,
        site: siteID,
        sites: this.data.map( (el) => {
          return { id: el.id, name: el.code };
        }),
        shippingcompanys: this.shippingcompanys.map( (el) => {
          return { id: el.id, name: el.name };
        })
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'token' ) {
        const opts = {
          'id': parseInt(result.data.site, 10),
          'lspid': parseInt(result.data.shippingcompany, 10),
          'visitadatahora': result.data.visitadatahora,
          'file': result.data.anexo
        };
        this.openEmailDialog(parseInt(result.data.site, 10), parseInt(result.data.shippingcompany, 10)).subscribe((response) => {
          this.siteService.createToken(opts).subscribe((data) => {
            const link = this.url + '/site-investigation/' + data.siteid + '/' + data.accesstoken;
            this.dialog.open(TokenDialogComponent,
                             { data: {
                               link: link
                             }});
            const old = this.data.find((el) => el.id === result.data.site);
            old.accesstokenshow = true;
            old.accesstokenlink = link;
            old.visitadatahora = result.data.visitadatahora;
            this.snackBar.open('Token gerado com sucesso', 'Ok', {duration: 4000});
          });
        });
      }
    });
  }

  addItem() { // CREATE site
    const tipo = {};
    this.tipos.tipo.map( (el) => {
      tipo[el.value] = false;
    });
    const dialogRef = this.dialog.open(NewSiteDialogComponent, {
      data: {
        tipos: this.tipos,
        tipo: tipo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'insert' ) {
        // unifica tipo em um array
        const tipoArr = [];
        this.tipos.tipo.map( (el) => {
          if ( result.data.tipo[el.value] === true ) {
            tipoArr.push({ tipo: el.value });
          }
        });
        result.data.tipo = tipoArr;
        // envia pro back
        this.siteService.create(result.data).subscribe((data) => {
          this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        });
      }

    });
  }

  onRowClick(row, that) { // that aponta para this, mas é necessário para garantir que não é o this do app-table
    if ( that.role === 1 ) {
      that.openEditDialog(row);
    } else {
      that.openViewDialog(row);
    }
  }

  rowStyle(row, that) { // that aponta pra this, mas é necessário para garantir que não é o this do app-table
    if ( row.visitadatahora < new Date() ) {
      return 'red';
    }

    if ( !row.aprovado ) {
      return 'yellow';
    }
    if ( row.acesso !== ACESSO.LIBERADO ) {
      return 'yellow';
    }
    if ( !row.aprovado && row.acesso !== ACESSO.LIBERADO ) {
      return 'yellow';
    }

    if ( row.aprovado && row.acesso === ACESSO.LIBERADO ) {
      return 'green';
    }
  }

  openViewDialog(row) { // VIEW site (para LSPs)
    // separa tipo em objeto
    const tipo = {};
    this.tipos.tipo.map( (el) => {
      tipo[el.value] = false;
    });
    row.tipo.map( (el) => {
      tipo[el.tipo] = true;
    });
    row.tipo = tipo;
    row.tipos = this.tipos;
    const dialogRef = this.dialog.open(ViewSiteDialogComponent, {
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      // unifica tipo em um array (a partir de row)
      const tipoArr = [];
      this.tipos.tipo.map( (el) => {
        if ( row.tipo[el.value] === true ) {
          tipoArr.push({ tipo: el.value });
        }
      });
      row.tipo = tipoArr;
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'new-token' ) {
        this.createToken( row.id );
      }
    });
  }

  openEditDialog(row) { // EDIT site
    // separa tipo em objeto
    const tipo = {};
    this.tipos.tipo.map( (el) => {
      tipo[el.value] = false;
    });
    row.tipo.map( (el) => {
      tipo[el.tipo] = true;
    });
    row.tipo = tipo;
    row.tipos = this.tipos;
    const dialogRef = this.dialog.open(EditSiteDialogComponent, {
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      // unifica tipo em um array (a partir de row)
      const tipoArr = [];
      this.tipos.tipo.map( (el) => {
        if ( row.tipo[el.value] === true ) {
          tipoArr.push({ tipo: el.value });
        }
      });
      row.tipo = tipoArr;
      if ( !result ) { return; } // unfocus clicando fora do modal
      result.data.tipo = row.tipo;
      if ( result.what === 'delete' ) {
        this.siteService.delete(result.data.id).subscribe((data) => {
          this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        });
      } else if ( result.what === 'update' ) {
        // separa os anexos
        const toSave = Object.assign({}, result.data);
        const files = [];
        [ 'form_1_1_anexo', 'form_1_2_anexo', 'form_1_3_anexo', 'form_1_4_anexo', 'form_1_5_anexo', 'form_1_6_anexo', 'form_1_7_anexo', 'form_1_8_anexo', 'form_1_9_anexo', 'form_2_1_anexo', 'form_2_2_anexo', 'form_2_3_anexo', 'form_2_4_anexo', 'form_2_5_anexo', 'form_2_6_anexo', 'form_2_7_anexo', 'form_2_8_anexo', 'form_2_9_anexo', 'form_3_1_anexo', 'form_3_2_anexo', 'form_3_3_anexo', 'form_4_1_anexo', 'form_4_2_anexo', 'form_4_3_anexo', 'form_5_1_anexo', 'form_5_2_anexo', 'form_5_3_anexo', 'form_6_1_anexo', 'form_6_2_anexo', 'form_6_3_anexo', 'form_6_4_anexo', 'form_6_5_anexo', 'form_7_1_anexo', 'form_7_2_anexo', 'form_7_3_anexo' ].forEach( (el) => {
          if ( !!toSave[el] && !!toSave[el].name ) {
            const filename = el + '.' + toSave[el].name.split('.').pop();
            files.push({ file: toSave[el], field: filename });
          }
          delete toSave[el];
        });
        // envia pro back
        this.snackBar.open('Enviando... isto pode demorar um pouco', 'Ok', {duration: 2000});
        this.siteService.updateFiles(toSave.id, files).subscribe((dataUpdateFiles) => {
          this.siteService.update(result.data).subscribe((dataUpdate) => {
            this.snackBar.open('Item atualizado com sucesso', 'Ok', {duration: 4000});
            let old = this.data.find((el) => el.id === result.data.id);
            old = result.data;
          });
        });
      } else if ( result.what === 'new-token' ) {
        this.createToken( row.id );
      } else if ( result.what === 'approve' ) {
        this.approveSiteInvestigation( row );
      }
    });
  }

  openEmailDialog(siteid, lspid): Observable<any> {
    if ( this.role !== 1 ) {
      return of(false);
    }
    // mostra dialog
    const dialogRef = this.dialog.open(LSPEmailDialogComponent, {
      // mantem somente usuarios da LSP selecionada
      data: this.shippingcompanyusers.filter((u) => {
        return u.usc.idShippingCompany === lspid;
      }),
    });
    return dialogRef.afterClosed().map(result => {
      if ( result[0] ) {
        const selectedUsers = result[1].value;
        if ( selectedUsers !== null ) {
          return this.siteService.sendLSPEmail(siteid, selectedUsers).subscribe(resultSend => {
            // move on
          });
        }
      }
    });
  }
}

@Component({
  selector: 'app-dialog-edit-site-table-dialog',
  templateUrl: 'dialog-edit-site-table-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class EditSiteDialogComponent {
  SITE_ESTRUTURA = ESTRUTURA;
  tipos: any[] = [];
  google: any;
  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementId: 'printable', // the id of html/table element
  };
  constructor(
    public dialogRef: MatDialogRef<EditSiteDialogComponent>, public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, private exportAsService: ExportAsService) {
    this.tipos = data.tipos;
    delete data.tipos;
  }

  onClickPrint() {
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'report');
  }

  convertAddressToLatLng(obj: any) {
    const addr = obj.address;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        obj.lat = lat;
        obj.lng = lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  onClickBack(): void {
    this.dialogRef.close({ what: '', data: this.data });
  }
  onClickRemove(): void {
    this.dialogRef.close({ what: 'delete', data: this.data });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'update', data: this.data });
  }
  onClickNewToken(): void {
    this.dialogRef.close({ what: 'new-token', data: this.data });
  }
  onClickApprove(): void {
    this.dialogRef.close({ what: 'approve', data: this.data });
  }
  address2latlng(): void {
    this.convertAddressToLatLng(this.data);
  }
  latlng2address(): void {
    convertLatLngToAddress(this.data);
  }
}

@Component({
  selector: 'app-dialog-new-site-table-dialog',
  templateUrl: 'dialog-new-site-table-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class NewSiteDialogComponent {
  SITE_ESTRUTURA = ESTRUTURA;
  tipos: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<NewSiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tipos = data.tipos;
    delete data.tipos;
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'insert', data: this.data });
  }
  address2latlng(): void {
    // convertAddressToLatLng(this.data);
  }
  latlng2address(): void {
    convertLatLngToAddress(this.data);
  }
}

@Component({
  selector: 'app-dialog-new-site-token-dialog',
  templateUrl: 'dialog-new-site-token-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class NewTokenDialogComponent {
  site = <number> null;
  sites: any[] = [];
  shippingcompanys: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<NewTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.sites = data.sites;
    this.shippingcompanys = data.shippingcompanys;
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickToken(): void {
    this.dialogRef.close({ what: 'token', data: this.data });
  }
}

@Component({
  selector: 'app-dialog-token-dialog',
  templateUrl: 'dialog-token-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class TokenDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-dialog-approve-dialog',
  templateUrl: 'dialog-approve-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class ApproveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ApproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClickOk(): void {
    this.dialogRef.close({ what: 'approve' });
  }

}
@Component({
  selector: 'app-dialog-view-site-table-dialog',
  templateUrl: 'dialog-view-site-table-dialog.html',
  styleUrls: ['./site-investigation.component.scss']
})
export class ViewSiteDialogComponent {
  tipos: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<EditSiteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tipos = data.tipos;
    delete data.tipos;
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '', data: this.data });
  }
  onClickNewToken(): void {
    this.dialogRef.close({ what: 'new-token', data: this.data });
  }
}
