import { Component, OnInit, Inject } from '@angular/core';
import {MatIcon, MatDialog, MatTableDataSource, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent} from '@angular/material';
import {COPQMDR} from '../../../models/copq__mdr';
import {TranslateService} from '@ngx-translate/core';
import {PriceObservable} from '../../../services/price.service';
import {MdrService} from '../../../providers/mdr.service';
import {MdrObservable} from '../../../services/mdr.service';
import {ActivatedRoute} from '@angular/router';
import {MDR, MDR_STATUS} from '../../../models/mdr';
import {Transport} from '../../../models/transport';
import {Copq} from '../../../models/copq';
import {CostAvoidance} from '../../../models/costAvoidance';
import {UOC} from '../../../models/uoc';
import {UOCMDR} from '../../../models/uoc__mdr';
import {CostAvoidanceMDR} from '../../../models/costAvoidance__mdr';
import {Price} from '../logistic-order.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
import { User } from '../../../models/user';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent implements OnInit {
  statusContainer = false;
  mdr: MDR = new MDR;
  role: any;
  currentUser: any;
  sumCOPQ = 0;
  sumAvoid = 0;
  sumUOC = 0;
  sumTotal = 0;
  showAll = false;
  COPQs: COPQMDR[] = [];
  costAvoidances: CostAvoidanceMDR[] = [];
  UOCs: UOCMDR[] = [];
  uoc: UOC[] = [];
  copq: UOC[] = [];
  price: any;
  user: any;
  nfePrice: number;
  editing_freight = false;
  MDR_STATUS = MDR_STATUS;

  update = true;

  payment = {
    'tooltips': {
            'green': 'Pago Realizado',
            'yellow': 'Pago parcial realizado',
            'red': 'En espera de pago',
            'undefined': '',
    },
    'icons' : {
            'green':  'fa-check',
            'yellow':    'fa-exclamation-triangle',
            'red':    'fa-exclamation-triangle',
            'undefined': '',
    },
    'classes' : {
            'green':  'icon-green',
            'yellow': 'icon-yellow',
            'red':    'icon-red',
            'undefined': '',
    }
  };
  payField = {
    'color': 'undefined',
    'ov': []
  };

  constructor(public translate: TranslateService,
              public snackBar: MatSnackBar,
              public priceObservable: PriceObservable,
              public mdrService: MdrService,
              public mdrObservable: MdrObservable,
              public aRoute: ActivatedRoute,
              public excel: ExcelService,
              private dpipe: DatePipe,
              private cpipe: CurrencyPipe,
              public dialog: MatDialog) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;

  }

  ngOnInit() {
    this.priceObservable.currentPrice.subscribe((price) => {
      if (price.sc !== null) {
      }
      this.price = price;
      // console.log('Habemos Preço');
      // console.log(price);
    });
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.sumTotal = mdr.valorem + mdr.frete + mdr.additional;
      if (this.sumTotal > 100000) {
        this.sumTotal = 0;
      }
      // this.mdr.totalNF = Number(this.mdr.totalNF);
      this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
        this.sumCOPQ = 0;
        data.forEach((elem) => {
          elem.value = Number(elem.value) / 100;
          this.sumCOPQ += elem.value;
          elem.value = elem.value.toFixed(2);
        });
        this.sumCOPQ = this.sumCOPQ / 100;
        this.COPQs = data;
      });
      this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
        this.sumAvoid = 0;
        data.forEach((elem) => {
          this.sumAvoid += Number(elem.value);
        });
        this.costAvoidances = data;
      });
      this.mdrService.getUOC(this.mdr).subscribe((data) => {
        this.sumUOC = 0;
        data.forEach((elem) => {
          elem.value = Number(elem.value) / 100;
          if (elem.status === true) {
            this.sumUOC += elem.value;
          }
          elem.value = elem.value.toFixed(2);
        });
        this.sumUOC /= 100;
        this.UOCs = data;

        this.mdrService.getStatusPayment(this.mdr.id).subscribe( (payments) => {
          let frete_count = 0;
          let frete_paid = 0;
          // console.log({payments: payments});
          // itera sobre os pagamentos
          for (let i = 0 ; i < payments.length ; i++ ) {
            if (payments[i].origem === 'FRETE') {
              frete_count = frete_count + 1;
              if (payments[i].status === 'PAID') {
                frete_paid = frete_paid + 1;
              }
            } else { // pra origem='UOC'
              const uoc = this.UOCs.find((u) => (u.id === parseInt(payments[i].origemId, 10)));
              if (!uoc) {
                // pagamento nao está presente na lista de pagamentos
              } else if (payments[i].status === 'PAID') {
                uoc['color'] = 'green';
              } else if (payments[i].status === 'READY_TO_PAY') {
                uoc['color'] = 'red';
              } else {
                uoc['color'] = 'undefined';
              }
            }
          }
          if ( frete_count === frete_paid ) {
            this.payField.color = 'green';
          } else if (frete_paid === 0) {
            this.payField.color = 'red';
          } else {
            this.payField.color = 'yellow';
          }
        });
      });
      this.mdrService.getListUOC().subscribe((data) => {
        this.uoc = data;
      });
      this.mdrService.getListCoPQ().subscribe( (data) => {
        this.copq = data;
      });
    });

  }

  editValues() {
    this.editing_freight = true;
  }

  saveValues() {
    this.editing_freight = false;
    // save freight values
    this.savePrice();
    // save mdr.totalNF and mdr.totalKM
    this.saveMDR();
  }

  saveMDR() {
    this.mdrService.update(this.mdr, this.user).subscribe((response) => {
      // console.log(response);
    });
  }

  sendApprovalRequest() {
    const dialogRef = this.dialog.open(ChooseUsersDialogComponent, {
      width: '600px',
      data: {
        text: 'Deseja realmente aprovar o custo extra?',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        const users = [];
        response[1].forEach(element => {
          const user = new User();
          user.email = element.value;
          users.push(user);
        });
        this.mdrService.sendApproveEmail(this.mdr, users).subscribe(el => {
          this.snackBar.open('Email sent', 'Ok', {
            duration: 4000,
          });
        });
      }
    });

  }

  approveUOC(uoc) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: 'Deseja realmente aprovar o custo extra?',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeOccurrence(uoc, true).subscribe((elem) => {
          this.mdrService.getUOC(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo Aprovado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumUOC = 0;
            data.forEach((item) => {
              item.value = Number(item.value) / 100;
              if (item.status === true) {
                this.sumUOC += item.value;
              }
              item.value = item.value.toFixed(2);
            });
            this.sumUOC /= 100;
            this.UOCs = data;
          });
        });
      }
    });
  }

  removeUOC(uoc) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: 'Deseja realmente deletar o custo extra? Essa ação não pode ser desfeita.',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeOccurrence(uoc, false).subscribe((elem) => {
          this.mdrService.getUOC(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo deletado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumUOC = 0;
            data.forEach((item) => {
              item.value = Number(item.value) / 100;
              if (item.status === true) {
                this.sumUOC += item.value;
              }
              item.value = item.value.toFixed(2);
            });
            this.sumUOC /= 100;
            this.UOCs = data;
          });
        });
      }
    });
  }

  removeCOPQ(copq: COPQMDR) {
   // console.log(copq);
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeOccurrenceCOPQ(copq).subscribe((elem) => {
          this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo deletado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumCOPQ = 0;
            data.forEach((item) => {
              item.value = Number(item.value) / 100;
              this.sumCOPQ += item.value;
              item.value = item.value.toFixed(2);
            });
            this.sumCOPQ /= 100;
            this.COPQs = data;
          });
        });
      }
    });
  }

  sanitizeInputMdr(field: string, value: any, tag: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(/ /g, '');
    input = input.replace(/\./g, '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.mdr[field] = input;
    this.update = true;
  }

  sanitizeInputPrice(field: string, value: any, tag: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(' ', '');
    input = input.replace('.', '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.price[field] = input;
    this.update = true;
  }

  amountCOPQ() {
    this.sumCOPQ = 0;
    this.COPQs.forEach((elem) => {
      this.sumCOPQ += Number(elem.value);
    });
    return (this.sumCOPQ);
  }

  amountCA() {
    this.sumAvoid = 0;
    this.costAvoidances.forEach((elem) => {
      this.sumAvoid += Number(elem.value);
    });
    return this.sumAvoid;
  }

  saveConveyor() {
    this.mdrService.update(this.mdr, this.user).subscribe((response) => {
      // console.log(response);
    });
  }

  savePrice() {
   // console.log(this.price);
    this.mdrService.savePrice(this.price, this.mdr).subscribe( (data) => {
     // console.log('teste');
    });
  }

  getTotal() {
    return this.price.total;
  }

  saveUOC() {

  }

  saveCOPQ() {
    this.COPQs.forEach((copq) => {
      this.mdrService.createCoPQ(copq).subscribe((response) => {
        // console.log(response);
        this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
          this.sumCOPQ = 0;
          data.forEach((elem) => {
            this.sumCOPQ += Number(elem.value);
          });
          this.COPQs = data;
        });
      });
    });
  }

  getAllPriceWithKm() {
    // console.log(this.mdr);
    this.mdrService.getAllPriceR(this.mdr).subscribe( (data) => {
      // console.log(data);
      this.price = data;
      this.mdr.SAD = data.sapCd;
      this.mdrObservable.changeMDR(this.mdr);
    });
  }

  saveCA() {
    this.costAvoidances.forEach((costAv) => {
      this.mdrService.createCostAvoidance(costAv).subscribe((response) => {
        // console.log(response);
        this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
          this.sumAvoid = 0;
          data.forEach((elem) => {
            this.sumAvoid += Number(elem.value);
          });
          this.costAvoidances = data;
        });
      });
    });
  }
  amountUOC() {
    this.sumUOC = 0;
    this.UOCs.forEach( (elem) => {
      if (elem.status === true) {
        this.sumUOC += Number(elem.value) *  Number(elem['multiplicator']);
      }
    });
    return this.sumUOC;
  }
  amountTotal() {
    this.sumTotal = Number(this.mdr.valorem) + Number(this.mdr.valorem) + Number(this.mdr.additional);
    if (this.sumTotal > 100000) {
      this.sumTotal = 0;
    }
    return this.sumTotal;
  }
  addCOPQ() {
    this.COPQs.push(new COPQMDR);
    this.COPQs[this.COPQs.length - 1].idMDR = this.mdr.id;
  }
  addCA() {
    this.costAvoidances.push(new CostAvoidanceMDR);
    this.costAvoidances[this.costAvoidances.length - 1].idMDR = this.mdr.id;

  }
  toggleClass() {
    if (this.statusContainer === true) {
      this.statusContainer = false;
    } else {
      this.statusContainer = true;
    }
  }

  download() {
    const json = [];

    json.push({
      0: 'MDR ID',
      1: 'Veiculo',
      2: 'Data de coleta',
      3: 'Total NF subida',
      4: 'KM',
      5: 'Total',
      6: 'Tipo',
      7: 'Obs'
    });

    json.push({
      0: this.mdr.pickupID,
      1: this.mdr.vehicle,
      2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
      3: this.cpipe.transform(this.nfePrice, 'BRL'),
      4: this.mdr.totalKM,
      5: this.cpipe.transform(this.price.total, 'BRL'),
      6: 'Frete',
      7: ''
    });

    for (const u of this.UOCs) {
      json.push({
        0: this.mdr.pickupID,
        1: this.mdr.vehicle,
        2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
        3: this.cpipe.transform(this.nfePrice, 'BRL'),
        4: this.mdr.totalKM,
        5: this.cpipe.transform(u.value, 'BRL'),
        6: 'UOC',
        7: u.uoc
      });
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      skipHeader: true
    });
    const workbook: XLSX.WorkBook = { Sheets: { 'taxes': worksheet }, SheetNames: ['taxes'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.excel.saveAsExcelFile(excelBuffer, 'taxes');
  }
}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: 'confirm-delete-dialog.html',
  styleUrls: ['./../logistic-order.component.scss']
})
export class ConfirmDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-choose-users-dialog',
  templateUrl: 'choose-users-dialog.html',
  styleUrls: ['./../logistic-order.component.scss']
})
export class ChooseUsersDialogComponent {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    public dialogRef: MatDialogRef<ChooseUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
      data.ovs = [];
    // console.log(translate);
  }


  add(event: MatChipInputEvent): void {
    console.log(event.value);
    const input = event.input;
    const value = event.value.trim();

    if (value) {
      let duplicate = false;
      for (const ov of this.data.ovs) {
        if (ov.value === value) {
          duplicate = true;
          break;
        }
      }

      if (!duplicate) {
        this.data.ovs.push({ value: value, found: true });
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(ov): void {
    const index = this.data.ovs.indexOf(ov);

    if (index >= 0) {
      this.data.ovs.splice(index, 1);
    }
  }

  ngOnInit() {
  }
}
