export class Zrsdpac {
  public id: number;
  public ourOrderNo: string;
  public boxNo: string;
  public box: string;
  public type: string;
  public grossWeight: string;
  public height: string;
  public length: string;
  public width: string;
  public volume: string;
  public agreementId: string;
  public customerPoNumber: string;
  public customerPoItem: string;
  public site: string;
  public soNumber: string;
  public soItem: string;
  public delivery: string;
  public shipment: string;
  public loadingWeight: string;
  public weightUnit: string;
  public uolForDimensions: string;
  public soGroupId: string;
  public netWeight: string;
  public countryOfOrigin: string;
  public rfrStatus: string;
  public externalId1: string;
  public externalId2: string;
  public descriptionShip: string;
  public shipToPartyPoNum: string;
  public shipToPartyPoLte: string;
  public integrated: boolean;
  public insertedBy: number;
  public updatedBy: number;
  public insert: Date;
  public update: Date;
  public active: boolean;

  constructor() {
    this.id = null;
    this.ourOrderNo = null;
    this.boxNo = null;
    this.box = null;
    this.type = null;
    this.grossWeight = null;
    this.height = null;
    this.length = null;
    this.width = null;
    this.volume = null;
    this.agreementId = null;
    this.customerPoNumber = null;
    this.customerPoItem = null;
    this.site = null;
    this.soNumber = null;
    this.soItem = null;
    this.delivery = null;
    this.shipment = null;
    this.loadingWeight = null;
    this.weightUnit = null;
    this.uolForDimensions = null;
    this.soGroupId = null;
    this.netWeight = null;
    this.countryOfOrigin = null;
    this.rfrStatus = null;
    this.externalId1 = null;
    this.externalId2 = null;
    this.descriptionShip = null;
    this.shipToPartyPoNum = null;
    this.shipToPartyPoLte = null;
    this.integrated = null;
    this.insertedBy = null;
    this.updatedBy = null;
    this.insert = null;
    this.update = null;
    this.active = null;
  }
}
