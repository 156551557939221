import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import {DrService} from '../../providers/dr.service';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService} from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';

@Component({
  selector: 'app-finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.scss']
})

export class FinanceListComponent implements OnInit {
  paymentList: any[] = [];
  data: any[] = [];
  listPay: any[] = [];
  role: number;

  columns = [
    {value: 'status', name: 'FINANCE_LIST/table/status'},
    {value: 'client', name: 'FINANCE_LIST/table/client'},
    {value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp'},
    {value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr'},
    {value: 'prolog', name: 'FINANCE_LIST/table/prolog'},
    {value: 'costProject', name: 'FINANCE_LIST/table/costProject'},
    {value: 'purchaseOrder', name: 'FINANCE_LIST/table/costProject'},
    {value: 'invoice', name: 'invoice'},
    {value: 'migo', name: 'migo'},
    {value: 'actualSapCode', name: 'sapCode'},
    // {value: 'OV', name: 'FINANCE_LIST/table/ov'},
    {value: 'type', name: 'FINANCE_LIST/table/type'},
    {value: 'origem', name: 'FINANCE_LIST/table/origem'},
    {value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL'},
    {value: 'createdAt', name: 'FINANCE_LIST/table/date'}
  ];

  constructor(public dialog: MatDialog,
              private mdrService: MdrService,
              public excelService: ExcelService,
              private drService: DrService,
              public router: Router,
              public snackBar: MatSnackBar,
              public dpipe: DatePipe) {
    this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit() {
    this.mdrService.getAllPaymentList().subscribe( (data) => {
      // console.log(data);
      const keys = data.length ? Object.keys(data[0]) : [];
      this.data = data.map( (k) => {
        for (let i = 0 , l = keys.length; i < l; i++) {
          if ( k[keys[i]] === null || k[keys[i]] === undefined ) {
            k[keys[i]] = '';
          }
        }
      });
      data.forEach( (k) => {
        k.currency = k.value / 100;
        k.grossValue = k.currency - k.currency * 0.13;
        delete k.value;
        k.createdAt = new Date(k.createdAt);
        k.drDateScheduling = k.drDateScheduling ? new Date(k.drDateScheduling) : null;
      });
      this.data = data;
    });
  }

  insertPaymentFromFile(fileupload: any) {
   // console.log(fileupload);
    // console.log(fileupload);
    const input = fileupload.target;
    // console.log(fileupload);
    this.paymentList = [];
    for (let index = 0; index < input.files.length; index++) {
      this.excelService.importFromExcel(fileupload).subscribe( (data) => {
        data.forEach( (el, i, arr) => {
          const payment = {};
         // console.log(el);
          try {
            payment[''] = el.pickup_address;
            // console.log(drAux.status);

          } catch (ex) {
            // console.log('erro ao ler arquivo xlsx ',ex);
            this.snackBar.open('Erro ao ler arquivo', 'Ok', {duration: 4000});
          }

        });
      });
    }
  }

  updateFromFile(fileupload: any) {
    const input = fileupload.target;
    const error = false;
    const re = /(?:\.([^.]+))?$/;
    for (let index = 0; index < input.files.length; index++) {
      const ext = re.exec(input.files[index].name)[1];
      if ( ext === 'xlsx' ) {
        this.excelService.importFromExcel(input.files[index]).subscribe( (data) => {
          const checkRow = (row) => {
            // status é válido
            if ( 'status' in row
            && !Object.values(PAYMENT_STATUS).includes(row['status']) ) {
              this.snackBar.open(`Linha ${row.__rowNum__} contém status inválido`, 'Ok', {duration: 2000});
              return false;
            }
            // campos necessários estão presentes
            if ( !('origemId' in row)
            || !('invoice' in row)
            || !('OV' in row) ) {
              this.snackBar.open(`Linha ${row.__rowNum__} não contém campos obrigatórios [origemId,invoice,OV]`, 'Ok', {duration: 2000});
              return false;
            }
            return true;
          };
          // se todas as linhas recebidas são válidas
          if ( data.every(checkRow) ) {
            // atualiza as linhas
            const total = data.length;
            data.forEach( (row, i) => {
              this.mdrService.updatePayment(row).subscribe((response) => {
                this.snackBar.open('PO (' + i + '/' + total + ') atualizado', 'OK', { duration: 1000 });
                // console.log(i,'/',total);
              }, (er) => {
                this.snackBar.open('PO (' + i + '/' + total + ') falhou atualização', 'OK', { duration: 1000 });
                // console.error(i,'/',total);
              });
            });
            this.ngOnInit();
          } else {
            this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
          }
        });
      }
    }
  }

  goToMDR(row, that) {
    if ( row.mdrId !== 0 ) {
      that.router.navigate(['logistic-order/' + row.mdrId]);
    } else {
      that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
    }
  }

  addList(listPayments) {
    this.listPay = listPayments;
  }

  payNF() {
    this.mdrService.payNF(this.listPay).subscribe( (response) => {
      this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
    });
  }

  remove() {
    let removed = 0;
    const total = this.listPay.length;
    this.listPay.forEach( payment => {
      this.mdrService.removePayment(payment.id).subscribe((response) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
        const paymentI = this.data.findIndex(e => e.id === payment.id);
        if (paymentI >= 0) {
          this.data.splice(paymentI, 1);
        }
      }, (error) => {
        removed++;
        this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
      });
    });
  }

}
