export class OV {
  public id: number;
  public sDDocument: string;
  public salesDocument: string;
  public salesOrganization: string;
  public salesOffileKam: string;
  public salesDocumentType: string;
  public customerPurchaseOrderNumber: string;
  public nameOfOrderer: string;
  public customerName: string;
  public customerCity: string;
  public customerAddress: string;
  public shipToPartyPurchaseOrderNumber: string;
  public assignmentInformation: string;
  public salesSequence: string;
  public higherLevelItemInBill: string;
  public materialCode: string;
  public materialDescription: string;
  public salesOrderQuantity: string;
  public conditionValue: string;
  public reasonForRejection: string;
  public billingDocument: string;
  public referenceNoNf: string;
  public referenceDoc: string;
  public itemNomeText: string;
  public integrated: boolean;
  public integratedDate: Date;
  public insertedBy: number;
  public updatedBy: number;
  public insertDate: Date;
  public updateDate: Date;
  public active: boolean;

    constructor() {
      this.id = null;
      this.sDDocument = null;
      this.salesDocument = null;
      this.salesOrganization = null;
      this.salesOffileKam = null;
      this.salesDocumentType = null;
      this.customerPurchaseOrderNumber = null;
      this.nameOfOrderer = null;
      this.customerName = null;
      this.customerCity = null;
      this.customerAddress = null;
      this.shipToPartyPurchaseOrderNumber = null;
      this.assignmentInformation = null;
      this.salesSequence = null;
      this.higherLevelItemInBill = null;
      this.materialCode = null;
      this.materialDescription = null;
      this.salesOrderQuantity = null;
      this.conditionValue = null;
      this.reasonForRejection = null;
      this.billingDocument = null;
      this.referenceNoNf = null;
      this.referenceDoc = null;
      this.itemNomeText = null;
      this.integrated = null;
      this.integratedDate = null;
      this.insertedBy = null;
      this.updatedBy = null;
      this.insertDate = null;
      this.updateDate = null;
      this.active = null;
    }
}
