import { OnInit, EventEmitter } from '@angular/core';
import { UploadFileService } from '../../providers/upload.service';
import { HttpEventType } from '@angular/common/http';
var UploadComponent = /** @class */ (function () {
    function UploadComponent(uploadService) {
        this.uploadService = uploadService;
        this.uploadMassive = null;
        this.messageEvent = new EventEmitter();
        this.currentFileUpload = [];
        this.progress = { percentage: 0 };
        this.fileInputValue = '';
        this.sendStatus = false;
    }
    UploadComponent.prototype.ngOnInit = function () { };
    UploadComponent.prototype.selectFile = function (event) {
        this.selectedFiles = event.target.files;
        for (var k = 0; k < this.selectedFiles.length; k++) {
            this.currentFileUpload.push(this.selectedFiles.item(k));
        }
        if (this.currentFileUpload.length > 1) {
            this.uploadMulti();
        }
        else {
            this.upload();
        }
    };
    UploadComponent.prototype.upload = function () {
        var _this = this;
        if (this.uploadMassive !== null) {
            // console.log(this.uploadMassive);
            this.uploadMassive.forEach(function (elem) {
                // console.log(elem);
                _this.sendStatus = false;
                _this.progress.percentage = 0;
                _this.uploadService.pushFileToStorage(_this.currentFileUpload, elem.id + '', elem.entity).subscribe(function (event) {
                    if (HttpEventType.UploadProgress === event.type) {
                        _this.progress.percentage = Math.round(100 * event.loaded / event.total);
                    }
                    else {
                        // console.log('File is completely uploaded!');
                        // this.currentFileUpload = [];
                        _this.fileInputValue = '';
                        _this.messageEvent.emit('ok');
                        _this.sendStatus = true;
                    }
                });
            });
            this.selectedFiles = undefined;
        }
        else {
            this.sendStatus = false;
            this.progress.percentage = 0;
            this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(function (event) {
                if (HttpEventType.UploadProgress === event.type) {
                    _this.progress.percentage = Math.round(100 * event.loaded / event.total);
                }
                else {
                    // console.log('File is completely uploaded!');
                    _this.currentFileUpload = [];
                    _this.fileInputValue = '';
                    _this.messageEvent.emit('ok');
                    _this.sendStatus = true;
                }
            });
            this.selectedFiles = undefined;
        }
    };
    UploadComponent.prototype.uploadMulti = function () {
        var _this = this;
        this.sendStatus = false;
        this.progress.percentage = 0;
        this.uploadService.pushFileToStorage(this.currentFileUpload, this.id + '', this.entity).subscribe(function (event) {
            if (HttpEventType.UploadProgress === event.type) {
                _this.progress.percentage = Math.round(100 * event.loaded / event.total);
            }
            else if (_this.progress.percentage === 100 && _this.sendStatus === false) {
                // console.log('File is completely uploaded!');
                _this.currentFileUpload = [];
                _this.fileInputValue = '';
                _this.messageEvent.emit('ok');
                _this.sendStatus = true;
            }
        });
        this.selectedFiles = undefined;
    };
    return UploadComponent;
}());
export { UploadComponent };
