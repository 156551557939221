import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var ReportService = /** @class */ (function () {
    function ReportService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    ReportService.prototype.getOperation = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/operation/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getLSP = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/LSP/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getStakeholders = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getStakeholdersByDR = function (drs) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/stakeholders_dr/[' + drs + ']', {
            headers: header,
        });
    };
    ReportService.prototype.getPerfLSP = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/perfLSP/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getLSPKPI = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/LSPKPI/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getBattery = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/battery/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getCMDR = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cmdr/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getCOV = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cov/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getPODDP = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/poddp/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getHub = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/hub/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getDPUnion = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/dpunion/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getCockpit = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/cockpit/' + start + '/' + end, {
            headers: header,
        });
    };
    ReportService.prototype.getUOC = function (start, end) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'report/uoc/' + start + '/' + end, {
            headers: header,
        });
    };
    return ReportService;
}());
export { ReportService };
