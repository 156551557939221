import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-forgot-mobile',
  templateUrl: './forgot-password-mobile.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotMobileComponent implements OnInit {
  username: any;
  email: any;
  password = '';
  loading = false;
  captcha = false;
  error = '';
  confimPassword: string;

  constructor(public translate: TranslateService, public auth: AuthService, public router: Router, private activatedRoute: Router, public snackBar: MatSnackBar) { }

  resolved(captchaResponse: string) {
    // this.auth.verifyCaptchaToken(captchaResponse).subscribe(result => {
    //   this.captcha =result;
    //   //console.log( this.captcha);
    // });
    this.captcha = true;
  }

  ngOnInit() {
    const token = this.activatedRoute['currentUrlTree'].queryParams.token;
    this.auth.validateForgotPasswordMobileToken(token)
      .subscribe(result => {
        if (result === false) {
          // login failed
          this.error = 'Username or password is incorrect';
          this.loading = false;
          return false;
        } else {
          const data1 =  Date.parse(result['user'].createdAt);
          const data2 =  Date.parse(result['user'].dateNow);
          if (data2 - data1 >= 8640000) {
            this.snackBar.open('Token Expirado', 'Fechar', {
              duration: 2000,
            });
            this.router.navigate(['login']);
          } else {
            this.email = result['user'].email;
          }
        }
      });
  }
  sendPassword() {
    if (this.captcha === false) {
      // login failed
      this.snackBar.open('Captcha não verificado', 'Fechar', {
        duration: 2000,
      });
      this.error = 'Username or password is incorrect';
      this.loading = false;
      return;
    } else if (this.password.length >= 8) {
      if (this.password === this.confimPassword) {
        this.loading = true;
        // console.log(this.password)
        this.auth.changeForgotPasswordMobile(this.email, this.password)
          .subscribe(result => {
            if (result) {
              // login failed
              this.snackBar.open('Senha alterada para usuário mobile', 'Fechar', {
                duration: 2000,
              });
              this.router.navigate(['login']);
            } else {
              this.snackBar.open('Erro na alteração de senha', 'Fechar', {
                duration: 2000,
              });
            }
          });
      } else {
        this.snackBar.open('Senhas diferentes', 'Fechar', {
          duration: 2000,
        });
      }
    } else {
        this.snackBar.open('Senha deve ter pelo menos 8 caracteres', 'Fechar', {
          duration: 2000,
        });
    }
  }

}
