import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { PipesModule } from './misc/pipes/pipes.module';
import { MaterialModule } from './material.module';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRippleModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';

import {
  MatNativeDateModule,
  MatTableDataSource,
  MAT_DATE_LOCALE,
} from '@angular/material';
import {MatChipInputEvent} from '@angular/material';
import {MatChipsModule} from '@angular/material/chips';

import {CurrencyPipe, DatePipe, registerLocaleData} from '@angular/common';
// import { AddressPipe } from './misc/parse-address/address.pipe';
// import { MdrIdPipe } from './misc/mdr-id/mdr.pipe';
import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ForgotComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotMobileComponent } from './pages/forgot-password/forgot-password-mobile.component';
import { RecaptchaModule, RecaptchaLoaderService } from 'ng-recaptcha';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './providers/loading.interceptor';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  DeliveryRequestComponent
  } from './pages/delivery-request/delivery-request.component';
import {AgmCoreModule, MarkerManager} from '@agm/core';
import { DeliveryOptimizerComponent, TypeMdrDialogComponent } from './pages/delivery-optimizer/delivery-optimizer.component';
import { HeaderComponent } from './pages/header/header.component';
import { SideNavService } from './services/sidenav.service';
import {MdrObservable} from './services/mdr.service';
import { MenuComponent } from './pages/menu/menu.component';
import { RoleGuardService } from './providers/role-guard.service';
import { AuthService } from './providers/auth.service';
import { DeliveryOrderComponent } from './pages/delivery-order/delivery-order.component';
import {
  AcceptMdrReproveDialogComponent, LSPEmailDialogComponent, CreateTokenDialogComponent,
  AcceptMdrReservationDialogComponent, DeliveredCertifiedDialogComponent, AcceptMdrReturnDialogComponent,
} from './pages/logistic-order/logistic-order.component';
import { AcceptOccurrenceDialogComponent, EditOccurrenceDialogComponent} from './pages/logistic-order/occurrence/occurrence.component';
import { ChatComponent} from './pages/chat/chat.component';
import { SocketService} from './providers/socket.service';
import { ShippingCompanyAdminComponent } from './pages/shipping-company-admin/shipping-company-admin.component';
import { OVService} from './providers/ov.service';
import { DrService} from './providers/dr.service';
import { ERBService } from './providers/erb.service';
import { NgxMaskModule} from 'ngx-mask';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MdrService} from './providers/mdr.service';
import { NewMdrRequestComponent } from './pages/new-mdr-request/new-mdr-request.component';
import { MdrManagementComponent } from './pages/mdr-management/mdr-management.component';
import { AgmDirectionModule } from 'agm-direction';
import { OccurrenceListComponent } from './pages/occurrence-list/occurrence-list.component';
import { FinanceListComponent } from './pages/finance-list/finance-list.component';
import { CockpitModule } from './pages/cockpit/cockpit.module';
import { TowerControlModule } from './pages/tower-control/tower-control.module';
import { DrBookingComponent } from './pages/dr-booking/dr-booking.component';
import { DrValidationComponent, DialogDrValidationComponent } from './pages/dr-booking/dr-validation/dr-validation.component';
import { LogisticOrderModule } from './pages/logistic-order/logistic-order.module';
import {ChatService} from './providers/chat.service';
import { DriverComponent } from './pages/logistic-order/driver/driver.component';
import { ReportService } from './providers/report.service';
import { AnnexComponent } from './pages/logistic-order/annex/annex.component';
import { UocComponent } from './pages/logistic-order/uoc/uoc.component';
import { CopqComponent } from './pages/logistic-order/copq/copq.component';
import { CostAvoidanceComponent } from './pages/logistic-order/cost-avoidance/cost-avoidance.component';
import {PriceObservable} from './services/price.service';
import {DrObservable} from './services/dr.service';
import {UOCObservable} from './services/uoc.service';
import {InsertNfeComponent} from './pages/insert-nfe/insert-nfe.component';
import {BulkNfeComponent} from './pages/bulk-nfe-upload/bulk-nfe-upload.component';
import {ClientUserComponent} from './pages/client-user/client-user.component';
import {ClientUserService} from './providers/client-user.service';
import {NfeService} from './providers/nfe.service';
import {UploadFileService} from './providers/upload.service';
import { LightboxModule } from 'ngx-lightbox';
import localept from '@angular/common/locales/pt';
import { NewDeliveryRequestComponent,  OverviewExampleDialogComponent } from './pages/new-delivery-request/new-delivery-request.component';
import { TableModule } from './misc/table/table.module';
import { MdrLspComponent } from './pages/mdr-lsp/mdr-lsp.component';
import { PriceMakerComponent } from './pages/price-maker/price-maker.component';
import { PriceMakerTableComponent,
         EditPriceMakerTableDialogComponent,
         NewPriceMakerTableDialogComponent } from './pages/price-maker/table/price-maker-table.component';
import { PriceMakerService } from './providers/price-maker.service';
import { SiteService } from './providers/site.service';
import {NotificationService} from './providers/notification.service';
import { MdrMapComponent } from './pages/mdr-map/mdr-map.component';
import {ExcelService} from './misc/export-xlsx/export-xlsx';
registerLocaleData(localept, 'pt');
import {HubService} from './providers/hub.service';
import {HubComponent} from './pages/hub/hub.component';
import {AddHubComponent} from './pages/add-hub/add-hub.component';
import {MdrOptimizerComponent} from './pages/mdr-optimizer/mdr-optimizer.component';
import {InsertDataComponent} from './pages/insert-data/insert-data.component';
import {ReportsComponent} from './pages/reports/reports.component';
import {PapaParseModule} from 'ngx-papaparse';
import { ExportAsModule } from 'ngx-export-as';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {DeleteUserDialogComponent, EditUserDialogComponent, LspUserAdminComponent} from './pages/lsp-user-admin/lsp-user-admin.component';
import { SiteInvestigationComponent,
         EditSiteDialogComponent,
         ViewSiteDialogComponent,
         NewSiteDialogComponent,
         NewTokenDialogComponent,
         TokenDialogComponent,
         ApproveDialogComponent } from './pages/site-investigation/site-investigation.component';
import { SiteInvestigationWithTokenComponent,
         RestoreDataDialogComponent,
         StoreDataDialogComponent} from './pages/site-investigation-with-token/site-investigation-with-token.component';
import { FormUploadComponent } from './misc/upload/form-upload.component';
import { WithoutLoginModule } from './pages/without-login/without-login.module';
import { SiteInvestigationReportComponent } from './pages/site-investigation-report/site-investigation-report.component';
import { DocsCntrlComponent } from './pages/docs-cntrl/docs-cntrl.component';
import { ImageLightboxModule } from './misc/image-lightbox/image-lightbox.module';
import { OvsearchComponent } from './pages/ovsearch/ovsearch.component';
import { TermResultComponent } from './pages/ovsearch/termresults/termresults.component';
import { VehicleCalculatorComponent } from './pages/vehicle-calculator/vehicle-calculator.component';
import { CreateOccurrenceStatusDialogComponent } from './pages/tower-control/tower-control.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardService } from './providers/dashboard.service';
import { GraphCardComponent } from './components/graph-card/graph-card.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

const appRoutes: Routes = [
  { path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'forgot-mobile', component: ForgotMobileComponent },
  { path: 'dr-booking', component: DrBookingComponent, canActivate: [RoleGuardService] },
  { path: 'dr-validation/:id', component: DrValidationComponent, canActivate: [RoleGuardService] },
  { path: 'delivery-requests', component: DeliveryRequestComponent, canActivate: [RoleGuardService]},
  { path: 'new-delivery-request', component: NewDeliveryRequestComponent, canActivate: [RoleGuardService]},
  { path: 'delivery-optimizer', component: DeliveryOptimizerComponent, canActivate: [RoleGuardService]},
  { path: 'list-mdrs', component: DeliveryOrderComponent, canActivate: [RoleGuardService]},
  { path: 'mdr-lsp', component: MdrLspComponent, canActivate: [RoleGuardService]},
  { path: 'delivery-order', component: DeliveryOrderComponent, canActivate: [RoleGuardService]},
  { path: 'attachments', component: AnnexComponent, canActivate: [RoleGuardService]},
  { path: 'shipping-company-admin', component: ShippingCompanyAdminComponent, canActivate: [RoleGuardService]},
  { path: 'new-mdr-request', component: NewMdrRequestComponent, canActivate: [RoleGuardService]},
  { path: 'price-maker', component: PriceMakerComponent, canActivate: [RoleGuardService]},
  { path: 'price-maker/table', component: PriceMakerTableComponent, canActivate: [RoleGuardService]},
  { path: 'mdr-management', component: MdrManagementComponent, canActivate: [RoleGuardService]},
  { path: 'insert-nfe', component: InsertNfeComponent, canActivate: [RoleGuardService]},
  { path: 'bulk-nfe-upload', component: BulkNfeComponent, canActivate: [RoleGuardService]},
  { path: 'occurrence-list', component: OccurrenceListComponent, canActivate: [RoleGuardService]},
  { path: 'finance-list', component: FinanceListComponent, canActivate: [RoleGuardService]},
  { path: 'chat/:id/:type', component: ChatComponent, canActivate: [RoleGuardService]},
  { path: 'insert-nfe', component: InsertNfeComponent, canActivate: [RoleGuardService]},
  { path: 'client-tp', component: ClientUserComponent, canActivate: [RoleGuardService]},
  { path: 'mdr-map', component: MdrMapComponent, canActivate: [RoleGuardService]},
  { path: 'hub', component: HubComponent, canActivate: [RoleGuardService]},
  { path: 'add-hub', component: AddHubComponent, canActivate: [RoleGuardService]},
  { path: 'mdr-optimizer', component: MdrOptimizerComponent, canActivate: [RoleGuardService]},
  { path: 'insert-new-data', component: InsertDataComponent, canActivate: [RoleGuardService]},
  { path: 'add-hub/:id', component: AddHubComponent, canActivate: [RoleGuardService]},
  { path: 'lsp-user-admin', component: LspUserAdminComponent, canActivate: [RoleGuardService]},
  { path: 'site-investigation', component: SiteInvestigationComponent, canActivate: [RoleGuardService]},
  { path: 'site-report/:siteid', component: SiteInvestigationReportComponent, canActivate: [RoleGuardService] },
  { path: 'sheets', component: DocsCntrlComponent, canActivate: [RoleGuardService] },
  { path: 'site-investigation/:siteid/:token', component: SiteInvestigationWithTokenComponent, canDeactivate: [RoleGuardService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RoleGuardService] },
  { path: 'reports', component: ReportsComponent, canActivate: [RoleGuardService]},
  { path: 'vehicle-calculator', component: VehicleCalculatorComponent, canActivate: [RoleGuardService]},
  { path: 'ovsearch/:input', component: OvsearchComponent, canActivate: [RoleGuardService]},
  { path: 'card', component: GraphCardComponent, canActivate: [RoleGuardService] },
  { path: '**', component: PageNotFoundComponent }
];

import { CompositeBarsChartComponent } from './components/graph-card/composite-bars-chart/composite-bars-chart.component';
import { PieChartComponent } from './components/graph-card/pie-chart/pie-chart.component';
import { SpeedometerChartComponent } from './components/graph-card/speedometer-chart/speedometer-chart.component';
import { BasicBarChartComponent } from './components/graph-card/basic-bar-chart/basic-bar-chart.component';
import { WordcloudChartComponent } from './components/graph-card/wordcloud-chart/wordcloud-chart.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    DrBookingComponent,
    DrValidationComponent,
    DialogDrValidationComponent,
    HeaderComponent,
    MenuComponent,
    PageNotFoundComponent,
    AddHubComponent,
    DeliveryRequestComponent,
    LspUserAdminComponent,
    ShippingCompanyAdminComponent,
    DocsCntrlComponent,
    DeliveryOptimizerComponent,
    AnnexComponent,
    DeliveryOrderComponent,
    LoginComponent,
    MdrOptimizerComponent,
    LogoutComponent,
    ClientUserComponent,
    InsertNfeComponent,
    BulkNfeComponent,
    OverviewExampleDialogComponent,
    CreateOccurrenceStatusDialogComponent,
    AcceptOccurrenceDialogComponent,
    AcceptMdrReservationDialogComponent,
    AcceptMdrReturnDialogComponent,
    DeliveredCertifiedDialogComponent,
    AcceptMdrReproveDialogComponent,
    EditPriceMakerTableDialogComponent,
    NewPriceMakerTableDialogComponent,
    TypeMdrDialogComponent,
    EditSiteDialogComponent,
    ViewSiteDialogComponent,
    NewSiteDialogComponent,
    NewTokenDialogComponent,
    TokenDialogComponent,
    RestoreDataDialogComponent,
    StoreDataDialogComponent,
    ApproveDialogComponent,
    LSPEmailDialogComponent,
    CreateTokenDialogComponent,
    ChatComponent,
    NewMdrRequestComponent,
    MdrManagementComponent,
    OccurrenceListComponent,
    FinanceListComponent,
    DriverComponent,
    AnnexComponent,
    UocComponent,
    CopqComponent,
    CostAvoidanceComponent,
    EditOccurrenceDialogComponent,
    ForgotComponent,
    ForgotMobileComponent,
    HubComponent,
    EditUserDialogComponent,
    DeleteUserDialogComponent,
    NewDeliveryRequestComponent,
    MdrLspComponent,
    PriceMakerComponent,
    PriceMakerTableComponent,
    DashboardComponent,
    GraphCardComponent,
    InsertDataComponent,
    // AddressPipe,
    // MdrIdPipe,
    MdrMapComponent,
    SiteInvestigationComponent,
    SiteInvestigationReportComponent,
    SiteInvestigationWithTokenComponent,
    ReportsComponent,
    FormUploadComponent,
    DrValidationComponent,
    OvsearchComponent,
    TermResultComponent,
    VehicleCalculatorComponent,
    CompositeBarsChartComponent,
    SpeedometerChartComponent,
    BasicBarChartComponent,
    PieChartComponent,
    WordcloudChartComponent,
  ],
  imports: [
    MaterialModule,
    PipesModule,
    TableModule,
    ImageLightboxModule,
    LightboxModule,
    BrowserModule,
    HttpClientModule, // must be included after BrowserModule
    PapaParseModule,
    RecaptchaModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ExportAsModule,
    MatToolbarModule,
    MatChipsModule,
    MatTooltipModule,
    MatPaginatorModule,
    Ng4LoadingSpinnerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbtxRrJstl_v2wIpqaJK_FRcTN-UsI5ns',
      libraries: ['places'],
      apiVersion: '4',
      language: 'pt'
    }),
    AgmDirectionModule,
    NgxMaskModule.forRoot({
      // patterns: {
      //   '9': {
      //     pattern: new RegExp('[0-9]'),
      //     optional: false
      //   },
      //   '8': {
      //     pattern: new RegExp('[0-9]'),
      //     optional: true
      //   }
      // }
    }),
    MatIconModule,
    MatBadgeModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CockpitModule,
    TowerControlModule,
    LogisticOrderModule,
    WithoutLoginModule,
    FlexLayoutModule,
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  entryComponents: [
    DialogDrValidationComponent,
    PieChartComponent,
    WordcloudChartComponent,
    SpeedometerChartComponent,
    TypeMdrDialogComponent,
    EditPriceMakerTableDialogComponent,
    NewPriceMakerTableDialogComponent,
    EditOccurrenceDialogComponent,
    ViewSiteDialogComponent,
    EditSiteDialogComponent,
    NewSiteDialogComponent,
    NewTokenDialogComponent,
    TokenDialogComponent,
    RestoreDataDialogComponent,
    CreateOccurrenceStatusDialogComponent,
    StoreDataDialogComponent,
    ApproveDialogComponent,
    OverviewExampleDialogComponent,
    AcceptOccurrenceDialogComponent,
    AcceptMdrReservationDialogComponent,
    AcceptMdrReturnDialogComponent,
    DeliveredCertifiedDialogComponent,
    LSPEmailDialogComponent,
    CreateTokenDialogComponent,
    EditUserDialogComponent,
    DeleteUserDialogComponent,
    CompositeBarsChartComponent,
    SpeedometerChartComponent,
    BasicBarChartComponent,
    AcceptMdrReproveDialogComponent],
  providers: [
    RecaptchaLoaderService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'pt' },
    { provide: LOCALE_ID, useValue: 'pt' },
    CookieService,
    SideNavService,
    MdrObservable,
    PriceObservable,
    DrObservable,
    UOCObservable,
    ExcelService,
    RoleGuardService,
    AuthService,
    PriceMakerService,
    SiteService,
    ClientUserService,
    HubService,
    SocketService,
    NfeService,
    ChatService,
    OVService,
    UploadFileService,
    DrService,
    ERBService,
    MdrService,
    NotificationService,
    ReportService,
    DatePipe,
    DashboardService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true}
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
