import {Component, OnInit, Input, ViewChild, Inject} from '@angular/core';
import {DR} from '../../../models/dr';
import {MatTableDataSource, MatSort, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatPaginator} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {DrService} from '../../../providers/dr.service';
import {DrObservable} from '../../../services/dr.service';
import {TranslateService} from '@ngx-translate/core';
import {Occurrence} from '../../../models/occurrence';
import {HUB} from '../../../models/hub';
import {HubService} from '../../../providers/hub.service';
import {HUBDR} from '../../../models/hub__dr';
import {OVService} from '../../../providers/ov.service';
import {OV} from '../../../models/ov';
import { MDR } from '../../../models/mdr';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import { ESTRUTURA } from '../../../providers/site.service';
import {ClientUserService} from '../../../providers/client-user.service';
import * as FileSaver from 'file-saver';

// table with expandable rows:
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DialogDrValidationComponent } from '../../dr-booking/dr-validation/dr-validation.component';

@Component({
  selector: 'app-delivery-route',
  templateUrl: './delivery-route.component.html',
  styleUrls: ['./delivery-route.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeliveryRouteComponent implements OnInit {
  data: DR[] = [];
  @Input() mdr: MDR;
  dataSource = new MatTableDataSource<DR>(this.data);
  columnsToDisplay = ['deliveryAddress', 'site', 'status', 'datePickupScheduling', 'dateScheduling', 'client', 'arrivalTime', 'actualDelivery'];

  @ViewChild(MatSort) sort: MatSort;
  currentUser: any;
  role: any;
  siteNomes = {
    'form_6_1': 'Grua',
    'form_6_2': 'Correia',
    'form_6_3': 'Veículos Esp.',
    'form_6_4': 'Operação Manual',
    'form_6_5': 'Guindates'
  };
  clientList = [];
  hubList = [];

  constructor(public aRoute: ActivatedRoute,
              private drService: DrService,
              public hubService: HubService,
              public clientService: ClientUserService,
              public snackBar: MatSnackBar,
              public drObservable: DrObservable,
              public dialog: MatDialog,
              public ovService: OVService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.clientService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
    this.hubService.getAll().subscribe( (hubs) => {
      this.hubList = hubs;
    });
  }

  ngOnInit() {
    this.drObservable.currentDR.subscribe(dr => {
      this.data = dr;
      // console.log(this.data);
      for (let i = 0; i < this.data.length; i++) { // TODO optimize this n3 disgusting function
        const ovAux: OV[] = [];
        let str = '';
        if (this.data[i].ovList !== undefined) {
          for (let j = 0; j < this.data[i].ovList.length; j++) {
            let flag = 0;
            for (let k = 0; k < ovAux.length; k++) {
              if (ovAux[k].salesDocument === this.data[i].ovList[j].salesDocument) {
                flag = 1;
                break;
              }
            }
            if (flag === 0) {
              ovAux.push(this.data[i].ovList[j]);
              if (j !== 0) {
                str += ', ';
              }
              str += this.data[i].ovList[j].salesDocument;
            }
          }
          this.data[i].ovList = ovAux;
          this.data[i].obs = str;
          console.log(this.data[i]['deliveryDateShadow']);
          // this.data[i]['deliveryDateShadow'] = if( new Date(this.data[i]['deliveryDateShadow']);
        }

        // detalhes do site.form_6_?
        this.data[i]['site_form6'] = [];
        if ( this.data[i]['site_form'] ) {
          const keys = Object.keys(this.data[i]['site_form']).filter(e => e.indexOf('form_6_') === 0).filter(e => e.indexOf('_obs') === -1);
          for (let j = 0, len = keys.length ; j < len ; j++) {
            // console.log(this.data[i]['site_form'][keys[j]]);
            if (this.data[i]['site_form'][keys[j]] === ESTRUTURA.EX) {
              this.data[i]['site_form6'].push(this.siteNomes[keys[j]]);
            }
          }
        }
      }
      this.dataSource = new MatTableDataSource<DR>(this.data);
    });
  }

  setUserArrivalTime(dr: DR) {
    dr.userArrivalTime = new Date();

    this.drService.update(dr).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
      } else {
        dr.userArrivalTime = null;
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  openEditDialog(dr: DR): void {
    const dialogRef = this.dialog.open(EditDrDialogComponent, {
      data: {
        role: this.role,
        dr: dr,
        clientList: this.clientList,
        hubList: this.hubList
      }
    });

    dialogRef.afterClosed().subscribe( (response) => {
    });
  }

  openViewDialog(dr: DR): void {

    const dialogRef = this.dialog.open(ViewInfoComponent, {
      width: '800px',
      data: {
        dr: dr,
        role: this.role
      },
    });

    dialogRef.afterClosed().subscribe( (response) => {
      // console.log(response);
    });
  }

  openHistoryDialog(dr: DR): void {
    this.drService.getHistory(dr.id).subscribe((history) => {
     // console.log(history);
     const hist = history.sort( (a, b) =>  a.quando - b.quando);
      const dialogRef = this.dialog.open(HistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: {history: hist}
      });

    });

  }

}

@Component({
  selector: 'app-edit-dr-dialog',
  templateUrl: 'edit-dr-dialog.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class EditDrDialogComponent implements OnInit {
  current: DR;
  hubList: HUB[];
  hubId: any;
  hubObs: any;
  clientList = [];
  statusList = ['InTransit',
                'READY_TO_PICKUP',
                'Replan - Retido',
                'Replan - Sefaz',
                'Replan - Pernoite',
                'Replan - Antecipação',
                'Replan - Postergação',
                'Waiting ASP',
                'HubReturn',
                'Canceled',
                'Delivered'];

  constructor(
    public dialogRef: MatDialogRef<EditDrDialogComponent>,
    public hubService: HubService,
    @Inject(MAT_DIALOG_DATA) public data,
    public translate: TranslateService,
    public drService: DrService,
    public snackBar: MatSnackBar
  ) {
    this.hubList = data.hubList;
    this.clientList = data.clientList;
  }

  ngOnInit() {
    // faz uma copia da DR para edição
    this.current = Object.assign({}, this.data.dr);
  }

  onYesClick() {
    // compara DR copiada para edição com DR anterior
    const checkChanges = (key) => {
      return ( this.current[key] !== this.data.dr[key] );
    };

    // se nao houve alguma modificação
    const keys = Object.keys(this.current);
    if ( !keys.some(checkChanges) ) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    if ( this.current.status === 'HubReturn') {
      const newHUBDR = new HUBDR();
      newHUBDR.hubID = this.hubId;
      newHUBDR.drID = this.data.dr.id;
      newHUBDR.when = new Date();
      newHUBDR.observation = this.hubObs;
      this.hubService.createHubDr(newHUBDR).subscribe( (elem) => {
        // console.log(elem);
      });
    }

    this.drService.update(this.current).subscribe( (response) => {
      if ( response === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        // copia valores pra DR anterior
        keys.forEach((key) => {
          this.data.dr[key] = this.current[key];
        });
        this.dialogRef.close();
      } else {
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-view-info',
  templateUrl: 'view-info.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class ViewInfoComponent implements OnInit {
  select: any;
  dateCollect: Date;
  hour: any;
  previous: any;
  hubList: HUB[];
  hubId: any;
  hubObs: any;
  role: number;
  listOVs = [];
  dataSource = new MatTableDataSource<any>(this.data);
  displayedColumns2 = ['salesOrderQuantity', 'materialCode', 'materialDescription', 'customerName', 'assignmentInformation', 'customerCity', 'customerAddress', 'salesOrganization', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  HUs: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditDrDialogComponent>, public hubService: HubService, public excelService: ExcelService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public drService: DrService, public snackBar: MatSnackBar) {
    // console.log("Received Data");
    // console.log(data);
    this.role = data.role;
  }

  ngOnInit() {
    // console.log(this.data.id);
    this.drService.getAllInfo(this.data.dr.id).subscribe((k) => {
      const list = [];
      k.forEach(ov => {
        const el = {};
        el['id'] = ov.id;
        el['material'] = ov.materialCode;
        el['materialDescription'] = ov.materialDescription;
        el['client'] = ov.customerName;
        el['prolog'] = ov.assignmentInformation;
        el['serial'] = ov.customerAddress;
        el['uoc'] = ov.customerCity;
        el['qtd'] = ov.salesOrderQuantity;
        el['obs'] = ov.salesOrganization;
        el['drId'] = ov.drId;
        list.push(el);
        this.listOVs.push(ov);
      });
      // this.listOVs.find;
      this.dataSource = new MatTableDataSource<any>(list);
      setTimeout(() => this.dataSource.paginator = this.paginator);
    });
  }
  addNewRow() {
    const row = {material: null, materialDescription: null, client: null, prolog: null, serial: null, uoc: null, qtd: null, salesOrganization: null};
    this.openDialog(row);
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(DialogDrValidationComponent, {
      data: {
        row: row,
        listOv: this.listOVs
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( !result ) { return; } // unfocus clicando fora do modal
      if ( result.what === 'approve' ) {
        // this.drService.getAllInfo(this.dr.id).subscribe((k) => {
        //   console.log(k);
        //   const list = [];
        //   k.forEach(ov => {
        //     const el = {};
        //     el['id'] = ov.id;
        //     el['material'] = ov.materialCode;
        //     el['materialDescription'] = ov.materialDescription;
        //     el['client'] = ov.customerName;
        //     el['prolog'] = ov.assignmentInformation;
        //     el['serial'] = ov.customerAddress;
        //     el['uoc'] = ov.customerCity;
        //     el['qtd'] = ov.salesOrderQuantity;
        //     list.push(el);
        //     this.listOVs.push(ov);
        //   });
        //   this.dataSource = new MatTableDataSource<any>(list);
        //   setTimeout(() => this.dataSource.paginator = this.paginator);
        // });
        // this.snackBar.open('Updated Correctly', 'Ok', {duration: 4000});
        this.ngOnInit();
      }
    });
  }

  downloadPDF() {
    this.drService.downloadPDF(this.data.dr.id).subscribe(data => {
      FileSaver.saveAs(data, `Report-Logistic_Order-${this.data.dr.id}.pdf`);
    });
  }

  downloadReport() {
    // const arr = [];
    // this.dataSource.data.forEach( (k) => {
    //   const elem = {};
    //   elem['materialCode'] = k.materialCode;
    //   elem['materialDescription'] = k.materialDescription;
    //   elem['salesOrderQuantity'] = k.salesOrderQuantity;
    //   elem['']
    // });
    // exporta
    this.drService.downloadPDFData(this.data.dr.id).subscribe(data => {
      // console.log(data);
      this.excelService.exportAsExcelFile(data, 'CFID-' + this.data.dr.site + '');
    });

  }

  onYesClick() {
    if ( this.previous.select === this.select
      && this.previous.dateCollect === this.dateCollect
      && this.previous.hour === this.hour
      && this.previous.deliveryAddress === this.data.dr.deliveryAddress) {
      this.snackBar.open('Nenhum valor foi alterado', 'Ok', {duration: 2000});
      this.dialogRef.close();
      return;
    }

    if ( this.select === 'HubReturn') {
      const newHUBDR = new HUBDR();
      newHUBDR.hubID = this.hubId;
      newHUBDR.drID = this.data.dr.id;
      newHUBDR.when = new Date();
      newHUBDR.observation = this.hubObs;
      this.hubService.createHubDr(newHUBDR).subscribe( (elem) => {
        // console.log(elem);
      });
    }

    // if (this.previous === 'HubReturn') {
    //
    // }

    this.data.dr.status = this.select;
    if (this.hour !== undefined && this.dateCollect !== undefined) {
      // console.log('cheguei!');
      const time = this.hour.split(':');
      this.data.dr.dateScheduling = new Date(this.dateCollect.setHours(parseInt(time[0], 0), parseInt(time[1], 0)));
    }
    // console.log(this.data);
    this.drService.update(this.data.dr).subscribe( (el) => {
      if ( el === true ) {
        this.snackBar.open('DR atualizada com sucesso', 'Ok', {duration: 2000});
        this.dialogRef.close();
      } else {
        this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', {duration: 2000});
      }
    });
  }


  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-history',
  templateUrl: 'history.html',
  styleUrls: ['./delivery-route.component.scss']
})
export class HistoryDialogComponent implements OnInit {
  history = [];
  columns = [
    {value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address'},
    {value: 'status', name: 'DELIVERY_ROUTE/status'},
    {value: 'quem', name: 'DELIVERY_ROUTE/by'},
    {value: 'quando', name: 'DELIVERY_ROUTE/on'},
    {value: 'dateScheduling', name: 'DELIVERY_ROUTE/date'}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.history = data.history;
  }
  ngOnInit() {

  }
}
