<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'NEW-DELIVERY-REQUEST/title' | translate }}</p>
  <p class="mx-4">{{ 'NEW-DELIVERY-REQUEST/sub-title' | translate }}</p>
  <div class="mx-4">
    <i class="fas fa-magic" style="display: inline-block;"></i>
    <input style="display: inline-block; width: calc(100% - 40px)" [(ngModel)]="paste_input" (paste)="fillFromClipboard($event)" matInput placeholder="{{ 'ATTACHMENTS/paste' | translate }}">
  </div>
</div>

<div class="colored-background pt-5">
  <mat-card *ngIf="!uploaded" class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <div class="col-md-12">
      <div class="row">
        <span class="title col-md-3 bold-text">{{ 'NEW-DELIVERY-REQUEST/text/import' | translate }}</span>
        <div class="title col-md-3 ">
          <input id="input-file-id"  #fileInput accept='xlsx' (change)='openFile($event)' class="none" multiple type="file"/>
          <label for="input-file-id" class="button-file">{{ 'NEW-DELIVERY-REQUEST/text/select-file' | translate }}</label>
        </div>
        Ou
        <div class="title col-md-3">
          <a href="../../../assets/extras/multiUpload.xlsx" download="">{{ 'NEW-DELIVERY-REQUEST/dowload_model' | translate }} <i matSuffix font class="fa fa-download"></i></a>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      Shipment<mat-slide-toggle [(ngModel)]="ovOrNf">{{ 'NEW-DELIVERY-REQUEST/text/nfe' | translate }}</mat-slide-toggle>
    </div>
    <mat-form-field *ngIf="ovOrNf" class="col-md-10">
      <input matInput placeholder="{{ 'NEW-DELIVERY-REQUEST/text/search1' | translate }}" [(ngModel)]="inputNFs" (keyup.enter)="searchNFe()">
      <i matSuffix font class="fa fa-search" (click)="searchNFe()"></i>
    </mat-form-field>
    <mat-form-field *ngIf="!ovOrNf" class="col-md-10">
      <input matInput placeholder="{{ 'NEW-DELIVERY-REQUEST/text/search2' | translate }}" [(ngModel)]="inputOVs" (keyup.enter)="searchOV()">
      <i matSuffix font class="fa fa-search" (click)="searchOV()"></i>
    </mat-form-field>
    <!-- fim import ou download -->

    <table mat-table #table class="col-md-12 p-0" [dataSource]="dataSource" matSort *ngIf="dataSource.data.length > 0">
      <ng-container matColumnDef="select">
        <th mat-header-cell [ngClass]="'customWidthClass'" *matHeaderCellDef>
          <mat-checkbox [color]="blue" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
          <th>
            <td mat-cell [ngClass]="'customWidthClass'" *matCellDef="let row">
              <mat-checkbox [color]="blue" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
      </ng-container>
      <ng-container matColumnDef="salesDocument">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/ov' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.salesDocument}} </td>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/client' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
      </ng-container>
      <ng-container matColumnDef="referenceNoNf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/nf' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.referenceNoNf}} </td>
      </ng-container>
      <ng-container matColumnDef="materialDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/equipment' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.materialDescription}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
    </table>
    <div class="w-100 mb-2" *ngIf="dataSource.data.length > 0">
      <button mat-raised-button class="w-100 primary-button" (click)="addNewOV()">{{'NEW-DELIVERY-REQUEST/text/add-ov' | translate}}</button>
    </div>

    <!-- fim busca de OV/Invoice -->
    <form #form="ngForm" style="width: 100%">
      <div class="col-md-12">
        <mat-form-field style="width: calc(100% - 40px);">
          <input name='address' matInput #search2 type="text"  autocapitalize="off" spellcheck="off"
                placeholder="{{ 'NEW-DELIVERY-REQUEST/text/delivery-address' | translate }}"
                id="deliveryAddress"
                [(ngModel)]="dr.deliveryAddress"
                required
                (change)="convertAddressToLatLng()">
        </mat-form-field>
        <span (click)="toggleMap()"><i class="fa fa-map"></i></span>
      </div>
      <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-6">
          <input matInput placeholder="{{ 'NEW-DELIVERY-REQUEST/text/lat' | translate }}"
            id="latitudeAddress"
            name='lat'
            [(ngModel)]="dr.lat"
            required
            (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput placeholder="{{ 'NEW-DELIVERY-REQUEST/text/lng' | translate }}"
            id="longitudeAddress"
            name='lng'
            [(ngModel)]="dr.lng"
            required
            (change)="convertLatLngToAddress()">
        </mat-form-field>
      </div>
      <div *ngIf="map_show" class="col-md-12">
        <agm-map [latitude]="dr.lat" [longitude]="dr.lng" [mapTypeControl]="true" [zoom]="15">
          <agm-marker [iconUrl]="red_marker" [latitude]="dr.lat" [longitude]="dr.lng"></agm-marker>
          <agm-marker *ngFor="let m of map_data" [iconUrl]="blue_marker" [latitude]="m.lat" [longitude]="m.lng"></agm-marker>
        </agm-map>
        <br>
      </div>
      <div class="col-md-12">
        <mat-checkbox class="col-md-6" name='pickupDifAddress' [(ngModel)]="dr.pickupDifAddress">
          {{ 'NEW-DELIVERY-REQUEST/text/collect-address' | translate }}</mat-checkbox>
        <mat-form-field *ngIf="dr.pickupDifAddress" class="col-md-6">
          <mat-select name='hub'  placeholder="HUB" [(ngModel)]="dr.pickupAddress"  >
            <mat-option *ngFor="let elem of hubList" [value]="elem.id" [matTooltip]="elem.address" matTooltipPosition="after">
              {{ elem.name }} - {{ elem.address }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="col-md-6">
        <input matInput name='receiverName' placeholder="{{ 'NEW-DELIVERY-REQUEST/text/name-receptor' | translate }}" [(ngModel)]="dr.receiverName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput name='receiverPhone' #tel mask="(00) 00009-0000" type="text" placeholder="{{ 'NEW-DELIVERY-REQUEST/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput name='supervisorName' placeholder="{{ 'NEW-DELIVERY-REQUEST/text/name-supervisor' | translate }}" [(ngModel)]="dr.supervisorName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput #tel name='supervisorPhone'  mask="(00) 00009-0000"  placeholder="{{ 'NEW-DELIVERY-REQUEST/text/cellphone-higher' | translate }}" [(ngModel)]="dr.supervisorPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput
          name="collect"
          [owlDateTimeTrigger]="dt"
          [owlDateTime]="dt"
          [placeholder]="'NEW-DELIVERY-REQUEST/text/datetime' | translate"
          [(ngModel)]="dr.dateScheduling" required>
        <owl-date-time #dt></owl-date-time>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput name='site' placeholder="{{ 'NEW-DELIVERY-REQUEST/text/site' | translate }}" [(ngModel)]="dr.site" (change)="checkSite()">
        <i class="fa" [ngClass]="site.color ? [ site.icons[site.color] , site.classes[site.color] ] : ''" matSuffix [matTooltip]="site.tooltips[site.color]"></i>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input name='infra' matInput placeholder="{{ 'NEW-DELIVERY-REQUEST/text/infra' | translate }}" [(ngModel)]="dr.infraType">
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>{{'NEW-DELIVERY-REQUEST/text/client' | translate}}</mat-label>
        <mat-select [(ngModel)]="dr.client" name="client" required>
          <mat-option *ngFor="let client of clientList" [value]="client">
            {{client}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <input matInput name='siteTeam' placeholder="{{ 'NEW-DELIVERY-REQUEST/text/site-team' | translate }}" [(ngModel)]="dr.siteTeam">
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <input matInput name="prolog" placeholder="{{ 'NEW-DELIVERY-REQUEST/text/prolog' | translate }}" [(ngModel)]="dr.prolog">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}" [(ngModel)]="dr.salesOrders" name="salesOrders">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput placeholder="{{ 'DR_BOOKING/text/unification-id' | translate }}" [(ngModel)]="dr.unificationId" name="unificationId">
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <textarea matInput name="obs" placeholder="{{ 'NEW-DELIVERY-REQUEST/text/obs' | translate }}" [(ngModel)]="dr.obs"></textarea>
      </mat-form-field>
    </form>
    <!-- fim preenchimento campos -->

    <div *ngIf="showSiteInfo" class="col-md-12 row">
      <div class="col-md-3">
        <p>Lat:</p>
        <p>{{siteSelected.lat}}</p>
      </div>
      <div class="col-md-3">
        <p>Lng:</p>
        <p>{{siteSelected.lng}}</p>
      </div>
      <div class="col-md-3">
        <p>Regional:</p>
        <p>{{siteSelected.regional}}</p>
      </div>
      <div class="col-md-3">
        <p>Cliente:</p>
        <p>{{siteSelected.operadora}}</p>
      </div>
      <div class="col-md-3">
        <p>Obs:</p>
        <p>{{siteSelected.obs}}</p>
      </div>
      <div class="col-md-3">
        <p>Operação:</p>
        <p>{{siteSelected.operation.join(", ")}}</p>
      </div>
      <div class="col-md-3">
        <p>Vistoria:</p>
        <p>{{siteSelected.vistoriadatahora |  date: "dd/MM/yy, h:mm a"}}</p>
      </div>
      <div class="col-md-3">
        <p>Site ID:</p>
        <p>{{siteSelected.code}}</p>
      </div>
      <div class="col-md-3">
        <p>LSP:</p>
        <p>{{siteSelected.shippingcompany ? siteSelected.shippingcompany.name : 'Sem LSP associada'}}</p>
      </div>
      <div class="col-md-3">
        <p>Laudo:</p>
        <p>
          <a [routerLink]="'/site-report/'+siteSelected.id">link</a>
        </p>
      </div>
    </div>
    <!-- fim informações do site -->

    <p class="mat-h2 pt-2">{{'NEW-DELIVERY-REQUEST/text/selected-ovs' | translate}}</p>
    <table mat-table #table [dataSource]="dataSourceOV" matSort class="col-md-12 p-0" *ngIf="dataSourceOV.data.length > 0">
      <ng-container matColumnDef="salesDocument">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/ov' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.salesDocument}} </td>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/client' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
      </ng-container>
      <ng-container matColumnDef="referenceNoNf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/nf' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.referenceNoNf}} </td>
      </ng-container>
      <ng-container matColumnDef="materialDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/equipment' | translate }} <th>
          <td mat-cell *matCellDef="let element"> {{element.materialDescription}} </td>
      </ng-container>
      <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
    <p *ngIf="dataSourceOV.data.length === 0" class="col-md-12 p-0">{{'NEW-DELIVERY-REQUEST/text/no-selected-ovs' | translate}}</p>
    <!-- fim tabela com OVs selecionadas -->

    <button *ngIf="dataSourceOV.data.length > 0" [disabled]="!form.valid" mat-raised-button class="w-100 primary-button" (click)="create()">{{'NEW-DELIVERY-REQUEST/text/create' | translate}}</button>

  </mat-card>
  <div  *ngIf="uploaded" class="colored-background pt-5">
    <mat-card class="mx-5 mb-5  flex-row flex-wrap align-items-center rounded-corners">
      <div class="row row-height60">
        <p class="mat-h1 center-h1">
          <span>{{ 'NEW-DELIVERY-REQUEST/text/ov-selected' | translate }}</span>
        </p>
      </div>
      <div class="row icon-back">
        <p class="col-md-4 ">
          <span (click)="uploaded=false"><i class="fas fa-undo-alt"></i></span>
        </p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mat-elevation-z8 col-md-12" *ngIf="dataSourceDR.data.length > 0" style="margin-bottom: 30px;">
            <table mat-table #table [dataSource]="dataSourceDR" matSort>
              <ng-container matColumnDef="dateScheduling">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/date' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.dateScheduling | date: "dd/MM/yy, h:mm a"}} </td>
              </ng-container>
              <ng-container matColumnDef="deliveryAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/delivery-address' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
              </ng-container>
              <ng-container matColumnDef="infraType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/infra' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.infraType}} </td>
              </ng-container>
              <ng-container matColumnDef="obs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/observation' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.obs}} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/list-ov' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
              </ng-container>
              <ng-container matColumnDef="pickupAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/collect-address1' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.pickupAddress}} </td>
              </ng-container>
              <ng-container matColumnDef="site">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'NEW-DELIVERY-REQUEST/text/site' | translate }} <th>
                <td mat-cell *matCellDef="let element"> {{element.site}} </td>
              </ng-container>
              <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns3"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns3;" [ngClass]="{'make-red': row.idDRidentifier === 'red' }"></tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span *ngIf="!allValid"><button mat-raised-button class="left green-button" *ngIf="dataSourceDR.data.length> 0" (click)="validate()">Validate DRs</button></span>
          <button mat-raised-button class="left green-button" *ngIf="allValid" (click)="createRequisition()">{{'NEW-DELIVERY-REQUEST/text/create' | translate}}</button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
