import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {DrService} from '../../providers/dr.service';
import {DR} from '../../models/dr';
import {MDR} from '../../models/mdr';
import {MdrService} from '../../providers/mdr.service';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { delay } from 'rxjs/operators';

declare var google: any;

@Component({
  selector: 'app-delivery-optimizer',
  templateUrl: './delivery-optimizer.component.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})

export class DeliveryOptimizerComponent implements OnInit {
  inputOVs: number;
  selection = new SelectionModel<DR>(true, []);
  @ViewChild(MatSort) sort: MatSort;
  opened: any;
  data: DR[];
  showLightBox = false;
  filter = '';
  currentUser = null;
  role = null;
  user = null;
  isBooking = false;
  dataSource: MatTableDataSource<DR> = null;

  displayedColumns = ['select', 'idDRidentifier', 'deliveryAddress', 'dateScheduling', 'site', 'createdBy', 'client', 'thirdPartInvoice', 'goTo'];

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(public dialog: MatDialog, private mapsAPILoader: MapsAPILoader,
              private drService: DrService,
              public mdrService: MdrService, public router: Router, public snackBar: MatSnackBar, public dpipe: DatePipe) {
    this.dataSource = new MatTableDataSource<DR>([]);
    this.dataSource.sort = this.sort;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;
   }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<DR>([]);
    this.dataSource.sort = this.sort;
    console.log(this.isBooking);
    if (this.role === 7 || this.isBooking) {
      this.displayedColumns = ['select', 'idDRidentifier', 'site', 'prolog', 'deliveryAddress', 'dateScheduling', 'goTo'];
      this.drService.getBookingsLSP().subscribe( (elem) => {
        console.log(elem);
        elem.forEach( (row) => {
          if (row.ovs) {
            row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
          } else {
            row.ovList = '';
          }
        });
        const listDRs = [];
        elem.forEach((k) => {
          if (k.status === 'BOOKINGLSP') {
          listDRs.push(k);
          }
        });

        this.data = listDRs;
      // console.log(this.data);
        this.dataSource = new MatTableDataSource<DR>(this.data);
        setTimeout(() => {this.dataSource.sort = this.sort; }, 10);

      });
    } else {
      const listDR = [];
      this.drService.getAllOpen().subscribe( (data2) => {
        data2.forEach( (row) => {
          if (row.ovs) {
            row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
          } else {
            row.ovList = '';
          }

          if (row.status === 'READY_TO_PICKUP' ) {
            listDR.push(row);
          }
        });
        this.data = listDR;
      // console.log(this.data);
      this.dataSource = new MatTableDataSource<DR>(this.data);
      setTimeout(() => {this.dataSource.sort = this.sort; }, 10);
      });
    }
  }

  changeBooking() {
    this.isBooking = !this.isBooking;
    this.ngOnInit();
  }

  goToBooking(elem) {
    this.router.navigate(['dr-validation/' + elem.id]);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // console.log(this.dataSource.filteredData);
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => {
          if (this.dataSource.filteredData.indexOf(row) > -1) {
            this.selection.select(row);
          }
        });
  }

  unmakeDR() {
      this.drService.unmakeDR(this.selection.selected).subscribe(
        (data) => {
          const listDR = [];
          this.drService.getAllOpen().subscribe( (data2) => {
            data2.forEach( (row) => {
              if (row.ovs) {
                row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
              } else {
                row.ovList = '';
              }

              if (row.status === 'READY_TO_PICKUP' ) {
                listDR.push(row);
              }
            });
            this.data = listDR;
          // console.log(this.data);
            this.dataSource = new MatTableDataSource<DR>(this.data);
            this.dataSource.sort = this.sort;
            this.snackBar.open('Undo Correctly', 'Ok', {
              duration: 4000,
            });
            this.router.navigate(['delivery-optimizer']);
          });
        });
  }

  returnDR() {
    this.selection.selected.forEach ( (dr: DR) => {
      dr.status = 'BOOKINGLSP';
      dr.siteTeam = '2';
      dr.obs = 'WARNING: REMOVED FROM ALISTADO!   ' + dr.obs;
      this.drService.update(dr).subscribe(k => {
        const listDR = [];
        this.drService.getAllOpen().subscribe( (data2) => {
          data2.forEach( (row) => {
            if (row.ovs) {
              row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
            } else {
              row.ovList = '';
            }

            if (row.status === 'BOOKINGLSP' ) {
              listDR.push(row);
            }
          });
          this.data = listDR;
        // console.log(this.data);
          this.dataSource = new MatTableDataSource<DR>(this.data);
          this.dataSource.sort = this.sort;
          this.snackBar.open('Removed correctly. Please reload the page.', 'Ok', {
            duration: 4000,
          });
          this.router.navigate(['delivery-optimizer']);
        });
      });
    });

  }

  goToMDRAvailable() {
    this.selection.selected.forEach ( (dr: DR) => {
      dr.thirdPartInvoice = true;
      this.drService.update(dr).subscribe(k => {
      });
      this.snackBar.open('Correctly sent to MDR Available', 'Ok', {
        duration: 4000,
      });
      this.router.navigate(['delivery-optimizer']);
    });
  }

  goToMDRUnavailable() {
    this.selection.selected.forEach ( (dr: DR) => {
      dr.thirdPartInvoice = false;
      this.drService.update(dr).subscribe(k => {
      });
      this.snackBar.open('Correctly sent to MDR Unavailable', 'Ok', {
        duration: 4000,
      });
      this.router.navigate(['delivery-optimizer']);
    });
  }

  validateDR() {
    this.selection.selected.forEach ( (dr: DR) => {
      dr.status = 'READY_TO_PICKUP';
      this.drService.update(dr).subscribe(k => {
        // const listDR = [];
        // this.drService.getAllOpen().subscribe( (data2) => {
        //   data2.forEach( (row) => {
        //     if (row.ovs) {
        //       row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
        //     } else {
        //       row.ovList = '';
        //     }

        //     if (row.status === 'BOOKINGLSP' ) {
        //       listDR.push(row);
        //     }
        //   });
        //   this.data = listDR;
        // // console.log(this.data);
        //   this.dataSource = new MatTableDataSource<DR>(this.data);
        //   this.snackBar.open('Validated Correctly', 'Ok', {
        //     duration: 4000,
        //   });
        //   this.router.navigate(['delivery-optimizer']);
        // });
      });
      this.snackBar.open('Validated Correctly', 'Ok', {
        duration: 4000,
      });
      this.ngOnInit();
    });
  }

  createConsolidationMDR() {
    const mdr = new MDR();
    mdr.status = 'MDR em validação';
    this.selection.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });
    this.mdrService.insertConsolidation(mdr).subscribe( (data) => {
     // console.log('MDR new Service');
     // console.log(data);
      this.mdrService.createLegs(data.id, false).subscribe((legs) => {
       // console.log(legs);
      });
      const listDR = [];
      this.drService.getAllOpen().subscribe( (data2) => {
        data2.forEach( (row) => {
          if (row.ovs) {
            row.ovList = row.ovs.map(e => e.salesDocument).join(', ');
          } else {
            row.ovList = '';
          }

          if (row.status === 'READY_TO_PICKUP' ) {
            listDR.push(row);
          }
        });
        this.data = listDR;
      // console.log(this.data);
        this.dataSource = new MatTableDataSource<DR>(this.data);
        this.dataSource.sort = this.sort;
        this.snackBar.open('MDR EDB-TP-000' + data.id + ' criada com sucesso', 'Ok', {
          duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
      });
    });
  }

  openDialog(): void {
    let flag = 0;
    let isOwner = 0;
    this.selection.selected.forEach((el: DR) => {
      if (el.thirdPartInvoice !== true) {
        this.snackBar.open('DR selected not available to shipment', 'Ok', {
          duration: 4000,
        });
        flag = 1;
      }
      if (el.createdBy !== this.currentUser.user.name) {
        isOwner = 1;
      }
    });

    if (flag === 0) {
      const dialogRef = this.dialog.open(TypeMdrDialogComponent, {
        width: '600px',
        height: '500px',
        data: {
          id: 1,
          isOwner: isOwner,
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data !== undefined) {
          this.createNewMDR(data);
        }
      });
    }
  }

  createNewMDR(typeMDR: any) {
    // console.log(typeMDR);
    // cria MDR
    const mdr = new MDR();
    mdr.type = typeMDR.type;
    mdr.status = 'MDR em validação';
    // adiciona DRs selecionadas
    this.selection.selected.forEach((el: DR) => {
      mdr.drList.push(el);
    });

    let flag = false;
    if (mdr.type === 'Cross Doc') {
      this.createConsolidationMDR();
      return;
    } else if (mdr.type === 'CMS' && typeMDR.data) {
      mdr.SAD = typeMDR.data;
      if (typeMDR.data === true) {
        flag = true;
      }
    } else if (mdr.type === 'DTR' && typeMDR.data) {
      mdr['dtr'] = typeMDR.data;
    }
    // }else if(mdr.type === 'DTR' && typeMDR.data){
    //   const drList = [];
    //   for(let i = typeMDR.data.length-1; i>=0; i--){
    //     const dr = new DR();
    //     dr.deliveryAddress = typeMDR.data[i].address;
    //     dr.lat = typeMDR.data[i].lat;
    //     dr.lng = typeMDR.data[i].lng;
    //     dr.uf = typeMDR.data[i].uf;
    //     earlydate.setDate(earlydate.getDate() - i - 1);
    //     dr.dateScheduling = earlydate;
    //     drList.push(dr);
    //   }
    //   //console.log(drList);
    // }
    this.mdrService.insert(mdr, flag).subscribe( (data) => {
      const listDR = [];
      this.selection = new SelectionModel<DR>(true, []);
      this.ngOnInit();
    });
  }
}

@Component({
  selector: 'app-type-mdr-dialog',
  templateUrl: 'type-mdr-dialog.html',
  styleUrls: ['./delivery-optimizer.component.scss']
})

export class TypeMdrDialogComponent implements OnInit {
  public types = [{id: 'MF', name: 'MF'}, {id: 'SWAP', name: 'SWAP'}, {id: 'CMS', name: 'CMS'}, {id: 'DTR', name: 'INV'}, {id: 'Cross Doc', name: 'HUB'}];
  typeMDR: any;
  returnLeg = false;
  listAddresses = [];
  addressReturn = [];
  address = null;
  isFromSameUser = false;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor( public dialogRef: MatDialogRef<TypeMdrDialogComponent>, private mapsAPILoader: MapsAPILoader, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.typeMDR = null;
    console.log(this.data.isOwner);
    if (this.data.isOwner === 1) {
      this.isFromSameUser = true;
    }

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  addAddress() {
    const address = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    this.listAddresses.push({address: address});
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': address}, (results, status) => {
      // console.log(results);
      const index = this.listAddresses.length - 1;
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const uf = results[0].address_components[2].short_name;
        this.addressReturn.push({address: address, lat: lat, lng: lng, uf: uf});
        // console.log(this.addressReturn);
      } else {
        this.listAddresses.splice(index, 1);
        // this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
        // console.log(status);
      }
    });
  }

  removeAddress(i) {
    this.listAddresses.splice(i, 1);
  }

  onYesClick(): void {
    if (this.typeMDR === 'CMS') {
      this.dialogRef.close({type : this.typeMDR, data: this.returnLeg});
    } else if (this.typeMDR === 'DTR') {
      this.dialogRef.close({type : this.typeMDR, data: this.addressReturn});
    } else {
      this.dialogRef.close({type : this.typeMDR, data: false});
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
