import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';

export interface Element {
  name: string;
  position: number;
  role: string;
  symbol: string;
}

const ELEMENT_DATA: Element[] = [
  {position: 1, name: 'Henrique Fischer', role: 'Role1', symbol: 'H'},
  {position: 2, name: 'Felipe Fontes', role: 'Role1', symbol: 'He'},
  {position: 3, name: 'Maria Antónia', role: 'Role4', symbol: 'Li'},
  {position: 4, name: 'Mario Douglas', role: 'Role1', symbol: 'Be'},
  {position: 5, name: 'Roberto Rodrigues', role: 'Role2', symbol: 'B'},
  {position: 6, name: 'Lucas Bentes', role: 'Role3', symbol: 'C'},
  {position: 7, name: 'Sérgio Nascimento', role: 'Role1', symbol: 'N'},
];

@Component({
  selector: 'app-shipping-company-admin',
  templateUrl: './shipping-company-admin.component.html',
  styleUrls: ['./shipping-company-admin.component.scss']
})

export class ShippingCompanyAdminComponent implements OnInit {
  displayedColumns = ['position', 'name', 'role', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  opened: any;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor() { }

  ngOnInit() {
  }

}
