import {OV} from './ov';
import {NF} from './nfe';

export enum DR_STATUS  {  READY_TO_PICKUP = 'READY_TO_PICKUP'
                        , DELIVERED = 'Delivered'
                        , INTRANSIT = 'InTransit'
                        , CANCELED = 'Canceled'
                        , HUB_RETURN = 'HubReturn'
                        , REPLAN_RETIDO = 'Replan - Retido'
                        , REPLAN_ANTECIPACAO = 'Replan - Antecipação'
                        , REPLAN_PERNOITE = 'Replan - Pernoite'
                        , REPLAN_POSTERGACAO = 'Replan - Postergação'
                        , BOOKING = 'BOOKING'
}

export class DR {

  public id: number;
  public idMDR: number;
  public client: string;
  public pickupDifAddress: boolean;
  public idDRidentifier: string;
  public deliveryAddress: string;
  public pickupAddress: string;
  public site: string;
  public receiverPhone: number;
  public receiverName: string;
  public value: number;
  public volume: number;
  public weight: number;
  public maxHeight: number;
  public maxWidth: number;
  public maxLength: number;
  public supervisorPhone: number;
  public supervisorName: string;
  public infraType: string;
  public obs: string;
  public dateScheduling: Date;
  public datePickupScheduling: Date;
  public createdAt: Date;
  public createdBy: string;
  public idDisplay: number;
  public thirdPartInvoice: boolean;
  public lat: number;
  public lng: number;
  public status: string;
  public arrivalTime: Date;
  public userArrivalTime: Date;
  public prolog: string;
  public siteTeam: string;
  public unificationId: string;
  public salesOrders: string[];
  public uf: string;
  public city: string;
  ovList: Array<OV>;
  nfList: Array<NF>;
  public responsableASP: string;
  public responsableCPM: string;
  public model: string;

  constructor() {
    this.id = null;
    this.idMDR = null;
    this.client = null;
    this.pickupDifAddress = null;
    this.idDRidentifier = '';
    this.deliveryAddress = '';
    this.pickupAddress = '';
    this.site = '';
    this.receiverPhone = null;
    this.supervisorPhone = null;
    this.supervisorName = '';
    this.receiverName = '';
    this.lat = null;
    this.lng = null;
    this.infraType = '';
    this.ovList = new Array<OV>();
    this.nfList = new Array<NF>();
    this.obs = '';
    this.dateScheduling = null;
    this.datePickupScheduling = null;
    this.idDisplay = null;
    this.createdAt = null;
    this.createdBy = '';
    this.thirdPartInvoice = null;
    this.status = null;
    this.value = null;
    this.weight = null;
    this.maxHeight = null;
    this.maxLength = null;
    this.maxWidth = null;
    this.volume = null;
    this.uf = null;
    this.prolog = null;
    this.siteTeam = '';
    this.unificationId = '';
    this.salesOrders = [];
    this.city = null;
    this.responsableASP = null;
    this.responsableCPM = null;
    this.model = '';
  }

}
