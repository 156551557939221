import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { PAYMENT_STATUS } from '../../models/payment';
var FinanceListComponent = /** @class */ (function () {
    function FinanceListComponent(dialog, mdrService, excelService, drService, router, snackBar, dpipe) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.excelService = excelService;
        this.drService = drService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.paymentList = [];
        this.data = [];
        this.listPay = [];
        this.columns = [
            { value: 'status', name: 'FINANCE_LIST/table/status' },
            { value: 'client', name: 'FINANCE_LIST/table/client' },
            { value: 'shippingCompany', name: 'FINANCE_LIST/table/lsp' },
            { value: 'mdrPickupId', name: 'FINANCE_LIST/table/mdr' },
            { value: 'prolog', name: 'FINANCE_LIST/table/prolog' },
            { value: 'costProject', name: 'FINANCE_LIST/table/costProject' },
            { value: 'purchaseOrder', name: 'FINANCE_LIST/table/costProject' },
            { value: 'invoice', name: 'invoice' },
            { value: 'migo', name: 'migo' },
            { value: 'actualSapCode', name: 'sapCode' },
            // {value: 'OV', name: 'FINANCE_LIST/table/ov'},
            { value: 'type', name: 'FINANCE_LIST/table/type' },
            { value: 'origem', name: 'FINANCE_LIST/table/origem' },
            { value: 'currency', name: 'FINANCE_LIST/table/value', currency: 'BRL' },
            { value: 'createdAt', name: 'FINANCE_LIST/table/date' }
        ];
        this.role = JSON.parse(localStorage.getItem('currentUser')).user.role;
    }
    FinanceListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getAllPaymentList().subscribe(function (data) {
            // console.log(data);
            var keys = data.length ? Object.keys(data[0]) : [];
            _this.data = data.map(function (k) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    if (k[keys[i]] === null || k[keys[i]] === undefined) {
                        k[keys[i]] = '';
                    }
                }
            });
            data.forEach(function (k) {
                k.currency = k.value / 100;
                k.grossValue = k.currency - k.currency * 0.13;
                delete k.value;
                k.createdAt = new Date(k.createdAt);
                k.drDateScheduling = k.drDateScheduling ? new Date(k.drDateScheduling) : null;
            });
            _this.data = data;
        });
    };
    FinanceListComponent.prototype.insertPaymentFromFile = function (fileupload) {
        var _this = this;
        // console.log(fileupload);
        // console.log(fileupload);
        var input = fileupload.target;
        // console.log(fileupload);
        this.paymentList = [];
        for (var index = 0; index < input.files.length; index++) {
            this.excelService.importFromExcel(fileupload).subscribe(function (data) {
                data.forEach(function (el, i, arr) {
                    var payment = {};
                    // console.log(el);
                    try {
                        payment[''] = el.pickup_address;
                        // console.log(drAux.status);
                    }
                    catch (ex) {
                        // console.log('erro ao ler arquivo xlsx ',ex);
                        _this.snackBar.open('Erro ao ler arquivo', 'Ok', { duration: 4000 });
                    }
                });
            });
        }
    };
    FinanceListComponent.prototype.updateFromFile = function (fileupload) {
        var _this = this;
        var input = fileupload.target;
        var error = false;
        var re = /(?:\.([^.]+))?$/;
        for (var index = 0; index < input.files.length; index++) {
            var ext = re.exec(input.files[index].name)[1];
            if (ext === 'xlsx') {
                this.excelService.importFromExcel(input.files[index]).subscribe(function (data) {
                    var checkRow = function (row) {
                        // status é válido
                        if ('status' in row
                            && !Object.values(PAYMENT_STATUS).includes(row['status'])) {
                            _this.snackBar.open("Linha " + row.__rowNum__ + " cont\u00E9m status inv\u00E1lido", 'Ok', { duration: 2000 });
                            return false;
                        }
                        // campos necessários estão presentes
                        if (!('origemId' in row)
                            || !('invoice' in row)
                            || !('OV' in row)) {
                            _this.snackBar.open("Linha " + row.__rowNum__ + " n\u00E3o cont\u00E9m campos obrigat\u00F3rios [origemId,invoice,OV]", 'Ok', { duration: 2000 });
                            return false;
                        }
                        return true;
                    };
                    // se todas as linhas recebidas são válidas
                    if (data.every(checkRow)) {
                        // atualiza as linhas
                        var total_1 = data.length;
                        data.forEach(function (row, i) {
                            _this.mdrService.updatePayment(row).subscribe(function (response) {
                                _this.snackBar.open('PO (' + i + '/' + total_1 + ') atualizado', 'OK', { duration: 1000 });
                                // console.log(i,'/',total);
                            }, function (er) {
                                _this.snackBar.open('PO (' + i + '/' + total_1 + ') falhou atualização', 'OK', { duration: 1000 });
                                // console.error(i,'/',total);
                            });
                        });
                        _this.ngOnInit();
                    }
                    else {
                        _this.snackBar.open('Arquivo contém linhas inválidas', 'OK', { duration: 4000 });
                    }
                });
            }
        }
    };
    FinanceListComponent.prototype.goToMDR = function (row, that) {
        if (row.mdrId !== 0) {
            that.router.navigate(['logistic-order/' + row.mdrId]);
        }
        else {
            that.snackBar.open('Não há MDR associada a este item', 'Ok', { duration: 4000 });
        }
    };
    FinanceListComponent.prototype.addList = function (listPayments) {
        this.listPay = listPayments;
    };
    FinanceListComponent.prototype.payNF = function () {
        var _this = this;
        this.mdrService.payNF(this.listPay).subscribe(function (response) {
            _this.snackBar.open('Pagamento realizado corretamente', 'Ok', { duration: 4000 });
        });
    };
    FinanceListComponent.prototype.remove = function () {
        var _this = this;
        var removed = 0;
        var total = this.listPay.length;
        this.listPay.forEach(function (payment) {
            _this.mdrService.removePayment(payment.id).subscribe(function (response) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') removido', 'OK', { duration: 4000 });
                var paymentI = _this.data.findIndex(function (e) { return e.id === payment.id; });
                if (paymentI >= 0) {
                    _this.data.splice(paymentI, 1);
                }
            }, function (error) {
                removed++;
                _this.snackBar.open('PO (' + removed + '/' + total + ') falhou remoção', 'OK', { duration: 4000 });
            });
        });
    };
    return FinanceListComponent;
}());
export { FinanceListComponent };
