import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Message } from '../models/message';
import { Event } from '../models/events';
import * as socketIo from 'socket.io-client';
import {environment} from '../../environments/environment';

const base_url: string = environment.websocketUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class SocketService {
  private socket;

  // constructor(public auth: AuthService, public router: Router) {}

  public initSocket(id): void {
    // console.log('init socket',base_url,id);
    this.socket = socketIo(base_url,
      {
        transports: ['websocket'],
        secure: true
      });
    this.joinConversation(id);
    // console.log(this.socket);
  }
  public initSocketMDR(id: number, userid: string): void {
    // console.log('init socket');
    this.socket = socketIo(base_url, {
      transports: ['websocket'],
      secure: true
    });
    this.socket.emit('enter conversation mdr', {room: id, userId: userid});

    // console.log(this.socket);

  }

  public initSocketNotification(id: number): void {
    // console.log('init socket');
    this.socket = socketIo(base_url, {
      transports: ['websocket'],
      secure: true
    });
    this.socket.emit('enter notification', {room: id});

    // console.log(this.socket);

  }

  public emit(eventName, eventBody): void {
    // console.log('emitindo',eventName,eventBody);//DEBUG
    this.socket.emit(eventName, eventBody);
  }

  public send(message: Message): void {
    this.emit('message', message);
  }

  public sendNotification(message: Message): void {
   // console.log('Send message to Server');
   // console.log(message);

    this.socket.emit('notification', message);
  }

  public joinConversation(id): void {
    this.socket.emit('enter conversation', {room: id});
  }

  public onMessage(): Observable<Message> {
    return this.onEvent(Event.NEW_MESSAGE);
  }

  public onComeMDRLists(): Observable<any> {
    return this.onEvent(Event.NEW_MDRList);
  }
  public onNotification(): Observable<any> {
    return this.onEvent(Event.NEW_NOTIFICATION);
  }

  public onEvent(event: Event): Observable<any> {
    // console.log('Chegou alguma coisa');
    // console.log(event)
    return new Observable<Event>(observer => {
      this.socket.on(event, (data) => {
        // console.log('chegou evento',event,data);//DEBUG
        observer.next(data);
      });
    });
  }



}
