import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var base_url = environment.apiUrl;
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(http) {
        this.http = http;
    }
    ConfigurationService.prototype.loadConfigurations = function () {
        var _this = this;
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'configuration', { headers: header }).map(function (configurations) {
            _this.configurations = configurations;
            return configurations;
        });
    };
    ConfigurationService.prototype.getConfiguration = function (page) {
        var _this = this;
        // quando as configurations já foram carregadas
        if (this.configurations) {
            return new Observable(function (observer) {
                var found = _this.configurations.find(function (e) { return e.page === page; });
                if (found) {
                    observer.next(found.configuration);
                }
                else {
                    throw new Error('Not found');
                }
            });
        }
        else {
            // quando as configurations ainda não foram carregadas
            return this.loadConfigurations().pipe(map(function (configurations) {
                var found = configurations.find(function (e) { return e.page === page; });
                if (found) {
                    return found.configuration;
                }
                else {
                    throw new Error('Not found');
                }
            }));
        }
    };
    ConfigurationService.prototype.allCount = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        // let params = new HttpParams().set("token",token);
        return this.http.get(base_url + 'occurrence/allCount', { headers: header })
            .map(function (response) {
            if (response['OccurrenceList']) {
                return response['OccurrenceList'];
            }
            else {
                return 0;
            }
            // console.log(response);
        });
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
