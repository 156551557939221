<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'MDR_LSP/title' | translate }}</p>
</div>
<div class="colored-background table-background px-5 py-2">
  <app-table
    [columns]="columns"
    [data]="mdr"
    [this]="this"
    [clickCallback]="onRowClick"
  ></app-table>
</div>
