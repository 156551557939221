var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { MatSort, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
import { MDR, MDR_STATUS } from '../../models/mdr';
import { MdrObservable } from '../../services/mdr.service';
import { PriceObservable } from '../../services/price.service';
import { DrObservable } from '../../services/dr.service';
import { UOCObservable } from '../../services/uoc.service';
import { DrService } from '../../providers/dr.service';
import { UOCMDR } from '../../models/uoc__mdr';
import { Driver } from '../../models/driver';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import 'rxjs/add/operator/takeWhile';
import { TranslateService } from '@ngx-translate/core';
import { TransportComponent } from './transport/transport.component';
import { Message } from '../../models/message';
import { SocketService } from '../../providers/socket.service';
import { ChatService } from '../../providers/chat.service';
import { Event } from '../../models/events';
import { ExportAsService } from 'ngx-export-as';
import { Title } from '@angular/platform-browser';
var LogisticOrderComponent = /** @class */ (function () {
    function LogisticOrderComponent(dialog, titleService, translate, mdrService, router, aRoute, snackBar, mdrObservable, priceObservable, drObservable, drService, uocObservable, socketService, exportAsService, chat) {
        this.dialog = dialog;
        this.titleService = titleService;
        this.translate = translate;
        this.mdrService = mdrService;
        this.router = router;
        this.aRoute = aRoute;
        this.snackBar = snackBar;
        this.mdrObservable = mdrObservable;
        this.priceObservable = priceObservable;
        this.drObservable = drObservable;
        this.drService = drService;
        this.uocObservable = uocObservable;
        this.socketService = socketService;
        this.exportAsService = exportAsService;
        this.chat = chat;
        this.chatType = 5; // transportadora <-> motorista
        this.exportAsConfig = {
            type: 'pdf',
            elementId: 'printable',
            options: {
                pagespilt: true,
                html2canvas: {
                    useCORS: true,
                },
                pagebreak: {
                    mode: 'avoid-all',
                }
            }
        };
        this.drivers = [];
        this.print = false;
        this.mdr = new MDR();
        this.editable = true;
        this.driverEditable = false;
        this.role = 0;
        this.editMDRStatus = false;
        // use *ngIf="display" in your html to take
        // advantage of this
        this.reservation = '';
        this.reprove = '';
        this.shipmentReturned = '';
        this.positionOptions = 'after';
        this.tooltip = 'Esta MDR esta sendo editada por outra pessoa';
        this.price = { price: 0, total: 0, adValorem: 0, addDelivery: 0, taxedPrice: 0, sc: '', vehi: '' };
        this.hiddenMap = true;
        this.lock = false;
        this.valueNfStatus = false;
        this.mdrStatus = [
            { value: 'MDR em validação', name: 'LOGISTIC-ORDER/mdr-status/validation' },
            { value: 'MDR em criação', name: 'LOGISTIC-ORDER/mdr-status/creation' },
            { value: 'MDR devolvida por LSP', name: 'LOGISTIC-ORDER/mdr-status/returned-lsp' },
            { value: 'MDR aguardando LSP', name: 'LOGISTIC-ORDER/mdr-status/waiting-lsp' },
            { value: 'MDR aprovada LSP', name: 'LOGISTIC-ORDER/mdr-status/approved-lsp' },
            { value: 'Motorista e veiculo definidos', name: 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined' },
            { value: 'Veículo carregado', name: 'LOGISTIC-ORDER/mdr-status/vehicle-loaded' },
            { value: 'Carga entregue com sucesso', name: 'LOGISTIC-ORDER/mdr-status/cargo-delivered' },
            { value: 'Carga retornada total', name: 'LOGISTIC-ORDER/mdr-status/cargo-returned' },
            { value: 'Entrega de carga certificada pelo LSP', name: 'LOGISTIC-ORDER/mdr-status/certified-delivery' },
            { value: 'MDR paga / concluída', name: 'LOGISTIC-ORDER/mdr-status/mdr-paid' },
        ];
        this.mdrStatusTranslated = {
            'MDR em validação': 'LOGISTIC-ORDER/mdr-status/validation',
            'MDR em criação': 'LOGISTIC-ORDER/mdr-status/creation',
            'MDR devolvida por LSP': 'LOGISTIC-ORDER/mdr-status/returned-lsp',
            'MDR aguardando LSP': 'LOGISTIC-ORDER/mdr-status/waiting-lsp',
            'MDR aprovada LSP': 'LOGISTIC-ORDER/mdr-status/approved-lsp',
            'Motorista e veiculo definidos': 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined',
            'Veículo carregado': 'LOGISTIC-ORDER/mdr-status/vehicle-loaded',
            'Carga entregue com sucesso': 'LOGISTIC-ORDER/mdr-status/cargo-delivered',
            'Carga retornada total': 'LOGISTIC-ORDER/mdr-status/cargo-returned',
            'Entrega de carga certificada pelo LSP': 'LOGISTIC-ORDER/mdr-status/certified-delivery',
            'MDR paga / concluída': 'LOGISTIC-ORDER/mdr-status/mdr-paid',
        };
        this.MDR_STATUS = MDR_STATUS;
        this.driver = new Driver();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.display = false;
        this.alive = true;
    }
    LogisticOrderComponent.prototype.ngOnDestroy = function () {
        this.titleService.setTitle('Bluebee');
    };
    LogisticOrderComponent.prototype.startup = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            _this.channel = params.id;
        });
        TimerObservable.create(0, 5 * 60 * 10000)
            .takeWhile(function () { return _this.alive; })
            .subscribe(function () {
        });
        this.aRoute.params.subscribe(function (params) {
            _this.mdrService.getMDR(params.id).subscribe(function (data) {
                _this.mdrService.getNf(params.id).subscribe(function (nfs) {
                    var totalValue = nfs.filter(function (nf) { return parseFloat(nf.totalValue) >= 500000; });
                    if (totalValue.length > 0) {
                        _this.valueNfStatus = true;
                    }
                });
                _this.mdr = data;
                if (_this.mdr.pickupID !== null) {
                    _this.titleService.setTitle('BB: ' + _this.mdr.pickupID);
                }
                if (_this.mdr.status === 'MDR aprovada com ressalvas LSP') {
                    _this.mdrService.getMDRReservation(params.id).subscribe(function (elem) {
                        // console.log(elem);
                        _this.reservation = elem;
                    });
                }
                if (_this.mdr.status === 'MDR em criação') {
                    _this.mdrService.getMDRReprove(params.id).subscribe(function (elem) {
                        _this.reprove = elem;
                    });
                }
                if (_this.mdr.status === 'MDR devolvida por LSP') {
                    _this.mdrService.getMDRReprove(params.id).subscribe(function (elem) {
                        _this.reprove = elem;
                    });
                }
                if (_this.mdr.status === 'Carga retornada total') {
                    _this.mdrService.getMDRReprove(params.id).subscribe(function (elem) {
                        // console.log(elem);
                        _this.shipmentReturned = elem;
                    });
                }
                _this.aRoute.params.subscribe(function (route) {
                    _this.drService.getByMdr(route.id).subscribe(function (newDr) {
                        _this.drObservable.changeDR(newDr);
                    });
                });
                _this.mdrService.getListUOC().subscribe(function (uoc) {
                    _this.uocObservable.changeUOC(uoc);
                });
                _this.mdrObservable.changeMDR(_this.mdr);
                _this.initIoConnection(_this.channel);
                if (_this.mdr.status === 'MDR em validação') {
                    _this.mdrService.createLegs(_this.mdr.id, false).subscribe(function (legs) {
                        // console.log(legs);
                    });
                }
                else if (_this.mdr.status === 'MDR em criação') {
                    _this.priceObservable.changePrice(_this.price);
                    _this.mdrService.getAllPriceR(_this.mdr).subscribe(function (elem) {
                        console.log(elem);
                        _this.price = elem;
                        // this.mdr.totalNF = '0';
                        // this.mdr.sapCode = '0';
                        _this.mdr.SAD = elem.sapCd;
                        _this.mdr.totalKM = elem.km;
                        _this.mdr.vehicle = elem.vehi;
                        _this.priceObservable.changePrice(elem);
                        _this.mdrObservable.changeMDR(_this.mdr);
                    });
                }
                else {
                    _this.mdrService.getAlreadyExistentPrice(_this.mdr).subscribe(function (elem) {
                        _this.price = elem;
                        _this.price.addDelivery = elem.addEntrega;
                        _this.price.adValorem = elem.adValorem;
                        _this.price.price = elem.frete;
                        _this.price.total = elem.total;
                        _this.price.taxedPrice = elem.freteTax;
                        _this.priceObservable.changePrice(elem);
                    });
                }
            }, function (error) {
                _this.snackBar.open('Erro ao carregar MDR.', 'Ok', {
                    duration: 4000,
                });
                _this.router.navigate(['/']);
            });
        });
    };
    LogisticOrderComponent.prototype.ngOnInit = function () {
        this.startup();
    };
    LogisticOrderComponent.prototype.updateNFCost = function () {
        this.tax.nfePrice = this.attachments.totalNfCost;
    };
    LogisticOrderComponent.prototype.ngAfterViewInit = function () {
    };
    LogisticOrderComponent.prototype.mapButtonClick = function () {
        this.hiddenMap = !this.hiddenMap;
    };
    LogisticOrderComponent.prototype.showMap = function () {
        switch (this.mdr.status) {
            case 'MDR em validação':
            case 'MDR em criação':
            case 'MDR devolvida por LSP':
            case 'MDR aguardando LSP':
            case 'MDR aprovada LSP':
            case 'Motorista e veiculo definidos':
            case 'Veículo carregado':
                return true;
            default:
                return false;
        }
    };
    LogisticOrderComponent.prototype.onClickPrint = function () {
        this.print = true;
        this.exportAsService.save(this.exportAsConfig, this.mdr.pickupID);
        this.print = false;
    };
    LogisticOrderComponent.prototype.editStatus = function () {
        this.editMDRStatus = !this.editMDRStatus;
    };
    LogisticOrderComponent.prototype.saveStatus = function () {
        var _this = this;
        this.editMDRStatus = !this.editMDRStatus;
        // console.log(this.currentUser.user);
        this.mdrService.update(this.mdr, this.currentUser.user.id).subscribe(function (data) {
            _this.snackBar.open('Status atualizado com sucesso. Por favor, recarregue a página.', 'Ok', {
                duration: 4000,
            });
        });
    };
    LogisticOrderComponent.prototype.createDrivers = function () {
        var _this = this;
        this.transport.drivers.forEach(function (el, i, arr) {
            _this.createDriver(el);
        });
    };
    LogisticOrderComponent.prototype.createDriver = function (driver) {
        driver.mdrId = this.mdr.id;
        this.mdrService.createDriver(driver).subscribe(function (response) {
            // console.log(response);
        });
    };
    LogisticOrderComponent.prototype.initIoConnection = function (id) {
        this.user = this.currentUser.user.id;
        this.socketService.initSocketMDR(id, this.user);
        // this.ioConnection = this.socketService.onComeMDRLists()
        //   .subscribe((message) => {
        //    //console.log(message);
        //     this.idsocket= message['user'][this.user];
        //     if(message['socket'][0]!==this.idsocket){
        //       this.lock =false;
        //     }else{
        //       this.lock =true;
        //     }
        //     this.aRoute.params.subscribe( params => {
        //       this.mdrService.getMDR(params.id).subscribe((data) => {
        //         this.mdr = data;
        //         this.mdr.lock = this.lock;
        //         this.mdrObservable.changeMDR(this.mdr);
        //       })
        //     });
        //
        //   });
        this.socketService.onEvent(Event.CONNECT)
            .subscribe(function () {
            // console.log('connected');
        });
        this.socketService.onEvent(Event.NEW_MESSAGE)
            .subscribe(function (message) {
            // console.log(message);
            // this.messages.push(message);
        });
        this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(function () {
            // console.log('disconnected');
        });
    };
    LogisticOrderComponent.prototype.cancelMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR aguardando LSP';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.deleteMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR Reprovado';
        this.mdrService.update(this.mdr, this.user).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mdrService.liberateDR(this.mdr).subscribe(function (data) {
                            _this.snackBar.open(_this.mdr.status, 'Ok', {
                                duration: 4000,
                            });
                        })];
                    case 1:
                        _a.sent();
                        this.router.navigate(['delivery-order']);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    LogisticOrderComponent.prototype.createToken = function () {
        var _this = this;
        this.mdrService.createToken(this.mdr.id).subscribe(function (response) {
            var link = 'view/' + response.token + '/mdr';
            var dialogRef = _this.dialog.open(CreateTokenDialogComponent, {
                data: {
                    link: link
                }
            });
        });
    };
    LogisticOrderComponent.prototype.reproveMDR = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReproveDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.shippingCompany = '';
                _this.mdrService.updateReprove(_this.mdr, response[1]).subscribe(function (data) {
                    _this.snackBar.open(_this.mdr.status, 'Ok', {
                        duration: 4000,
                    });
                    _this.router.navigate(['delivery-order']);
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        });
    };
    LogisticOrderComponent.prototype.openHistory = function () {
        var _this = this;
        this.mdrService.getHistory(this.mdr.id).subscribe(function (history) {
            var hist = history.sort(function (a, b) { return a.quando - b.quando; });
            var dialogRef = _this.dialog.open(MdrHistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: hist }
            });
        });
    };
    LogisticOrderComponent.prototype.giveBackMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR devolvida por LSP';
        this.mdr.shippingCompany = '';
        this.mdrService.update(this.mdr, this.user).subscribe(function (response) {
            _this.mdrService.giveBack(_this.mdr).subscribe(function (data) {
            });
        });
    };
    LogisticOrderComponent.prototype.cancelTake = function () {
        this.mdr.status = 'MDR em criação';
        // this.mdr.shippingCompany = '';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
        });
    };
    LogisticOrderComponent.prototype.allShipmentReturned = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReturnDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.status = 'Carga retornada total';
                _this.notification(response);
                _this.mdrService.updateReturn(_this.mdr, response[1]).subscribe(function (data) {
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        });
    };
    LogisticOrderComponent.prototype.updateMDR = function () {
        if (this.mdr.status === 'MDR em criação') {
            this.tax.savePrice();
        }
        this.transport.saveDriver();
        this.transport.saveConveyor();
        this.tax.saveConveyor();
        this.tax.saveUOC();
        this.tax.saveCOPQ();
        this.tax.saveCA();
        this.attachments.updateInfo();
    };
    LogisticOrderComponent.prototype.approveWithConditionsMDR = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AcceptMdrReservationDialogComponent, {
            width: '600px',
            data: {
                text: '',
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response[0] === true) {
                _this.mdr.status = 'MDR aprovada com ressalvas LSP';
                _this.notification(response);
                _this.mdrService.updateRessalva(_this.mdr, response[1]).subscribe(function (data) {
                });
            }
            else {
                _this.router.navigate(['delivery-order']);
            }
        });
    };
    LogisticOrderComponent.prototype.returnTodeliveryAgain = function () {
        var _this = this;
        this.mdr.status = 'Carga retornada para re-entrega';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.sendLSP = function () {
        var _this = this;
        // verifica se as informações de transportadora estão preenchidas
        // console.log();
        if (['shippingCompany', 'vehicle', 'pickupDate', 'pickupID'].some(function (e) {
            if (_this.transportInfo.mdr[e] === null ||
                _this.transportInfo.mdr[e] === '') {
                return true;
            }
            return false;
        })) {
            this.snackBar.open(this.translate.instant('ERROR/MISSING_INFO_SEND_LSP'), 'Ok');
            return;
        }
        // verifica se a data de coleta é válida
        // const pickupDate = new Date(this.transportInfo.mdr.pickupDate);
        // this.drService.getByMdr(this.transportInfo.mdr.id).subscribe((drList) => {
        //   if( drList.some( e => {
        //     let dateScheduling = new Date(e.dateScheduling);
        //     if(pickupDate.getTime() > dateScheduling.getTime()) {
        //       // se a data de coleta for maior que a data da entrega
        //       return true;
        //     }
        //     return false;
        //   })){
        //     this.snackBar.open("Data de coleta deve ser menor que a data de entrega",'Ok', { duration: 4000 });
        //   } else {
        // data é válida, prossegue
        this.mdrService.getLSPUsers(this.mdr.shippingCompany).subscribe(function (users) {
            if (users !== null) {
                if (users.length > 0) {
                    var dialogRef = _this.dialog.open(LSPEmailDialogComponent, {
                        data: users,
                    });
                    dialogRef.afterClosed().subscribe(function (result) {
                        if (result[0]) {
                            var selectedUsers = result[1].value;
                            if (selectedUsers !== null) {
                                _this.mdrService.sendLSPEmail(_this.mdr, selectedUsers).subscribe();
                            }
                            _this.tax.savePrice();
                            if (_this.mdr.type === 'CMS' && _this.mdr.SAD === 'true') {
                                var uoc = new UOCMDR();
                                uoc.idMDR = _this.mdr.id;
                                uoc.value = _this.tax.getTotal() * 0.5 * 100;
                                uoc.uoc = 'Retorno Ericsson';
                                _this.mdrService.createUOC(uoc).subscribe(function (resp) {
                                    // console.log(resp);
                                });
                            }
                            _this.mdr.status = 'MDR aguardando LSP';
                            _this.mdrService.update(_this.mdr, _this.user).subscribe(function (data) {
                                // this.router.navigate(['delivery-optimizer']);
                            });
                        }
                    });
                }
                else {
                    if (_this.mdr.type === 'CMS' && _this.mdr.SAD === 'true') {
                        var uoc = new UOCMDR();
                        uoc.idMDR = _this.mdr.id;
                        uoc.value = _this.tax.getTotal() * 0.5 * 100;
                        uoc.uoc = 'Retorno Ericsson';
                        // console.log(uoc);
                        _this.mdrService.createUOC(uoc).subscribe(function (resp) {
                            // console.log(resp);
                        });
                    }
                    _this.tax.savePrice();
                    _this.mdr.status = 'MDR aguardando LSP';
                    _this.mdrService.update(_this.mdr, _this.user).subscribe(function (data) {
                        _this.router.navigate(['delivery-optimizer']);
                    });
                }
            }
        });
        // this.mdrService.savePrice(this.price, this.mdr).subscribe((el) => {
        // console.log('chegou aqui!');
        // });
    };
    LogisticOrderComponent.prototype.saveAddressInMapp = function () {
        var _this = this;
        this.mdr.status = 'MDR em criação';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.startup();
        });
    };
    LogisticOrderComponent.prototype.reproveRessalva = function () {
        var _this = this;
        this.mdr.status = 'MDR em criação';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.aproveRessalva = function () {
        var _this = this;
        this.mdr.status = 'MDR aprovada LSP';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.acceptMDR = function () {
        var _this = this;
        this.mdr.status = 'MDR aprovada LSP';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.sendWithoutDriver = function () {
        this.mdr.status = 'Motorista e veiculo definidos';
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            // this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.defineDriver = function () {
        if (this.transport.drivers.length === 0) {
            this.snackBar.open('Favor definir um motorista', 'Ok', { duration: 4000 });
            return;
        }
        this.mdr.status = 'Motorista e veiculo definidos';
        this.createDrivers();
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            // this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.vehicleLoaded = function () {
        var _this = this;
        this.mdr.status = 'Veículo carregado';
        // //console.log(this.mdr);
        this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            for (var i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP') {
                    drList[i].status = 'InTransit';
                    _this.drService.update(drList[i]).subscribe(function (k) {
                        // console.log("UPDATED");
                    });
                }
            }
        });
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.delivered = function () {
        var _this = this;
        this.mdr.status = 'Carga entregue com sucesso';
        this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            for (var i = 0; i < drList.length; i++) {
                if (drList[i].status === 'READY_TO_PICKUP' || drList[i].status === 'InTransit') {
                    drList[i].status = 'Delivered';
                    _this.drService.update(drList[i]).subscribe(function (k) {
                        // console.log("UPDATED");
                    });
                }
            }
        });
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
        this.mdrService.update(this.mdr, this.user).subscribe(function (data) {
            _this.notification(data);
        });
    };
    LogisticOrderComponent.prototype.deliveredCertified = function () {
        var _this = this;
        if (!this.attachments.hasEmptyPOD()) {
            var dialogRef = this.dialog.open(DeliveredCertifiedDialogComponent, {
                width: '600px',
                data: {
                    text: '',
                }
            });
            dialogRef.afterClosed().subscribe(function (response) {
                if (response[0] === true) {
                    _this.mdr.status = 'Entrega de carga certificada pelo LSP';
                    _this.mdrService.update(_this.mdr, _this.user).subscribe(function (data) {
                        _this.router.navigate(['delivery-order']);
                    });
                }
            });
        }
        else {
            this.snackBar.open('Faltam inserir PODs ou Foto de UOC', 'Ok', {
                duration: 4000,
            });
        }
    };
    LogisticOrderComponent.prototype.pay = function () {
        var _this = this;
        this.mdr.status = 'MDR paga / concluída';
        this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            var k = true;
            drList.forEach(function (dr) {
                if (!dr.prolog) {
                    k = false;
                }
            });
            // if (k) {
            _this.mdrService.update(_this.mdr, _this.user).subscribe(function (data) {
                _this.notification(data);
            });
            // } else {
            //   this.snackBar.open('DRs sem prolog. Impossível realizar', 'Ok', {
            //     duration: 4000,
            //   });
            // }
        });
    };
    LogisticOrderComponent.prototype.goToChat = function (chatType) {
        this.router.navigate(['chat/' + this.mdr.id + '/' + chatType]);
    };
    LogisticOrderComponent.prototype.notification = function (data) {
        var _this = this;
        this.socketService.initSocketNotification(Number(this.user));
        var message = new Message(this.user, 'teste', this.user, new Date(), this.chatType);
        this.socketService.sendNotification(message);
        // console.log(data);
        data.forEach(function (value) {
            var notification = new Message(value.idUser, 'teste', Number(value.idUser), new Date(), _this.chatType);
            _this.socketService.sendNotification(notification);
        });
        this.snackBar.open(this.mdr.status, 'Ok', {
            duration: 4000,
        });
        this.router.navigate(['delivery-optimizer']);
    };
    return LogisticOrderComponent;
}());
export { LogisticOrderComponent };
var AcceptMdrReservationDialogComponent = /** @class */ (function () {
    function AcceptMdrReservationDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReservationDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReservationDialogComponent;
}());
export { AcceptMdrReservationDialogComponent };
var AcceptMdrReturnDialogComponent = /** @class */ (function () {
    function AcceptMdrReturnDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReturnDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReturnDialogComponent;
}());
export { AcceptMdrReturnDialogComponent };
var AcceptMdrReproveDialogComponent = /** @class */ (function () {
    function AcceptMdrReproveDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    AcceptMdrReproveDialogComponent.prototype.ngOnInit = function () {
    };
    return AcceptMdrReproveDialogComponent;
}());
export { AcceptMdrReproveDialogComponent };
var DeliveredCertifiedDialogComponent = /** @class */ (function () {
    function DeliveredCertifiedDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        // console.log(translate);
    }
    DeliveredCertifiedDialogComponent.prototype.ngOnInit = function () {
    };
    return DeliveredCertifiedDialogComponent;
}());
export { DeliveredCertifiedDialogComponent };
var LSPEmailDialogComponent = /** @class */ (function () {
    function LSPEmailDialogComponent(dialogRef, data, translate) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.translate = translate;
        this.selected = new FormControl();
        // console.log(translate);
    }
    LSPEmailDialogComponent.prototype.ngOnInit = function () {
    };
    return LSPEmailDialogComponent;
}());
export { LSPEmailDialogComponent };
var CreateTokenDialogComponent = /** @class */ (function () {
    function CreateTokenDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    CreateTokenDialogComponent.prototype.onClickOk = function () {
        this.dialogRef.close();
    };
    return CreateTokenDialogComponent;
}());
export { CreateTokenDialogComponent };
var MdrHistoryDialogComponent = /** @class */ (function () {
    function MdrHistoryDialogComponent(data) {
        this.data = data;
        this.history = [];
        this.columns = [
            { value: 'status', name: 'LOGISTIC-ORDER/history/status/' },
            { value: 'quem', name: 'LOGISTIC-ORDER/history/by/' },
            { value: 'quando', name: 'LOGISTIC-ORDER/history/on/' },
            { value: 'pickupDate', name: 'LOGISTIC-ORDER/history/date/' }
        ];
        this.history = data.history;
        // console.log(data.history);
    }
    MdrHistoryDialogComponent.prototype.ngOnInit = function () {
    };
    return MdrHistoryDialogComponent;
}());
export { MdrHistoryDialogComponent };
