import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../environments/environment';
import {MDR} from '../models/mdr';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class UploadFileService {
  public token: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  pushFileToStorage(files: File[], mdr: string, entity: string): Observable<HttpEvent<{}>> {
    if ( entity === 'occurrence' ) { return this.attachFileToOccurrence(files, mdr); }

    const formdata: FormData = new FormData();
    for (const i of Object.keys(files)) {
      formdata.append('file', files[i]);
    }
    console.log('Ready To send File');
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    const param = new HttpParams().set('id', mdr).append('entity', entity);
    // const req = new HttpRequest('POST', base_url + 'occurrence/files', formdata, {
    const req = new HttpRequest('POST', base_url + 'file/aqui', formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: header,
      params: param,
    });

    return this.http.request(req);
  }

  attachFileToOccurrence(files: File[], occurrenceID: string): Observable<HttpEvent<{}>> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);

    const param = new HttpParams().set('id', occurrenceID);
    const formdata: FormData = new FormData();
    for (const i of Object.keys(files)) {
      formdata.append('file', files[i]);
    }

    const req = new HttpRequest('POST', base_url + 'occurrence/files', formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: header,
      params: param,
    });
    return this.http.request(req);
  }

  updateFileInfo(fileInfo: any): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'file/updateInfo', {info: fileInfo}, {headers: header})
               .map((response) => {
                 return true;
               });
  }

  findByEntity(id: number, entity: string ): Observable<any> {
    if ( entity === 'occurrence' ) { return this.getOccurrenceAttachments(id); }

    if (id === null) {
      return null;
    } else if (id === undefined) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'file/findByEntity', {id: id, entity: entity}, {headers: header})
      .map((response) => {
        const OVdata = response;
        return OVdata;
      });
  }

  getOccurrenceAttachments(occurrenceID: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get( base_url + 'occurrence/' + occurrenceID + '/files' , {headers: header}).map((response) => {
      return response;
    });
  }

  findByEntityWithInfo(id: number, entity: string ): Observable<any> {
    if (id === null) {
      return null;
    } else if (id === undefined) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.post(base_url + 'file/findByEntityWithInfo', {id: id, entity: entity}, {headers: header})
      .map((response) => {
        const OVdata = response;
        return OVdata;
      });
  }

  deleteByEntity(id: number, entity: string ): Observable<any> {
    if (id === null) {
      return null;
    }
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.post(base_url + 'file/deleteByEntity', {id: id}, {headers: header})
      .map((response) => {
        const OVdata = response;
        return OVdata;
      });
  }

  getImage(imageUrl: string): Observable<any> {
    if (imageUrl === null) {
      return null;
    }
    const header = new HttpHeaders();
    return this.http.get(imageUrl, {headers: header, responseType: 'blob' })
               .map((res) => {
                 return res;
               });
  }





}
