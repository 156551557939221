import { OnInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ClientUserService } from '../../providers/client-user.service';
import { HubService } from '../../providers/hub.service';
import { HUB } from '../../models/hub';
import { SiteService } from '../../providers/site.service';
var AddHubComponent = /** @class */ (function () {
    function AddHubComponent(dialog, mapsAPILoader, clientService, drService, hubService, aRoute, siteService, router, snackBar, dpipe) {
        var _this = this;
        this.dialog = dialog;
        this.mapsAPILoader = mapsAPILoader;
        this.clientService = clientService;
        this.drService = drService;
        this.hubService = hubService;
        this.aRoute = aRoute;
        this.siteService = siteService;
        this.router = router;
        this.snackBar = snackBar;
        this.dpipe = dpipe;
        this.hub = new HUB();
        this.transportadora = [];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
        this.siteService.getShippingCompanys().subscribe(function (data) {
            _this.transportadora = data;
        });
    }
    AddHubComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.aRoute.params.subscribe(function (params) {
            if (params.id !== undefined) {
                _this.hubService.getHub(params.id).subscribe(function (h) {
                    _this.hub = h;
                });
            }
        });
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElementRef.nativeElement, {
                types: ['address']
            });
        });
    };
    AddHubComponent.prototype.createHub = function () {
        var _this = this;
        this.hub.address = document.getElementById('hubAddress').value;
        this.hubService.create(this.hub).subscribe(function (el) {
            // console.log(el);
            if (el !== null) {
                _this.router.navigate(['hub/']);
            }
            else {
                _this.snackBar.open('Não foi possível salvar o HUB', 'Ok', { duration: 3000 });
            }
        });
    };
    AddHubComponent.prototype.convertAddressToLatLng = function () {
        var _this = this;
        var addr = document.getElementById('hubAddress').value;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': addr }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var lng = results[0].geometry.location.lng();
                _this.hub.lat = lat;
                _this.hub.lng = lng;
            }
            else {
                _this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    AddHubComponent.prototype.convertLatLngToAddress = function () {
        var _this = this;
        if (this.hub.lat === null || this.hub.lng === null) {
            return;
        }
        var lat = document.getElementById('latitudeAddress').value;
        var lng = document.getElementById('longitudeAddress').value;
        var geocoder = new google.maps.Geocoder();
        var location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': location }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var addr = results[0].formatted_address;
                _this.hub.address = addr;
            }
            else {
                _this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', { duration: 2000 });
                // console.log(status);
            }
        });
    };
    AddHubComponent.prototype.goToMDR = function (k) {
        this.router.navigate(['logistic-order/' + k.dr.idMDR]);
    };
    return AddHubComponent;
}());
export { AddHubComponent };
