import { AfterViewInit } from '@angular/core';
import { DashboardService } from '../../providers/dashboard.service';
import { GraphCardComponent } from '../../components/graph-card/graph-card.component';
import { TranslateService } from '@ngx-translate/core';
import { ClientUserService } from '../../providers/client-user.service';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(dashboardService, translateService, clientService) {
        var _this = this;
        this.dashboardService = dashboardService;
        this.translateService = translateService;
        this.clientService = clientService;
        this.dateReference = [];
        this.selectedDate = '';
        this.clientList = [];
        this.lspList = [];
        this.lspSelected = [];
        this.typeSelected = [];
        this.clientSelected = [];
        this.ufSelected = [];
        this.ufList = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
        this.typeList = ['EDB', 'ED', 'CMS', 'DTR', 'Cross Doc', 'Cross Docking', 'Cross Docking'];
        this.colors = ['#8ed4c6', '#b4bae8', '#ebe095', '#9bdeb2', '#addded', '#eedab8'];
        // relativos a busca de data
        this.dateSearch = [];
        this.dates = [null, null];
        this.clientService.getClients().subscribe(function (data) {
            _this.clientList = data;
            console.log(_this.clientList);
        });
        this.clientService.getLspList().subscribe(function (lsps) {
            _this.lspList = lsps;
            console.log(_this.lspList);
        });
    }
    DashboardComponent.prototype.ngAfterViewInit = function () {
        this.loadCharts();
    };
    DashboardComponent.prototype.applyFilter = function () {
        this.loadCharts();
    };
    DashboardComponent.prototype.loadCharts = function () {
        this.createMdrChart();
        this.createOvsChart();
        this.createDrsByClient();
        this.createCostKgChart();
        this.createDrByStatusChart();
        this.createEmptyBarChartData();
        this.createDeliveryPrecision();
        this.createMdrByShippingChart();
    };
    DashboardComponent.prototype.setDRData = function (data) {
        return data['aggregate'].map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countRightPathDRs + day.countWrongPathDRs
            };
        });
    };
    DashboardComponent.prototype.setDrDeliveryPrecisionData = function (data) {
        return data['aggregate'].map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countWrongPathDRs
            };
        });
    };
    DashboardComponent.prototype.createMdrByLspChart = function (data) {
        var chartData = [{ name: 'Outside Delivery Precision', y: 0 }, { name: 'Inside Delivery Precision', y: 0 }];
        data['aggregate'].forEach(function (el) {
            chartData[0].y += el['countRightPathDRs'];
            chartData[1].y += el['countWrongPathDRs'];
        });
        this.dpPerc.createChart(chartData, 'dp-perc', data['separate']);
    };
    DashboardComponent.prototype.createDrDeliveryChart = function (data) {
        var ovData = this.setDRData(data);
        if (ovData.length !== 0) {
            var chartData = {
                number: ovData.map(function (e) { return e.total; }).reduce(function (a, b) { return a + b; }, 0),
                percentage: this.setDRsPercentage(ovData),
                categories: ovData.map(function (arr) { return arr.day; }),
                series: [{
                        name: this.translateService.instant('DASHBOARD/drDelivered'),
                        type: undefined,
                        data: ovData.map(function (arr) { return (arr.total); })
                    }],
                subtitle: this.translateService.instant('DASHBOARD/drDelivered/Subtitle'),
            };
            this.drDelivery.createChart(chartData, 'dr-delivered', data['separate']);
        }
        else {
            var emptyData = this.createEmptyBarChartData();
            this.drDelivery.createChart(emptyData, 'dr-delivered', data['separate']);
        }
    };
    DashboardComponent.prototype.createDrPrecisionChart = function (data) {
        // first time right
        var firstTimeData = this.setDrDeliveryPrecisionData(data);
        // console.log(firstTimeData);
        if (firstTimeData.length !== 0) {
            var chartData = {
                number: firstTimeData.map(function (e) { return e.total; }).reduce(function (a, b) { return a + b; }, 0),
                percentage: this.setDRsPercentage(firstTimeData),
                categories: firstTimeData.map(function (arr) { return arr.day; }),
                series: [{
                        name: this.translateService.instant('DASHBOARD/deliveryPrecision'),
                        type: undefined,
                        data: firstTimeData.map(function (arr) { return (arr.total); })
                    }],
                subtitle: this.translateService.instant('DASHBOARD/deliveryPrecision/Subtitle'),
            };
            this.drPrecision.createChart(chartData, 'delivery-precision', data['separate']);
        }
        else {
            var emptyData = this.createEmptyBarChartData();
            this.drPrecision.createChart(emptyData, 'delivery-precision', data['separate']);
        }
    };
    DashboardComponent.prototype.createDeliveryPrecision = function () {
        var _this = this;
        this.dashboardService.getDeliveryPrecision(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            console.log('Delivery Precision');
            console.log(data);
            _this.createMdrByLspChart(data);
            _this.createDrDeliveryChart(data);
            _this.createDrPrecisionChart(data);
        });
    };
    DashboardComponent.prototype.sortByDate = function (data) {
        return data.sort(function (a, b) {
            if (a.createdAt > b.createdAt) {
                return 1;
            }
            if (a.createdAt <= b.createdAt) {
                return -1;
            }
        });
    };
    DashboardComponent.prototype.setCostPerKiloData = function (data) {
        return data['aggregate'].map(function (day) {
            var currentDay = new Date(day.startDate);
            return { 'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(), 'cost': day.cost, 'weight': day.weight };
        });
    };
    DashboardComponent.prototype.sortDate = function (data) {
        return data.sort(function (b, a) {
            if (a.year > b.year) {
                return 1;
            }
            else if (a.year < b.year) {
                return -1;
            }
            else {
                if (a.month > b.month) {
                    return 1;
                }
                else if (a.month < b.month) {
                    return -1;
                }
                else {
                    if (a.day > b.day) {
                        return 1;
                    }
                    else if (a.day < b.day) {
                        return -1;
                    }
                    return 0;
                }
            }
        });
    };
    DashboardComponent.prototype.joinDates = function (dates, numberDays) {
        var data = [];
        this.sortDate(dates);
        for (var i = 1; (numberDays > 0) && (i < dates.length); i++) {
            if (dates[i - 1].date === dates[i].date) {
                dates[i - 1].countCreated += dates[i].countCreated;
                dates[i - 1].countFinished += dates[i].countFinished;
                data.push(dates[i - 1]);
                i++;
            }
            else {
                data.push(dates[i - 1]);
            }
            numberDays--;
        }
        return data;
    };
    DashboardComponent.prototype.formate = function (data) {
        var sum;
        var finished;
        for (var i = 0; i < 2; i++) {
            for (var j = 0; j < data[i].length; j++) {
                data[i][j].startDate = new Date(data[i][j].startDate);
                data[i][j] = {
                    'date': data[i][j].startDate.getDate() + '/' + (data[i][j].startDate.getMonth() + 1) + '/' + data[i][j].startDate.getFullYear(),
                    'day': data[i][j].startDate.getDate(),
                    'month': data[i][j].startDate.getMonth() + 1,
                    'year': data[i][j].startDate.getFullYear(),
                    'count': Object.values(data[i][j].shippingCompanies)
                };
                sum = 0;
                data[i][j].count.forEach(function (elem) { return sum += elem; });
                if (i === 0) {
                    data[i][j].countCreated = sum;
                    data[i][j].countFinished = 0;
                }
                else {
                    data[i][j].countCreated = 0;
                    data[i][j].countFinished = sum;
                }
            }
        }
        finished = this.joinDates(data[0].concat(data[1]), 30);
        data = {
            dates: [],
            valuesCreated: [],
            valuesFinished: [],
            sumCreated: 0,
            sumFinished: 0
        };
        for (var i = 0; i < finished.length; i++) {
            data.dates.push(finished[i].date);
            data.valuesCreated.push(finished[i].countCreated);
            data.valuesFinished.push(finished[i].countFinished);
            data.sumCreated += finished[i].countCreated;
            data.sumFinished += finished[i].countFinished;
        }
        data.dates.reverse();
        data.valuesCreated.reverse();
        data.valuesFinished.reverse();
        return data;
    };
    DashboardComponent.prototype.setOVData = function (data) {
        return data.map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countRightPathDRs + day.countWrongPathDRs
            };
        });
    };
    DashboardComponent.prototype.setFirstTimeRightData = function (data) {
        return data.map(function (day) {
            var currentDay = new Date(day.startDate);
            return {
                'day': currentDay.getDate() + '/' + (currentDay.getMonth() + 1) + '/' + currentDay.getFullYear(),
                'total': day.countRightPathDRs
            };
        });
    };
    DashboardComponent.prototype.setCostPerKiloPercentage = function (data) {
        var percentage = 0;
        var numberBefore = data.map(function (arr) { return (arr.cost / arr.weight); });
        if (numberBefore.length >= 2) {
            numberBefore = numberBefore[numberBefore.length - 2];
            var number = data.map(function (arr) { return (arr.cost / arr.weight); });
            number = number[number.length - 1];
            percentage = (number - numberBefore) / numberBefore * 100;
            percentage = Math.round(percentage * 100) / 100;
        }
        return percentage;
    };
    DashboardComponent.prototype.setDRsPercentage = function (data) {
        var percentage = 0;
        var numberBefore = data.map(function (arr) { return (arr.total); });
        if (numberBefore.length >= 2) {
            numberBefore = numberBefore[numberBefore.length - 2];
            var number = data.map(function (arr) { return (arr.total); });
            number = number[number.length - 1];
            percentage = (number - numberBefore) / numberBefore * 100;
            percentage = Math.round(percentage * 100) / 100;
        }
        return percentage;
    };
    DashboardComponent.prototype.createEmptyBarChartData = function () {
        var chartData = {
            number: 0,
            percentage: 0,
            categories: [],
            series: [],
            subtitle: '',
        };
        return chartData;
    };
    DashboardComponent.prototype.createMdrChart = function () {
        var _this = this;
        this.dashboardService.getMdrByCreated(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            _this.dashboardService.getMdrByDelivered(_this.dates, _this.ufSelected, _this.typeSelected, _this.lspSelected, _this.clientSelected).subscribe(function (data2) {
                var finished = [];
                finished.push(data['aggregate']);
                finished.push(data2['aggregate']);
                // console.log(finished);
                // console.log({"final":this.formate(finished)} );
                var finalData = _this.formate(finished);
                // console.log(finalData);
                finalData['createdTitle'] = 'COMPOSITE_BARS/created/title';
                finalData['finishedTitle'] = 'COMPOSITE_BARS/finished/title';
                finalData['createdTooltip'] = 'COMPOSITE_BARS/created/tooltip';
                finalData['finishedTooltip'] = 'Delivered';
                _this.mdrsChart.createChart(finalData, 'composite-bars', data['separate']);
            });
        });
    };
    DashboardComponent.prototype.createMdrByShippingChart = function () {
        var _this = this;
        this.dashboardService.getMdrByShippingCompany(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // get names of lsps
            var lsps = _this.lspList.map(function (lsp) { return lsp.name; });
            if (_this.lspSelected.length > 0) {
                lsps = _this.lspSelected;
            }
            // create record to merge count by lsp, backend separate data by day, month or year
            var mergedLspCount = {};
            // merge and add total
            _this.lspList.map(function (lsp) { return mergedLspCount[lsp.name] = 0; });
            data['aggregate'].reduce(function (acc, val) {
                acc[val.shippingCompany] += val.count;
                return acc;
            }, mergedLspCount);
            var chartData = [];
            // separate only selected lsps for plotting
            lsps.forEach(function (shippingCompany) {
                chartData.push({ name: shippingCompany, y: mergedLspCount[shippingCompany] });
            });
            if (chartData) {
                _this.mdrByLspChart.createChart(chartData, 'mdr-by-lsp', data['separate']);
            }
            else {
                var emptyData = _this.createEmptyBarChartData();
                _this.mdrByLspChart.createChart(emptyData, 'mdr-by-lsp', data['separate']);
            }
        });
    };
    DashboardComponent.prototype.createDrsByClient = function () {
        var _this = this;
        this.dashboardService.getDrByClient(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            data['aggregate'] = data['aggregate'].sort(function (a, b) { return a.count > b.count ? -1 : a.count < b.count ? 1 : 0; }).slice(0, 6);
            var chartData = [];
            data['aggregate'].forEach(function (client) {
                if (client.client === null) {
                    client.client = 'CLIENTE';
                }
                chartData.push({ name: client.client, y: client.count });
            });
            _this.pieComponentChart.createChart(chartData, 'dr-by-client', data['separate']);
        });
    };
    DashboardComponent.prototype.createOvsChart = function () {
        var _this = this;
        // related to first time right and delivered ovs charts
        this.dashboardService.getOVsDelivered(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // delivered ovs
            var ovData = _this.setOVData(data['aggregate']);
            var firstTimeData = _this.setFirstTimeRightData(data['aggregate']);
            var percentage = [];
            for (var i = 0; i < ovData.length; i++) {
                percentage.push({ 'day': ovData[i].day, 'total': firstTimeData[i].total * 100 / ovData[i].total });
            }
            if (ovData.length !== 0) {
                var chartData = {
                    number: ovData[ovData.length - 1].total,
                    percentage: Math.round(firstTimeData[firstTimeData.length - 1].total * 100 / ovData[ovData.length - 1].total),
                    categories: ovData.map(function (arr) { return arr.day; }),
                    series: [{
                            name: _this.translateService.instant('TOTAL_DRS'),
                            type: undefined,
                            visible: false,
                            data: ovData.map(function (arr) { return (arr.total); })
                        }, {
                            name: _this.translateService.instant('FIRST_TIME_RIGHT'),
                            type: undefined,
                            visible: false,
                            data: firstTimeData.map(function (arr) { return (arr.total); })
                        }, {
                            name: 'FTR Percentage',
                            type: undefined,
                            data: percentage.map(function (arr) { return (arr.total); })
                        }],
                    subtitle: _this.translateService.instant('TOTAL_DRS'),
                };
                _this.deliveredOvsChart.createChart(chartData, 'delivered-ovs', data['separate']);
            }
            else {
                var emptyData = _this.createEmptyBarChartData();
                _this.deliveredOvsChart.createChart(emptyData, 'delivered-ovs', data['separate']);
            }
            // first time right
            // if (firstTimeData.length !== 0) {
            //   const chartData = {
            //     number: firstTimeData[firstTimeData.length - 1].total,
            //     percentage: this.setDRsPercentage(firstTimeData),
            //     categories: firstTimeData.map(arr => arr.day),
            //     series: [{
            //       name: this.translateService.instant('FIRST_TIME_RIGHT'),
            //       type: undefined,
            //       data: firstTimeData.map((arr) => (arr.total))
            //     },
            //   ],
            //     subtitle: this.translateService.instant('FIRST_TIME_RIGHT'),
            //   };
            //   this.firstTimeRightChart.createChart(chartData, 'first-time-right', data['separate']);
            // } else {
            //   const emptyData = this.createEmptyBarChartData();
            //   this.firstTimeRightChart.createChart(emptyData, 'first-time-right', data['separate']);
            // }
        });
    };
    DashboardComponent.prototype.createDrByStatusChart = function () {
        this.dashboardService.getC02ByCreatedAt(this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            // console.log('co2', data);
            var resultObj = {};
            resultObj['dates'] = [];
            resultObj['valuesCreated'] = [];
            resultObj['valuesFinished'] = [];
            resultObj['sumCreated'] = 0;
            resultObj['sumFinished'] = 0;
            for (var _i = 0, _a = Object.entries(data['resultPerVehicle']); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                resultObj['dates'].push(key);
                resultObj['valuesCreated'].push(value['kgco2'] / 1000);
                resultObj['valuesFinished'].push(value['kg'] / 1000);
                resultObj['sumCreated'] += Math.floor(value['kgco2'] / 1000);
                resultObj['sumFinished'] += Math.floor(value['kg'] / 1000);
                resultObj['createdTitle'] = 'CO2/created/title';
                resultObj['finishedTitle'] = 'CO2/finished/title';
                resultObj['createdTooltip'] = 'CO2/created/tooltip';
                resultObj['finishedTooltip'] = 'CO2/finished/tooltip';
                resultObj['eco'] = 'eco';
            }
        });
    };
    DashboardComponent.prototype.createCostKgChart = function () {
        var _this = this;
        // related to cost per kilo chart
        this.dashboardService.getCostKg('day', this.dates, this.ufSelected, this.typeSelected, this.lspSelected, this.clientSelected).subscribe(function (data) {
            var dataDay = _this.setCostPerKiloData(data);
            if (dataDay.length !== 0) {
                var dataNumber = dataDay.map(function (arr) { return (arr.cost / arr.weight); });
                var chartData = {
                    number: "$ " + Math.round(dataNumber[dataNumber.length - 1] * 100) / 100,
                    percentage: _this.setCostPerKiloPercentage(dataDay),
                    categories: dataDay.map(function (arr) { return arr.day; }),
                    series: [{
                            name: _this.translateService.instant('COST_KG'),
                            type: undefined,
                            data: dataDay.map(function (costPerKilo) { return (costPerKilo.cost / costPerKilo.weight); })
                        }, {
                            name: _this.translateService.instant('COST'),
                            visible: false,
                            type: 'line',
                            data: dataDay.map(function (cost) { return cost.cost; })
                        }, {
                            name: _this.translateService.instant('WEIGHT'),
                            visible: false,
                            type: 'line',
                            data: dataDay.map(function (weight) { return weight.weight; })
                        }],
                    subtitle: _this.translateService.instant('COST_PER')
                };
                _this.costPerKiloChart.createChart(chartData, 'cost-per-kilo', data['separate']);
            }
            else {
                var emptyData = _this.createEmptyBarChartData();
                _this.costPerKiloChart.createChart(emptyData, 'cost-per-kilo', data['separate']);
            }
        });
        // Occurrence Word Map
        this.dashboardService.getOccurrenceWords(this.dates).subscribe(function (elem) {
            _this.wordcloudChart.createChart(elem, 'wordcloud');
            // console.log({word:elem});
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
