import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';

import { CockpitComponent } from './cockpit.component';

const routes: Routes = [
  { path: 'cockpit', component: CockpitComponent, canActivate: [RoleGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CockpitRoutingModule { }
