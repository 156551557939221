import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {DrService} from '../../providers/dr.service';
import {MapsAPILoader} from '@agm/core';
import {Router} from '@angular/router';
import {MdrService} from '../../providers/mdr.service';
import {MDR} from '../../models/mdr';

@Component({
  selector: 'app-mdr-management',
  templateUrl: './mdr-management.html',
  styleUrls: ['./mdr-management.scss']
})
export class MdrManagementComponent implements OnInit {
  columns = [
    {value: 'observation', name: 'MDR_MANAGEMENT/table/observation'},
    {value: 'status', name: 'MDR_MANAGEMENT/table/status'},
    {value: 'createBy', name: 'MDR_MANAGEMENT/table/userName'},
    {value: 'createdAt', name: 'MDR_MANAGEMENT/table/date'}
  ];
  mdrStatusTranslated = {
    'MDR em validação': 'LOGISTIC-ORDER/mdr-status/validation',
    'MDR em criação': 'LOGISTIC-ORDER/mdr-status/creation',
    'MDR devolvida por LSP':  'LOGISTIC-ORDER/mdr-status/returned-lsp',
    'MDR aguardando LSP': 'LOGISTIC-ORDER/mdr-status/waiting-lsp',
    'MDR aprovada LSP': 'LOGISTIC-ORDER/mdr-status/approved-lsp',
    'Motorista e veiculo definidos': 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined',
    'Veículo carregado': 'LOGISTIC-ORDER/mdr-status/vehicle-loaded',
    'Carga entregue com sucesso': 'LOGISTIC-ORDER/mdr-status/cargo-delivered' ,
    'Carga retornada total': 'LOGISTIC-ORDER/mdr-status/cargo-returned' ,
    'Entrega de carga certificada pelo LSP': 'LOGISTIC-ORDER/mdr-status/certified-delivery',
    'MDR paga / concluída': 'LOGISTIC-ORDER/mdr-status/mdr-paid',
  };
  data: any[] = [];

  constructor(public dialog: MatDialog,
              public mdrService: MdrService,
              private translateService: TranslateService,
              public router: Router) {
  }

  ngOnInit() {
    this.mdrService.getAllMDR().subscribe( (data: MDR[]) => {
      this.data = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação') {
          data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('EDB-TP' + ('0000000' + data[i].id).slice(-6)) );
          this.data.push(data[i]);
        }
      }
      this.data.forEach( (elem) => {
        if (this.mdrStatusTranslated[elem.status])  {
          elem.status = this.translateService.instant(this.mdrStatusTranslated[elem.status]);
        }
      });
    });
  }

  goTo(row, that) {
    that.router.navigate(['logistic-order', row.id]);
  }
}
