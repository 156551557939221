export class CostAvoidanceMDR {
  id: number;
  costAvoidance: string;
  idMDR: number;
  value: number;
  observation: string;

  constructor() {
    this.id = null;
    this.costAvoidance = '';
    this.idMDR = null;
    this.value = null;
    this.observation = '';
  }
}
