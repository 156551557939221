import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OV } from '../models/ov';
import {DR} from '../models/dr';
import {environment} from '../../environments/environment';
import {Message} from '../models/message';

const base_url: string = environment.apiUrl;
const api_key: string = environment.apiKey;

@Injectable()
export class ChatService {
    public token: string;

    constructor(private http: HttpClient) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

  public newMessagesAvailable(id: number, chatType: number): Observable<any> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    return this.http.get(base_url + 'chat/UnseenMessages/' + id + '/' + chatType, {headers: header}).map( response => {
      return response;
    });
  }

  public getByChannel(id: number, chatType: number): Observable<Message[]> {
    const token = JSON.parse(localStorage.getItem('currentUser')).token;
    const header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
    // console.log(header);
    return this.http.get(base_url + 'chat/All/' + id + '/' + chatType, {headers: header}).map( (response: Message[]) => {
      return response;
    });
  }
}
