<div class="background">
  <div class="text-center">
    <img id="logo" src="../../assets/images/logos-name-vertical-transp.png">
  </div>
  <div class="text-center text-white m-5">
    <h4><b>{{ 'LOGIN/hi' | translate }}</b> {{ 'LOGIN/authenticate' | translate }}</h4>
  </div>
  <mat-card class="login-card mx-auto container-fluid">
    <form #form="ngForm">
      <mat-form-field class="w-100">
        <input matInput placeholder="{{ 'LOGIN/email' | translate }}" [(ngModel)]="username" name="email" required>
      </mat-form-field>
      <mat-form-field class="w-100">
        <input matInput type="password" [(ngModel)]="password" placeholder="{{ 'LOGIN/password' | translate }}" name="password" required>
      </mat-form-field>
      <div class="mt-4 text-center">
        <button mat-raised-button
          type="submit"
          color="accent"
          class="login text-white px-3 py-1"
          (click)="login()">
          {{ 'LOGIN' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
  <div class="text-center mt-4">
    <button mat-button (click)="forgotPassword()" class="text-white">
      {{ 'PASSWORD_FORGOT' | translate }}
    </button>
  </div>
</div>
