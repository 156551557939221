<div style="max-height: 550px;">
  <div class="mat-elevation-z8" style="margin-bottom: 30px;">
    <!-- <span (click)="downloadReport()"><mat-icon class="fa fa-file-download ml-1"></mat-icon></span> -->
    <div style="padding: 15px 20px; display: flex; justify-content: space-between;">
      <i class="fa fa-file-excel" matTooltip="Download excel" (click)="downloadReport()"></i>
      <i class="fa fa-file-pdf" matTooltip="Download PDF" (click)="downloadPDF()"></i>
    </div>
    <div class="more-info">
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lat' | translate }}</span>: {{ data.dr['lat'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/lng' | translate }}</span>: {{ data.dr['lng'].toFixed(6) }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/userArrivalTime' | translate }}</span>: {{ data.dr['userArrivalTime'] | date: 'medium'}}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/arrivalTime' | translate }}</span>: {{ data.dr['arrivalTime'] | date: 'medium' }}</p>
      <p><span style="font-weight: bold">{{ 'DELIVERY_ROUTE/hus' | translate }}</span>: {{ HUs.length }}</p>
      <button *ngIf="role !== 7" mat-raised-button class="w-100 primary-button col-md-2  offset-md-8" (click)="addNewRow()">Add</button>
    </div>
    <div *ngIf="dataSource.data.length > 0" class="col-md-12">
      <mat-table #table2 [dataSource]="dataSource" matSort>

        <!-- <ng-container matColumnDef="salesDocument">
          <mat-header-cell *matHeaderCellDef mat-sort-header> OV </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.salesDocument}} </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="assignmentInformation">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Prolog </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.prolog}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="customerCity">
          <mat-header-cell *matHeaderCellDef mat-sort-header> UOM </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.uoc}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="customerAddress">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Serial </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.serial}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="salesOrderQuantity">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.qtd}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="materialCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Material </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.material}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="materialDescription">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Material Description</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.materialDescription}} </mat-cell>
          </ng-container>

        <ng-container matColumnDef="customerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.client}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="salesOrganization">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Sales Organization </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.obs}} </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="receiverPhone">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/phone' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.receiverPhone}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="receiverName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.receiverName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateScheduling">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/date' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.dateScheduling | date:'d/M/yy, h:mm a'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DELIVERY_ROUTE/status' | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status | translate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nameOfTheEndUserForFTrade">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Customer </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.nameOfTheEndUserForFTrade }} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let element"> <span (click)="openDialog(element)">{{ 'DELIVERY_ROUTE/edit' | translate }}</span> </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>

      </mat-table>
      <mat-paginator #paginator
        [pageSize]="5"
        [pageSizeOptions]="[5, 10]"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>
</div>
