<div class="app-cockpit">
  <div class="mx-5 my-4">
    <p class="mat-h1">{{ 'COCKPIT/title' | translate }}</p>
    <p class="mx-4">{{ 'COCKPIT/sub-title' | translate }} </p>
  </div>
  <div class="colored-background d-flex flex-column h-100">

    <div class="d-flex minHeight justify-content-between mx-5">
      <mat-form-field class="mt-3 pt-1 pl-3 mr-2 rounded-corners col-md-6" floatLabel="never">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let chip of searchChips"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chip)">
            {{chip.name}}
            <i matChipRemove class="fa fa-times ml-1" *ngIf="removable"></i>
          </mat-chip>
          <!-- <mat-label><i class="fa fa-search mr-1"></i>{{ 'CONTROL_TOWER/search' | translate }}</mat-label> -->
          <input placeholder=" {{ 'COCKPIT/type' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <div class="searchToggle">
          {{ 'COCKPIT/all' | translate }}<mat-slide-toggle [(ngModel)]="search_toggle" (change)="applyFilter()">{{ 'COCKPIT/some' | translate }}</mat-slide-toggle>
        </div>
      </mat-form-field>

      <button mat-icon-button aria-label="Excel Export" type="button" class="export-button" (click)="exportAsXlsx()">
        <i class="fas fa-file-download"></i>
      </button>

      <mat-form-field floatLabel="never" class="mt-3 pt-1 pl-3 mx-2 rounded-corners col-md-4">
        <input matInput
          placeholder="{{ 'COCKPIT/date_search' | translate }}"
          [(ngModel)]="searchDates"
          (dateTimeChange)="dateChanged()"
          [selectMode]="'range'"
          [owlDateTimeTrigger]="rangeDatepicker"
          [owlDateTime]="rangeDatepicker">
        <owl-date-time #rangeDatepicker></owl-date-time>
      </mat-form-field>

      <button mat-icon-button aria-label="Map" type="button" class="export-button" (click)="openMap()">
        <i class="fas fa-map-marked"></i>
      </button>
    </div>

    <div class="columns flex-fill">
      <div class="pt-3 pb-3 mt-1 mx-5 d-flex justify-content-between h-100">
        <!-- para remover a coluna com 'status indefinido', use: "let status of statusList" -->
        <!-- let status of statusList.concat(undefinedStatus); -->
        <!-- let status of statusList; -->
        <mat-card *ngFor="let status of displayList.concat(undefinedStatus); let i = index; trackBy: trackByFn"
          [id]="'cockpit-list-'+status"
          class="card-column p-0 d-flex flex-column">
          <mat-card-title fxLayout="column" fxLayoutAlign="space-evenly center">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
              <div *ngFor="let color of colorList" class="color-line">
                  <span *ngIf="color != selectedColor" [ngClass]="color" (click)="setColor(color)" matTooltip="{{ 'COCKPIT/dr/'+color | translate }}">
                    {{ colors[status][color] }}
                  </span>
                  <mat-chip *ngIf="color == selectedColor" [ngClass]="['chip',color]" (click)="setColor(color)" [matTooltip]="'COCKPIT/dr/'+color | translate">
                    {{ colors[status][color] }}
                  </mat-chip>
              </div>
            </div>
            <p>{{status | translate}}</p>
          </mat-card-title>
          <mat-card-content class="d-flex flex-fill flex-column">
            <ng-container *ngFor="let dr of drLists[status];trackBy: trackByFn;">
              <app-dr-card
                *ngIf = "!dr.color || !selectedColor || dr.color === selectedColor"
                [dr] = "dr"
                class="px-2">
              </app-dr-card>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </div>
</div>
