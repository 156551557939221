<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'TAX/title' | translate }}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-9">
          <span class="title">{{ 'TAX/ordinary' | translate }}</span>
          <mat-icon (click)="getAllPriceWithKm()" matTooltip="{{ 'TAX/recalculate' | translate }}" font class="fa fa-calculator" style="font-size: 12px; margin-left: 10px;" aria-hidden="true"></mat-icon>
        </div>
        <button mat-icon-button
                (click)="download()">
          <i class="fas fa-file-download"></i>
        </button>
      </div>
      <!-- <div class="row" *ngIf="nfePrice !== undefined" >
        <mat-form-field class="col-md-12">
          <input disabled matInput type="text" placeholder="{{ 'TAX/value_nf_system' | translate }}" [ngModel]="nfePrice | currency:'BRL'">
        </mat-form-field>
      </div> -->
      <div class="row">
        <div class="col-12" style="text-align:right">
          <!-- edit values -->
          <i (click)="editValues()"
            *ngIf="
            (role === 1 || role === 12)
            &&
            [MDR_STATUS.MDR_PAGA_CONCLUIDA,MDR_STATUS.ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP].indexOf(mdr.status) === -1
            &&
            editing_freight === false
            "
            matTooltip="{{ 'TAX/edit-values' | translate }}"
            font
            class="fa fa-pencil-alt click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
          <!-- save values -->
          <i (click)="saveValues()"
            *ngIf="
            (role === 1 || role === 12)
            &&
            [MDR_STATUS.MDR_PAGA_CONCLUIDA,MDR_STATUS.ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP].indexOf(mdr.status) === -1
            &&
            editing_freight === true
            "
            matTooltip="{{ 'TAX/save-values' | translate }}"
            font
            class="fa fa-save click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
          <!-- recalculate values -->
          <i (click)="getAllPriceWithKm()"
            *ngIf="editing_freight === true"
            matTooltip="{{ 'TAX/recalculate' | translate }}"
            font
            class="fa fa-calculator click-button"
            style="font-size: 12px; margin-left: 10px;"
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div class="row" *ngIf="price !== undefined" >
        <!-- <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input1 *ngIf="update" type="text" placeholder="{{ 'TAX/value_nf' | translate }}" [ngModel]="mdr.totalNF | currency:'BRL'" (blur)="sanitizeInputMdr('totalNF', $event, input1)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Total:<span> {{mdr.totalNF | currency:'BRL'}}</span></p>
        </div> -->
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput type="text" placeholder="{{ 'TAX/total_km' | translate }}" [(ngModel)]="mdr.totalKM" [disabled]="editing_freight === false">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Distância:<span> {{mdr.totalKM}} KM</span></p>
        </div>
        <!-- <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input2 *ngIf="update" type="text" placeholder="{{ 'TAX/freight' | translate }}" [ngModel]="price.price | currency:'BRL'"  (blur)="sanitizeInputPrice('price', $event, input2)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Frete:<span> {{price.price | currency:'BRL'}}</span></p>
        </div> -->
      </div>
      <div class="row" *ngIf="price !== undefined">
        <!-- <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input4 *ngIf="update" type="text" placeholder="{{ 'TAX/tax_freight' | translate }}" [ngModel]="price.taxedPrice | currency:'BRL'"  (blur)="sanitizeInputPrice('taxedPrice',$event, input4)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Frete com Taxas:<span> {{price.taxedPrice | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input5 *ngIf="update" type="text" placeholder="{{ 'TAX/ad_valorem' | translate }}" [ngModel]="price.adValorem | currency:'BRL'"  (blur)="sanitizeInputPrice('adValorem', $event, input5)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Ad Valorem:<span> {{price.adValorem | currency:'BRL'}}</span></p>
        </div>
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input6 *ngIf="update" type="text" placeholder="{{ 'TAX/gris' | translate }}" [ngModel]="price.gris | currency:'BRL'"  (blur)="sanitizeInputPrice('gris',$event, input6)">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Gris:<span> {{price.gris | currency:'BRL'}}</span></p>
        </div> -->
        <mat-form-field *ngIf="role !== 5" class="col-md-3">
          <input matInput #input7 *ngIf="update" type="text" placeholder="{{ 'TAX/total' | translate }}" [ngModel]="price.total | currency:'$'"  (blur)="sanitizeInputPrice('total', $event, input7)" [disabled]="editing_freight === false">
        </mat-form-field>
        <div *ngIf="role === 5" class="col-md-3">
          <p>Total:<span> {{price.total | currency:'$'}}</span></p>
        </div>
        <div class="col-md-1" style="text-align:right">
          <i class="fa" [ngClass]="payField.color ? [ payment.icons[payField.color] , payment.classes[payField.color] ] : ''" matSuffix [matTooltip]="payment.tooltips[payField.color]"></i>
        </div>
      </div>
      <!--<div class="row" >-->
        <!--<div class="col-md-9">-->
          <!--<span class="title-taxa">Total: </span>-->
          <!--<span class="data-taxa">R$ {{amountTotal()}} </span>-->
        <!--</div>-->
      <!--</div>-->
      <div class="row">
        <div class="col-md-9" class="pad">
          <span class="title">{{ 'TAX/uoc' | translate }}</span><i class="fa fa-eye" (click)='showAll = !showAll'></i>
        </div>
      </div>
      <div *ngFor="let UOC of UOCs;let i = index;">
       <div *ngIf="showAll || UOCs[i].status !== false" class="row">
        <p class="col-md-5" [matTooltip]="UOCs[i].uoc"> {{UOCs[i].uoc}}</p>
        <!-- <mat-form-field class="col-md-3">
          <mat-select   placeholder="{{ 'TAX/type' | translate }}" [(ngModel)]="UOCs[i].uoc">
            <mat-option *ngFor="let elem of uoc" [value]="elem.name">
              {{ elem.name }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <p class="col-md-2">{{UOCs[i].value | currency: '$'}}</p>
        <p class="col-md-1"><i class="fa fa-check-circle" style="color: green;" *ngIf="UOCs[i].status" matTooltip="{{ 'TAX/statusApproved' | translate }}"></i><i class="fa fa-times-circle" style="color: red;" matTooltip="{{ 'TAX/statusReproved' | translate }}"  *ngIf="UOCs[i].status === false"></i></p>
        <p class="col-md-2"><i class="fa fa-thumbs-up" (click)='approveUOC(UOCs[i])' *ngIf="role === 1 && UOCs[i].status === null"></i><span *ngIf="UOCs[i].status !== null" matTooltip="{{ 'TAX/solvedBy' | translate }}">{{UOCs[i].approvedBy}}</span></p>
        <p class="col-md-1"><i class="fa fa-trash" (click)='removeUOC(UOCs[i])' *ngIf="role === 1 && UOCs[i].status === null"></i><span *ngIf="UOCs[i].status !== null" matTooltip="{{ 'TAX/solvedAt' | translate }}">{{UOCs[i].approvedAt | date: "dd/MM/yy, h:mm a"}}</span></p>
        <p class="col-md-1"><i class="fa fa-trash" (click)='removeUOC(UOCs[i])' *ngIf="role === 1 && UOCs[i].status !== null"></i></p>
        <!-- <mat-form-field class="col-md-2">
          <input   matInput type="text" placeholder="{{ 'TAX/uoc_value' | translate }}" [(ngModel)]="UOCs[i].value">
        </mat-form-field> -->
         <!--<mat-form-field class="col-md-2">-->
           <!--<input   matInput type="text" placeholder="Multiplicador" [(ngModel)]="UOCs[i].multiplicator">-->
         <!--</mat-form-field>-->
        <!--<mat-form-field class="col-md-4">-->
          <!--<input   matInput type="text" placeholder="Observações"  [(ngModel)]="UOCs[i].text">-->
        <!--</mat-form-field>-->
        <p class="col-md-1" style="text-align:right">
          <i class="fa" [ngClass]="[ payment.icons[UOC.color] , payment.classes[UOC.color] ]" matSuffix [matTooltip]="payment.tooltips[UOC.color]"></i>
        </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/uoc_total' | translate }} </span>
          <span class="data-taxa">{{sumUOC*100 | currency:'$'}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <button mat-raised-button class=' button green-button text-white' (click)="sendApprovalRequest()">{{ 'TAX/approval_request' | translate }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9" class="pad">
          <span class="title">{{ 'TAX/copq' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let COPQ of COPQs; let i = index;">
        <div class ="row">
          <p class="col-md-5"> {{COPQs[i].copq}}</p>
          <p class="col-md-2">{{COPQs[i].value | currency: '$'}}</p>
          <p class="col-md-2"><i class="fa fa-times" (click)='removeCOPQ(COPQs[i])' *ngIf="role === 1"></i></p>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/copq_total' | translate }}</span>
          <span class="data-taxa">{{sumCOPQ*100 | currency:'$'}}</span>
        </div>
      </div>
      <!-- <div class="row" >
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/total' | translate }}</span>
          <span class="data-taxa">{{(sumUOC*100) + (price.total)  | currency:'$'}}</span>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-md-9">
          <span class="title">{{ 'TAX/additional_tax' | translate }}</span>
        </div> -->
        <!--<div class="cd-md-1">-->
          <!--<button   mat-raised-button class=' button green-button text-white' (click)="addCOPQ()">Adiconar COPQ</button>-->
        <!--</div>-->
        <!--<div class="cd-md-1">-->
          <!--<button mat-raised-button class=' button green-button text-white' (click)="addCOPQ()">Adiconar COPQ</button>-->
        <!--</div>-->
      <!-- </div>
      <div *ngFor="let COPQ of COPQs;let i = index;">
        <div class="row" >
          <mat-form-field class="col-md-3">
            <mat-select   placeholder="{{ 'TAX/type' | translate }}" [(ngModel)]="COPQs[i].copq">
              <mat-option *ngFor="let elem of copq" [value]="elem.name">
                {{ elem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-3">
            <input    matInput type="text" placeholder="{{ 'TAX/value' | translate }}"  [(ngModel)]="COPQs[i].value">
          </mat-form-field>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-9">
          <span class="title-taxa">{{ 'TAX/total' | translate }}: </span>
          <span class="data-taxa">{{amountCOPQ() | currency:'$'}}</span>
        </div>
      </div> -->
      <!--<div class="row">-->
        <!--<div class="col-md-10">-->
          <!--<span class="title">Redução de Gastos</span>-->
        <!--</div>-->
        <!--&lt;!&ndash;<div class="cd-md-1" >&ndash;&gt;-->
          <!--&lt;!&ndash;<button    mat-raised-button class='button green-button text-white' (click)="addCA()">Adiconar CA</button>&ndash;&gt;-->
        <!--&lt;!&ndash;</div>&ndash;&gt;-->
      <!--</div>-->
      <!--<div *ngFor="let costAvoidance of costAvoidances;let i = index;">-->
        <!--<div class="row" >-->
          <!--<mat-form-field class="col-md-3">-->
            <!--<input   matInput type="text" placeholder="Tipo de Redução" [(ngModel)]="costAvoidances[i].costAvoidance">-->
          <!--</mat-form-field>-->
          <!--<mat-form-field class="col-md-3">-->
            <!--<input   matInput type="text" placeholder="Valor" [(ngModel)]="costAvoidances[i].value">-->
          <!--</mat-form-field>-->
          <!--<mat-form-field class="col-md-4">-->
            <!--<input   matInput type="text" placeholder="Observações" [(ngModel)]="costAvoidances[i].observation">-->
          <!--</mat-form-field>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="row" >-->
        <!--<div class="col-md-9">-->
          <!--<span class="title-taxa">Total: </span>-->
          <!--<span class="data-taxa">R$ {{amountCA()}} </span>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  </div>
</div>
</div>
