import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { MdrService } from '../../../providers/mdr.service';

@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss']
})
export class SlaComponent implements OnInit {
  @Input() id: number;
  @Input() type: string;
  public isSLA = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mdrService: MdrService
  ) { }

  ngOnInit() {
    const id$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.mdrService.isSLA(parseInt(params.get('id'), 10)))
    );
    id$.subscribe((response: any) => {
      this.isSLA = response;
    });
  }

  toggleSLA() {
    if ( this.isSLA ) {
      this.unsetSLA();
    } else {
      this.setSLA();
    }
  }

  setSLA() {
    this.mdrService.setSLA(this.id).subscribe((response) => {
      this.isSLA = true;
    });
  }

  unsetSLA() {
    this.mdrService.unsetSLA(this.id).subscribe((response) => {
      this.isSLA = false;
    });
  }

}
