<div class="mx-5 my-4">
  <p class="mat-h1">{{'Mensagens em Grupo'}}</p>
</div>
<div class="colored-background">
  <div class="mx-5">
    <p class="ml-5 py-4 my-0 mat-h1 text-white">{{ pickupID ? pickupID : (channel | mdrId) }}</p>
    <mat-card class="p-0 rounded-corners">
      <mat-card-header class="align-items-center pl-2">
        <span *ngFor="let u of users; let i = index" [ngClass]="{'me': (i === 0)}">{{u}}<span *ngIf="i < users.length-1" class="pr-1">, </span></span>
      </mat-card-header>
      <mat-card-content class="mb-0" #scrollMe>
        <mat-list class="mx-3">
          <mat-list-item *ngFor="let message of messages"
            class="my-2 rounded-corners mat-elevation-z2"
            [ngClass]="[(message.action === undefined && message.user.id === user.id)? 'message-mine': 'message-theirs']">
            <h4 mat-line *ngIf="message.action === undefined && message.user.id !== user.id">
              <b>{{message.user.name}}</b>
            </h4>
            <p mat-line *ngIf="message.action === undefined" class="align-items-end">
              <span style="font-size: 12px;"> {{message.content}} </span>
              <span style="font-size: 10px; float: right; "> {{ message.createdAt | date: "dd/MM/yy, h:mm a"}}</span>
            </p>
            <p mat-line *ngIf="message.action === action.JOINED">
              <span> <b>{{message.user.name}}</b> joined to the conversation. </span>
            </p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-card-actions class="d-flex align-items-center">
        <mat-form-field class="ml-3">
          <input matInput
            #inputMessage
            maxlength="140"
            class="w-100"
            [placeholder]="'Insira seu texto aqui'"
            [(ngModel)]="messageContent"
            (keyup.enter)="sendMessage(messageContent)">
          <mat-hint align="end">{{inputMessage.value.length}}/140</mat-hint>
        </mat-form-field>
        <button mat-icon-button class="m-2">
          <i class="fa fa-comment-alt" aria-label="send message"></i>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
