import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var api_key = environment.apiKey;
var UploadFileService = /** @class */ (function () {
    function UploadFileService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    UploadFileService.prototype.pushFileToStorage = function (files, mdr, entity) {
        if (entity === 'occurrence') {
            return this.attachFileToOccurrence(files, mdr);
        }
        var formdata = new FormData();
        for (var _i = 0, _a = Object.keys(files); _i < _a.length; _i++) {
            var i = _a[_i];
            formdata.append('file', files[i]);
        }
        console.log('Ready To send File');
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var param = new HttpParams().set('id', mdr).append('entity', entity);
        // const req = new HttpRequest('POST', base_url + 'occurrence/files', formdata, {
        var req = new HttpRequest('POST', base_url + 'file/aqui', formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: header,
            params: param,
        });
        return this.http.request(req);
    };
    UploadFileService.prototype.attachFileToOccurrence = function (files, occurrenceID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        var param = new HttpParams().set('id', occurrenceID);
        var formdata = new FormData();
        for (var _i = 0, _a = Object.keys(files); _i < _a.length; _i++) {
            var i = _a[_i];
            formdata.append('file', files[i]);
        }
        var req = new HttpRequest('POST', base_url + 'occurrence/files', formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: header,
            params: param,
        });
        return this.http.request(req);
    };
    UploadFileService.prototype.updateFileInfo = function (fileInfo) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'file/updateInfo', { info: fileInfo }, { headers: header })
            .map(function (response) {
            return true;
        });
    };
    UploadFileService.prototype.findByEntity = function (id, entity) {
        if (entity === 'occurrence') {
            return this.getOccurrenceAttachments(id);
        }
        if (id === null) {
            return null;
        }
        else if (id === undefined) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'file/findByEntity', { id: id, entity: entity }, { headers: header })
            .map(function (response) {
            var OVdata = response;
            return OVdata;
        });
    };
    UploadFileService.prototype.getOccurrenceAttachments = function (occurrenceID) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.get(base_url + 'occurrence/' + occurrenceID + '/files', { headers: header }).map(function (response) {
            return response;
        });
    };
    UploadFileService.prototype.findByEntityWithInfo = function (id, entity) {
        if (id === null) {
            return null;
        }
        else if (id === undefined) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'file/findByEntityWithInfo', { id: id, entity: entity }, { headers: header })
            .map(function (response) {
            var OVdata = response;
            return OVdata;
        });
    };
    UploadFileService.prototype.deleteByEntity = function (id, entity) {
        if (id === null) {
            return null;
        }
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('x-api-key', api_key);
        return this.http.post(base_url + 'file/deleteByEntity', { id: id }, { headers: header })
            .map(function (response) {
            var OVdata = response;
            return OVdata;
        });
    };
    UploadFileService.prototype.getImage = function (imageUrl) {
        if (imageUrl === null) {
            return null;
        }
        var header = new HttpHeaders();
        return this.http.get(imageUrl, { headers: header, responseType: 'blob' })
            .map(function (res) {
            return res;
        });
    };
    return UploadFileService;
}());
export { UploadFileService };
