import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
var DrCardComponent = /** @class */ (function () {
    function DrCardComponent(snackBar) {
        this.snackBar = snackBar;
        this.tooltip = [];
        this.pickupID = null;
        this.shippingCompany = '';
        this.truckSaw = false;
        this.novasMensagens = {
            5: false,
            6: false
        };
        this.icons = {
            'red': 'fa-exclamation-circle',
            'yellow': 'fa-exclamation-triangle',
            'green': '',
            'undefined': 'fa-hourglass-half'
        };
        this.show = true;
    }
    DrCardComponent.prototype.getStatusClass = function () {
        return 'dr-status-' + this.color;
    };
    DrCardComponent.prototype.ngOnInit = function () {
        this.color = this.dr['color'];
        this.tooltip = this.dr['tooltip'];
        this.truckSaw = this.dr['truckSaw'];
        this.novasMensagens = this.dr['novasMensagens'];
        if (this.dr.mdr !== undefined) {
            this.shippingCompany = this.dr.mdr.shippingCompany;
            this.pickupID = this.dr.mdr.pickupID;
            if (this.dr.mdr.type === 'DTR') {
                this.dr.mdr.type = 'INV';
            }
        }
    };
    DrCardComponent.prototype.setRedirectUrl = function () {
        if (this.dr.status === 'BOOKING') {
            return (window.location.origin + '/dr-validation/' + this.dr.id);
        }
        if (this.dr.idMDR !== null && this.dr.idMDR !== 0) {
            return (window.location.origin + '/logistic-order/' + this.dr.idMDR);
        }
        return (window.location.origin + '/dr-validation/' + this.dr.id);
    };
    return DrCardComponent;
}());
export { DrCardComponent };
