<div class="mx-5 my-4 d-flex bd-highlight">
  <div class="p-2 flex-grow-1 bd-highlight">
    <p class="mat-h1">{{ 'DR_BOOKING/title' | translate }}</p>
    <p class="mx-4">{{ 'DR_BOOKING/sub-title' | translate }}</p>
  </div>
  <div class="p-2 bd-highlight">
    <i class="fas fa-magic"></i>
  </div>
  <div class="p-2 bd-highlight">
    <input [(ngModel)]="paste_input" (paste)="fillFromClipboard($event)" matInput placeholder="{{ 'ATTACHMENTS/paste' | translate }}">
  </div>
</div>

<div class="colored-background pt-5">
  <mat-card class="mx-5 mb-5 d-flex flex-row flex-wrap align-items-center rounded-corners">
    <form #form="ngForm" class="d-flex flex-wrap">
      <mat-form-field class="col-md-12">
        <input matInput required
          placeholder="{{ 'DR_BOOKING/text/sales-orders' | translate }}"
          [(ngModel)]="salesOrders"
          name="salesOrders">
      </mat-form-field>
      <div class="col-md-6">
        <mat-form-field style="width: calc(100% - 28px);">
          <input matInput required #addrSearch
                 type="text"
                 autocapitalize="off"
                 spellcheck="off"
                 placeholder="{{ 'DR_BOOKING/text/delivery-address' | translate }}"
                 name="deliveryAddress"
                 id="deliveryAddress"
                 [(ngModel)]="dr.deliveryAddress"
                 (change)="convertAddressToLatLng()">
        </mat-form-field>
        <span (click)="toggleMap()"><i class="fa fa-map"></i></span>
      </div>
      <div class="col-md-6 d-flex flex-row p-0">
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lat' | translate }}"
            id="latitudeAddress"
            name="latitudeAddress"
            [(ngModel)]="dr.lat"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
        <mat-form-field class="col-md-6">
          <input matInput required
            placeholder="{{ 'DR_BOOKING/text/lng' | translate }}"
            id="longitudeAddress"
            name="longitudeAddress"
            [(ngModel)]="dr.lng"
            (change)="convertLatLngToAddress()">
        </mat-form-field>
      </div>
      <div *ngIf="map_show" class="col-md-12">
        <agm-map [latitude]="dr.lat" [longitude]="dr.lng" [mapTypeControl]="true" [zoom]="15">
          <agm-marker [iconUrl]="red_marker" [latitude]="dr.lat" [longitude]="dr.lng"></agm-marker>
          <agm-marker *ngFor="let m of map_data" [iconUrl]="blue_marker" [latitude]="m.lat" [longitude]="m.lng"></agm-marker>
        </agm-map>
        <br>
      </div>
      <mat-form-field class="col-md-6">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/name-receptor' | translate }}" [(ngModel)]="dr.receiverName" name="receiverName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required mask="(00) 00009-0000" type="text" placeholder="{{ 'DR_BOOKING/text/cellphone-recept' | translate }}" [(ngModel)]="dr.receiverPhone" name="receiverPhone">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/name-supervisor' | translate }}" [(ngModel)]="dr.supervisorName" name="dr.supervisorName">
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <input matInput required mask="(00) 00009-0000"  placeholder="{{ 'DR_BOOKING/text/cellphone-higher' | translate }}" [(ngModel)]="dr.supervisorPhone" name="dr.supervisorPhone">
      </mat-form-field>

      <mat-form-field class="col-md-6">
        <input matInput required name="dateCollect" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" [placeholder]="'DR_BOOKING/text/date' | translate" [(ngModel)]="dr.dateScheduling">
        <owl-date-time #dt></owl-date-time>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/site' | translate }}" [(ngModel)]="dr.site" name="dr.site" (change)="checkSite()">
        <i class="fa" [ngClass]="site.color ? [ site.icons[site.color] , site.classes[site.color] ] : ''" matSuffix [matTooltip]="site.tooltips[site.color]"></i>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <input matInput required placeholder="{{ 'DR_BOOKING/text/infra' | translate }}" [(ngModel)]="dr.infraType" name="dr.infraType">
      </mat-form-field>

      <mat-form-field class="col-md-4">
        <input matInput placeholder="{{ 'DR_BOOKING/text/unification-id' | translate }}" [(ngModel)]="dr.unificationId" name="unificationId">
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <input matInput placeholder="{{ 'DR_BOOKING/text/site-team' | translate }}" [(ngModel)]="dr.siteTeam" name="siteTeam">
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>{{'DR_BOOKING/text/client' | translate}}</mat-label>
        <mat-select [(ngModel)]="dr.client" name="client">
          <mat-option *ngFor="let client of clientList" [value]="client">
            {{client}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="col-md-12">
        <textarea matInput placeholder="{{ 'DR_BOOKING/text/obs' | translate }}" [(ngModel)]="dr.obs" name="dr.obs"></textarea>
      </mat-form-field>
      <!-- fim preenchimento campos -->

    </form>

    <div *ngIf="showSiteInfo" class="col-md-12 row">
      <div class="col-md-3">
        <p>Lat:</p>
        <p>{{siteSelected.lat}}</p>
      </div>
      <div class="col-md-3">
        <p>Lng:</p>
        <p>{{siteSelected.lng}}</p>
      </div>
      <div class="col-md-3">
        <p>Regional:</p>
        <p>{{siteSelected.regional}}</p>
      </div>
      <div class="col-md-3">
        <p>Cliente:</p>
        <p>{{siteSelected.operadora}}</p>
      </div>
      <div class="col-md-3">
        <p>Obs:</p>
        <p>{{siteSelected.obs}}</p>
      </div>
      <div class="col-md-3">
        <p>Operação:</p>
        <p>{{siteSelected.operation.join(", ")}}</p>
      </div>
      <div class="col-md-3">
        <p>Vistoria:</p>
        <p>{{siteSelected.vistoriadatahora |  date: "dd/MM/yy, h:mm a"}}</p>
      </div>
      <div class="col-md-3">
        <p>Site ID:</p>
        <p>{{siteSelected.code}}</p>
      </div>
      <div class="col-md-3">
        <p>LSP:</p>
        <p>{{siteSelected.shippingcompany ? siteSelected.shippingcompany.name : 'Sem LSP associada'}}</p>
      </div>
      <div class="col-md-3">
        <p>Laudo:</p>
        <p>
          <a [routerLink]="'/site-report/'+siteSelected.id">link</a>
        </p>
      </div>
    </div>
    <!-- fim informações do site -->

    <button mat-raised-button class="w-100 primary-button" [disabled]="!form.valid" (click)="createDRBooking()">{{'DR_BOOKING/btn/create-booking' | translate}}</button>
  </mat-card>
</div>
