import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { PriceMakerService } from '../../../providers/price-maker.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-price-maker-table',
  templateUrl: './price-maker-table.component.html',
  styleUrls: ['./price-maker-table.component.scss']
})

export class PriceMakerTableComponent implements OnInit {
  data: any[];
  columns = [
    {name: 'PRICE_MAKER_TABLE/table/id', value: 'id'},
    {name: 'PRICE_MAKER_TABLE/table/shippingCompany', value: 'shippingCompany'},
    {name: 'PRICE_MAKER_TABLE/table/service', value: 'service'},
    {name: 'PRICE_MAKER_TABLE/table/region', value: 'region'},
    {name: 'PRICE_MAKER_TABLE/table/vehicle', value: 'vehicle'},
    {name: 'PRICE_MAKER_TABLE/table/price', value: 'price'},
    {name: 'PRICE_MAKER_TABLE/table/absolute', value: 'absolute'},
    {name: 'PRICE_MAKER_TABLE/table/sapCode', value: 'sapCode'}
  ];

  constructor(public pmService: PriceMakerService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {
    this.data = [];
  }

  ngOnInit() {
    this.pmService.tableRead().subscribe((data) => {
      // pega as keys dos objetos retornados
      const keys = data.length ? Object.keys(data[0]) : [] ;
      // remove os valores null
      this.data = data.map( (el) => {
        for (let i = 0, l = keys.length; i < l; i++) {
          if ( el[keys[i]] === null ) {
            el[keys[i]] = '';
          }
        }
        return el;
      });

    });
  }

  addItem() {
    const dialogRef = this.dialog.open(NewPriceMakerTableDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result.what === 'insert' ) {
        this.pmService.tableCreate(result.data).subscribe((data) => {
          this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        });
      }
    });
  }

  onRowClick(row, that) {
    // that aponta para this, mas é necessário para garantir que não é o this do app-table
    that.openDialog(row);
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(EditPriceMakerTableDialogComponent, {
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if ( result.what === 'delete' ) {
        this.pmService.tableDelete(result.data.id).subscribe((data) => {
          this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', {duration: 4000});
        });
      } else if ( result.what === 'update' ) {
        this.pmService.tableUpdate(result.data).subscribe((data) => {
        });
      }
    });
  }

}

@Component({
  selector: 'app-dialog-edit-price-maker-table-dialog',
  templateUrl: 'dialog-edit-price-maker-table-dialog.html',
  styleUrls: ['./price-maker-table.component.scss']
})
export class EditPriceMakerTableDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditPriceMakerTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickRemove(): void {
    this.dialogRef.close({ what: 'delete', data: this.data });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'update', data: this.data });
  }
}

@Component({
  selector: 'app-dialog-new-price-maker-table-dialog',
  templateUrl: 'dialog-new-price-maker-table-dialog.html',
  styleUrls: ['./price-maker-table.component.scss']
})
export class NewPriceMakerTableDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NewPriceMakerTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  onClickBack(): void {
    this.dialogRef.close({ what: '' });
  }
  onClickSave(): void {
    this.dialogRef.close({ what: 'insert', data: this.data });
  }
}
