import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
var base_url = environment.apiUrl;
var PriceMakerService = /** @class */ (function () {
    function PriceMakerService(http) {
        this.http = http;
        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }
    PriceMakerService.prototype.getPrice = function (price) {
        // console.log(price);
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        // console.log(token);
        return this.http.post(base_url + 'PM/getPriceMaker', {
            price: price
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    PriceMakerService.prototype.getPriceTable = function (vehicle, distance, fromEstate, toEstate, subRegion, value, drAmount) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'PM/getPriceTable', {
            vehicle: vehicle,
            km: distance,
            fromEstate: fromEstate,
            toEstate: toEstate,
            subRegion: subRegion,
            value: value,
            drAmount: drAmount
        }, { headers: header }).map(function (response) {
            return response;
        });
    };
    PriceMakerService.prototype.tableRead = function () {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.get(base_url + 'PM/table/read', { headers: header }).map(function (response) {
            return response;
        });
    };
    PriceMakerService.prototype.tableDelete = function (id) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.delete(base_url + 'PM/table/delete/' + id, { headers: header }).map(function (response) {
            return response;
        });
    };
    PriceMakerService.prototype.tableUpdate = function (pm) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.put(base_url + 'PM/table/update', pm, { headers: header }).map(function (response) {
            return response;
        });
    };
    PriceMakerService.prototype.tableCreate = function (pm) {
        var token = JSON.parse(localStorage.getItem('currentUser')).token;
        var header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this.http.post(base_url + 'PM/table/create', pm, { headers: header }).map(function (response) {
            return response;
        });
    };
    return PriceMakerService;
}());
export { PriceMakerService };
