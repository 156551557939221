import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {DR} from '../../models/dr';
import {MatSnackBar} from '@angular/material';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DrService } from '../../providers/dr.service';
import { ERBService } from '../../providers/erb.service';
import {SiteService,
        ESTRUTURA,
        ACESSO} from '../../providers/site.service';
import { ClientUserService } from '../../providers/client-user.service';

declare var google: any;

@Component({
  selector: 'app-dr-booking',
  templateUrl: './dr-booking.component.html',
  styleUrls: ['./dr-booking.component.scss']
})
export class DrBookingComponent implements OnInit {
  public dr: DR = new DR();
  salesOrders;
  @ViewChild('addrSearch')
  public searchElementRef: ElementRef;
  public paste_input = '';
  showSiteInfo = false;
  siteSelected: any;
  site = {
    'color' : '',
    'tooltips': {
      'grey': 'site não existe',
      'yellow-1': 'site não aprovado',
      'yellow-2': 'site não liberado',
      'yellow-3': 'site não aprovado e não liberado',
      'green': 'site aprovado',
      'undefined': ''
    },
    'icons' : {
      'grey': 'fa-question-circle',
      'yellow-1': 'fa-exclamation-triangle',
      'yellow-2': 'fa-exclamation-triangle',
      'yellow-3': 'fa-exclamation-triangle',
      'green': 'fa-check',
      'undefined': ''
    },
    'classes' : {
      'grey' : 'icon-grey',
      'yellow-1': 'icon-yellow',
      'yellow-2': 'icon-yellow',
      'yellow-3': 'icon-yellow',
      'green': 'icon-green',
      'undefined': ''
    }
  };
  clientList: string[] = [];

  public map_show = false;
  public map_data = null;
  public red_marker = {
    url: './assets/images/pin_red.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };
  public blue_marker = {
    url: './assets/images/pin.png',
    scaledSize: {
      width: 35,
      height: 40
    }
  };

  constructor(
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    private clientUserService: ClientUserService,
    private drService: DrService,
    private erbService: ERBService,
    private siteService: SiteService
  ) {
    this.clientUserService.getClients().subscribe((clients) => {
      this.clientList = clients.map(e => e.clientGroup);
    });
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
    });
  }

  convertAddressToLatLng() {
    const addr = (document.getElementById('deliveryAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( {'address': addr}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.dr.lat = +lat;
        this.dr.lng = +lng;
      } else {
        this.snackBar.open('Não foi possível converter o endereço em latlng', 'Ok', {duration: 2000});
      }
    });
  }

  convertLatLngToAddress() {
    if (this.dr.lat === null || this.dr.lng === null) {
      return;
    }
    const lat = (document.getElementById('latitudeAddress') as HTMLInputElement).value;
    const lng = (document.getElementById('longitudeAddress') as HTMLInputElement).value;
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({'latLng': location}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const addr = results[0].formatted_address;
        this.dr.deliveryAddress = addr;
      } else {
        this.snackBar.open('Não foi possível converter as coordenadas em um endereço', 'Ok', {duration: 2000});
      }
    });
  }

  createDRBooking() {
    this.dr.deliveryAddress = ((document.getElementById('deliveryAddress') as HTMLInputElement).value);
    this.dr.salesOrders = this.salesOrders
                              .replace(/ /g, ',')   // troca espaços por vírgulas
                              .split(',')          // quebra em array nas vírgulas
                              .filter(e => e.length); // remove strings vazias
    this.drService.createBooking(this.dr).subscribe((response) => {
      this.snackBar.open('DR Booking criada com sucesso', 'Ok', {duration: 2000});
      // reseta campos
      this.dr = new DR();
      this.salesOrders = '';
    }, (err) => {
      this.snackBar.open('Erro ao criar DR Booking', 'Ok', {duration: 2000});
    });
  }

  fillFromClipboard(e) {
    const DMStoDD = (str: string): number => {
      const parts = str.split(/[°'"]/).map((part) => {
        return +part.replace(',', '.');
      });

      const decimal = (parts[0] < 0 ? -1 : 1) * (parts[1] + (parts[2] / 60)) / 60;
      return parts[0] + decimal;
    };

    const input = e.clipboardData.getData('text');
    const splitted = input.split('\t');
    this.salesOrders = splitted[1];
    this.dr.deliveryAddress = splitted[6];
    this.dr.lat = +DMStoDD(splitted[25]);
    this.dr.lng = +DMStoDD(splitted[26]);
    this.dr.receiverName = splitted[9];
    this.dr.receiverPhone = parseInt(splitted[10], 10);
    this.dr.dateScheduling = new Date(splitted[3]);
    this.dr.site = splitted[4];
    this.dr.infraType = splitted[11];
    this.dr.unificationId = splitted[0];
    this.dr.obs = splitted[12];

    setTimeout(() => {
      this.paste_input = '';
    }, 200);
  }

  toggleMap() {
    if (this.map_show) {
      this.map_show = false;
    } else {
      if (this.dr.lat && this.dr.lng) {
        this.erbService.getNearERB(this.dr.lat, this.dr.lng).subscribe((data) => {
          this.dr.lat = +this.dr.lat;
          this.dr.lng = +this.dr.lng;

          this.map_data = data;
          this.map_show = true;
        });
      }
    }
  }

  checkSite(): void {
    this.showSiteInfo = false;
    if ( this.dr.site === '' ) {
      this.site.color = '';
      return;
    }

    this.siteService.getSiteFromCode(this.dr.site).subscribe(
      (data) => {
        if ( !data ) {
          this.site.color = 'grey';
          return;
        }

        this.siteSelected = data;
        this.siteSelected.operation = [];
        if (this.siteSelected.form_6_1 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Grua');
        }
        if (this.siteSelected.form_6_2 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Correia');
        }
        if (this.siteSelected.form_6_3 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Veiculo Esp.');
        }
        if (this.siteSelected.form_6_4 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Manual');
        }
        if (this.siteSelected.form_6_4 === ESTRUTURA.EX) {
          this.siteSelected.operation.push('Guindaste');
        }

        if (this.siteSelected.operation.length > 0) {
          this.dr.thirdPartInvoice = true;
         // console.log(this.dr.thirdPartInvoice);
        }

        if ( !data.aprovado ) {
          this.site.color = 'yellow-1';
        }
        if ( data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-2';
        }
        if ( !data.aprovado && data.acesso !== ACESSO.LIBERADO ) {
          this.site.color = 'yellow-3';
        }

        if ( data.aprovado && data.acesso === ACESSO.LIBERADO ) {
          this.showSiteInfo = true;
          this.site.color = 'green';
        }
      },
      (error) => {
        this.site.color = 'grey';
      }
    );
  }
}
