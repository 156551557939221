var User = /** @class */ (function () {
    function User() {
        this.id = null;
        this.name = '';
        this.password = '';
        this.email = '';
        this.role = '';
    }
    return User;
}());
export { User };
