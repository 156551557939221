import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from '../../providers/role-guard.service';

import { LogisticOrderComponent } from './logistic-order.component';

const routes: Routes = [
  { path: 'logistic-order/:id', component: LogisticOrderComponent, canActivate: [RoleGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogisticRoutingModule { }
