import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MdrService } from '../../providers/mdr.service';
var MdrManagementComponent = /** @class */ (function () {
    function MdrManagementComponent(dialog, mdrService, translateService, router) {
        this.dialog = dialog;
        this.mdrService = mdrService;
        this.translateService = translateService;
        this.router = router;
        this.columns = [
            { value: 'observation', name: 'MDR_MANAGEMENT/table/observation' },
            { value: 'status', name: 'MDR_MANAGEMENT/table/status' },
            { value: 'createBy', name: 'MDR_MANAGEMENT/table/userName' },
            { value: 'createdAt', name: 'MDR_MANAGEMENT/table/date' }
        ];
        this.mdrStatusTranslated = {
            'MDR em validação': 'LOGISTIC-ORDER/mdr-status/validation',
            'MDR em criação': 'LOGISTIC-ORDER/mdr-status/creation',
            'MDR devolvida por LSP': 'LOGISTIC-ORDER/mdr-status/returned-lsp',
            'MDR aguardando LSP': 'LOGISTIC-ORDER/mdr-status/waiting-lsp',
            'MDR aprovada LSP': 'LOGISTIC-ORDER/mdr-status/approved-lsp',
            'Motorista e veiculo definidos': 'LOGISTIC-ORDER/mdr-status/vehicle-driver-defined',
            'Veículo carregado': 'LOGISTIC-ORDER/mdr-status/vehicle-loaded',
            'Carga entregue com sucesso': 'LOGISTIC-ORDER/mdr-status/cargo-delivered',
            'Carga retornada total': 'LOGISTIC-ORDER/mdr-status/cargo-returned',
            'Entrega de carga certificada pelo LSP': 'LOGISTIC-ORDER/mdr-status/certified-delivery',
            'MDR paga / concluída': 'LOGISTIC-ORDER/mdr-status/mdr-paid',
        };
        this.data = [];
    }
    MdrManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrService.getAllMDR().subscribe(function (data) {
            _this.data = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].status === 'MDR em validação' || data[i].status === 'MDR em criação') {
                    data[i].observation = (data[i].pickupID ? String(data[i].pickupID) : ('EDB-TP' + ('0000000' + data[i].id).slice(-6)));
                    _this.data.push(data[i]);
                }
            }
            _this.data.forEach(function (elem) {
                if (_this.mdrStatusTranslated[elem.status]) {
                    elem.status = _this.translateService.instant(_this.mdrStatusTranslated[elem.status]);
                }
            });
        });
    };
    MdrManagementComponent.prototype.goTo = function (row, that) {
        that.router.navigate(['logistic-order', row.id]);
    };
    return MdrManagementComponent;
}());
export { MdrManagementComponent };
