export class Pin {
  public lat: number;
  public lng: number;
  public label: string;
  public title: string;

  constructor() {
    this.lat = null;
    this.lng = null;
    this.label = null;
    this.title = null;
  }
}

export class Legs {
  public id: number;
  public pointInit: Pin;
  public pointEnd: Pin;
  public type: string;
  public createdAt: Date;
  public distance: number;
  public ETA: number;
  public status: number;
  public order: number;

  constructor() {
    this.id = null;
    this.pointInit = new Pin();
    this.pointEnd = new Pin();
    this.type = null;
    this.createdAt = null;
    this.distance = null;
    this.ETA = null;
    this.status = 1;
    this.order = null;
  }
}
