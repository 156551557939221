import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddressPipe } from './parse-address/address.pipe';
import { MdrIdPipe } from './mdr-id/mdr.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AddressPipe,
    MdrIdPipe,
  ],
  exports: [
    AddressPipe,
    MdrIdPipe,
  ],
  providers: [
    AddressPipe,
    MdrIdPipe,
  ]
})
export class PipesModule { }
