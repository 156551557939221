import { OnInit } from '@angular/core';
import { PriceMakerService } from '../../../providers/price-maker.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
var PriceMakerTableComponent = /** @class */ (function () {
    function PriceMakerTableComponent(pmService, dialog, snackBar) {
        this.pmService = pmService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.columns = [
            { name: 'PRICE_MAKER_TABLE/table/id', value: 'id' },
            { name: 'PRICE_MAKER_TABLE/table/shippingCompany', value: 'shippingCompany' },
            { name: 'PRICE_MAKER_TABLE/table/service', value: 'service' },
            { name: 'PRICE_MAKER_TABLE/table/region', value: 'region' },
            { name: 'PRICE_MAKER_TABLE/table/vehicle', value: 'vehicle' },
            { name: 'PRICE_MAKER_TABLE/table/price', value: 'price' },
            { name: 'PRICE_MAKER_TABLE/table/absolute', value: 'absolute' },
            { name: 'PRICE_MAKER_TABLE/table/sapCode', value: 'sapCode' }
        ];
        this.data = [];
    }
    PriceMakerTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pmService.tableRead().subscribe(function (data) {
            // pega as keys dos objetos retornados
            var keys = data.length ? Object.keys(data[0]) : [];
            // remove os valores null
            _this.data = data.map(function (el) {
                for (var i = 0, l = keys.length; i < l; i++) {
                    if (el[keys[i]] === null) {
                        el[keys[i]] = '';
                    }
                }
                return el;
            });
        });
    };
    PriceMakerTableComponent.prototype.addItem = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NewPriceMakerTableDialogComponent, {
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result.what === 'insert') {
                _this.pmService.tableCreate(result.data).subscribe(function (data) {
                    _this.snackBar.open('Item inserido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                });
            }
        });
    };
    PriceMakerTableComponent.prototype.onRowClick = function (row, that) {
        // that aponta para this, mas é necessário para garantir que não é o this do app-table
        that.openDialog(row);
    };
    PriceMakerTableComponent.prototype.openDialog = function (row) {
        var _this = this;
        var dialogRef = this.dialog.open(EditPriceMakerTableDialogComponent, {
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result.what === 'delete') {
                _this.pmService.tableDelete(result.data.id).subscribe(function (data) {
                    _this.snackBar.open('Item removido com sucesso, recarregue a página para atualizar a tabela', 'Ok', { duration: 4000 });
                });
            }
            else if (result.what === 'update') {
                _this.pmService.tableUpdate(result.data).subscribe(function (data) {
                });
            }
        });
    };
    return PriceMakerTableComponent;
}());
export { PriceMakerTableComponent };
var EditPriceMakerTableDialogComponent = /** @class */ (function () {
    function EditPriceMakerTableDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    EditPriceMakerTableDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    EditPriceMakerTableDialogComponent.prototype.onClickRemove = function () {
        this.dialogRef.close({ what: 'delete', data: this.data });
    };
    EditPriceMakerTableDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'update', data: this.data });
    };
    return EditPriceMakerTableDialogComponent;
}());
export { EditPriceMakerTableDialogComponent };
var NewPriceMakerTableDialogComponent = /** @class */ (function () {
    function NewPriceMakerTableDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    NewPriceMakerTableDialogComponent.prototype.onClickBack = function () {
        this.dialogRef.close({ what: '' });
    };
    NewPriceMakerTableDialogComponent.prototype.onClickSave = function () {
        this.dialogRef.close({ what: 'insert', data: this.data });
    };
    return NewPriceMakerTableDialogComponent;
}());
export { NewPriceMakerTableDialogComponent };
