import { OnInit } from '@angular/core';
import { MDR } from '../../../models/mdr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, TooltipPosition } from '@angular/material';
import { MdrObservable } from '../../../services/mdr.service';
import { PriceObservable } from '../../../services/price.service';
import { MdrService } from '../../../providers/mdr.service';
import { TranslateService } from '@ngx-translate/core';
import { Driver } from '../../../models/driver';
import { DrObservable } from '../../../services/dr.service';
import { SiteService } from '../../../providers/site.service';
var TransportComponent = /** @class */ (function () {
    function TransportComponent(translate, router, priceObservable, mdrService, mdrObservable, drObservable, aRoute, siteService, dialog, snackBar) {
        var _this = this;
        this.translate = translate;
        this.router = router;
        this.priceObservable = priceObservable;
        this.mdrService = mdrService;
        this.mdrObservable = mdrObservable;
        this.drObservable = drObservable;
        this.aRoute = aRoute;
        this.siteService = siteService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.mdr = new MDR;
        this.statusContainer = false;
        this.veiculo = [
            { id: '1', name: 'Pequeño - Hasta 1.500 Kg' },
            { id: '2', name: 'Mediano - Hasta 3.500 Kg' },
            { id: '3', name: 'Grande - Hasta 6.000 Kg' },
            { id: '4', name: 'Torton - Hasta 17.000 Kg' },
            { id: '5', name: 'Trailer - Hasta 20.000 Kg' },
            { id: '6', name: 'Camión Pluma' },
            { id: '7', name: 'Camioneta 4x4' },
            { id: '8', name: 'Entrega en Bodega' },
            { id: '9', name: 'Courier' }
        ];
        this.transportadora = [];
        this.drivers = [];
        this.leader = null;
        this.showVehicleData = false;
        this.positionOptions = 'after';
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        this.user = this.currentUser.user.id;
        this.siteService.getShippingCompanys().subscribe(function (data) {
            _this.transportadora = data;
        });
    }
    TransportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            // console.log(this.mdr.pickupDate);
            // console.log(this.mdr);
            _this.mdrService.getVehicleData(_this.mdr.id).subscribe(function (data) {
                // console.log(data);
                _this.tooltip = data;
                _this.tooltip.maxH = (_this.tooltip.maxH / 1000).toFixed(3);
                _this.tooltip.maxL = (_this.tooltip.maxL / 1000).toFixed(3);
                _this.tooltip.maxW = (_this.tooltip.maxW / 1000).toFixed(3);
                _this.tooltip.wei = (_this.tooltip.wei / 100).toFixed(2);
                _this.tooltip.vol = (_this.tooltip.vol / 100).toFixed(2);
                // this.tooltip = '\n\nAltura: ' + data.maxH/1000 m + '\nLargura: ' + data.maxW + '\nComprimento: ' + data.maxL + '\nPeso: ' + data.wei + '\nVol: ' + data.vol;
                // console.log(this.tooltip);
            });
            var date = new Date(_this.mdr.pickupDate);
            // console.log(this.mdr.pickupDate);
            if (_this.mdr.pickupDate === null) {
                _this.time = '00:00';
            }
            else {
                _this.time = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
            }
            _this.getDriver();
        });
        // this.drObservable.currentDR.subscribe(dr => {
        //   this.dr = dr;
        //   //console.log(this.dr);
        //   this.dr.forEach (el => {
        //     if(el.obs !== null){
        //       this.mdr.observation += el.obs;
        //     }
        //   })
        // });
    };
    TransportComponent.prototype.updateShippingCompany = function (elem) {
        var _this = this;
        // console.log(elem);
        this.mdr.shippingCompany = elem.name;
        this.mdrService.getAllPrice(this.mdr).subscribe(function (resp) {
            _this.priceObservable.changePrice(resp);
        });
    };
    TransportComponent.prototype.changeTime = function () {
        var time = this.time.split(':');
        // console.log(time);
        this.mdr.pickupDate = new Date(this.mdr.pickupDate);
        this.mdr.pickupDate.setHours(time[0]);
        this.mdr.pickupDate.setMinutes((time[1]));
    };
    TransportComponent.prototype.updateVehicle = function (elem) {
        var _this = this;
        // console.log(elem);
        this.mdr.vehicle = elem.name;
        this.mdrService.getAllPrice(this.mdr).subscribe(function (resp) {
            // console.log(resp);
            _this.priceObservable.changePrice(resp);
        });
    };
    TransportComponent.prototype.goToChat = function () {
        this.router.navigate(['chat/' + this.mdr.id + '-driver']);
    };
    TransportComponent.prototype.getVehicleSizes = function () {
        // End Point para pega valores que seta tamanho do veículo
    };
    TransportComponent.prototype.getDriver = function () {
        var _this = this;
        this.mdrService.getDriverByMdrIdAll(this.mdr.id).subscribe(function (response) {
            // console.log(response);
            _this.drivers = response;
            var index = _this.drivers.map(function (driver, i) { return [i, driver]; }).filter(function (driver) { return driver[1]['leader'] === true; });
            if (index.length) {
                _this.leader = index[0][0];
            }
        });
    };
    TransportComponent.prototype.saveDriver = function () {
        var _this = this;
        // se algum dos motoristas estiver incompleto
        if (this.drivers.some(function (el, i, arr) {
            if (el.name === null
                || el.phoneNumber === null
                || el.plate === null) {
                return true;
            }
        })) {
            this.snackBar.open('Favor preencher os detalhes do motorista', 'Ok', { duration: 4000 });
            return;
        }
        this.drivers = this.drivers.map(function (driver) { driver.leader = false; return driver; });
        var driversCompare = this.drivers.filter(function (driver) { return driver.status === true; }).length;
        if (driversCompare >= 2) {
            this.drivers[this.leader].leader = true;
        }
        // console.log(this.drivers);
        this.mdrService.updateDriver(this.drivers, this.mdr.id).subscribe(function (response) {
            _this.snackBar.open('Alterações Salvas ', 'Ok', { duration: 4000 });
            _this.getDriver();
        });
    };
    TransportComponent.prototype.saveConveyor = function () {
        // console.log(this.mdr);
        this.mdrService.update(this.mdr, this.user).subscribe(function (response) {
            // console.log(response);
        });
    };
    TransportComponent.prototype.addDriver = function () {
        var newDriver = new Driver();
        newDriver.phoneNumber = this.mdr.id + '' + this.drivers.length;
        this.drivers.push(newDriver);
        var index = this.drivers.map(function (driver, i) { return [i, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
        if (index.length === 2) {
            this.leader = index[0][0];
        }
    };
    TransportComponent.prototype.removerDriver = function (i) {
        this.drivers[i].status = false;
        if (i === this.leader) {
            var index = this.drivers.map(function (driver, idx) { return [idx, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
            // console.log(index);
            if (index.length === 1) {
                this.leader = null;
            }
            else {
                this.leader = index[0][0];
            }
        }
        else {
            var index = this.drivers.map(function (driver, idx) { return [idx, driver]; }).filter(function (driver) { return driver[1]['status'] !== false; });
            if (index.length === 1) {
                this.leader = null;
            }
        }
    };
    return TransportComponent;
}());
export { TransportComponent };
