<div class="mx-5 my-4">
  <p class="mat-h1">{{ 'SITE_INVESTIGATION_WITH_TOKEN' | translate }}</p>
  <p class="mx-4">{{ 'SITE_INVESTIGATION_WITH_TOKEN/txt' | translate }}</p>
</div>


<div *ngIf="!site" style="width: 500px; height: 100%; display: block; margin-left: auto; margin-right: auto;">
  <img style="width: 300px; display: block; margin-top: 10%; margin-left: auto; margin-right: auto;" src="../../../../assets/images/happy_face.png">
  <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> {{ 'SITE_INVESTIGATION_WITH_TOKEN/empty' | translate }}</p>
</div>

<div id="printable" *ngIf="site">
  <div class="colored-background pt-5">
    <mat-card class="mx-5 py-3">
      <mat-card-content>
        <span (click)="onClickPrint()">Print</span>
        <p *ngIf="site.anexo">
          {{ 'SITE_INVESTIGATION/table/attachment' | translate }}: <a [href]="site.anexo.path">{{ site.anexo.fileOriginalName }}</a>
        </p>

        <form #form="ngForm" class="flex-wrap d-flex">
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/visitadatahora/data' | translate }}</mat-label>
            <input matInput disabled
              name="visitadata"
              [(ngModel)]="site.visitadata"
              [owlDateTimeTrigger]="visitadata"
              [owlDateTime]="visitadata">
            <owl-date-time #visitadata></owl-date-time>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/visitadatahora/hora' | translate }}</mat-label>
            <input matInput disabled
              name="visitahora"
              [owlDateTimeTrigger]="visitahora"
              [owlDateTime]="visitahora"
              [(ngModel)]="site.visitahora">
            <owl-date-time #visitahora pickerType="timer"></owl-date-time>
          </mat-form-field>

          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriadatahora/data' | translate }}</mat-label>
            <input matInput disabled
              name="vistoriadata"
              [owlDateTimeTrigger]="vistoriadata"
              [owlDateTime]="vistoriadata"
              [(ngModel)]="site.vistoriadata">
            <owl-date-time #vistoriadata></owl-date-time>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriadatahora/hora' | translate }}</mat-label>
            <input matInput disabled
              name="vistoriahora"
              [owlDateTimeTrigger]="vistoriahora"
              [owlDateTime]="vistoriahora"
              [(ngModel)]="site.vistoriahora">
            <owl-date-time #vistoriahora pickerType="timer"></owl-date-time>
          </mat-form-field>

          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/acesso' | translate }}</mat-label>
            <mat-select disabled [(ngModel)]="site.acesso" name="acesso">
              <mat-option *ngFor="let item of enums.acesso" [value]="item.value">
                {{item.show | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/obs' | translate }}</mat-label>
            <textarea disabled matInput [(ngModel)]="site.obs" name="obs"></textarea>
          </mat-form-field>

          <mat-form-field class="col-md-4">
            <mat-label>{{ 'SITE_INVESTIGATION/table/code' | translate }}</mat-label>
            <input disabled matInput [(ngModel)]="site.code" name="code">
          </mat-form-field>
          <mat-form-field class="col-md-4">
            <mat-label>{{ 'SITE_INVESTIGATION/table/operadora' | translate }}</mat-label>
            <input disabled matInput [(ngModel)]="site.operadora" name="operadora">
          </mat-form-field>
          <mat-form-field class="col-md-4">
            <mat-label>{{ 'SITE_INVESTIGATION/table/prolog' | translate }}</mat-label>
            <input disabled matInput [(ngModel)]="site.prolog" name="prolog">
          </mat-form-field>

          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/address' | translate }}</mat-label>
            <input disabled matInput [(ngModel)]="site.address" name="address">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/lat' | translate }}</mat-label>
            <input disabled matInput type="number" [(ngModel)]="site.lat" name="lat">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/lng' | translate }}</mat-label>
            <input disabled matInput type="number" [(ngModel)]="site.lng" name="lng">
          </mat-form-field>

          <button class="full-width primary-button" mat-raised-button (click)="getlocation()">{{ 'SITE_INVESTIGATION/getlocation' | translate }}</button>

          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/municipio' | translate }}</mat-label>
            <input disabled matInput type="number" [(ngModel)]="site.municipio" name="municipio">
          </mat-form-field>

          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/rsu' | translate }}</mat-label>
            <input disabled matInput [(ngModel)]="site.rsu" name="rsu">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/site' | translate }}</mat-label>
            <input disabled matInput type="number" [(ngModel)]="site.site" name="site">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/regional' | translate }}</mat-label>
            <input required matInput [(ngModel)]="site.regional" name="regional">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/visitanumero' | translate }}</mat-label>
            <input disabled matInput type="number" [(ngModel)]="site.visitanumero" name="visitanumero">
          </mat-form-field>

          <div>
            {{ 'SITE_INVESTIGATION/table/tipo' | translate }}:
            <p *ngFor="let item of enums.tipo">
              <mat-checkbox
                [checked]="site.tipo[item.value]"
                (change)="site.tipo[item.value] = !site.tipo[item.value]"
                name="item.show">
                {{item.show | translate}}</mat-checkbox>
            </p>
          </div>

          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/infra' | translate }}</mat-label>
            <mat-select required [(ngModel)]="site.infra" name="infra">
              <mat-option *ngFor="let item of enums.infra" [value]="item.value">
                {{item.show | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_nome' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.vistoriador_nome" name="vistoriador_nome" required>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_rg' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.vistoriador_rg" name="vistoriador_rg">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/vistoriador_cpf' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.vistoriador_cpf" name="vistoriador_cpf">
          </mat-form-field>
          <mat-form-field class="col-md-12">
            <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_nome' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.responsavel_nome" name="responsavel_nome" required>
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_rg' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.responsavel_rg" name="responsavel_rg">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_cpf' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.responsavel_cpf" name="responsavel_cpf">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_contato' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.responsavel_contato" name="responsavel_contato">
          </mat-form-field>
          <mat-form-field class="col-md-6">
            <mat-label>{{ 'SITE_INVESTIGATION/table/responsavel_funcao' | translate }}</mat-label>
            <input matInput [(ngModel)]="site.responsavel_funcao" name="responsavel_funcao">
          </mat-form-field>

          <mat-divider class="col-md-12"></mat-divider>

          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_1' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_1_1','form_1_2','form_1_3','form_1_4','form_1_5','form_1_6','form_1_7','form_1_8','form_1_9']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_2' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_2_1','form_2_2','form_2_3','form_2_4','form_2_5','form_2_6','form_2_7','form_2_8','form_2_9']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_3' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_3_1','form_3_2','form_3_3']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_4' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_4_1','form_4_2','form_4_3']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_5' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_5_1','form_5_2','form_5_3']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_6' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_6_1','form_6_2','form_6_3','form_6_4','form_6_5']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

          <mat-divider class="col-md-12"></mat-divider>
          <h5 class="col-md-12">{{ 'SITE_INVESTIGATION/table/form_7' | translate }}</h5>

          <div class="col-md-12 p-0"
            *ngFor="let form of ['form_7_1','form_7_2','form_7_3']">
            <p>{{ 'SITE_INVESTIGATION/table/'+form | translate }}</p>
            <mat-form-field class="col-md-12">
              <mat-label>{{ 'REQUIRED' | translate }}</mat-label>
              <mat-select required [(ngModel)]="site[form]" [name]="form">
                <mat-option *ngFor="let item of enums.estrutura" [value]="item.value">
                  {{item.show | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <mat-label>{{ 'SITE_INVESTIGATION/table/'+form+'_obs' | translate }}</mat-label>
              <input matInput [(ngModel)]="site[form+'_obs']" [name]="form+'_obs'">
            </mat-form-field>
            <div class="col-md-12"
              *ngIf="site[form] === SITE_ESTRUTURA.EX">
              <span *ngIf="site[form+'_anexo'] && site[form+'_anexo'].path">
                <app-image-lightbox [images]="[{id:form+'_anexo',url:site[form+'_anexo'].path}]"></app-image-lightbox>
              </span>
              <app-form-upload [name]="form+'_anexo'" (setFile)="site[form+'_anexo'] = $event"></app-form-upload>
            </div>
            <mat-divider [inset]="true"></mat-divider>
          </div>

        </form>

      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button class="primary-button" [disabled]="!form.valid" (click)="save()">{{ 'SITE_INVESTIGATION_WITH_TOKEN/dialog/btn/save' | translate }}</button>
      </mat-card-actions>

    </mat-card>
  </div>
</div>
