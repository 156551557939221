import {Component, OnInit, ViewChild, Input, AfterViewInit, AfterContentInit, OnChanges, ElementRef} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MdrService} from '../../providers/mdr.service';
import {DrService} from '../../providers/dr.service';
import {MDR} from '../../models/mdr';
import {DR} from '../../models/dr';
import {ActivatedRoute, Router} from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';

@Component({
  selector: 'app-mdr-map',
  templateUrl: './mdr-map.component.html',
  styleUrls: ['./mdr-map.component.scss']
})
export class MdrMapComponent implements OnInit {
  zoom = 5;
  lat = 0;
  lng = 0;
  markers = {
    'red': './assets/images/red_truck.png',
    'yellow': './assets/images/blue_truck.png',
    'green': './assets/images/green_truck.png',
    'undefined': './assets/images/white_truck.png'
  };
  list = [];

  // configs das buscas
  search_toggle = true;
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  // valores das buscas
  searchDates = { 'start': null, 'end': null };
  searchChips: any[] = [];
  searchFields = {
    chips: ['pickupID', 'site'],
    dates: ['createdAt']
  };

  constructor(
    public snackBar: MatSnackBar,
    public mdrService: MdrService,
    public drService: DrService,
    private router: Router,
  ) {
    this.getData();
  }

  ngOnInit() {
  }

  statusColor(when) {
    let color = 'green';
    const hora = 1000 * 60 * 60;
    const now = Date.now();
    const d = new Date(when);
    if ( now - d.getTime() > 1 * hora ) {
      // se o caminhao nao é visto a mais de 1h
      color = 'red';
    } else if ( now - d.getTime() > 0.5 * hora ) {
      // se o caminhao nao é visto a mais de 30min
      color = (color === 'red') ? color : 'yellow';
    }
    return color;
  }

  getData() {
    // pega todas as DRs
    this.drService.getAll().subscribe((responseAll) => {
      // pega as DRs que tem status InTransit
      const drList = responseAll.filter(e => e.status === 'InTransit');
      // passa os idMDRs pra pegar as ultimas posicoes
      this.mdrService.getLastPositions(drList.map(e => e.idMDR)).subscribe((response) => {
        for (let i = 0, len = response.length ; i < len ; i++) {
          const dr = drList.find(e => e.idMDR === response[i].mdrId);
          this.list.push({
            lat: response[i].lat,
            lng: response[i].lng,
            icon: {
              url: this.markers[this.statusColor(response[i].createdAt)],
              scaledSize: {
                width: 40,
                height: 40
              }
            },
            mdrId: dr.idMDR,
            pickupID: dr['mdr'].pickupID,
            site: dr.site,
            createdAt: response[i].createdAt,
            show: true
          });
        }
        this.centerMap();
        if (this.list.length === 0) {
          this.snackBar.open('Nenhuma posição disponível', 'Ok', {duration: 2000});
        }
      });
    });
  }

  showMDR(point) {
    this.router.navigate(['logistic-order/' + point.mdrId]);
  }

  centerMap() {
    const lats = this.list.map(e => e.lat);
    const lngs = this.list.map(e => e.lng);
    // calcula o centro do mapa
    const latSUM = lats.reduce(function(a, b) { return a + b; }, 0);
    this.lat = latSUM / this.list.length;
    const lngSUM = lngs.reduce(function(a, b) { return a + b; }, 0);
    this.lng = lngSUM / this.list.length;
  }

  /****
   * funções relativas a busca
   */

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.searchChips.push({'name': value.trim()});
    }
    if (input) {
      input.value = '';
    }
    this.applyFilter();
  }

  remove(chip): void {
    const index = this.searchChips.indexOf(chip);
    if (index >= 0) {
      this.searchChips.splice(index, 1);
    }
    this.applyFilter();
  }

  dateChanged() {
    this.applyFilter();
  }

  applyFilter() {
    const dates = this.searchDates;

    this.list.forEach( (item) => {
      item.show = true;
      if ( this.searchChips.length === 0 && dates.start === null && dates.end === null ) {
        // busca vazia
        return;
      }

      // assume que pode mostrar
      let displayFromDate = true;
      const d = new Date(item[this.searchFields.dates[0]]); // TODO mudar pra poder receber um array de dates
      // se estiver fora do range, nao mostra
      if ( dates.start && d.getTime() < dates.start.getTime() ) {
        displayFromDate = false;
      }
      if ( dates.end && dates.end.getTime() <= d.getTime() ) {
        displayFromDate = false;
      }

      const checkChip = (chip) => { // retorna true, se houver match com algum chip
        return this.searchFields.chips.some( (key) => {
          if (key in item && item[key] !== null) {
            // se o campo existe
            if (typeof item[key] === 'string' &&
               item[key].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
              // se o tipo é string e existe uma match
              return true;
            } else if (typeof item[key] === 'number' &&
                      item[key] === parseInt(chip, 10)) {
              // se o tipo é number e existe uma match
              return true;
            }
          }
          return false;
        });
      };
      // dependendo se deve haver match para todos os chips, ou para algum chip
      const displayFromChips = this.search_toggle ?
        this.searchChips.map( el => el.name ).some(checkChip) :
        this.searchChips.map( el => el.name ).every(checkChip);

      // se puder mostrar pelo chip ou pelas datas
      item.show = displayFromChips && displayFromDate;
    });

  }


}
